import './Input.scss';

import classNames from 'classnames';
import React from 'react';

const Input = React.forwardRef(
  (
    {className, hasMultipleValues = false, isValid = true, disabled, value, ...rest}: InputProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <input
        ref={ref}
        className={classNames(
          'Input',
          {
            'is-valid': isValid,
            'has-multiple-value': hasMultipleValues,
            disabled,
          },
          className
        )}
        disabled={disabled}
        placeholder={hasMultipleValues ? 'Multiple values' : rest.placeholder}
        // This leaves the field empty, allowing to start typing immediately
        value={hasMultipleValues ? '' : value}
        {...rest}
      />
    );
  }
);

Input.displayName = 'Input';
export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  hasMultipleValues?: boolean;
  isValid?: boolean;
};

export default Input;
