import './ChartLegendOthersItem.scss';

import {Range} from 'immutable';
import React, {useRef, useState} from 'react';
import {Overlay, Popover} from 'react-bootstrap';

import {defaultOverlayProps} from 'toolkit/utils/react-bootstrap';

import ChartLegendItem from './ChartLegendItem';
import {OTHERS_ITEM_COLOR} from './utils';

const ChartLegendOthersItem: React.FC<ChartLegendItemProps> = props => {
  const {getLegendItem, othersCount, visibleCount} = props;

  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const othersItem = useRef<HTMLDivElement>(null);

  return (
    <ChartLegendItem
      ref={othersItem}
      color={OTHERS_ITEM_COLOR}
      isDatasetHidden={false}
      primaryLabel={`+${othersCount} More`}
      isToggleEnabled
      onToggle={() => setPopoverOpen(true)}
    >
      <Overlay
        {...defaultOverlayProps}
        placement="bottom"
        show={isPopoverOpen}
        target={othersItem.current}
        flip
        onHide={() => setPopoverOpen(false)}
      >
        <Popover id="series-data-popover">
          <div className="chart-legend-others-popover-content">
            {Range(visibleCount, visibleCount + othersCount).map(index =>
              getLegendItem(index, {style: undefined})
            )}
          </div>
        </Popover>
      </Overlay>
    </ChartLegendItem>
  );
};
type ChartLegendItemProps = React.HTMLAttributes<HTMLDivElement> & {
  getLegendItem: (datasetIndex: number, props: Partial<ChartLegendItemProps>) => React.ReactNode;
  othersCount: number;
  visibleCount: number;
};
ChartLegendOthersItem.displayName = 'ChartLegendOthersItem';
export default ChartLegendOthersItem;
