import React from 'react';

import {Status} from 'utils/status';

import Centered from './Centered';
import ErrorPlaceholder from './ErrorPlaceholder';
import Loader, {LoaderProps} from './Loader';

const CenteredLoader: React.FunctionComponent<LoaderProps> = props => {
  const {children, status, ...rest} = props;
  switch (status) {
    case Status.succeeded:
      return <>{children}</>;
    case Status.failed:
      return (
        <Centered>
          <ErrorPlaceholder />
        </Centered>
      );
    case Status.unstarted:
    case Status.inProgress:
    default:
      return (
        <Centered>
          <Loader {...rest} />
        </Centered>
      );
  }
};

CenteredLoader.displayName = 'CenteredLoader';
export default CenteredLoader;
