import './OptionChooser.scss';
import 'dragula/dist/dragula.min.css';

import classNames from 'classnames';
import {Set} from 'immutable';
import React, {useCallback} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {Placement} from 'react-bootstrap/Overlay';

import {IconSpec} from 'toolkit/icons/types';
import {swallowEvents} from 'toolkit/utils/events';
import {defaultOverlayTriggerTooltipProps} from 'toolkit/utils/react-bootstrap';
import {isNonNullish, noop} from 'utils/functions';

import Icon from './Icon';

export interface Option<T> {
  readonly icon?: IconSpec;
  readonly label: string;
  readonly tooltip?: string;
  readonly value: T;
}

const OptionButton: React.FC<OptionButtonProps<any>> = ({
  className,
  isEnabled,
  isSelected,
  areTooltipsEnabled,
  onClick,
  option,
  tooltipPlacement,
  onlyIcons,
}) => {
  const button = (
    <div
      className={classNames('OptionButton', className, {
        disabled: !isEnabled,
        'selected-button': isSelected,
        'unselected-button': !isSelected,
      })}
      onClick={onClick}
    >
      <Icon icon={option.icon} label={option.label} />
      {!onlyIcons && (
        <div className="button-text-content">
          <div className="button-title">{option.label}</div>
        </div>
      )}
    </div>
  );

  return areTooltipsEnabled ? (
    <OverlayTrigger
      {...defaultOverlayTriggerTooltipProps}
      overlay={<Tooltip id={`tooltip-${option.value}`}>{option.tooltip || option.label}</Tooltip>}
      placement={tooltipPlacement}
    >
      {button}
    </OverlayTrigger>
  ) : (
    button
  );
};
interface OptionButtonProps<T> {
  className?: string;
  index: number;
  isEnabled: boolean;
  isSelected: boolean;
  areTooltipsEnabled: boolean;
  onClick: React.MouseEventHandler<HTMLElement>;
  onDelete?: () => void;
  onlyIcons: boolean;
  option: Option<T>;
  tooltipPlacement: Placement;
}

const OptionChooser = <T,>({
  className,
  options,
  selected,
  onChange,
  disabledOptions,
  hasMultipleValues,
  getItemClassName,
  tooltipPlacement = 'top' as Placement,
  tooltipsEnabled = true,
  onlyIcons = false,
}: OptionChooserProps<T>) => {
  const handleButtonClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, index: number) => {
      if (isNonNullish(onChange)) {
        swallowEvents(event);
        onChange(options[index].value, index);
      }
    },
    [onChange, options]
  );

  const getButton = useCallback(
    (option: Option<T>, index: number) => {
      const isEnabled = !disabledOptions || !disabledOptions.contains(option.value);
      return (
        <OptionButton
          areTooltipsEnabled={tooltipsEnabled}
          className={
            (isNonNullish(getItemClassName) && getItemClassName(option.value, index)) || ''
          }
          index={index}
          isEnabled={isEnabled}
          isSelected={selected === option.value}
          onlyIcons={onlyIcons}
          option={option}
          tooltipPlacement={tooltipPlacement}
          onClick={event => (isEnabled ? handleButtonClick(event, index) : noop())}
        />
      );
    },
    [
      disabledOptions,
      getItemClassName,
      handleButtonClick,
      onlyIcons,
      selected,
      tooltipPlacement,
      tooltipsEnabled,
    ]
  );

  return (
    <div
      className={classNames('OptionChooser', className, {'has-multiple-values': hasMultipleValues})}
    >
      {options.map((option, index) => (
        <React.Fragment key={`${option.value}-button`}>{getButton(option, index)}</React.Fragment>
      ))}
    </div>
  );
};
OptionChooser.displayName = 'OptionChooser';

export interface OptionChooserProps<T> {
  className?: string;
  disabledOptions?: Set<T>;
  getItemClassName?: (item: T, index: number) => string;
  hasMultipleValues?: boolean;
  options: ReadonlyArray<Option<T>>;
  tooltipsEnabled?: boolean;
  tooltipPlacement?: Placement;
  selected?: T;
  onChange?: (value: T, index: number) => void;
  onDelete?: (index: number) => void;
  onOrderChange?: (oldIndex: number, newIndex: number) => void;
  onlyIcons?: boolean;
}

export default OptionChooser;
