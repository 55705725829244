import {GridApi} from 'ag-grid-community';
import React from 'react';

import {ComputeResultData, TypedRowNode} from 'toolkit/ag-grid/types';
import {listenForRowExpansionChange} from 'toolkit/ag-grid/utils';
import TreeExpandCollapseArrow from 'toolkit/components/TreeExpandCollapseArrow';
import * as Types from 'types';
import {noop} from 'utils/functions';

import AttributeCell from './AttributeCell';
import {getPaddingAmount} from './cell-utils';
import {AttributeTreeCellParams} from './types';

export default class TreeCell extends React.PureComponent<Props> {
  private unsubscribe: null | (() => void) = null;

  static defaultProps = {
    onRowSelectionChanged: noop,
    showSelectionCheckbox: true,
  };

  componentDidMount() {
    this.unsubscribe = listenForRowExpansionChange(this.props.api, () => this.forceUpdate());
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
  }

  onRowSelectionChanged = () => {
    this.props.onRowSelectionChanged?.(this.props.node);
  };

  render() {
    return (
      <div className="AttributeCell TreeCell">
        {!this.props.compact && (
          <div style={{paddingLeft: `${getPaddingAmount(this.props.data)}rem`}}>
            {this.props.node.isExpandable() && (
              <TreeExpandCollapseArrow isExpanded={this.props.node.expanded} />
            )}
          </div>
        )}
        <AttributeCell {...this.props} />
      </div>
    );
  }
}

type Props = AttributeTreeCellParams & {
  data: ComputeResultData;
  level: number;
  node: TypedRowNode<ComputeResultData>;
  value: Types.AttributeValue;
  valueFormatted: string;
  api: GridApi;
};
