import {composeWithDevToolsLogOnlyInProduction} from '@redux-devtools/extension';
import {routerMiddleware} from 'connected-react-router';
import {applyMiddleware, createStore, Store} from 'redux';
import thunk from 'redux-thunk';

import * as Globals from 'app/globals';
import ActionType from 'redux/actions/types';
import createRootReducer, {AvailableAction, Dispatch, RootState} from 'redux/reducers';
import {history} from 'utils/history';

import reduxNavigationMiddleware from './middleware/navigationMiddleware';
import reduxPresentationMiddleware from './middleware/presentation';
import reduxPromiseMiddleware from './middleware/promise';
import reduxVendorMiddleware from './middleware/vendorMiddleware';

const fieldsToHideToSaveMemory = ['thinViews', 'experiments'];

const composeEnhancers = composeWithDevToolsLogOnlyInProduction({
  trace: !Globals.profiling,
  actionsDenylist: [ActionType.IncrementActiveRequestCount, ActionType.DecrementActiveRequestCount],
  maxAge: 40,
  serialize: {
    replacer: (key, value) =>
      fieldsToHideToSaveMemory.includes(key) ? '<hidden to avoid devtools OOM>' : value,
  },
});

interface AlloyStore extends Store<RootState, AvailableAction> {
  dispatch: Dispatch;
}

export const store: AlloyStore = createStore(
  createRootReducer(history),
  composeEnhancers(
    applyMiddleware(
      thunk,
      routerMiddleware(history),
      reduxPromiseMiddleware,
      reduxPresentationMiddleware,
      reduxVendorMiddleware,
      reduxNavigationMiddleware
    )
  )
);
