import './TableSortIndicator.scss';

import {Column, GridApi} from 'ag-grid-community';
import classNames from 'classnames';
import React, {useEffect, useState} from 'react';

import Icon from 'toolkit/components/Icon';

interface SortOrder {
  direction: 'asc' | 'desc' | null;
  order: number | null;
}

function getCurrentSort(col: Column, disabled: boolean, gridApi: GridApi): SortOrder {
  if (disabled) {
    return {
      direction: null,
      order: null,
    };
  }
  const direction = col.isSortAscending() ? 'asc' : col.isSortDescending() ? 'desc' : null;
  const columnsInOrder = gridApi.getSortModel().map(sortedColumn => sortedColumn.colId);
  const order = columnsInOrder.indexOf(col.getColId());
  return {
    direction,
    order: columnsInOrder.length <= 1 || order === -1 ? null : order + 1,
  };
}

const TableSortIndicator: React.FC<Props> = ({
  gridApi,
  column,
  children,
  isCompact = false,
  arrowOnRight = false,
  disabled = false,
  underlineWhenActive = true,
  boldWhenActive = false,
}) => {
  const [sortOrder, setSortOrder] = useState<SortOrder>(() =>
    getCurrentSort(column, disabled, gridApi)
  );

  useEffect(() => {
    const onSortChanged = () => {
      setSortOrder(getCurrentSort(column, disabled, gridApi));
    };

    gridApi.addEventListener('sortChanged', onSortChanged);
    return () => {
      gridApi.removeEventListener('sortChanged', onSortChanged);
    };
  }, [column, disabled, gridApi]);

  const sortOrderNumber = sortOrder.order ? (
    <span className="order-number">{sortOrder.order}</span>
  ) : null;

  const indicator =
    !isCompact && sortOrder.direction ? (
      <div
        className={classNames(
          'sorting-indicator-container',
          sortOrder.order && 'sort-order',
          arrowOnRight ? 'right' : 'left'
        )}
      >
        <Icon
          className={classNames('sorting-indicator', `sorting-indicator-${sortOrder.direction}`)}
          icon={sortOrder.direction === 'asc' ? 'arrow-up' : 'arrow-down'}
        />
        {sortOrderNumber}
      </div>
    ) : null;

  const isActiveSort = !isCompact && !!sortOrder.direction;
  return (
    <div
      className={classNames('TableSortIndicator', `sort-${sortOrder.direction}`, {
        'is-active-sort': isActiveSort,
        underline: underlineWhenActive,
        bold: boldWhenActive,
        disabled,
      })}
    >
      {arrowOnRight ? null : indicator}
      <div className="container">{children}</div>
      {arrowOnRight ? indicator : null}
    </div>
  );
};
TableSortIndicator.displayName = 'TableSortIndicator';

interface Props {
  gridApi: GridApi;
  column: Column;
  isCompact?: boolean;
  arrowOnRight?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  underlineWhenActive?: boolean;
  boldWhenActive?: boolean;
}

export default TableSortIndicator;
