import './TableTruncatedTooltip.scss';

import {ColDef, Column, ColumnApi, GridApi} from 'ag-grid-community';
import React, {useMemo, useEffect} from 'react';

import {getTextWidthInPixels} from 'toolkit/utils/style';
import {first} from 'utils/arrays';
import {isNullish, noop} from 'utils/functions';

import {getTruncatedBaseCellOffsetPx} from './truncated-tooltip-utils';
import {TypedRowNode} from './types';

// eslint-disable-next-line fp/no-let
let currentTooltip: HTMLElement | null = null;

const contains = function (rect: DOMRect, x: number, y: number) {
  return rect.x <= x && x <= rect.x + rect.width && rect.y <= y && y <= rect.y + rect.height;
};

const TableTruncatedTooltip = <T,>(props: Props<T>) => {
  const {
    reactContainer,
    location,
    columnApi,
    colDef,
    value,
    api,
    rowIndex,
    getOffsetInPx = noop,
    hasDefaultCellPadding = true,
  } = props;

  const previousTooltip = currentTooltip;
  currentTooltip = reactContainer;

  // HACK: agrid has problems reacting to mouse moves properly
  // and also doesn't clear up all tooltips for some reason
  useEffect(() => {
    const removeTooltipOnMouseout = (e: MouseEvent) => {
      if (!reactContainer.parentNode) {
        window.removeEventListener('mousemove', removeTooltipOnMouseout);
        return;
      }
      const highlightedCell = document.querySelector('.ag-row-hover .ag-column-hover');

      if (!highlightedCell || !contains(highlightedCell.getBoundingClientRect(), e.x, e.y)) {
        reactContainer.parentNode.removeChild(reactContainer);
        window.removeEventListener('mousemove', removeTooltipOnMouseout);
      }
    };
    window.addEventListener('mousemove', removeTooltipOnMouseout);
    // ensures we show only one tooltip
    if (currentTooltip !== previousTooltip && previousTooltip?.parentNode) {
      previousTooltip.parentNode.removeChild(previousTooltip);
    }
    return () => {
      window.removeEventListener('mousemove', removeTooltipOnMouseout);
    };
  }, [reactContainer, previousTooltip]);

  const colId = useMemo(() => {
    if (!isNullish(colDef.colId)) {
      return colDef.colId;
    } else {
      // Use the header name to find the generated colId because the provided column
      // prop from ag-grid doesn't actually work
      const columns = columnApi.getAllColumns();
      const currentColumn = columns.find(column => {
        return column.getUserProvidedColDef().headerName === colDef.headerName;
      });
      return currentColumn?.getColId();
    }
  }, [colDef.colId, colDef.headerName, columnApi]);

  const textWidth = useMemo(() => getTextWidthInPixels(value.formatted), [value.formatted]);
  const columnWidth = useMemo(() => {
    const rowNode = api.getDisplayedRowAtIndex(rowIndex);
    const columnState = !isNullish(colId)
      ? columnApi.getColumnState().filter(state => state.colId === colId)
      : [];
    // default columnWidth to inifity if we can't find the column, so the tooltip never shows up
    const columnWidthPx =
      (columnState.length > 0 && first(columnState).width) || Number.POSITIVE_INFINITY;
    const offsetWidthPx =
      (getOffsetInPx(rowNode) ?? 0) + getTruncatedBaseCellOffsetPx(hasDefaultCellPadding);
    return columnWidthPx - offsetWidthPx;
  }, [api, colId, columnApi, getOffsetInPx, hasDefaultCellPadding, rowIndex]);

  if (location !== 'cell') {
    return null;
  }

  const isTruncated = columnWidth <= textWidth;
  return isTruncated && !!value.formatted ? (
    <div className="TableTruncatedTooltip">{value.formatted}</div>
  ) : (
    // ag-grid throws an error if we return null
    <div />
  );
};
TableTruncatedTooltip.displayName = 'TableTruncatedTooltip';

// use an interface instead of just passing in a string for the value
// since ag-grid escapes charaters if its only a string
export interface TooltipValue {
  formatted: string;
}

export interface TableTruncatedTooltipProps<T> {
  /** Width of additional padding or other elements within the cell other than text */
  getOffsetInPx?: (node: TypedRowNode<T>) => number;
  /** Defaults to true. If false, removes the default cell padding from the calculations */
  hasDefaultCellPadding?: boolean;
}

interface AgCellProps {
  location: string;
  rowIndex: number;
  column: () => Column;
  api: GridApi;
  columnApi: ColumnApi;
  colDef: ColDef;
  value: TooltipValue;
  reactContainer: HTMLElement;
}

type Props<T> = TableTruncatedTooltipProps<T> & AgCellProps;

export default TableTruncatedTooltip;
