import React from 'react';
import {withRouter} from 'react-router';
import {RouteComponentProps} from 'react-router-dom';

import {nodeEnv} from 'app/globals';
import Centered from 'toolkit/components/Centered';
import {UserFacingError} from 'types/error';
import {invalidateFailedRequests} from 'utils/api';
import {captureException} from 'utils/exceptions';

import ErrorPlaceholder from './ErrorPlaceholder';

const DEFAULT_ERROR_MESSAGE =
  'Something went wrong. Click to retry. ' +
  'Meanwhile, the error has been logged and will be looked into.';

class ErrorBoundary extends React.PureComponent<ErrorBoundaryProps, State> {
  private unregisterHistoryListener: (() => void) | null = null;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    this.unregisterHistoryListener = this.props.history.listen(this.dismissError);
  }

  componentWillUnmount() {
    this.unregisterHistoryListener?.();
    this.unregisterHistoryListener = null;
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({
      error,
    });

    captureException(error, info);
  }

  dismissError = () => {
    invalidateFailedRequests();
    this.setState({error: null});
  };

  render() {
    if (!this.state.error) {
      return this.props.children;
    }

    const errorDisplay =
      nodeEnv === 'test'
        ? (this.state.error.stack ?? String(this.state.error) ?? 'Unknown error')
        : this.state.error instanceof UserFacingError
          ? this.state.error
          : DEFAULT_ERROR_MESSAGE;

    const defaultErrorDisplay = (
      <Centered className="error-mask" onClick={this.dismissError}>
        <ErrorPlaceholder error={errorDisplay} />
      </Centered>
    );

    if (this.props.renderError) {
      return this.props.renderError(defaultErrorDisplay, this.state.error);
    } else {
      return defaultErrorDisplay;
    }
  }
}

type ErrorBoundaryProps = RouteComponentProps & {
  children?: React.ReactNode;
  renderError?: (renderedError: React.ReactNode, error: Error) => React.ReactNode;
};
interface State {
  error: Error | null;
}

const ErrorBoundaryWithRouter = withRouter(ErrorBoundary);

export default ErrorBoundaryWithRouter;

export function withErrorBoundary<P extends object>(Component: React.ComponentType<P>) {
  return (props: P) => (
    <ErrorBoundaryWithRouter>
      <Component {...props} />
    </ErrorBoundaryWithRouter>
  );
}
