import './OrdersPreviewModal.scss';

import React, {useEffect, useMemo} from 'react';

import {useCurrentUser} from 'redux/context/user';
import useSelector from 'redux/selectors/useSelector';
import LoadingOverlay from 'toolkit/components/LoadingOverlay';
import {
  augmentTransactionEventResult,
  getAttributesForTransactionQuery,
  getItemColumnAttribute,
  getLocationColumnAttribute,
  makeTransactionAnalysisRequest,
} from 'toolkit/transactions/utils';
import * as Types from 'types';
import {useRequest} from 'utils/ajax';
import {Status} from 'utils/status';

import OrdersPreviewChart from './OrdersPreviewChart';
import OrdersPreviewTable, {TransactionAnalysisData} from './OrdersPreviewTable';

const OrdersPreviewModalContent: React.FC<Props> = ({
  attributeFilters,
  calendarProperties,
  evaluationDate,
  metricInstance,
}) => {
  const allGroupings = useSelector(state => state.analysis.data.allGroupings);

  const defaultAttributeHierarchies = useSelector(
    state => state.analysis.data.defaultAttributeHierarchies
  );
  const currentUser = useCurrentUser();
  const [transactionEventRequest, setTransactionEventRequest] =
    useRequest<readonly Types.DisplayTransactionEvent[]>();

  const itemAttribute = useMemo(() => {
    const defaultProductHierarchy = defaultAttributeHierarchies.get(
      Types.AttributeHierarchyType.PRODUCT,
      []
    );
    return getItemColumnAttribute(
      currentUser.settings.analysisSettings.ordersDefaultProductAttributeId,
      defaultProductHierarchy,
      allGroupings
    );
  }, [
    defaultAttributeHierarchies,
    currentUser.settings.analysisSettings.ordersDefaultProductAttributeId,
    allGroupings,
  ]);
  const locationAttribute = useMemo(() => {
    return getLocationColumnAttribute(
      currentUser.settings.analysisSettings.ordersDefaultLocationAttributeId,
      allGroupings
    );
  }, [currentUser.settings.analysisSettings.ordersDefaultLocationAttributeId, allGroupings]);
  const transactionAttributes = useMemo<readonly Types.AttributeInstance[]>(
    () => getAttributesForTransactionQuery(allGroupings, itemAttribute, locationAttribute!),
    [itemAttribute, locationAttribute, allGroupings]
  );

  const augmentedTransactionData: readonly TransactionAnalysisData[] = useMemo(() => {
    if (transactionEventRequest.status === Status.succeeded) {
      return augmentTransactionEventResult(
        transactionEventRequest.result,
        metricInstance,
        itemAttribute,
        locationAttribute!
      );
    }
    return [];
  }, [transactionEventRequest, itemAttribute, locationAttribute, metricInstance]);

  useEffect(() => {
    setTransactionEventRequest(
      makeTransactionAnalysisRequest(
        transactionAttributes,
        [metricInstance],
        attributeFilters,
        calendarProperties.name,
        evaluationDate,
        itemAttribute,
        locationAttribute
      )
    );
  }, [
    metricInstance,
    transactionAttributes,
    attributeFilters,
    calendarProperties,
    evaluationDate,
    itemAttribute,
    locationAttribute,
    setTransactionEventRequest,
  ]);

  return (
    <LoadingOverlay
      error={
        transactionEventRequest.status === Status.failed ? transactionEventRequest.result : null
      }
      isLoading={transactionEventRequest.status === Status.inProgress}
    >
      {transactionEventRequest.status === Status.succeeded && (
        <>
          <OrdersPreviewChart
            augmentedTransactionData={augmentedTransactionData}
            metricInstance={metricInstance}
          />
          <OrdersPreviewTable metricInstance={metricInstance} rowData={augmentedTransactionData} />
        </>
      )}
    </LoadingOverlay>
  );
};

interface Props {
  attributeFilters: readonly Types.AttributeFilter[];
  calendarProperties: Types.CalendarProperties;
  evaluationDate: moment.Moment;
  metricInstance: Types.MetricInstance;
}

OrdersPreviewModalContent.displayName = 'OrdersPreviewModalContent';

export default OrdersPreviewModalContent;
