import './ManagementSidebar.scss';

import classNames from 'classnames';
import React, {HTMLProps} from 'react';

import {IconSpec} from 'toolkit/icons/types';

import CanvasBlock from './CanvasBlock';
import Icon from './Icon';
import TruncatedTooltip from './TruncatedTooltip';

export const ManagementSidebarTitle: React.FC<{children: React.ReactNode}> = ({children}) => {
  return <div className="section-title">{children}</div>;
};

export const ManagementSidebarItem: React.FC<ManagementSidebarItemProps> = ({
  name,
  count,
  className,
  selected,
  icon,
  ...rest
}) => {
  return (
    <div className={classNames('sidebar-item', className, {selected})} {...rest}>
      <div className="sidebar-item-title">
        {icon && <Icon className="sidebar-item-icon" icon={icon} />}
        <TruncatedTooltip className="sidebar-item-name" value={name} />
      </div>
      <span className="item-count">{count}</span>
    </div>
  );
};
ManagementSidebarItem.displayName = 'ManagementSidebarItem';

export const ManagementSidebar: React.FC<ManagementSidebarProps> = ({className, children}) => {
  return (
    <CanvasBlock
      bordered={false}
      className={classNames('ManagementSidebar', className)}
      rounded={false}
      padded
    >
      {children}
    </CanvasBlock>
  );
};
ManagementSidebar.displayName = 'ManagementSidebar';

interface ManagementSidebarProps {
  children: React.ReactNode;
  className?: string;
}

type ManagementSidebarItemProps = HTMLProps<HTMLDivElement> & {
  className?: string;
  selected: boolean;
  name: string;
  count: number;
  icon?: IconSpec;
};
