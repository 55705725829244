import {AssistantSidebarStatus} from 'assistant/types';
import {AssistantAction} from 'redux/actions/assistant';
import ActionType from 'redux/actions/types';
import * as Types from 'types';
import {replaceAt} from 'utils/arrays';

export interface AssistantSession {
  readonly initialQuery: string;
  readonly session: Types.ChatSession;
}

export interface AssistantState {
  readonly activeSessionIndex: number;
  readonly assistantSidebarStatus: AssistantSidebarStatus;
  readonly isLoadingResponse: boolean;
  readonly sessions: readonly AssistantSession[];
}

const initialUserDataState: AssistantState = {
  activeSessionIndex: -1,
  assistantSidebarStatus: AssistantSidebarStatus.CLOSED,
  isLoadingResponse: false,
  sessions: [],
};

export default function assistant(
  state = initialUserDataState,
  action: AssistantAction
): AssistantState {
  if (action.type === ActionType.StartNewAssistantSession) {
    return {
      ...state,
      activeSessionIndex: state.sessions.length,
      sessions: [...state.sessions, action.session],
    };
  } else if (action.type === ActionType.SetAssistantSidebarOpenState) {
    return {...state, assistantSidebarStatus: action.assistantSidebarStatus};
  } else if (action.type === ActionType.SetAssistantSession) {
    return {
      ...state,
      sessions: replaceAt(state.sessions, state.activeSessionIndex, action.session),
    };
  } else if (action.type === ActionType.SetLoadingAssistantResponse) {
    return {...state, isLoadingResponse: action.isLoadingResponse};
  }
  return state;
}
