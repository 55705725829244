import './TableHeaderCell.scss';

import {IHeaderParams} from 'ag-grid-community';
import classNames from 'classnames';
import React, {useRef} from 'react';

import Icon from 'toolkit/components/Icon';
import InfoHover from 'toolkit/components/InfoHover';
import Tooltip from 'toolkit/components/Tooltip';
import TruncatedTooltip from 'toolkit/components/TruncatedTooltip';
import {swallowEvents} from 'toolkit/utils/events';
import {TableHeaderColorScheme} from 'widgets/types';

import TableSortIndicator from './TableSortIndicator';
import {AttributeHeaderParams} from './types';
import {useTableHeaderFilterActive} from './useTableHeaderFilterActive';

const TableHeaderCell: React.FunctionComponent<Props> = ({
  api,
  column,
  compact,
  displayName,
  enableSorting,
  enableMenu,
  hasCapitalizedHeader,
  headerColorScheme = TableHeaderColorScheme.DEFAULT,
  infoHoverText,
  isChangingSortDisabled,
  isFlattened = true,
  isLeafColumn,
  subtitle,
  textAlignment = 'left',
  showColumnMenu,
  progressSort,
}) => {
  const refButton = useRef(null);
  const isFilterActive = useTableHeaderFilterActive(column);

  const onSortRequested = (event: React.MouseEvent<HTMLElement>) => {
    if (enableSorting && !isChangingSortDisabled) {
      progressSort!(event.shiftKey);
    }
  };

  const onMenuClicked = (event: React.MouseEvent<HTMLDivElement>) => {
    swallowEvents(event);
    if (refButton.current) {
      showColumnMenu!(refButton.current);
    }
  };

  const label = (
    <div className="label-container">
      <div className="title-container">
        {column.getColDef().headerTooltip ? (
          <Tooltip className="title" tooltip={column.getColDef().headerTooltip}>
            {displayName}
          </Tooltip>
        ) : (
          <TruncatedTooltip className="title" value={displayName} />
        )}
      </div>
      {subtitle && <div className="subtitle">{subtitle}</div>}
    </div>
  );

  const infoHover = !!infoHoverText && <InfoHover>{infoHoverText}</InfoHover>;

  return (
    <div
      className={classNames(
        'TableHeaderCell',
        {
          compact,
          grouped: !isFlattened,
          'is-leaf-column': isLeafColumn,
          'color-scheme-grey': headerColorScheme === TableHeaderColorScheme.GREY,
          'has-capitalized-header': hasCapitalizedHeader ?? true,
          'changing-sort-disabled': isChangingSortDisabled,
        },
        `aligned-${textAlignment}`
      )}
      id="agHeaderCellLabel"
      onClick={onSortRequested}
    >
      <TableSortIndicator
        arrowOnRight={textAlignment === 'left'}
        column={column}
        disabled={!enableSorting || textAlignment === 'center'}
        gridApi={api!}
        isCompact={compact}
      >
        {infoHover}
        {label}
        {enableMenu && (
          <div
            ref={refButton}
            className={classNames('menu-button', {'show-on-hover': !isFilterActive})}
            onClick={onMenuClicked}
          >
            <Icon icon={isFilterActive ? 'filter' : 'bars'} />
          </div>
        )}
      </TableSortIndicator>
    </div>
  );
};
TableHeaderCell.displayName = 'TableHeaderCell';

type Props = AttributeHeaderParams & IHeaderParams;

export default TableHeaderCell;
