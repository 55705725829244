import {useState, useEffect} from 'react';

/**
 * A hook that observes changes to the given element's child list. It returns the number of
 * mutations it has observed. This can be used as a component's `key` in order to force
 * unmounting when ag-grid temporarily removes the `ag-react-container` from the DOM.
 *
 * For example, when you edit a cell, ag-grid will remove the cell renderer from the DOM without
 * unmounting the component (and re-add it once editing is done). This creates the
 * problem that if a tooltip was visible when editing was triggered (and chances
 * for that are pretty good), then the `OverlayTrigger` never receives a mouseout event
 * and thus the tooltip remains visible. To work around this, you can use this hook to
 * generate `OverlayTrigger`'s key, forcing it to be re-rendered (with its initial state of
 * "no tooltip") when the removal happens.
 *
 * The given element should be at least at grandparent distance from the component it's being
 * used in.
 */
const useChildListMutationObserver = (elem: HTMLElement) => {
  const [mutationCount, setMutationCount] = useState(0);

  useEffect(() => {
    const mutationObserver = new MutationObserver(() => setMutationCount(count => count + 1));
    mutationObserver.observe(elem, {childList: true});

    return () => mutationObserver.disconnect();
  }, [elem]);

  return mutationCount;
};

export default useChildListMutationObserver;
