import * as History from 'history';

export function getUrlSearchParam(location: History.Location, name: string) {
  return location && location.search ? new URLSearchParams(location.search).get(name) : null;
}

export function clearUrlParams(history: History.History) {
  const location = history?.location;
  if (location && location.search) {
    history.replace(location.pathname);
  }
}

export function toHref(location: {pathname: string; search: string; hash: string}) {
  return location.pathname + location.search + location.hash;
}

export function isExternalUrlValid(externalUrl: string): boolean {
  try {
    const url = new URL(externalUrl);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (_) {
    return false;
  }
}
