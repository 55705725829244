import './ItemChooserApplyButton.scss';

import classNames from 'classnames';
import React from 'react';

const ItemChooserApplyButton: React.FC<Props> = ({children, className, ...rest}) => {
  return (
    <button className={classNames('ItemChooserApplyButton save', className)} tabIndex={0} {...rest}>
      {children || 'Apply'}
    </button>
  );
};
ItemChooserApplyButton.displayName = 'ItemChooserApplyButton';
type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;
export default ItemChooserApplyButton;
