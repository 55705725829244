import {Map} from 'immutable';

import * as Types from 'types';
import {isTruthy} from 'utils/functions';

import {ComputeResultExtended, IComputeResultExtended, ThinComputeResultRowExtended} from './types';

function createEmptyColumnData(request: Types.ComputeRequest): Types.ThinComputeResultColumn {
  return {
    value: null,
    children: [],
    metricMetadata: request.metrics.map((_): Types.MetricValueMetadata[] => []),
    metricValues: request.metrics.map(_ => NaN),
  };
}

function mergeComputeResultColumn(
  columnData: Types.ThinComputeResultColumn,
  columnToMerge: Types.ThinComputeResultColumn
): Types.ThinComputeResultColumn {
  return {
    ...columnData,
    metricMetadata: [...columnData.metricMetadata, ...columnToMerge.metricMetadata],
    metricValues: [...columnData.metricValues, ...columnToMerge.metricValues],
  };
}

/**
 * Takes the two compute request & compute result pairs and merges them into one ComputeResult.
 * This function makes the assumption that both requests have the same single grouping, that
 * their values overlap, and that they have the same filters.
 */
export function mergeComputeResults(
  computeRequestA: Types.ComputeRequest,
  computeResultA: IComputeResultExtended,
  computeRequestB: Types.ComputeRequest,
  computeResultB: IComputeResultExtended
): ComputeResultExtended {
  const columnDataMapA = Map(computeResultA.data.children.map(row => [row.value, row.columnData]));
  const columnDataMapB = Map(computeResultB.data.children.map(row => [row.value, row.columnData]));

  const groupingValues =
    computeResultA.data.children.length > 0
      ? computeResultA.data.children.map(row => row.value)
      : computeResultB.data.children.map(row => row.value);

  const children: ThinComputeResultRowExtended[] = groupingValues
    .map(value => {
      if (!columnDataMapA.has(value) && !columnDataMapB.has(value)) {
        return null;
      } else {
        const computeColumnData = columnDataMapA.has(value)
          ? columnDataMapA.get(value)!
          : createEmptyColumnData(computeRequestA);
        const npiColumnData = columnDataMapB.has(value)
          ? columnDataMapB.get(value)!
          : createEmptyColumnData(computeRequestB);
        return {
          children: [],
          value,
          columnData: mergeComputeResultColumn(computeColumnData, npiColumnData),
          values: [],
        };
      }
    })
    .filter(isTruthy);

  const mergedResult: IComputeResultExtended = {
    columnGroupings: [...computeResultA.columnGroupings, ...computeResultB.columnGroupings],
    data: {
      ...computeResultA.data,
      children,
      columnData: mergeComputeResultColumn(
        computeResultA.data.columnData,
        computeResultB.data.columnData
      ),
    },
    filters: computeRequestA.filters,
    totalRowCount: children.length,
    cacheStatus: computeResultA.cacheStatus,
    cacheVersion: computeResultA.cacheVersion,
    metrics: [...computeResultA.metrics, ...computeResultB.metrics],
    requestId: computeResultA.requestId,
    rowGroupings: [...computeResultA.rowGroupings, ...computeResultB.rowGroupings],
    computeTimeMilliseconds: null,
  };
  const mergedRequest: Types.ComputeRequest = {
    ...computeRequestA,
    metrics: [...computeRequestA.metrics, ...computeRequestB.metrics],
  };

  return ComputeResultExtended.fromJS(mergedRequest, mergedResult);
}

export function getEmptyComputeResult(
  metrics: readonly Types.MetricInstance[],
  value: Types.ThinAttributeValue | null
): ThinComputeResultRowExtended {
  const columnData: Types.ThinComputeResultColumn = {
    children: [],
    metricMetadata: metrics.map(_ => []),
    metricValues: metrics.map(_ => NaN),
    value,
  };
  return {
    children: [],
    columnData,
    value,
    values: [],
  };
}
