import './CopyableText.scss';

import classNames from 'classnames';
import React, {useRef} from 'react';

import {showAlert} from 'app/alerts';

import Button, {ButtonDisplayVariant} from './Button';
import Input, {InputProps} from './Input';

const CopyableText: React.FC<Props> = ({
  borderless,
  condensed = true,
  iconPlacement = 'left',
  monospace = true,
  showIcon = true,
  readOnly,
  value,
  ...rest
}) => {
  const inputElement = useRef<HTMLInputElement | null>(null);

  const copy = () => {
    navigator.clipboard.writeText(String(value));
    showAlert('Copied to clipboard.');
  };

  const icon = showIcon && (
    <Button
      className={classNames('copy-icon', {condensed})}
      displayVariant={ButtonDisplayVariant.TERTIARY}
      icon={['far', 'clipboard']}
      onClick={copy}
    />
  );

  return (
    <div className="CopyableText">
      {iconPlacement === 'left' && icon}
      <Input
        ref={inputElement}
        className={classNames('text', {borderless, condensed, monospace})}
        type="text"
        value={value}
        readOnly
        onClick={copy}
        {...rest}
      />
      {iconPlacement === 'right' && icon}
    </div>
  );
};
CopyableText.displayName = 'CopyableText';

type Props = InputProps & {
  borderless?: boolean;
  condensed?: boolean;
  iconPlacement?: 'left' | 'right';
  monospace?: boolean;
  showIcon?: boolean;
};

export default CopyableText;
