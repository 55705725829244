import {AjaxAction} from 'redux/actions/ajax';
import ActionType from 'redux/actions/types';

export interface AjaxState {
  readonly activeRequestCount: number;
  readonly e2eBuildUrl: string | null;
  readonly isCacheInspectorVisible: boolean;
}

const initialAjaxState: AjaxState = {
  activeRequestCount: 0,
  e2eBuildUrl: null,
  isCacheInspectorVisible: false,
};

export default function ajax(state = initialAjaxState, action: AjaxAction): AjaxState {
  if (action.type === ActionType.IncrementActiveRequestCount) {
    return {
      ...state,
      activeRequestCount: state.activeRequestCount + 1,
    };
  } else if (action.type === ActionType.DecrementActiveRequestCount) {
    return {
      ...state,
      activeRequestCount: state.activeRequestCount - 1,
    };
  } else if (action.type === ActionType.SetEndToEndTestBuildUrl) {
    return {
      ...state,
      e2eBuildUrl: action.buildUrl,
    };
  } else if (action.type === ActionType.ToggleCacheInspectorVisible) {
    return {
      ...state,
      isCacheInspectorVisible: !state.isCacheInspectorVisible,
    };
  }
  return state;
}
