import {Map, List} from 'immutable';

import {TypedTransposedNodeDetails} from 'toolkit/ag-grid/types';
import {ThinComputeResultRowExtended} from 'toolkit/compute/types';
import * as Types from 'types';

import {PlanningTableWidgetOwnProps} from './PlanningTableWidget';

export enum PlanRowType {
  PRIMARY = 'primary',
  EVENT_LIFT = 'event-lift',
  BASELINE = 'baseline',
  COMPS = 'comps',
  ADJUSTMENT = 'adjustment',
  UNADJUSTED = 'unadjusted',
  SCHEDULED_SHIPPED_UNITS = 'scheduled-shipped-units',
  SHIPMENT_PLAN_DETAILS = 'shipment-plan-details',
  SHIPMENT_PLAN_DETAILS_SHOW_MORE = 'shipment-plan-details-show-more',
  CONFIG = 'config',
  PROJECTED_RECEIPTS = 'projected_receipts',
}

export interface PlanRowAction {
  readonly isExternalLink: boolean;
  readonly label: string;
  readonly onClick: () => void;
  readonly useLabelAsHeader?: boolean;
}

export type PlanningNodeDetails = TypedTransposedNodeDetails<ThinComputeResultRowExtended> & {
  readonly editable?: boolean;
  readonly expandable?: boolean;
  readonly toggleExpanded?: () => void;
  readonly adjustments?: AdjustmentsByMetricNameAndFirstPathId;
  readonly parentMetricIndex?: number;
  readonly pathValueIds?: readonly number[];
  readonly planRowType?: PlanRowType;
  readonly isDirty?: boolean; // used to grey out certain rows when there are inactive adjustments
  readonly getRowAction?: (
    data: PlanningNodeDetails,
    value: Types.MetricInstance
  ) => PlanRowAction | undefined;
  readonly connectedPathValues?: readonly Types.AttributeValue[];
};
export const ADJUSTMENT_ROOT = Symbol.for('ADJUSTMENT_ROOT');
export type AdjustmentsByFirstPathId = Map<
  number | typeof ADJUSTMENT_ROOT,
  List<Types.ThinUserManualAdjustment>
>;
export type AdjustmentsByMetricNameAndFirstPathId = Map<string, AdjustmentsByFirstPathId>;

export type PlanningTableWidgetProps = Omit<
  PlanningTableWidgetOwnProps,
  | 'expandedIndices'
  | 'getCellClass'
  | 'getDefaultExpandedIndices'
  | 'isAtOriginalGranularity'
  | 'isCellDisabled'
  | 'isCellEditable'
  | 'onCellClick'
  | 'pendingPlanVersion'
  | 'pinnedTopRowData'
  | 'planPath'
  | 'rowData'
>;
