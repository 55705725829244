import './TagsList.scss';

import classNames from 'classnames';
import React from 'react';
import {Placement} from 'react-bootstrap/Overlay';
import Measure from 'react-measure';

import Tooltip from 'toolkit/components/Tooltip';
import Tag from 'toolkit/tags/Tag';

const TagsList: React.FC<Props> = ({
  className,
  maxWidth,
  onItemClick,
  onKeyDown,
  tooltipPlacement,
  value,
  valueTooltip = [],
  variant = 'light',
  multiline = false,
}) => {
  if (!value?.length) {
    return null;
  }

  return (
    <Measure scroll>
      {({measureRef, contentRect}) => {
        const tagsList = (
          <div
            ref={measureRef}
            className={classNames('TagsList', className, {dark: variant === 'dark', multiline})}
          >
            {value.map((name, index) => {
              const tooltipText = valueTooltip![index];
              const tag = (
                <Tag key={name} name={name} onItemClick={onItemClick} onKeyDown={onKeyDown} />
              );
              if (tooltipText) {
                return (
                  <Tooltip key={name} placement={tooltipPlacement} tooltip={tooltipText}>
                    {tag}
                  </Tooltip>
                );
              }
              return tag;
            })}
          </div>
        );
        if (contentRect.scroll?.width && maxWidth && contentRect.scroll.width > maxWidth) {
          return (
            <Tooltip placement={tooltipPlacement} tooltip={value?.join(', ')}>
              {tagsList}
            </Tooltip>
          );
        } else {
          return tagsList;
        }
      }}
    </Measure>
  );
};

interface Props {
  className?: string;
  // NOTE: If maxWidth is set, valueTooltip should be empty.
  // Otherwise two overlapping tooltips might be shown together.
  maxWidth?: number;
  onItemClick?: (name: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
  tooltipPlacement?: Placement;
  value: readonly string[] | null;
  valueTooltip?: ReadonlyArray<string | undefined>;
  variant?: 'light' | 'dark';
  multiline?: boolean;
}

export default TagsList;
