import Cookies from 'js-cookie';

import * as Api from 'api';
import * as Types from 'types';
import {FetchExtras, ResponseWithHeaders} from 'utils/ajax';
import {defaultDevOptions} from 'widgets/utils';

import {ComputeResultExtended} from './types';

const COMPUTE_CACHE_COOKIE = 'alloy_compute_cache_enabled';

function isComputeCacheGloballyEnabled(): boolean {
  const computeCacheCookie = Cookies.get()[COMPUTE_CACHE_COOKIE];
  return computeCacheCookie !== 'false';
}

export function toggleComputeCacheGlobally(seconds: number) {
  const enabled = isComputeCacheGloballyEnabled();
  if (enabled) {
    Cookies.set(COMPUTE_CACHE_COOKIE, 'false', {expires: new Date(+new Date() + seconds * 1000)});
  } else {
    Cookies.remove(COMPUTE_CACHE_COOKIE);
  }
  return !enabled;
}

export function getGlobalDevOptionOverrides(): Partial<Types.ComputeDevOptions> {
  return isComputeCacheGloballyEnabled() ? {} : {useComputeCache: false, useBigQueryCache: false};
}

export function withDevOptions(request: Types.ComputeRequest): Types.ComputeRequest {
  return {
    ...request,
    devOptions: {...defaultDevOptions, ...request.devOptions, ...getGlobalDevOptionOverrides()},
  };
}

export async function withExtendedComputeResult(
  request: Types.ComputeRequest,
  computeResultPromise: Promise<Types.ComputeResult>
) {
  const resultAndHeaders = await computeResultPromise;
  return ComputeResultExtended.fromJS(
    request,
    // Since we called the API with returnResponseHeaders right above,
    // we know the returned type is ResponseWithHeaders.
    // This is a hack to get this HTTP header information through
    // the autogenerated Api.Compute.compute which has opinions about types.
    resultAndHeaders as ResponseWithHeaders<Types.ComputeResult>
  );
}

export async function fetchComputeResult(
  request: Types.ComputeRequest,
  viewId?: string | null,
  widgetId?: string | null,
  extras?: FetchExtras
): Promise<ComputeResultExtended> {
  return withExtendedComputeResult(
    request,
    Api.Compute.compute(withDevOptions(request), {
      ...extras,
      returnResponseHeaders: true,
      extraHeaders: {'Alloy-View-Id': viewId, 'Alloy-Widget-Id': widgetId},
    })
  );
}

export async function fetchPlanningComputeResult(
  request: Types.PlanComputeRequest,
  extras?: FetchExtras
): Promise<ComputeResultExtended> {
  return Api.Compute.plan(request, {...extras, returnResponseHeaders: true}).then(
    resultAndHeaders => {
      return ComputeResultExtended.fromJS(
        request.computeRequest,
        resultAndHeaders as ResponseWithHeaders<Types.ComputeResult>
      );
    }
  );
}
