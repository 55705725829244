import {Seq, Map as ImmutableMap} from 'immutable';

export function groupBy<K, T, V>(
  items: readonly T[],
  keyMapper: (item: T) => K,
  valueMapper: (item: T) => V,
  unique = false
): ImmutableMap<K, readonly V[]> {
  return Seq(items)
    .groupBy(keyMapper)
    .map(values => {
      const mappedValues = values.valueSeq().map(valueMapper);
      if (unique) {
        return mappedValues.toOrderedSet().toArray();
      }
      return mappedValues.toArray();
    })
    .toMap();
}

export function mapValue<K, T, V>(
  originalMap: ReadonlyMap<K, T> | ImmutableMap<K, T>,
  valueMapper: (value: T) => V
): ReadonlyMap<K, V> {
  const result: Map<K, V> = new Map();
  originalMap.forEach((originalValue, key) => {
    const newValue = valueMapper(originalValue);
    result.set(key, newValue);
  });
  return result;
}
