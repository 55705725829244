import equal from 'fast-deep-equal';
// eslint-disable-next-line no-restricted-imports
import {useSelector as useReduxSelector} from 'react-redux';

import {RootState} from 'redux/reducers';

// for selecting objects so large that the cost of deep comparison outweighs the benefit
// of fewer react updates
export function useFastComparingRedundantUpdatingSelector<T>(selector: (state: RootState) => T) {
  return useReduxSelector(selector, (left, right) => left === right);
}

export default function useSelector<T>(selector: (state: RootState) => T) {
  return useReduxSelector(selector, equal);
}
