import equal from 'fast-deep-equal/es6';
import {List, Map} from 'immutable';

import {
  createAttributeInstance,
  fromThinValue,
  isLocationAttribute,
  isPartner,
  isProductAttribute,
  isThinAttributeInstance,
  LOADING_DISPLAY_VALUE,
  THIN_ATTRIBUTE_PLACEHOLDER_NAME,
  toThinAttributeInstance,
} from 'toolkit/attributes/utils';
import Format from 'toolkit/format/format';
import {capitalize} from 'toolkit/format/text';
import {getEffectiveMetric, getMetricsList} from 'toolkit/metrics/utils';
import {PUBLIC_VENDOR_ID} from 'toolkit/users/utils';
import {ThinAttributeFilter} from 'toolkit/views/types';
import * as Types from 'types';
import {ascendingBy, removeAt, removeValue, uniquify} from 'utils/arrays';
import {assertNonNullish, assertTruthy} from 'utils/assert';
import {identity} from 'utils/functions';
import {findMetricFilterUnsupportedGroupings} from 'widgets/utils';

import {UnsupportedMetricFilterExplanation} from './types';

/**
 * NOTE: The logic to merge filters in the front-end needs to be consistent with
 * how the backend merges filters. See https://github.com/alloytech/alloy/pull/7609
 * for more context, and FilterKeys.java for the backend implementation.
 *
 * on the client we don't remove noop filters for ui reasons. this is only done on the backend
 */
export const mergeAttributeFilters = (
  filters: readonly Types.AttributeFilter[]
): readonly Types.AttributeFilter[] => {
  return List(filters)
    .groupBy(grouperFilters)
    .valueSeq()
    .map(filterMap => mergeFiltersByAttribute(filterMap.valueSeq().toArray()))
    .toArray();
};

export const mergeFiltersByAttribute = (filters: readonly Types.AttributeFilter[]) => {
  const filtersInclusive = filters.filter(isInclusive);
  const filtersExclusive = filters.filter(filter => !isInclusive(filter));

  const mergedInclusive = mergeFiltersBy(intersectByValueId, filtersInclusive);
  if (!filtersExclusive.length) {
    return mergedInclusive;
  }

  const mergedExclusive = mergeFiltersBy(unionByValueId, filtersExclusive);
  if (!filtersInclusive.length) {
    return mergedExclusive;
  }

  const mergedValues = subtractByValueId(mergedInclusive.values, mergedExclusive.values);
  return updateFilterValues(mergedInclusive, mergedValues);
};

export const isFilterForAttributeInstance = (
  attributeInstance: Types.AttributeInstance,
  filter: Types.AttributeFilter
) => {
  return equal(filter.attributeInstance, attributeInstance);
};

export const getWidgetIndicesWithConflictingSelectionFilters = (
  newFilters: readonly Types.AttributeFilter[],
  existingFiltersByWidgetIndex: Map<number, readonly Types.AttributeFilter[]>
): readonly number[] => {
  const newFiltersByAttributeId = Map(
    newFilters.map(filter => [filter.attributeInstance.attribute.id, filter])
  );
  return existingFiltersByWidgetIndex
    .filter(filters =>
      filters.some(filter => {
        const existingFilter = newFiltersByAttributeId.get(filter.attributeInstance.attribute.id);
        return existingFilter && filtersOnSameAttributeConflict(filter, existingFilter);
      })
    )
    .keySeq()
    .toArray();
};

export const filtersOnSameAttributeConflict = (
  left: Types.AttributeFilter,
  right: Types.AttributeFilter
) => {
  if (!left.inclusive && !right.inclusive) {
    return false;
  }
  const leftValueIds = left.values.map(value => value.id);
  const rightValueIds = right.values.map(value => value.id);
  if (!left.inclusive) {
    return rightValueIds.every(id => leftValueIds.includes(id));
  } else if (!right.inclusive) {
    return leftValueIds.every(id => rightValueIds.includes(id));
  }
  return leftValueIds.every(id => !rightValueIds.includes(id));
};

export const invertFilter = (filter: Types.AttributeFilter) =>
  createFilter(filter.attributeInstance, filter.values, !filter.inclusive);

export const flattenFilterMap = (filterMap: Map<number, readonly Types.AttributeFilter[]>) =>
  filterMap
    .valueSeq()
    .flatMap(filters => filters)
    .toArray();

export function withoutGraphContext(filter: Types.AttributeFilter) {
  return !!filter.attributeInstance.graphContext
    ? {...filter, attributeInstance: {...filter.attributeInstance, graphContext: null}}
    : filter;
}

export function isLocationFilter(attributeFilter: Types.AttributeFilter) {
  return isLocationAttribute(attributeFilter.attributeInstance.attribute);
}
export function isProductFilter(attributeFilter: Types.AttributeFilter) {
  return isProductAttribute(attributeFilter.attributeInstance.attribute);
}

type FilterValues = readonly Types.AttributeValue[];
type ReducerFn<T, G> = (acc: G, items: T) => G;

const mergeFiltersBy = (
  reduceFn: ReducerFn<FilterValues, FilterValues>,
  filters: readonly Types.AttributeFilter[]
) => {
  const first = filters[0];
  const rest = removeAt(filters, 0);

  if (!rest.length) {
    return first;
  }
  const mergedValues = rest.map(filter => filter.values).reduce(reduceFn, first.values);
  return {...first, values: mergedValues};
};

const intersectByValueId = (setA: FilterValues, setB: FilterValues) => {
  const keysB = setB.map(attributeValueId);
  return setA.filter(valueA => keysB.includes(attributeValueId(valueA)));
};

const unionByValueId = (setA: FilterValues, setB: FilterValues) =>
  uniquify(
    setB.reduce((acc, valueB) => {
      const keysA = acc.map(attributeValueId);
      return keysA.includes(attributeValueId(valueB)) ? acc : [...acc, valueB];
    }, setA)
  );

const subtractByValueId = (setA: FilterValues, setB: FilterValues) =>
  setB.reduce((acc, valueB) => {
    const keysA = acc.map(attributeValueId);
    return keysA.includes(attributeValueId(valueB)) ? removeValue(acc, valueB) : acc;
  }, setA);

const attributeValueId = (value: Types.AttributeValue) => value.id;

const grouperFilters = (filter: Types.AttributeFilter) =>
  `${filter.attributeInstance.attribute.id}-${filter.attributeInstance.graphContext}`;

const isInclusive = (filter: Types.AttributeFilter) => filter.inclusive;

export function getAlwaysPassFilter(filter: Types.AttributeFilter): Types.AttributeFilter {
  return {...filter, inclusive: false, values: []};
}
export function isAlwaysPassFilter(filter: Types.AttributeFilter) {
  return !filter.inclusive && filter.values.length === 0;
}

// equivalent to AttributeFilter::alwaysPass in Pewter
export function getAlwaysPassFilterForAttribute(attribute: Types.Attribute): Types.AttributeFilter {
  return {
    attributeInstance: {attribute, graphContext: null},
    inclusive: false,
    values: [],
  };
}

export function isNeverPassFilter(filter: Types.AttributeFilter) {
  return filter.inclusive && filter.values.length === 0;
}

export function toAttributeFilter(filter: ThinAttributeFilter): Types.AttributeFilter {
  const attribute: Types.Attribute = {
    id: filter.attribute.id,
    name: THIN_ATTRIBUTE_PLACEHOLDER_NAME,
    // the type is obviously a guess but the thin attribute does not contain the type.
    type: Types.AttributeType.PRODUCT,
    valueType: Types.AttributeValueType.string,
    vendorId: PUBLIC_VENDOR_ID,
    matching: false,
    partnerId: null,
    templateAttributeId: filter.attribute.templateAttributeId,
    templateAttributeName: THIN_ATTRIBUTE_PLACEHOLDER_NAME,
  };
  const attributeInstance: Types.AttributeInstance = {
    attribute,
    // graphContext may be undefined if this filter was minified (by toMinifiedJSAttributeInstance)
    graphContext: filter.attribute.graphContext ?? null,
  };
  return createFilter(
    attributeInstance,
    filter.valueIds.map(id => ({
      attribute,
      derived: false,
      displayValue: LOADING_DISPLAY_VALUE,
      id,
      value: id,
    })),
    filter.inclusive ?? true
  );
}

export const toThinAttributeFilter = (filter: Types.AttributeFilter): ThinAttributeFilter => ({
  attribute: toThinAttributeInstance(filter.attributeInstance),
  inclusive: filter.inclusive,
  valueIds: filter.values.map(value => assertNonNullish(value.id)),
});

export function createDefaultFilter(
  attributeInstance: Types.AttributeInstance,
  inclusive = true
): Types.AttributeFilter {
  if (attributeInstance.attribute.valueType === Types.AttributeValueType.date) {
    throw new Error(
      `Tried to create a filter with date attribute ${attributeInstance.attribute.name}`
    );
  }

  return {
    attributeInstance,
    inclusive,
    values: [],
  };
}

export function createFilterFromThinValues(
  attributeOrInstance: Types.Attribute | Types.AttributeInstance,
  thinAttributeValues: readonly Types.ThinAttributeValue[],
  inclusive = true
) {
  const attribute =
    'attribute' in attributeOrInstance ? attributeOrInstance.attribute : attributeOrInstance;
  const attributeValues = thinAttributeValues.map(value =>
    assertTruthy(fromThinValue(value, attribute))
  );
  return createFilter(attribute, attributeValues, inclusive);
}

export function createFilter(
  attributeOrInstance: Types.Attribute | Types.AttributeInstance,
  attributeValues: readonly Types.AttributeValue[],
  inclusive = true
): Types.AttributeFilter {
  const attributeInstance =
    'attribute' in attributeOrInstance
      ? attributeOrInstance
      : createAttributeInstance(attributeOrInstance)!;
  return {
    ...createDefaultFilter(attributeInstance, inclusive),
    values: uniquify(
      attributeValues.map(attributeValue => ({
        ...attributeValue,
        attribute: attributeInstance.attribute,
      }))
    ),
  };
}

export function createFilterForValue(
  attributeValue: Types.AttributeValue,
  inclusive = true
): Types.AttributeFilter {
  const attributeInstance = createAttributeInstance(attributeValue.attribute);
  return {
    ...createDefaultFilter(attributeInstance, inclusive),
    values: [attributeValue],
  };
}

export function updateFilterValues(
  filter: Types.AttributeFilter,
  newValues: readonly Types.AttributeValue[]
): Types.AttributeFilter {
  return {
    ...filter,
    values: uniquify(newValues),
  };
}

export function getPartnerFilters(filters: readonly Types.AttributeFilter[]) {
  return filters.filter(filter => filter.attributeInstance.attribute.name === 'Partner');
}

const isIncompleteAttributeValue = (attributeValue: Types.AttributeValue) =>
  attributeValue.displayValue === LOADING_DISPLAY_VALUE;

export const hasIncompleteFilterValues = (filters: readonly Types.AttributeFilter[]) =>
  filters.some(
    filter =>
      isThinAttributeInstance(filter.attributeInstance) ||
      filter.values.some(isIncompleteAttributeValue)
  );

export const hasIncompleteSelectionFilterValues = (
  selectionFilters: Map<number, readonly Types.AttributeFilter[]>
) =>
  selectionFilters
    .valueSeq()
    .flatMap(identity)
    .some(
      filter =>
        isThinAttributeInstance(filter.attributeInstance) ||
        filter.values.some(isIncompleteAttributeValue)
    );

export function getFilterDisplayValues(
  filter: Types.AttributeFilter,
  prefixGraphContext?: boolean
) {
  const graphContextPrefix =
    prefixGraphContext && filter.attributeInstance.graphContext
      ? `${capitalize(filter.attributeInstance.graphContext)}: `
      : '';
  return filter.values
    .map(value => `${graphContextPrefix}${Format.attributeValue(value)}`)
    .sort(ascendingBy(value => value));
}

export function getFilterByAttrName(filters: readonly Types.AttributeFilter[], name: string) {
  return filters.find(filter => filter.attributeInstance.attribute.name === name);
}

export function getLocationIdFromFilters(selectionFilters: readonly Types.AttributeFilter[]) {
  const locationFilter = getFilterByAttrName(selectionFilters, 'Location');
  return locationFilter ? assertTruthy(locationFilter.values[0].id) : null;
}

export function getUnsupportedMetricFilterExplanantion(
  widgetConfig: Types.Widget
): UnsupportedMetricFilterExplanation {
  const unsupportedGroupings = findMetricFilterUnsupportedGroupings(widgetConfig);

  const allGroupings = [...widgetConfig.rowGroupings, ...widgetConfig.columnGroupings];
  const hasProductOrLocationGroupings =
    allGroupings.some(grouping => grouping.attribute.type === Types.AttributeType.LOCATION) ||
    allGroupings.some(grouping => grouping.attribute.type === Types.AttributeType.PRODUCT);
  const hasLocationCountMetrics = widgetConfig.metrics
    .map(({metricInstance}) => getEffectiveMetric(metricInstance))
    .some(
      metric =>
        metric!.features.includes(Types.MetricFeature.IS_LOCATION_COUNT) ||
        metric!.name === 'projected_location_count'
    );
  const mixesProductOrLocationWithLocationCount =
    hasProductOrLocationGroupings && hasLocationCountMetrics;

  const hasUnsupportedMetrics = widgetConfig.metrics
    .flatMap(({metricInstance}) => getMetricsList(metricInstance))
    .some(metric => metric.name === 'per_store');

  const metricGroupings = widgetConfig.metrics
    .map(config => config.metricInstance.arguments.groupings)
    .filter(Boolean);
  const metricFilterMetricGroupings = widgetConfig.metricFilters
    .flatMap(x => x)
    .map(filter => filter.metric.arguments.groupings)
    .filter(Boolean);
  const hasUnsupportedMetricGroupings =
    widgetConfig.metricFilters.length > 0 &&
    metricFilterMetricGroupings.length > 0 &&
    (metricGroupings.length > 1 ||
      metricFilterMetricGroupings.length > 1 ||
      metricGroupings.length !== metricFilterMetricGroupings.length ||
      !equal(metricGroupings[0], metricFilterMetricGroupings[0]));

  return {
    unsupportedGroupings,
    hasUnsupportedMetrics,
    hasUnsupportedMetricGroupings,
    mixesProductOrLocationWithLocationCount,
  };
}

export function getNonPartnerFilters(
  filters: readonly Types.AttributeFilter[]
): readonly Types.AttributeFilter[] {
  return filters.filter(filter => !isPartner(filter.attributeInstance.attribute));
}

export function isSyndicatedDataFilter(
  filter: Types.AttributeFilter | null,
  syndicatedPartners: readonly Types.Partner[]
): boolean {
  return (
    !!filter &&
    filter.attributeInstance.attribute.name === 'Partner' &&
    filter.values.length > 0 &&
    filter.values.every(value =>
      syndicatedPartners.some(syndicatedPartner => syndicatedPartner.id === value.id)
    )
  );
}
