import React from 'react';

import {IconSpec} from 'toolkit/icons/types';
import * as Types from 'types';

export enum DateSortType {
  LastModified = 'Last Modified',
  LastOpenedByMe = 'Last Viewed By Me',
  LastOpenedByAnyone = 'Last Viewed',
}

export enum AnalysisEntityType {
  DASHBOARD = 'DASHBOARD',
  EXPERIMENT = 'EXPERIMENT',
  PAGE = 'PAGE',
  PLAN = 'PLAN',
  EVENT = 'EVENT',
  DESTINATION = 'DESTINATION',
  STATUS = 'STATUS',
  TEMPLATE = 'TEMPLATE',
  WORKFLOW = 'WORKFLOW',
}

interface BaseAnalysisEntity {
  readonly id: number | null;
  readonly isFavorite?: boolean;
  readonly lastVisited?: string | null | undefined;
  readonly isPrivate?: boolean;
  readonly icon?: IconSpec;
  readonly name: string;
  readonly nameRenderer?: (entity: AnalysisEntity) => React.ReactElement;
  readonly ownerId: number | null;
  readonly tags: readonly string[];
  readonly url: string;
  readonly type: AnalysisEntityType;
  readonly keywords?: readonly string[];
}

export interface DashboardAnalysisEntity<View = Types.ThinView> extends BaseAnalysisEntity {
  readonly type: AnalysisEntityType.DASHBOARD;
  readonly value: View;
  readonly isAdditionalTemplate: boolean;
  readonly workflows: readonly Types.Workflow[] | null;
}

export interface TemplateAnalysisEntity<View = Types.ThinView> extends BaseAnalysisEntity {
  readonly type: AnalysisEntityType.TEMPLATE;
  readonly value: View;
  readonly isAdditionalTemplate: boolean;
}

export interface ExperimentAnalysisEntity extends BaseAnalysisEntity {
  readonly type: AnalysisEntityType.EXPERIMENT;
  readonly value: Types.Experiment;
}

export interface PlanAnalysisEntity extends BaseAnalysisEntity {
  readonly type: AnalysisEntityType.PLAN;
  readonly value: Types.PlanVersion;
  readonly linkingEntityName: string;
}

export interface PageAnalysisEntity extends BaseAnalysisEntity {
  readonly type: AnalysisEntityType.PAGE;
}

export interface EventAnalysisEntity extends BaseAnalysisEntity {
  readonly type: AnalysisEntityType.EVENT;
  readonly value: Types.CalendarEvent;
}

export interface WorkflowAnalysisEntity extends BaseAnalysisEntity {
  readonly type: AnalysisEntityType.WORKFLOW;
  readonly workflow: Types.Workflow;
  readonly description: string;
}

export type AnalysisEntity =
  | DashboardAnalysisEntity<Types.ThinView>
  | DashboardAnalysisEntity<Types.View>
  | ExperimentAnalysisEntity
  | PageAnalysisEntity
  | PlanAnalysisEntity
  | EventAnalysisEntity
  | TemplateAnalysisEntity<Types.ThinView>
  | TemplateAnalysisEntity<Types.View>
  | WorkflowAnalysisEntity;

export type AnalysisManagementEntity =
  | DashboardAnalysisEntity
  | ExperimentAnalysisEntity
  | TemplateAnalysisEntity
  | WorkflowAnalysisEntity;

export type AnalysisManagementEntityType =
  | AnalysisEntityType.DASHBOARD
  | AnalysisEntityType.EXPERIMENT
  | AnalysisEntityType.TEMPLATE
  | AnalysisEntityType.WORKFLOW;

export type ViewOrExperiment = Types.View | Types.Experiment;
