import Format from 'toolkit/format/format';
import * as Types from 'types';
import {assertTruthy} from 'utils/assert';
import {isTruthy} from 'utils/functions';

import {MetricsByName} from './types';
import {getEffectiveMetric} from './utils';

function formatMetricValueLabel(label: string, metric: Types.MetricInstance, value: number | null) {
  return value !== null && value !== undefined
    ? `${label}: ${Format.metricValue(metric, value)}`
    : `${label}: unset`;
}

export function getTooltipTextForMetadata(
  metadataEntry: Types.MetricValueMetadata,
  metricInstance: Types.MetricInstance,
  metricsByName: MetricsByName
) {
  switch (metadataEntry.type) {
    case 'TEXT':
      return metadataEntry.value;
    case 'ORIGINAL_VALUE':
      return formatMetricValueLabel('Original value', metricInstance, metadataEntry.value);
    case 'TARGET_VALUE': {
      const effectiveMetricInstance: Types.MetricInstance = {
        metric: assertTruthy(getEffectiveMetric(metricInstance)),
        arguments: metricInstance.arguments,
      };
      return formatMetricValueLabel('Target', effectiveMetricInstance, metadataEntry.value);
    }
    case 'SOURCE_METRIC': {
      return `Source: ${metricsByName.get(metadataEntry.value, null)?.displayName || 'Unknown'}`;
    }
    default:
      return undefined;
  }
}

export function getTooltipText(
  metadata: readonly Types.MetricValueMetadata[] | null,
  metric: Types.MetricInstance,
  metricsByName: MetricsByName
) {
  if (!metadata) {
    return undefined;
  }
  return metadata
    .map(item => getTooltipTextForMetadata(item, metric, metricsByName))
    .filter(isTruthy)
    .join('\n');
}
