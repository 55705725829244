import {List} from 'immutable';

import * as Api from 'api';
import {ViewState} from 'toolkit/views/types';
import {isSaveableViewId} from 'toolkit/views/utils';
import * as Types from 'types';
import {history} from 'utils/history';
import {Widgets} from 'widgets/utils';
import {extractComputeResult} from 'widgets/widget-data';
import {getWidgetTreeWithoutData} from 'widgets/widget-layout';

type NavigationTimingKey = keyof Types.BrowserNavigationTiming & keyof PerformanceNavigationTiming;

const NAVIGATION_TIMING_PROPERTIES: NavigationTimingKey[] = [
  'connectStart',
  'connectEnd',
  'decodedBodySize',
  'domComplete',
  'domContentLoadedEventStart',
  'domContentLoadedEventEnd',
  'domInteractive',
  'encodedBodySize',
  'fetchStart',
  'loadEventEnd',
  'loadEventStart',
  'requestStart',
  'responseEnd',
  'responseStart',
  'startTime',
  'type',
];

// Layouts that span past the screen height are not currently supported
function isSupportedLayout(view: Types.View): boolean {
  if (
    view.layoutType === Types.LayoutType.HORIZONTAL ||
    view.layoutType === Types.LayoutType.VERTICAL ||
    // Note that landing pages are only supported because they have `isRenderedOffscreen`
    // flag for widgets; this makes them rendered also when not on screen.
    view.layoutType === Types.LayoutType.LANDING_PAGE
  ) {
    return true;
  } else if (view.layoutType === Types.LayoutType.GRID && view.isFixedHeight) {
    return true;
  }

  return false;
}

function createNavigationTiming(
  timing: Partial<Types.BrowserNavigationTiming>
): Types.BrowserNavigationTiming {
  // all fields in BrowserNavigationTiming are optional, but codegen doesn't capture that properly
  return timing as Types.BrowserNavigationTiming;
}

function getNavigationTiming(): Types.BrowserNavigationTiming {
  const navigationEntries = performance.getEntriesByType('navigation');
  if (navigationEntries.length !== 1) {
    return createNavigationTiming({});
  }
  const entry = navigationEntries[0] as PerformanceNavigationTiming;
  return createNavigationTiming(
    Object.fromEntries(NAVIGATION_TIMING_PROPERTIES.map(prop => [prop, entry[prop]]))
  );
}

function getDefaultWidgetTrackingEvent(widget: Types.Widget): Types.WidgetTrackingEvent {
  return {
    widgetId: widget.id!,
    widgetType: widget.type,
    customName: widget.customName,
    cacheHit: null,
    loadStart: null,
    loadEnd: null,
  };
}

interface ViewLoadingState {
  readonly viewId: number;
  readonly viewType: Types.ViewType;
  // Note that the map itself is mutable although the field is readonly
  readonly widgetStateByWidgetId: Map<number, Types.WidgetTrackingEvent>;
}

function isComplete(state: ViewLoadingState) {
  for (const widgetState of state.widgetStateByWidgetId.values()) {
    if (!widgetState.loadEnd) {
      return false;
    }
  }
  return true;
}

class PageLoadTracker {
  private enabled = true;
  private viewLoadingState: ViewLoadingState | null = null;
  private readonly pageLoadUrl: string = location.href;
  private viewOpenedTime = 0;
  private readonly unregisterHistoryListener: () => void = () => {};
  private referrer: string | null = null;
  private url: string = this.pageLoadUrl;

  constructor() {
    this.unregisterHistoryListener = history.listen(this.trackLocationChange);
  }

  trackLocationChange = () => {
    if (!this.enabled) {
      return;
    }
    this.viewOpenedTime = performance.now();
    this.referrer = this.url;
    this.url = location.href;
  };

  trackWidgetDataProvider<WidgetDataClass>(
    view: Types.View,
    widgetIndex: number,
    dataProvider: () => Promise<WidgetDataClass> | null
  ): Promise<WidgetDataClass | null> {
    const promise = Promise.resolve(dataProvider());
    if (!view.id) {
      return promise;
    }
    const start = performance.now();
    return promise.then(data => {
      const end = performance.now();
      const cacheHit = extractComputeResult(data)?.cacheStatus === Types.CacheInitialState.HIT;
      this.trackWidget(view, widgetIndex, start, end, cacheHit ?? null);
      return data;
    });
  }

  trackViewChange(viewState: ViewState) {
    if (!this.enabled) {
      return;
    }
    if (!isSaveableViewId(viewState.view.id)) {
      // only track saved dashboards
      this.reset();
      return;
    }

    if (!isSupportedLayout(viewState.view)) {
      this.reset();
      return;
    }

    const widgetTree = getWidgetTreeWithoutData(viewState.view.widgets);

    this.viewLoadingState = {
      viewId: viewState.view.id,
      viewType: viewState.view.type,
      widgetStateByWidgetId: new Map(
        widgetTree
          .map(widgetLayoutData => {
            if ((widgetLayoutData.children?.size ?? 0) > 0) {
              return widgetLayoutData.widget.type === Types.WidgetType.TAB_WIDGET
                ? List.of(widgetLayoutData.children!.get(0)!)
                : widgetLayoutData.children!;
            }
            return List.of(widgetLayoutData);
          })
          .flatMap(list => list)
          .filter(widgetLayoutData => !!Widgets[widgetLayoutData!.widget.type].dataProvider)
          .map(widgetLayoutData => widgetLayoutData!.widget)
          .map(widget => [widget.id!, getDefaultWidgetTrackingEvent(widget)])
      ),
    };
  }

  untrackWidget(widgetId: number) {
    this.viewLoadingState?.widgetStateByWidgetId.delete(widgetId);
  }

  private trackWidget(
    view: Types.View,
    widgetIndex: number,
    loadStart: number,
    loadEnd: number,
    cacheHit: boolean | null
  ) {
    if (!this.viewLoadingState) {
      return;
    }
    const state = this.viewLoadingState;
    const widget = view.widgets[widgetIndex];
    if (!widget.id) {
      return;
    }
    const widgetState = state.widgetStateByWidgetId.get(widget.id);
    if (!widgetState) {
      return;
    }
    state.widgetStateByWidgetId.set(widget.id, {
      ...widgetState,
      cacheHit,
      loadStart,
      loadEnd,
    });
    if (isComplete(state)) {
      this.publish();
    }
  }

  publish() {
    if (!this.enabled || !this.viewLoadingState) {
      return;
    }
    // We only track navigation timing for the first page load
    const navigationTiming = this.pageLoadUrl === location.href ? getNavigationTiming() : null;
    const trackingEvent: Types.ViewTrackingEvent = {
      url: location.href,
      viewId: this.viewLoadingState.viewId,
      viewType: this.viewLoadingState.viewType,
      timestamp: performance.now() - this.viewOpenedTime,
      widgets: [...this.viewLoadingState.widgetStateByWidgetId.values()],
      navigationTiming,
      referrer: this.referrer,
    };
    this.reset();
    return Api.Tracking.trackView(trackingEvent);
  }

  reset() {
    this.viewLoadingState = null;
  }

  disable() {
    this.enabled = false;
    this.unregisterHistoryListener();
  }
}

export const tracker = new PageLoadTracker();
