import './TopBarSearchInput.scss';

import React from 'react';

import Icon from 'toolkit/components/Icon';

import TopBarInput from './TopBarInput';

const TopBarSearchInput: React.FunctionComponent<Props> = ({
  placeholder = 'Search...',
  inputRef,
  ...props
}) => {
  return (
    <div className="TopBarSearchInput">
      <div className="icon">
        <Icon icon="search" />
      </div>
      <TopBarInput inputRef={inputRef} placeholder={placeholder} {...props} />
    </div>
  );
};

TopBarSearchInput.displayName = 'TopBarSearchInput';
type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  inputRef?: React.Ref<HTMLInputElement>;
  placeholder?: string;
};

export default TopBarSearchInput;
