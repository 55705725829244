import classNames from 'classnames';
import React from 'react';

import {useCurrentUser} from 'redux/context/user';
import {getAllMetricsWithAnyData} from 'redux/selectors/analysis';
import useSelector from 'redux/selectors/useSelector';
import Centered from 'toolkit/components/Centered';
import {getMobileClassName} from 'toolkit/styles/utils';
import {hasPermission} from 'toolkit/users/utils';
import * as Types from 'types';

const NoViewsPlaceholder: React.FC<Props> = ({onClick}) => {
  const availableMetrics = useSelector(getAllMetricsWithAnyData);
  const currentUser = useCurrentUser();
  return (
    <Centered
      className={classNames('no-views-placeholder', getMobileClassName())}
      onClick={!availableMetrics.isEmpty() ? onClick : undefined}
    >
      <div className="no-content">
        {!hasPermission(currentUser, Types.PermissionKey.DASHBOARD_VIEW_DASHBOARD)
          ? 'User not authorized. Contact support to gain access.'
          : availableMetrics.isEmpty()
            ? 'No metrics found! Set up some data first before creating dashboards.'
            : 'No dashboards found. Click to create one.'}
      </div>
    </Centered>
  );
};
interface Props {
  onClick?: () => void;
}

NoViewsPlaceholder.displayName = 'NoViewsPlaceholder';
export default NoViewsPlaceholder;
