import './LoadingOverlay.scss';

import {useSpring, animated} from '@react-spring/web';
import React from 'react';

import {noop} from 'utils/functions';

import ErrorPlaceholder from './ErrorPlaceholder';
import Loader from './Loader';

const LoadingOverlay: React.FunctionComponent<Props> = ({
  className = 'loading-overlay-wrapper',
  isLoading = false,
  error,
  children,
  onRetry = noop,
  ...rest
}) => {
  const failed = !!error;

  const opacityProps = useSpring({opacity: failed ? 0.85 : 0.4, from: {opacity: 0}});

  return (
    <div className="LoadingOverlay" {...rest}>
      <div className={className}>
        {children}
        {(isLoading || failed) && (
          <animated.div className="opacity-mask" style={opacityProps}>
            {isLoading ? (
              <Loader hideLabel />
            ) : (
              <ErrorPlaceholder error={error} onRetry={onRetry} />
            )}
          </animated.div>
        )}
      </div>
    </div>
  );
};

type Props = React.HTMLAttributes<HTMLDivElement> & {
  className?: string;
  error?: Error | string | null;
  isLoading?: boolean;
  onRetry?: () => void;
};

export default LoadingOverlay;
