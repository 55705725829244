import {PUBLIC_VENDOR_ID} from 'toolkit/users/utils';
import * as Types from 'types';
import {descendingBy} from 'utils/arrays';

function isPublicVendor(vendor: Types.Vendor) {
  return vendor.id === PUBLIC_VENDOR_ID;
}

export const vendorComparator = descendingBy(isPublicVendor).thenAscendingBy(
  vendor => vendor.displayName
);

export function getVendorDisplayName(vendor?: Types.Vendor | null) {
  return vendor ? vendor.displayName : 'Unknown Vendor';
}
