import './BaseTruncatedCellRenderer.scss';

import React from 'react';

import {TypedCellRendererParams} from 'toolkit/ag-grid/types';

const BaseTruncatedCellRenderer = <T,>({value, valueFormatted}: TypedCellRendererParams<T>) => {
  return (
    <div className="BaseTruncatedCellRenderer">
      <div className="content">{valueFormatted ?? value}</div>
    </div>
  );
};
BaseTruncatedCellRenderer.displayName = 'BaseTruncatedCellRenderer';

export default BaseTruncatedCellRenderer;
