import * as Types from 'types';

export class ApiError extends Error {
  public readonly code: number;
  public readonly error: Types.ErrorResponse | string | null;
  public readonly logErrors?: boolean;
  public readonly requestUrl?: string;
  public readonly traceId?: string | null;

  constructor(
    code: number,
    message: string,
    error: Types.ErrorResponse | string | null,
    logErrors: boolean,
    requestUrl: string,
    traceId: string | null,
    ...params: any[]
  ) {
    super(...params);
    this.code = code;
    this.error = error;
    this.logErrors = logErrors;
    this.requestUrl = requestUrl;
    this.traceId = traceId;
    this.message = message;
    this.name = 'ApiError';

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }
  }
}

export class FailedToFetchError extends Error {
  public readonly requestUrl?: string;
  public readonly logErrors?: boolean;
  public readonly originalError: Error;

  constructor(originalError: Error, requestUrl: string, logErrors: boolean) {
    super(originalError.message);
    this.requestUrl = requestUrl;
    this.logErrors = logErrors;
    this.name = 'FailedToFetchError';
    this.originalError = originalError;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FailedToFetchError);
    }
  }
}

export class InterruptedByRedirect extends Error {}

export class UserFacingError extends Error {}
