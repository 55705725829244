import ActionType from 'redux/actions/types';
import * as Types from 'types';

export interface EnvironmentAction {
  readonly type: ActionType.SetEnvironment;
  readonly environment: Types.Environment;
}

export function setEnvironment(environment: Types.Environment): EnvironmentAction {
  return {
    environment,
    type: ActionType.SetEnvironment,
  };
}
