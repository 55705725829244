import './Tag.scss';

import classNames from 'classnames';
import React from 'react';

import {GLOBAL_SEARCH_ITEM_CLASSNAME} from 'nav/utils';

const Tag: React.FC<Props> = ({color = 'green', name, onItemClick, onKeyDown}) => {
  const handleItemClick = (event: React.MouseEvent<HTMLElement>, name: string) => {
    event.preventDefault();
    onItemClick!(name);
  };
  return (
    <button
      className={classNames('tag-item', GLOBAL_SEARCH_ITEM_CLASSNAME, color, {
        clickable: onItemClick !== undefined,
      })}
      onClick={event => (onItemClick ? handleItemClick(event, name) : undefined)}
      onKeyDown={onKeyDown}
    >
      {name}
    </button>
  );
};
export default Tag;
export type TagColor = 'blue' | 'green';
interface Props {
  color?: TagColor;
  name: string;
  onItemClick?: (name: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
}
