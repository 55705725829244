import './EmptyListPlaceholder.scss';

import classNames from 'classnames';
import React, {ReactElement} from 'react';

import Icon from 'toolkit/components/Icon';

const EmptyListPlaceholder: React.FC<Props> = ({className, hideIcon, noResultsText}: Props) => {
  return (
    <div className={classNames('EmptyListPlaceholder', className)}>
      {!hideIcon && <Icon icon={['far', 'frown']} />}
      <div className="no-results-text">{noResultsText ? noResultsText : 'Sorry, no results.'}</div>
    </div>
  );
};
EmptyListPlaceholder.displayName = 'EmptyListPlaceholder';
interface Props {
  className?: string;
  hideIcon?: boolean;
  noResultsText?: string | ReactElement;
}
export default EmptyListPlaceholder;
