import {CurrentUser} from 'redux/reducers/user';
import {hasAnyPermission, hasPermission, isAtLeast} from 'toolkit/users/utils';
import * as Types from 'types';
import {Role} from 'types';

export function canManageCurrencyConversionRates(currentUser: CurrentUser): boolean {
  return (
    currentUser.settings.analysisSettings.hasVendorCurrencyRates &&
    isAtLeast(currentUser.user.role, Role.ADMIN) &&
    hasPermission(currentUser, Types.PermissionKey.DATA_MANAGE_EXCHANGE_RATES)
  );
}

export function canViewCurrencyConversionRates(currentUser: CurrentUser): boolean {
  return hasAnyPermission(
    currentUser,
    Types.PermissionKey.DATA_MANAGE_EXCHANGE_RATES,
    Types.PermissionKey.COMPUTE_USE_CURRENCY_CONVERSION
  );
}
