import './ContextMenuPopover.scss';

import classNames from 'classnames';
import React from 'react';
import {Popover, PopoverProps} from 'react-bootstrap';

const ContextMenuPopover = React.forwardRef(
  (
    {className, id, style, onClick, children, variant = 'normal', ...otherProps}: Props,
    ref: React.Ref<HTMLDivElement>
  ) => (
    <Popover
      ref={ref}
      {...otherProps}
      className={classNames('ContextMenuPopover', variant, className)}
      id={id}
      style={{...style}}
      onClick={onClick}
    >
      <menu>{children}</menu>
    </Popover>
  )
);
type Props = PopoverProps & {
  children?: React.ReactNode;
  className?: string;
  id: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  style?: React.CSSProperties;
  variant?: 'normal' | 'large';
};

export default ContextMenuPopover;
