import './MetricDescriptionTooltip.scss';
import React from 'react';

import useSelector from 'redux/selectors/useSelector';
import Icon from 'toolkit/components/Icon';
import Tooltip from 'toolkit/components/Tooltip';
import {getEffectiveMetricName} from 'toolkit/metrics/utils';
import * as Types from 'types';
import {assertTruthy} from 'utils/assert';

export const DEFAULT_DESCRIPTION = 'A description of this metric is not currently available.';

const MetricDescriptionTooltip: React.FunctionComponent<Props> = ({children, metricInstance}) => {
  // Note: can't use selectors in out-of-tree components, e.g. Tooltip as they don't have a Provider
  const metricName = assertTruthy(getEffectiveMetricName(metricInstance));
  const description = useSelector(state => state.analysis.data.metricDescriptions).get(metricName);
  const isHidden = metricInstance?.metric.features.includes(Types.MetricFeature.IS_HIDDEN);
  const icon = isHidden ? 'eye-slash' : 'info-circle';
  const descriptionToShow = (
    <div>
      {isHidden && (
        <p>
          This metric is hidden from users. You are seeing it because you are in the development
          mode.
        </p>
      )}
      <p>{description || DEFAULT_DESCRIPTION}</p>
    </div>
  );
  return (
    <Tooltip
      placement="bottom"
      tooltip={descriptionToShow}
      tooltipClassName="metric-description-tooltip"
    >
      {children || <Icon className="metric-description-icon" icon={icon} />}
    </Tooltip>
  );
};

interface Props {
  children?: React.ReactNode;
  metricInstance: Types.MetricInstance | null;
}

MetricDescriptionTooltip.displayName = 'MetricDescriptionTooltip';
export default MetricDescriptionTooltip;
