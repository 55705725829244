import moment from 'moment-timezone';
import React, {useContext} from 'react';

import {useValue} from 'toolkit/utils/useValue';
import * as Types from 'types';

import {defaultCalendar} from './constants';

export interface IPeriodEvaluationContext {
  readonly evaluationDate: moment.Moment;
  readonly calendar: Types.RetailCalendarEnum;
}

export const PeriodEvaluationContext = React.createContext<IPeriodEvaluationContext>({
  evaluationDate: moment(),
  calendar: defaultCalendar,
});

export const WithPeriodEvaluationCalendar: React.FC<{
  calendar: Types.RetailCalendarEnum;
  children: React.ReactNode;
}> = ({calendar, children}) => {
  const outerContext = useContext(PeriodEvaluationContext);
  const newContext = useValue<IPeriodEvaluationContext>({
    ...outerContext,
    calendar,
  });
  return (
    <PeriodEvaluationContext.Provider value={newContext}>
      {children}
    </PeriodEvaluationContext.Provider>
  );
};
