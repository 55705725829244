import {List} from 'immutable';

import * as Globals from 'app/globals';
import {getUserTitle, isAlloyUser} from 'toolkit/users/utils';
import * as Types from 'types';
import {assertTruthy} from 'utils/assert';
import {isRunningEndToEndTests} from 'utils/cookies';

export function isIntercomEnabled(environment: Types.Environment) {
  return environment.intercomAppId && Globals.nodeEnv === 'production' && !isRunningEndToEndTests();
}

function getCompanyRecord(
  user: Types.User,
  allowedVendors: List<Types.Vendor>
): Intercom_.IntercomSettings['company'] {
  if (allowedVendors.size === 1) {
    return {id: assertTruthy(allowedVendors.first()!.id), name: allowedVendors.first()!.name};
  } else if (allowedVendors.size > 1 && isAlloyUser(user.email)) {
    return {id: -1, name: 'alloy'};
  }
  // technically possible but should not happen; we have no multi-vendor users anymore
  const vendorsList = allowedVendors
    .filter(vendor => vendor.active && vendor.id !== -1 && !vendor.isDemo)
    .map(vendor => vendor.name)
    .toArray()
    .sort()
    // There's a 255 character limit in place: prevent the API from returning HTTP error 422.
    .reduce((acc, vendorName) => {
      const value = acc ? `${acc},${vendorName}` : vendorName;
      return value.length < 255 ? value : acc;
    }, '');

  return {id: vendorsList, name: vendorsList};
}

export function configureIntercom(
  userData: Types.CurrentUserData,
  userIntercomCredentials: Types.UserIntercomCredentials,
  allowedVendors: List<Types.Vendor>,
  environment: Types.Environment,
  featureFlags: readonly string[]
) {
  const intercomAppId = environment.intercomAppId;
  if (!isIntercomEnabled(environment) || !window.Intercom) {
    return;
  }
  const user = userData.user;
  const company = getCompanyRecord(user, allowedVendors);
  const intercomSettings: Intercom_.IntercomSettings = {
    alignment: 'right',
    app_id: intercomAppId,
    custom_launcher_selector: '#intercom-button',
    hide_default_launcher: true,
    feature_flags: featureFlags.join(', '),
    email: user.email,
    company,
    horizontal_padding: 20,
    name: getUserTitle(user, userData.userInfo),
    phone: userData.userInfo.phoneNumber || undefined,
    user_hash: userIntercomCredentials.hashedId,
    user_id: userIntercomCredentials.id,
    vertical_padding: 20,
  };

  window.Intercom('boot', intercomSettings);
}
