import React, {useEffect, useState, useCallback} from 'react';
import {HelmetProvider} from 'react-helmet-async';
import {Provider} from 'react-redux';

import * as Api from 'api';
import AppRouter from 'app/AppRouter';
import {setFavicon} from 'app/favicon-urls';
import {tracker} from 'app/PageLoadTracker';
import MaintenancePage from 'login/MaintenancePage';
import {store} from 'redux/store/store';
import {initializeMoment} from 'toolkit/time/moment-utils';
import {getEndToEndTestBuildUrlFromCookie} from 'utils/cookies';
import {configureSentry} from 'utils/exceptions';
import {history} from 'utils/history';

import Analytics from './analytics/analytics';
import {isIntercomEnabled} from './analytics/intercom';
import {configureIntercom} from './analytics/intercom-lib';

initializeMoment();

const App: React.FC<object> = () => {
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [publicEnvironmentName, setPublicEnvironmentName] = useState('');

  const setEnvironment = useCallback(() => {
    Api.Environment.getPublicEnvironment()
      .then(environment => {
        const e2eBuildUrl = getEndToEndTestBuildUrlFromCookie();
        Analytics.init(environment);
        if (isIntercomEnabled(environment)) {
          configureIntercom(environment.intercomAppId);
        }
        configureSentry(environment, e2eBuildUrl);
        setMaintenanceMode(false);
        setPublicEnvironmentName(environment.environmentName);
      })
      // eslint-disable-next-line no-console
      .catch(() => {
        tracker.disable();
        setMaintenanceMode(true);
        setTimeout(setEnvironment, 5000);
      });
  }, []);

  useEffect(setEnvironment, [setEnvironment]);
  useEffect(() => {
    setFavicon(publicEnvironmentName);
  }, [publicEnvironmentName]);

  return (
    <HelmetProvider>
      {maintenanceMode ? (
        <MaintenancePage />
      ) : (
        <Provider store={store}>
          <AppRouter history={history} publicEnvironmentName={publicEnvironmentName} />
        </Provider>
      )}
    </HelmetProvider>
  );
};
App.displayName = 'App';

export default App;
