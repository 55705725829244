import './ColorizedMetricValueCell.scss';

import classNames from 'classnames';
import React from 'react';

import {TypedCellRendererParams} from 'toolkit/ag-grid/types';
import {TABLE_COLUMN_COLORS, adjustLuminanceByFactor} from 'toolkit/utils/colors';
import {MetricCellParams} from 'widgets/tables/impl/types';

const ColorizedMetricValueCell: React.FC<MetricCellParams & {children: React.ReactNode}> = ({
  metricInstanceConfig,
  children,
}) => {
  const injectedBackgroundColor = metricInstanceConfig.customColor
    ? {
        style: {
          backgroundColor: TABLE_COLUMN_COLORS.get(metricInstanceConfig.customColor),
          borderColor: adjustLuminanceByFactor(
            TABLE_COLUMN_COLORS.get(metricInstanceConfig.customColor)!,
            0.75
          ),
        },
      }
    : {};
  return (
    <div
      className={classNames('ColorizedMetricValueCell', {
        colorized: !!metricInstanceConfig.customColor,
      })}
      {...injectedBackgroundColor}
    >
      <div className="metric-value-content">
        <div className="metric-value">{children}</div>
      </div>
    </div>
  );
};
ColorizedMetricValueCell.displayName = 'ColorizedMetricValueCell';

const ColorizedMetricValueCellFramework = <T,>({
  valueFormatted,
  ...otherProps
}: TypedCellRendererParams<T> & MetricCellParams) => (
  <ColorizedMetricValueCell {...otherProps}>{valueFormatted}</ColorizedMetricValueCell>
);
ColorizedMetricValueCellFramework.displayName = 'ColorizedMetricValueCellFramework';

export function withColorizedMetricValueCell<P extends MetricCellParams>(
  WrappedCell: React.ComponentType<P>
) {
  const Cell: React.FC<P> = props => (
    <ColorizedMetricValueCell {...props}>
      <WrappedCell {...props} />
    </ColorizedMetricValueCell>
  );

  return Cell;
}

export default ColorizedMetricValueCellFramework;
