import moment, {Moment} from 'moment-timezone';

import {defaultZone} from './constants';

const MIN_DATE = moment('0001-01-01');

export function initializeMoment() {
  moment.tz.setDefault(defaultZone);
  moment.updateLocale('en', {
    relativeTime: {
      M: 'a month',
      MM: '%d months',
      d: 'a day',
      dd: '%d days',
      future: 'in %s',
      h: 'an hour',
      hh: '%d hours',
      m: 'a minute',
      mm: '%d minutes',
      past: '%s',
      s: 'seconds',
      ss: '%s seconds',
      y: 'a year',
      yy: '%d years',
    },
  });
}

export function earlier(momentA: Moment, momentB: Moment) {
  return momentA.isBefore(momentB) ? momentA : momentB;
}

export function later(
  momentA: Moment | null | undefined,
  momentB: Moment | null | undefined
): Moment | null | undefined {
  return momentA?.isAfter(momentB ?? MIN_DATE) ? momentA : momentB;
}
