import ActionType from 'redux/actions/types';
import * as Types from 'types';

export interface IShareActions {
  setShareScreenshotRequest: (request: Types.ShareScreenshotRequest | null) => ShareAction;
  setShareViewRequest: (
    view: Types.View | null,
    request: Types.ShareViewRequest | null
  ) => ShareAction;
}

export const ShareActions: IShareActions = {
  setShareScreenshotRequest: (shareRequest: Types.ShareScreenshotRequest | null) => {
    return {
      shareRequest,
      type: ActionType.SetShareScreenshotRequest,
    };
  },

  setShareViewRequest: (view: Types.View | null, shareRequest: Types.ShareViewRequest | null) => {
    return {
      shareRequest,
      type: ActionType.SetViewShareRequest,
      view,
    };
  },
};

export type ShareAction =
  | {
      type: ActionType.SetShareScreenshotRequest;
      shareRequest: Types.ShareScreenshotRequest | null;
    }
  | {
      type: ActionType.SetViewShareRequest;
      view: Types.View | null;
      shareRequest: Types.ShareViewRequest | null;
    };
