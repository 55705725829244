import React from 'react';
import {NavLink} from 'react-router-dom';

import {TypedCellRendererParams} from 'toolkit/ag-grid/types';
import ExternalLink from 'toolkit/components/ExternalLink';
import * as Types from 'types';

export const ExternalLinkCellRenderer = (
  params: TypedCellRendererParams<Types.WidgetSubscription, LinkCellRendererParams>
) => <ExternalLink href={params.value.url}>{params.value.value}</ExternalLink>;

export const NavLinkCellRenderer = (
  params: TypedCellRendererParams<Types.WidgetSubscription, LinkCellRendererParams>
) => <NavLink to={params.value.url}>{params.value.value}</NavLink>;

export type LinkCellRendererParams = {value: string; url: string};
