import './NavTopBarUserMenu.scss';

import classNames from 'classnames';
import React from 'react';

import UserMenu from 'nav/UserMenu';
import {setUserMenuOpen} from 'redux/actions/navigation';
import {useCurrentUser} from 'redux/context/user';
import useSelector from 'redux/selectors/useSelector';
import useDispatch from 'redux/useDispatch';
import Icon from 'toolkit/components/Icon';
import {getUserInitials} from 'toolkit/users/utils';

const NavTopBarUserMenu: React.FC = () => {
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  const isUserMenuOpen = useSelector(state => state.navigation.isUserMenuOpen);

  const userItemRef = React.createRef<HTMLDivElement>();
  const hideUserMenu = () => dispatch(setUserMenuOpen(false));

  return (
    <>
      <div
        ref={userItemRef}
        className={classNames('user-menu-dropdown', {open: isUserMenuOpen})}
        onClick={() => dispatch(setUserMenuOpen(true))}
      >
        <div
          className={classNames('handle', {
            selected: (location.pathname.match(/\/settings\//)?.length ?? 0) > 0,
          })}
        >
          {currentUser.userInfo.firstName ? (
            <>
              <span className="user-firstname">
                {currentUser.userInfo.firstName} {currentUser.userInfo.lastName}
              </span>
            </>
          ) : (
            getUserInitials(currentUser.user, currentUser.userInfo)
          )}
          <Icon className="down-caret" icon="caret-down" />
        </div>
      </div>
      {isUserMenuOpen && <UserMenu menuRef={userItemRef} onHide={hideUserMenu} />}
    </>
  );
};

NavTopBarUserMenu.displayName = 'NavTopBarUserMenu';

export default NavTopBarUserMenu;
