import './ShortcutHelp.scss';

import React from 'react';
import {Route, Switch} from 'react-router';

import {useCurrentUser} from 'redux/context/user';
import useSelector from 'redux/selectors/useSelector';

import {isAppleOS} from './browser-utils';
import {
  getAnalysisHotKeyDefinitions,
  getGlobalHotKeyDefinitions,
  HotKeyDefinition,
} from './HotKeyDefinitions';

function modifierToChar(modifier: string): string {
  switch (modifier) {
    case 'ctrl':
      return isAppleOS() ? '⌃' : 'Ctrl';
    case 'option':
    case 'alt':
      return isAppleOS() ? '⌥' : 'Alt';
    default:
      return modifier;
  }
}

function tokenizeKeyMap(keyMap: string): string[] {
  // can handle the following cases only:
  // - space separated string, "m l" -> ["m" "l"]
  // - "option+key" => ["⌥", key]
  return keyMap.split(' ').flatMap(token => token.split('+').map(modifierToChar));
}

const ShortcutItem: React.FC<ShortcutItemProps> = ({definition}) => {
  const {keyMaps, description} = definition;
  return (
    <div className="shortcut-item">
      <span className="keys">
        {keyMaps.map((keyMap, i) => (
          <React.Fragment key={i}>
            {
              // add "or" between key spans
              i > 0 && 'or '
            }
            {tokenizeKeyMap(keyMap).map((char, j) => (
              <span key={j} className="shortcut-key">
                {char}
              </span>
            ))}
          </React.Fragment>
        ))}
      </span>
      {description}
    </div>
  );
};
interface ShortcutItemProps {
  definition: HotKeyDefinition;
}

const AnalysisPageShortcuts = () => {
  const analysisHotKeyDefinitions = getAnalysisHotKeyDefinitions();
  return (
    <div className="column">
      {analysisHotKeyDefinitions.map(item => (
        <ShortcutItem key={item.name} definition={item} />
      ))}
    </div>
  );
};

const ShortcutHelp: React.FunctionComponent<object> = () => {
  const currentUser = useCurrentUser();
  const allowedVendors = useSelector(state => state.userData.allowedVendors);

  const globalHotKeyDefinitions = getGlobalHotKeyDefinitions(currentUser, allowedVendors);

  return (
    <div className="ShortcutHelp">
      <div className="shortcut-help-content">
        * Use SHIFT key to open in new tab
        <div className="column">
          {globalHotKeyDefinitions.map(item => (
            <ShortcutItem key={item.name} definition={item} />
          ))}
        </div>
        <Switch>
          <Route component={AnalysisPageShortcuts} path="/:vendor/analysis/:viewSlug?" />
        </Switch>
      </div>
    </div>
  );
};
ShortcutHelp.displayName = 'ShortcutHelp';
export default ShortcutHelp;
