import Fuse from 'fuse.js';

import {
  getEntityNameRenderer,
  getTrySearchingEntityNameRenderer,
} from 'nav/AnalysisEntityNameRenderer';
import {AskAlloyRequest} from 'nav/types';
import {createAskAlloyView} from 'nav/utils';
import {CurrentUser} from 'redux/reducers/user';
import {Settings} from 'settings/utils';
import {DashboardAnalysisEntity, TemplateAnalysisEntity} from 'toolkit/analysis/types';
import {AttributeHierarchies, AttributesById} from 'toolkit/attributes/types';
import {getAttribute} from 'toolkit/attributes/utils';
import {IconSpec} from 'toolkit/icons/types';
import {MetricsByName} from 'toolkit/metrics/types';
import {isDashboardEntity, toAnalysisEntity} from 'toolkit/views/utils';
import * as Types from 'types';
import {isTruthy} from 'utils/functions';

import lightbulbIcon from './icons/icon-lightbulb.svg';

const ASK_ALLOY_ICON: IconSpec = {
  alloyIcon: lightbulbIcon,
};

export function getAskAlloyEntities(
  currentUser: CurrentUser,
  settings: Settings,
  allGroupings: AttributesById,
  availableMetrics: MetricsByName,
  defaultAttributeHierarchies: AttributeHierarchies,
  vendorName: string,
  metricsFuse: Fuse<Types.Metric>,
  partnersFuse: Fuse<Types.Partner>,
  attributesFuse: Fuse<Types.Attribute>,
  searchQuery: string
): ReadonlyArray<DashboardAnalysisEntity<Types.View>> {
  if (allGroupings.isEmpty()) {
    return [];
  }

  const [metricName, partnerName, groupingName] = getAskAlloySearchQueryParts(searchQuery);

  const trySearchingMetric = searchQuery ? null : availableMetrics.get('on_hand_units');
  const metrics = metricsFuse.search(getWithoutIncompletePrepositions(metricName));
  const metric = metrics.length ? metrics[0].item : trySearchingMetric;

  const productAttributes = defaultAttributeHierarchies?.get(Types.AttributeHierarchyType.PRODUCT);
  const productAttribute = productAttributes
    ? productAttributes[0]
    : getAttribute(allGroupings, 'Product');

  if (!metric || !productAttribute) {
    return [];
  }

  const partners = partnerName ? partnersFuse.search(partnerName) : [];
  const partner = partners.length && partners[0].item.id ? partners[0].item : null;

  const attributes = groupingName ? attributesFuse.search(groupingName) : [];
  const groupingAttribute = attributes.length ? attributes[0].item : null;

  const searchRequest: AskAlloyRequest | null = searchQuery
    ? {
        metric,
        groupingAttribute,
        partner,
      }
    : null;
  const suggestedRequest: AskAlloyRequest | null = groupingAttribute
    ? null
    : {
        metric,
        groupingAttribute: productAttribute,
        partner,
      };
  const requests: AskAlloyRequest[] = [searchRequest, suggestedRequest].filter(isTruthy);

  return requests
    .map(request =>
      createAskAlloyView(
        currentUser,
        settings,
        allGroupings,
        availableMetrics,
        request,
        !partnerName && !!groupingName
      )
    )
    .map((view): DashboardAnalysisEntity<Types.View> | TemplateAnalysisEntity<Types.View> => ({
      ...toAnalysisEntity<Types.View>(view, vendorName),
      nameRenderer: searchQuery ? getEntityNameRenderer : getTrySearchingEntityNameRenderer,
      icon: getAskAlloyIcon(),
    }))
    .filter(isDashboardEntity);
}

export function getAskAlloySearchQueryParts(searchQuery: string) {
  const [rest, groupingName] = searchQuery.split(' by ', 2).map(getWithoutIncompletePrepositions);
  const [metricName, partnerName] = rest.split(' at ', 2);

  return [metricName || '', partnerName || '', groupingName || ''];
}

function getWithoutIncompletePrepositions(searchPart: string) {
  return searchPart.replace(/\s+\b(a|b|at|by)\b\s*$/i, '').trim();
}

function getAskAlloyIcon(): IconSpec {
  return ASK_ALLOY_ICON;
}
