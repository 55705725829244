import {ShareAction} from 'redux/actions/share';
import ActionType from 'redux/actions/types';
import * as Types from 'types';

export const defaultShareState: ShareState = {
  shareScreenshotRequest: null,
  shareViewRequest: null,
  viewToShare: null,
};

export interface ShareState {
  readonly shareViewRequest: Types.ShareViewRequest | null;
  readonly shareScreenshotRequest: Types.ShareScreenshotRequest | null;
  readonly viewToShare: Types.View | null;
}

export default function shareReducer(
  state: ShareState = defaultShareState,
  action: ShareAction
): ShareState {
  if (action.type === ActionType.SetViewShareRequest) {
    return {...state, shareViewRequest: action.shareRequest, viewToShare: action.view};
  } else if (action.type === ActionType.SetShareScreenshotRequest) {
    return {...state, shareScreenshotRequest: action.shareRequest};
  }
  return state;
}
