import * as Types from 'types';

export enum DashboardTransferMessageContent {
  DASHBOARD = 'dashboard',
  FINISH_LOAD = 'finish_load',
}

export interface DashboardTransferMessage {
  content: DashboardTransferMessageContent;
  view?: Types.View;
  vendorId?: number;
}
