import './ChartLegend.scss';

import classNames from 'classnames';
import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';

import * as Types from 'types';
import {noop} from 'utils/functions';
import {ChartLegendPosition} from 'widgets/charts/types';

import {ChartLegendItems} from './chart-legend';
import {AugmentedChart} from './types';

export default class ChartLegend extends React.Component<Props> {
  static defaultProps = {
    resetAllHighlights: noop,
  };

  shouldComponentUpdate() {
    return true; // chart object is mutable and could have changed
  }

  toggleDatasetVisibility = (datasetIndices: readonly number[]) => {
    this.forceUpdate();
    this.props.onToggle(datasetIndices);
  };

  render() {
    return this.props.acquireChart() ? (
      <div
        className={classNames('ChartLegend', this.props.className, {
          disabled: this.props.disabled,
          vertical: this.props.isVertical,
        })}
      >
        <AutoSizer className="chart-legend-content-wrapper" disableHeight>
          {({width}) => (
            <div
              className={classNames('chart-legend-content', {
                'justify-left': this.props.position === ChartLegendPosition.TOP_LEFT,
                vertical: this.props.isVertical,
              })}
              style={{width}}
            >
              <ChartLegendItems
                chart={this.props.acquireChart()}
                isToggleEnabled={this.props.isToggleEnabled}
                isVertical={this.props.isVertical}
                maxAvailableWidth={width}
                widgetConfig={this.props.config}
                onHover={this.props.onItemHover}
                onHoverLeave={this.props.resetAllHighlights!}
                onToggle={this.toggleDatasetVisibility}
              />
            </div>
          )}
        </AutoSizer>
      </div>
    ) : null;
  }
}
interface Props {
  acquireChart: () => AugmentedChart | undefined;
  className?: string;
  config?: Types.Widget;
  disabled: boolean;
  position?: ChartLegendPosition;
  onItemHover: (datasetIndices: readonly number[]) => void;
  resetAllHighlights?: () => void;
  isToggleEnabled: boolean;
  onToggle: (datasetIndices: readonly number[]) => void;
  isVertical?: boolean;
}
