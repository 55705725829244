import equal from 'fast-deep-equal';
import {List, Map} from 'immutable';
import moment from 'moment-timezone';
import numeral from 'numeral';
import pluralize from 'pluralize';

import {PartnersById} from 'toolkit/attributes/types';
import {CONCATENATED_IDENTIFIER_DELIMITER} from 'toolkit/attributes/utils';
import {
  getInventoryTypePhrase,
  getMetricDisplayName,
  getSalesTypeDisplayName,
  getStockAggregatorPrefix,
} from 'toolkit/format/metricNames';
import {capitalize, capitalizeFirstLetter, depluralize, titleCase} from 'toolkit/format/text';
import {BaseMetricDisplayType} from 'toolkit/metrics/types';
import {
  areMetricArgumentsEqual,
  areMetricsEqual,
  DEFAULT_PERSPECTIVE,
  getComparisonMetricInstanceIdentifier,
  getDefaultMetricArguments,
  getEffectiveMetric,
  getMetricDisplayType,
  getMetricsList,
  isAsReportedCurrencyMetric,
  isForecastMetricInstance,
  isPriorValueOrContributionComparison,
  isRankBasedMetricFilter,
  isUnitMetric,
} from 'toolkit/metrics/utils';
import {CalendarEventTypeMetadata} from 'toolkit/time/calendar-utils';
import {defaultZone} from 'toolkit/time/constants';
import {
  getDaysForPeriod,
  getDaysForSimplePeriod,
  getPeriodUnit,
  isToday,
  isYesterday,
} from 'toolkit/time/utils';
import * as Types from 'types';
import {assertTruthy} from 'utils/assert';
import {isNonNullish, isNullish, isTruthy} from 'utils/functions';
import {REDUCED_PRECISION_FORMAT_OPTIONS} from 'widgets/chrome/insight-titles';
import {getWidgetTitle, Widgets} from 'widgets/utils';

import {
  DISPLAY_DATE_FORMAT,
  INFINITY_SYMBOL,
  LABELS,
  NAN_STRING,
  US_DATE_FORMAT,
} from './constants';
import {TextLengthFormat} from './types';

interface LengthFormatOptions {
  readonly lengthFormat?: TextLengthFormat;
  readonly fractionalDigitCount?: number;
  readonly significantFigures?: number;
}

interface CapitalizationFormatOptions {
  readonly capitalize?: boolean;
}

interface MetricInstanceFormatOptions {
  readonly hideDollarType?: boolean;
  readonly hideForecastSuffixes?: boolean;
  readonly hideCurrencySuffix?: boolean;
}

interface MetricFilterFormatOptions extends LengthFormatOptions {
  readonly customName?: string;
}

interface CalendarEventFormatOptions extends LengthFormatOptions {
  readonly hideCategoryName?: boolean;
}

interface CadenceFormatOptions {
  readonly lowercaseNonDays?: boolean;
  readonly plural?: boolean;
}

interface MetricValueFormatOptions {
  readonly includeDecimalsForSmallNonWholeIntegers?: boolean;
}

interface FloatFormatOptions {
  readonly significantDigitsForSmallMagnitudeNumbers?: number;
}

interface NumberFormatOptions {
  readonly alwaysIncludePositiveSign?: boolean;
  readonly hidePercentAndDollarSign?: boolean;
}

interface PercentFormatOptions {
  /** If the value is greater than the upper limit, we'll show "> [Upper Limit]%" */
  readonly upperLimit?: number;
  /** If the value is lower than the lower limit, we'll show "< [Lower Limit]%" */
  readonly lowerLimit?: number;
}

interface ListFormatOptions {
  readonly separator?: string;
}

interface PeriodFormatOptions extends LengthFormatOptions {
  readonly preferNaturalLanguage?: boolean;
  readonly isTimeAgoCalendarPeriodAligned?: boolean | null;
}

interface TimeAgoPeriodFormatOptions {
  readonly allowShortenedTimeAgo?: boolean;
  readonly isTimeAgoCalendarPeriodAligned?: boolean | null;
}

interface MoneyFormatOptions {
  readonly includeCurrency?: boolean;
  readonly useDollarSign?: boolean;
}

export type DateFormatOptions = LengthFormatOptions & {
  readonly calendar?: Types.RetailCalendarEnum;
  readonly weekFormat?: Types.WeekFormat;
  readonly formatString?: string;
  readonly isExclusiveEndDate?: boolean;
  // See https://momentjs.com/docs/#/displaying/fromnow/
  readonly isTimeFromNow?: boolean;
  readonly isInNaturalLanguage?: boolean;
};

export type DateUsingTodayOptions = {
  readonly today?: moment.Moment;
};

export type IntervalFormatOptions = DateFormatOptions & {
  readonly isEndDateInclusive?: boolean;
  readonly includeYear?: boolean;
};

type AttributeValueFormatOptions = DateFormatOptions & FloatFormatOptions;
type MetricFormatOptions = MetricValueFormatOptions &
  NumberFormatOptions &
  LengthFormatOptions &
  MoneyFormatOptions;

type ComparisonText = {
  infix: string;
  suffix: string;
};

const COMPARISON_TEXTS: Map<string, ComparisonText> = Map({
  contribution: {infix: 'Chg Contrib % of', suffix: 'Chg Contrib'},
  metric_comps_absolute: {infix: 'vs.', suffix: 'Chg'},
  metric_comps_percent: {infix: '% of', suffix: 'Chg %'},
});

const UNSUPPORTED_ABBREVIATED_COMPARISON_UNITS = [
  Types.CalendarUnit.DAYS,
  Types.CalendarUnit.SEASONS,
];

// Our integer-valued metrics can sometimes have fractional values (for example,
// when averaging or forecasting) and omitting the fractional part for small
// small values dramatically changes their overall magnitude
const MAX_VALUE_TO_SHOW_DECIMAL_FOR_INTEGERS = 3;

export function wrapIfExists(item: string | null, start?: string, end?: string) {
  return item ? `${start}${item}${end}` : '';
}

function formatUnit(
  unit: Types.CalendarUnit,
  options: LengthFormatOptions & CapitalizationFormatOptions = {
    lengthFormat: TextLengthFormat.FULL,
    capitalize: true,
  }
) {
  const formattedUnit =
    options.lengthFormat === TextLengthFormat.ABBREVIATED ? unit[0] : depluralize(unit);
  return options.capitalize ? capitalize(formattedUnit) : formattedUnit.toLowerCase();
}

function formatPeriod(
  period: Types.DatePeriod,
  options: PeriodFormatOptions = {
    preferNaturalLanguage: true,
    lengthFormat: TextLengthFormat.FULL,
  }
): string {
  if (options.lengthFormat === TextLengthFormat.ABBREVIATED) {
    switch (period.type) {
      case 'todate':
        return `${formatUnit(period.unit, {lengthFormat: options.lengthFormat})}tD`;
    }
  }
  switch (period.type) {
    case 'fixed':
      const startDate = moment(period.start);
      const endDate = moment(period.end).subtract(1, 'days');
      if (startDate.isSame(endDate)) {
        return startDate.format(US_DATE_FORMAT);
      }
      return `${moment(period.start).format(US_DATE_FORMAT)} to ${endDate.format(US_DATE_FORMAT)}`;
    case 'fixed_to_now':
      return `${moment(period.start).format(US_DATE_FORMAT)} to Now`;
    case 'trailing':
      return `Trailing ${pluralize(formatUnit(period.unit), period.amount, true)}`;
    case 'todate':
      return `${formatUnit(period.unit)} to Date`;
    case 'todate_weekend':
      return `${formatUnit(period.unit)} to Date (Week-End)`;
    case 'previous':
      if (period.amount === 1 && options.preferNaturalLanguage) {
        return period.unit === Types.CalendarUnit.DAYS
          ? 'Yesterday'
          : `Last ${formatUnit(period.unit)}`;
      }
      return `${pluralize(formatUnit(period.unit), period.amount, true)} Ago`;
    case 'future':
      if (period.amount === 0) {
        return period.unit === Types.CalendarUnit.DAYS
          ? 'Today'
          : `This ${formatUnit(period.unit)}`;
      }
      if (period.amount === 1) {
        return period.unit === Types.CalendarUnit.DAYS
          ? 'Tomorrow'
          : `Next ${formatUnit(period.unit)}`;
      }
      return `${pluralize(formatUnit(period.unit), period.amount, true)} from now`;
    case 'lastn':
      if (period.amount === 1) {
        return period.unit === Types.CalendarUnit.DAYS
          ? 'Yesterday'
          : `Last ${formatUnit(period.unit)}`;
      }
      return `Last ${pluralize(formatUnit(period.unit), period.amount, true)}`;
    case 'nextn':
      if (period.amount === 1) {
        return period.unit === Types.CalendarUnit.DAYS
          ? 'Tomorrow'
          : `Next ${formatUnit(period.unit)}`;
      }
      return `Next ${pluralize(formatUnit(period.unit), period.amount, true)}`;
    case 'complex': {
      const startPeriod = period.startPeriod;
      const endPeriod = period.endPeriod;
      const startText =
        startPeriod.type === 'fixed'
          ? moment(startPeriod.start).format(US_DATE_FORMAT)
          : formatPeriod(startPeriod);
      const endText =
        endPeriod.type === 'fixed'
          ? moment(endPeriod.end).subtract(1, 'days').format(US_DATE_FORMAT)
          : formatPeriod(endPeriod);
      return `${startText} through ${endText}`;
    }
    case 'now_to_fixed': {
      return `Today through ${period.end}`;
    }
    case 'to_go': {
      return `${formatUnit(period.unit)} to go`;
    }
    default:
      throw new Error(`Unsupported period type: ${(period as any).type}`);
  }
}

function formatTimeAgo(period: Types.SimplePeriod, isCalendarPeriodAligned: boolean | null) {
  const amount = period.amount;
  const unit = capitalize(pluralize(depluralize(period.unit), amount));
  return `${amount} ${isCalendarPeriodAligned ? 'Fiscal ' : ''}${unit} Ago`;
}

function formatSimplePeriod(period: Types.SimplePeriod, capitalize?: boolean) {
  const amount = period.amount;
  const unit = depluralize(period.unit.toLowerCase());
  return pluralize(capitalize ? capitalizeFirstLetter(unit) : unit, amount, true);
}

function formatMinutes(x: number): string {
  const m = x % 60;
  const h = (x - m) / 60;
  const hours = `${h}h`;
  const minutes = `${m}min`;
  if (!h) {
    return minutes;
  }
  if (!m) {
    return hours;
  }
  return `${hours} ${minutes}`;
}

function formatShortApproximateValue(value: number): string {
  return Format.integer(value, REDUCED_PRECISION_FORMAT_OPTIONS);
}

function formatComparisonPeriodShorthand(timeAgo: Types.SimplePeriod): string {
  if (timeAgo.amount === 1) {
    const unit = formatUnit(timeAgo.unit, {
      lengthFormat: TextLengthFormat.ABBREVIATED,
      capitalize: false,
    });
    return `${unit}/${unit}`;
  }
  return '';
}

// Try to keep aligned with ComparisonMetricUtils.java except for the abbreviated case
function formatComparisonMetricPeriod(
  leftInstance: Types.MetricInstance,
  rightInstance: Types.MetricInstance,
  options: LengthFormatOptions = {}
) {
  if (
    isPriorValueOrContributionComparison(leftInstance, rightInstance) &&
    isShortened(options.lengthFormat)
  ) {
    return formatComparisonPeriodShorthand(rightInstance.arguments.timeAgo!);
  }

  const {period: leftPeriod} = leftInstance.arguments;
  const {period: rightPeriod, timeAgo} = rightInstance.arguments;
  if (equal(leftPeriod, rightPeriod) && timeAgo) {
    const daysForPeriod = getDaysForPeriod(leftPeriod);
    const daysForTimeAgo = getDaysForSimplePeriod(timeAgo);

    const formattedPeriod = formatMetricPeriod(leftInstance, options);
    const formattedTimeAgo = formatSimplePeriod(timeAgo, true);

    const formattedPeriodUsingOffset = `${formattedPeriod} vs. ${formattedPeriod} offset ${formattedTimeAgo}`;

    if (
      !areMetricsEqual(leftInstance, rightInstance, ['timeAgo', 'isTimeAgoCalendarPeriodAligned'])
    ) {
      return formattedPeriodUsingOffset;
    }

    if (isNonNullish(daysForPeriod)) {
      const formattedPeriodAbbreviated =
        timeAgo.amount === 1 && !UNSUPPORTED_ABBREVIATED_COMPARISON_UNITS.includes(timeAgo.unit)
          ? `${formattedPeriod} (${formatComparisonPeriodShorthand(timeAgo)})`
          : undefined;

      if (daysForTimeAgo > daysForPeriod) {
        if (
          timeAgo.amount === 1 &&
          timeAgo.unit === Types.CalendarUnit.DAYS &&
          (isYesterday(leftPeriod) || isToday(leftPeriod))
        ) {
          return `${formattedPeriod} vs. ${isToday(leftPeriod) ? 'Yesterday' : 'Prior Day'}`;
        } else {
          return formattedPeriodAbbreviated ?? formattedPeriodUsingOffset;
        }
      } else if (daysForTimeAgo === daysForPeriod) {
        return (
          formattedPeriodAbbreviated ??
          `${formattedPeriod} vs. Prior ${
            timeAgo.amount === 1 ? formatUnit(timeAgo.unit) : formattedTimeAgo
          }`
        );
      } else {
        return formattedPeriodUsingOffset;
      }
    }
  }

  const leftPeriodName = formatMetricPeriod(leftInstance, {
    ...options,
    isTimeAgoCalendarPeriodAligned: leftInstance.arguments.isTimeAgoCalendarPeriodAligned,
  });
  const rightPeriodName = formatMetricPeriod(rightInstance, {
    ...options,
    allowShortenedTimeAgo: false,
    isTimeAgoCalendarPeriodAligned: rightInstance.arguments.isTimeAgoCalendarPeriodAligned,
  });
  if (leftPeriodName !== null && (leftPeriodName === rightPeriodName || rightPeriodName === null)) {
    return leftPeriodName;
  } else if (leftPeriodName !== null && rightPeriodName !== null) {
    return `${leftPeriodName} vs. ${rightPeriodName}`;
  }
  return null;
}

function formatMetricPeriod(
  metricInstance: Types.MetricInstance,
  options?: LengthFormatOptions & TimeAgoPeriodFormatOptions
): string;

function formatMetricPeriod(
  metricInstance: Types.MetricInstance | null,
  options: LengthFormatOptions & TimeAgoPeriodFormatOptions = {}
): string | null {
  if (!metricInstance) {
    return null;
  }
  const containedMetricNames = getMetricsList(metricInstance).map(metric => metric.name);
  if (
    (containedMetricNames.includes('metric_comps_percent') ||
      containedMetricNames.includes('metric_comps_absolute') ||
      containedMetricNames.includes('contribution')) &&
    isTruthy(metricInstance.arguments.comparisonMetrics)
  ) {
    return formatComparisonMetricPeriod(
      metricInstance.arguments.comparisonMetrics[0],
      metricInstance.arguments.comparisonMetrics[1],
      options
    );
  }
  const period = formatPeriod(metricInstance.arguments.period!, {
    ...options,
    isTimeAgoCalendarPeriodAligned: metricInstance.arguments.isTimeAgoCalendarPeriodAligned,
  });
  const timeAgo = metricInstance.arguments.timeAgo;

  if (timeAgo) {
    return formatMetricPeriodWithTimeAgo(metricInstance, options);
  }
  return period;
}

// Try to keep aligned with MetricInstance.java (getDisplayPeriod).
function formatMetricPeriodWithTimeAgo(
  metricInstance: Types.MetricInstance,
  options: LengthFormatOptions & TimeAgoPeriodFormatOptions = {}
) {
  const {allowShortenedTimeAgo = true} = options;
  const {period, timeAgo} = metricInstance.arguments;

  const periodUnit = getPeriodUnit(period);
  if (
    allowShortenedTimeAgo &&
    isNonNullish(periodUnit) &&
    period.type === 'lastn' &&
    period.amount === 1 &&
    periodUnit === timeAgo!.unit
  ) {
    return formatTimeAgo(
      {...timeAgo!, amount: timeAgo!.amount + period.amount},
      metricInstance.arguments.isTimeAgoCalendarPeriodAligned
    );
  }
  if (timeAgo?.amount === 1 && timeAgo.unit === 'YEARS') {
    return `${formatPeriod(period, options)}, LY`;
  }
  return `${formatPeriod(period, options)} offset ${formatSimplePeriod(timeAgo!, true)}`;
}

function formatMetricTimeAgo(metricInstance: Types.MetricInstance) {
  const timeAgo = metricInstance.arguments.timeAgo;
  if (!timeAgo) {
    return '';
  }
  return formatTimeAgo(timeAgo, metricInstance.arguments.isTimeAgoCalendarPeriodAligned);
}

function formatIntervalCompact(
  interval: Types.LocalInterval,
  {isEndDateInclusive = false, includeYear = true}: IntervalFormatOptions = {}
) {
  const start = moment(interval.start);
  const end = moment(interval.end).subtract(isEndDateInclusive ? 0 : 1, 'days');
  const yearSuffix = includeYear ? ', YYYY' : '';

  if (start.isSame(end, 'day')) {
    return start.format(`MMM D${yearSuffix}`);
  } else if (start.isSame(end, 'month')) {
    return `${start.format('MMM D')} – ${end.format(`D${yearSuffix}`)}`;
  } else if (start.isSame(end, 'year')) {
    return `${start.format('MMM D')} – ${end.format(`MMM D${yearSuffix}`)}`;
  } else {
    return `${start.format(`MMM D${yearSuffix}`)} – ${end.format(`MMM D${yearSuffix}`)}`;
  }
}

function formatInterval(
  interval: Types.LocalInterval | null | undefined,
  {isEndDateInclusive = false, includeYear = true, ...dateFormatOptions}: IntervalFormatOptions = {}
) {
  if (!interval) {
    return '-';
  }

  if (isShortened(dateFormatOptions.lengthFormat)) {
    return formatIntervalCompact(interval, {isEndDateInclusive, includeYear, ...dateFormatOptions});
  }

  const endDate = moment(interval.end);
  const startDate = moment(interval.start);

  const displayStartDate = formatDate(startDate, dateFormatOptions);
  const displayEndDate = formatDate(endDate, {
    ...dateFormatOptions,
    isExclusiveEndDate: !isEndDateInclusive,
  });
  if (displayStartDate === 'Invalid date' || displayEndDate === 'Invalid date') {
    return '-';
  } else if (endDate.diff(startDate, 'days') === 1 && !isEndDateInclusive) {
    return displayStartDate;
  }
  return `${displayStartDate} to ${displayEndDate}`;
}

function formatDate(
  date: string | moment.Moment | null,
  {
    isExclusiveEndDate = false,
    formatString = DISPLAY_DATE_FORMAT,
    isTimeFromNow = false,
    isInNaturalLanguage = false,
    today = moment(),
  }: DateFormatOptions & DateUsingTodayOptions = {}
) {
  const adjustedDate = moment(date).subtract(isExclusiveEndDate ? 1 : 0, 'days');

  if (isInNaturalLanguage) {
    return formatDateInNaturalLanguage(adjustedDate, today);
  }

  if (!isTimeFromNow) {
    return adjustedDate.format(formatString);
  }

  // moment() returns the current instant, but we only want the current day
  const daysFromNow = dateOnlyMoment(adjustedDate).diff(dateOnlyMoment(today), 'days');
  if (daysFromNow === 0) {
    return 'Today';
  } else if (daysFromNow === -1) {
    return 'Yesterday';
  } else if (daysFromNow === 1) {
    return 'Tomorrow';
  }
  return adjustedDate.format(formatString);
}

function formatDateTimeInNaturalLanguage(dateTime: moment.Moment, now: moment.Moment): string {
  const hoursFromNow = dateTime.diff(now, 'hours');
  const hours = Math.abs(hoursFromNow);
  const minutesFromNow = dateTime.diff(now, 'minutes');
  // If an hour or less, give a relative time in minute chunks
  if (hours <= 1) {
    if (minutesFromNow < -30) {
      return 'about an hour ago';
    } else if (minutesFromNow < -15) {
      return 'about 30 minutes ago';
    } else if (minutesFromNow <= -5) {
      return 'about 15 minutes ago';
    } else if (minutesFromNow > -5 && minutesFromNow < 0) {
      return 'a few minutes ago';
    } else if (minutesFromNow === 0) {
      return 'now';
    } else if (minutesFromNow > 0 && minutesFromNow < 5) {
      return 'in a few minutes';
    } else if (minutesFromNow >= 5 && minutesFromNow <= 15) {
      return 'in about 15 minutes';
    } else if (minutesFromNow > 15 && minutesFromNow <= 30) {
      return 'in about 30 minutes';
    } else {
      return 'in about an hour';
    }
  }
  // If it's within 12 hours, use a relative time in hours
  if (hours < 12) {
    return `${hours} hours ${hoursFromNow < 0 ? 'ago' : 'from now'}`;
  }
  const naturalLanguageDate = formatDateInNaturalLanguage(dateTime, now);
  // If it's further in the future, include the time as it might still be important
  if (hoursFromNow > 0) {
    return `${naturalLanguageDate} at ${dateTime.format('ha')}`;
  }

  // If it's further in the past, just use the date (time unlikely to be important)
  return naturalLanguageDate;
}
// We treat today/tomorrow/yesterday as proper nouns and capitalize, keep lowercase phrases
function formatDateInNaturalLanguage(date: moment.Moment, today: moment.Moment): string {
  // Negative daysFromNow means in the past
  const daysFromNow = dateOnlyMoment(date).diff(dateOnlyMoment(today), 'days');
  const days = Math.abs(daysFromNow);
  const weeks = Math.round(days / 7);
  if (daysFromNow < -13) {
    return `${weeks} weeks ago`;
  } else if (daysFromNow < -1) {
    return `${days} days ago`;
  } else if (daysFromNow === -1) {
    return `yesterday`;
  } else if (daysFromNow === 0) {
    return `today`;
  } else if (daysFromNow === 1) {
    return `tomorrow`;
  } else if (daysFromNow > 1 && daysFromNow < 8) {
    return `${areDatesInSameWeek(date, today) ? 'this' : 'next'} ${date.format('dddd')}`;
  } else if (daysFromNow >= 8 && daysFromNow < 14) {
    return `${days} days from now`;
  } else {
    return `${weeks} weeks from now`;
  }
}

function areDatesInSameWeek(date1: moment.Moment, date2: moment.Moment): boolean {
  // Checks if both dates share same week start. ISO week start is Monday.
  return date1.clone().startOf('isoWeek').isSame(date2.clone().startOf('isoWeek'));
}

function dateOnlyMoment(date: moment.Moment): moment.Moment {
  return moment([date.year(), date.month(), date.date()]);
}

function formatDuration(durationSeconds: number, unit: 'seconds' | 'minutes') {
  const valid = Number.isFinite(durationSeconds);
  if (unit === 'seconds') {
    return `${valid ? durationSeconds : '–'} s`;
  } else if (unit === 'minutes') {
    const durationMinutes = durationSeconds / 60;
    return `${valid ? Math.round(durationMinutes) : '—'} min`;
  }

  throw new Error(`Unsupported duration unit ${unit}`);
}

function formatDateRange(period: Types.FixedDatePeriod) {
  const startDate = moment(period.start);
  const endDate = moment(period.end);
  const isDifferentYear = startDate.year !== endDate.year;
  return `${startDate.format(`MMM DD${isDifferentYear ? ' YYYY' : ''}`)} -
  ${moment(period.end).format('MMM DD YYYY')}`;
}

function getMetricPrefix(metricInstance: Types.MetricInstance) {
  return [
    getStockAggregatorPrefix(metricInstance.arguments.stockAggregator),
    getSalesTypeDisplayName(metricInstance.arguments.salesType),
  ]
    .filter(isTruthy)
    .join(' ');
}

export function getInventoryMeasureDisplayName(inventoryMeasure: Types.InventoryMeasure): string {
  return LABELS.inventoryMeasure[inventoryMeasure];
}

function getInventoryMeasureSuffix(metricInstance: Types.MetricInstance, hideDollarType: boolean) {
  const {inventoryMeasure} = metricInstance.arguments;
  if (!isNullish(inventoryMeasure)) {
    return !hideDollarType || inventoryMeasure === Types.InventoryMeasure.UNITS
      ? `, ${getInventoryMeasureDisplayName(inventoryMeasure)}`
      : ' $';
  }
  return getOutOfStockCalculationMethodSuffix(metricInstance);
}

function getOutOfStockCalculationMethodSuffix(metricInstance: Types.MetricInstance) {
  switch (metricInstance.arguments.outOfStockCalculationMethod) {
    case Types.OutOfStockCalculationMethod.BELOW_PRESMIN:
      return ', Below Presmin';
    case Types.OutOfStockCalculationMethod.OUT_OF_STOCK:
    default:
      return '';
  }
}

/**
 * This function inserts a postfix such as (Gallons) or (Inbound) after a base
 * metric name but before any other postfixes.
 **/
function infixPostfix(name: string, toInsert: string): string {
  // capture any number of words in parens (e.g. (ForecastType), (Direction))
  // and interpolate the converted unit closest to the base metric name
  const postfixRegex = /( \(.*\))?$/;
  return name.replace(postfixRegex, `${toInsert} $1`);
}

function formatUnitConversionMetricName(
  name: string,
  unitConversionAttribute: Types.Attribute | null
): string {
  const unitsRemovedName = name.replace(/\(?\bUnits?\b\)?/, '').trim();
  const unitsInfix = ` (${unitConversionAttribute?.name || 'Units'})`;
  return capitalizeFirstLetter(infixPostfix(unitsRemovedName, unitsInfix));
}

function getHigherOrderMetricInfix(metricInstance: Types.MetricInstance, name: string) {
  const metricName = metricInstance.metric.name;
  if (metricName === 'outbound') {
    return infixPostfix(name, ' (Outbound)');
  } else if (metricName === 'inbound') {
    return infixPostfix(name, ' (Inbound)');
  } else if (metricName === 'unit_conversion') {
    return formatUnitConversionMetricName(name, metricInstance.arguments.unitConversionAttribute);
  } else if (metricName === 'invalid') {
    return infixPostfix(name, ' (Invalid)');
  }
  return name;
}

function getVersionMetricSuffix(
  versionRecency: Types.VersionRecency | null,
  {includeComma} = {includeComma: true}
) {
  if (!versionRecency || versionRecency.mostRecent) {
    return '';
  }

  if (versionRecency.lag !== null) {
    return (includeComma ? ',' : '') + ` with a Lag of ${versionRecency.lag}`;
  }

  return `${includeComma ? ',' : ''} as of ${
    versionRecency.asOfDate || Format.simplePeriod(versionRecency.relativePeriod!)
  }${versionRecency.relativePeriod ? ' ago' : ''}`;
}

function getDollarTypeDisplayName(dollarType: Types.DollarType): string {
  return LABELS.dollarType[dollarType];
}

function getDollarTypeSuffix(metricInstance: Types.MetricInstance): string {
  const {dollarType} = metricInstance.arguments;
  if (isNullish(dollarType)) {
    return '';
  }
  return ` (${getDollarTypeDisplayName(dollarType)})`;
}

function getCurrencySuffix(metricInstance: Types.MetricInstance) {
  const metricSupportsCurrency = getEffectiveMetric(metricInstance).requiredArguments.includes(
    Types.MetricArgument.currency
  );
  if (!metricSupportsCurrency) {
    return '';
  }
  const currency = metricInstance.arguments.currency;
  return isNonNullish(currency) && currency !== Types.CurrencyCode.AS_REPORTED
    ? ` (${metricInstance.arguments.currency})`
    : '';
}

function getMetricSuffix(
  metricInstance: Types.MetricInstance,
  options: MetricInstanceFormatOptions = {}
) {
  const hasDollarTypeArg = metricInstance.metric.requiredArguments.includes(
    Types.MetricArgument.dollarType
  );
  const hasForecastArgs =
    metricInstance.metric.requiredArguments.includes(Types.MetricArgument.forecastType) ||
    metricInstance.metric.requiredArguments.includes(Types.MetricArgument.forecastComposition) ||
    metricInstance.metric.optionalArguments.includes(Types.MetricArgument.forecastComposition);
  const inventorySuffix = getInventoryMeasureSuffix(metricInstance, !!options.hideDollarType);
  const versionSuffix = getVersionMetricSuffix(metricInstance.arguments.versionRecency);
  const currencySuffix = !options.hideCurrencySuffix ? getCurrencySuffix(metricInstance) : '';
  const dollarTypeSuffix =
    hasDollarTypeArg && !options.hideDollarType ? getDollarTypeSuffix(metricInstance) : '';
  const forecastSuffix =
    hasForecastArgs && !options.hideForecastSuffixes
      ? getForecastMetricInstanceSuffix(metricInstance)
      : '';
  return `${inventorySuffix}${versionSuffix}${currencySuffix}${dollarTypeSuffix}${forecastSuffix}`;
}

function getHigherOrderMetricPrefix(metricInstance: Types.MetricInstance) {
  const metricName = metricInstance.metric.name;
  if (metricName === 'percent_of_grouping') {
    return '% of Grouping,';
  } else if (metricName === 'percent_of_total') {
    return '% of';
  } else if (metricName === 'cumulative') {
    return 'Cumulative';
  } else if (metricName === 'upstream') {
    return 'Upstream';
  } else if (metricName === 'downstream') {
    return 'Downstream';
  } else if (metricName === 'forecast_error') {
    return `${LABELS.errorMetric[metricInstance.arguments.errorMetric!]} of`;
  }
  return '';
}

function formatGranularityAdverb(granularity: Types.CalendarUnit) {
  if (granularity === Types.CalendarUnit.DAYS) {
    return 'Daily';
  } else if (granularity === Types.CalendarUnit.SEASONS) {
    return 'Seasonal'; // deprecated
  } else {
    return `${capitalize(depluralize(granularity))}ly`;
  }
}

// must be in sync with names in Aggregator.java in pewter
function formatStatisticalAggregator(metricName: string) {
  if (metricName === 'mean') {
    return 'Avg';
  } else if (metricName === 'maximum') {
    return 'Max';
  } else if (metricName === 'minimum') {
    return 'Min';
  } else if (metricName === 'standard_deviation') {
    return 'Std Dev';
  } else if (metricName === 'variance') {
    return 'Var';
  } else {
    throw new Error(`Unexpected statistical aggregator: ${metricName}`);
  }
}

function getHigherOrderMetricSuffix(metricInstance: Types.MetricInstance) {
  const metricName = metricInstance.metric.name;
  const granularity = metricInstance.arguments.granularity;
  const storeType = metricInstance.arguments.storeType;
  if (metricName === 'per_store') {
    return ` per ${capitalize(assertTruthy(storeType))} Location`;
  } else if (
    metricInstance.metric.features.includes(Types.MetricFeature.IS_STATISTICAL_AGGREGATOR)
  ) {
    const granularityAdverb = formatGranularityAdverb(assertTruthy(granularity));
    if (metricName === 'deviations_from_mean') {
      return `, Std Dev from ${granularityAdverb} Mean`;
    } else {
      return `, ${granularityAdverb} ${formatStatisticalAggregator(metricName)}`;
    }
  }
  return '';
}

function getForecastMetricInstanceTypeSuffix(metric: Types.MetricInstance) {
  switch (metric.arguments.forecastType) {
    case null:
    case undefined:
      return '';
    case Types.ForecastType.PLAN:
      return getForecastTypeShortName(metric.arguments.forecastType);
    case Types.ForecastType.ANNUAL_GROWTH:
      if (isNullish(metric.arguments.growthFactor)) {
        return 'Missing Argument: Growth Factor';
      } else {
        return `${((metric.arguments.growthFactor.value ?? Number.NaN) * 100).toFixed(
          2
        )}% Fixed Growth Over Last Year`;
      }
    case Types.ForecastType.HISTORICAL_AVERAGE:
      return `Average over preceding ${formatSimplePeriod(metric.arguments.historicalPeriod!)}`;
    case Types.ForecastType.PROPHET:
    case Types.ForecastType.VENDOR:
    case Types.ForecastType.PARTNER:
    case Types.ForecastType.ALLOY:
    case Types.ForecastType.ENSEMBLE:
    case Types.ForecastType.USAGE:
    case Types.ForecastType.THETA:
    default:
      return getForecastTypeShortName(metric.arguments.forecastType);
  }
}

// If you edit this, make sure to edit the equivalent metric in MetricUtils in Pewter
function formatInflowOutflowAbbreviationsSuffix(metricArguments: Types.MetricArguments) {
  const inflows = metricArguments?.inflowTypes
    ? metricArguments.inflowTypes.map(inflow => inflowToAbbreviation(inflow))
    : [];
  const outflows = metricArguments?.outflowTypes
    ? metricArguments.outflowTypes.map(outflow => outflowToAbbreviation(outflow))
    : [];
  if (!(inflows.length || outflows.length)) {
    return '';
  }
  const inflowAbbreviations = makeFlowAbbreviation('In: ', inflows);
  const outflowAbbreviations = makeFlowAbbreviation('Out: ', outflows);
  const joiner: string = inflows.length && outflows.length ? ' | ' : '';
  const inAndOutFlowAbbreviations = inflowAbbreviations + joiner + outflowAbbreviations;
  return ` (${inAndOutFlowAbbreviations})`;
}

function makeFlowAbbreviation(prefix: string, abbreviations: string[]): string {
  if (!abbreviations.length) {
    return '';
  }
  const uniqueAbbreviationList = new Set(abbreviations);
  return prefix + Array.from(uniqueAbbreviationList).join(', ');
}

// If you edit this, make sure to edit the InflowType enum in Pewter
function inflowToAbbreviation(inflow: Types.InflowType): string {
  switch (inflow) {
    case Types.InflowType.FORECASTED_ORDERS:
    case Types.InflowType.FORECASTED_RECEIPTS:
    case Types.InflowType.FORECASTED_SHIPMENTS:
      return 'FCST';
    case Types.InflowType.REQUESTED_RECEIPTS:
    case Types.InflowType.SCHEDULED_RECEIPTS:
      return 'ERP';
    case Types.InflowType.SCHEDULED_SHIPMENTS:
    case Types.InflowType.REQUESTED_RECEIPTS_ON_ORDER:
    case Types.InflowType.SCHEDULED_RECEIPTS_ON_ORDER:
      return 'ERP (OO)';
    case Types.InflowType.PROJECTED_RECEIPTS_IN_TRANSIT:
    case Types.InflowType.REQUESTED_RECEIPTS_IN_TRANSIT:
    case Types.InflowType.SCHEDULED_RECEIPTS_IN_TRANSIT:
      return 'ERP (IT)';
    case Types.InflowType.LATE_REQUESTED_RECEIPTS:
    case Types.InflowType.LATE_SCHEDULED_RECEIPTS:
      return 'ERP (Late)';
    case Types.InflowType.PLANNED_RECEIPTS:
    case Types.InflowType.PLANNED_SHIPMENTS:
      return 'Plan';
    case Types.InflowType.RECOMMENDED_RECEIPTS:
      return 'REC';
  }
}

// If you edit this, make sure to edit the OutflowType enum in Pewter
function outflowToAbbreviation(outflow: Types.OutflowType): string {
  switch (outflow) {
    case Types.OutflowType.ACTUAL_TOTAL_SHIPMENTS:
      return 'Actuals';
    case Types.OutflowType.DOWNSTREAM_PLANNED_SHIPMENTS:
    case Types.OutflowType.PLANNED_SHIPMENTS:
      return 'Plan';
    case Types.OutflowType.DOWNSTREAM_FORECASTED_SHIPMENTS:
    case Types.OutflowType.FORECASTED_SHIPMENTS:
    case Types.OutflowType.UNIT_SALES:
      return 'FCST';
    case Types.OutflowType.FORECASTED_SHIPMENTS_BLENDED:
      return 'Blend';
    case Types.OutflowType.INTERNAL_SCHEDULED_SHIPMENTS:
      return 'Int';
    case Types.OutflowType.LATE_INTERNAL_SCHEDULED_SHIPMENTS:
    case Types.OutflowType.LATE_REQUESTED_SHIPMENTS:
    case Types.OutflowType.LATE_SCHEDULED_SHIPMENTS:
      return 'ERP (Late OO)';
    case Types.OutflowType.SCHEDULED_SHIPMENTS:
    case Types.OutflowType.REQUESTED_SHIPMENTS:
      return 'ERP (OO)';
    case Types.OutflowType.DOWNSTREAM_RECOMMENDED_ORDERS:
      return 'REC';
  }
}

export type DateFormatType = '' | 'yyyyMMdd' | "yyyy-MM-dd'T'HH:mm:ss";

export type StatisticalAggregation =
  | 'mean'
  | 'minimum'
  | 'maximum'
  | 'standard_deviation'
  | 'variance'
  | 'deviations_from_mean'
  | 'none';
type LabelsByArgument<T extends string> = Record<T, string>;

export type TimeUnit = Types.CalendarUnit.WEEKS | Types.CalendarUnit.DAYS;

export interface LabelsByArgumentType {
  dollarType: LabelsByArgument<Types.DollarType>;
  errorMetric: LabelsByArgument<Types.ErrorMetric>;
  forecastType: LabelsByArgument<Types.ForecastType>;
  forecastComposition: LabelsByArgument<Types.ForecastComposition>;
  granularity: LabelsByArgument<Types.CalendarUnit>;
  inventoryMeasure: LabelsByArgument<Types.InventoryMeasure>;
  inventoryTypes: LabelsByArgument<Types.InventoryType>;
  returnsCountingMethod: LabelsByArgument<Types.ReturnsCountingMethod>;
  stockAggregator: LabelsByArgument<Types.StockAggregator>;
  timeUnit: LabelsByArgument<TimeUnit>;
  salesType: LabelsByArgument<Types.SalesType>;
  storeType: LabelsByArgument<Types.StoreType>;
  perspective: LabelsByArgument<Types.GraphPerspective>;
  inflowTypes: LabelsByArgument<Types.InflowType>;
  outflowTypes: LabelsByArgument<Types.OutflowType>;
  outOfStockCalculationMethod: LabelsByArgument<Types.OutOfStockCalculationMethod>;
  weekFormat: LabelsByArgument<Types.WeekFormat>;
  planAdjustmentMode: LabelsByArgument<Types.PlanAdjustmentMode>;
  dateFormat: LabelsByArgument<DateFormatType>;
  exportFormat: LabelsByArgument<Types.ExportFormat>;
  statisticalAggregator: LabelsByArgument<StatisticalAggregation>;
}

function getForecastTypeDisplayName(
  forecastType: Types.ForecastType,
  options: LengthFormatOptions = {}
) {
  return isShortened(options.lengthFormat)
    ? getForecastTypeShortName(forecastType)
    : LABELS.forecastType[forecastType];
}

function getForecastTypeShortName(forecastType: Types.ForecastType) {
  switch (forecastType) {
    case Types.ForecastType.PLAN:
      return 'Plan';
    case Types.ForecastType.PROPHET:
      return 'GAM';
    case Types.ForecastType.VENDOR:
      return 'Internal';
    case Types.ForecastType.PARTNER:
      return 'Partner';
    case Types.ForecastType.ANNUAL_GROWTH:
      return 'Annual Growth';
    case Types.ForecastType.HISTORICAL_AVERAGE:
      return 'Historical Average';
    case Types.ForecastType.SEASONAL_HISTORICAL_AVERAGE:
      return 'Seasonal Historical Average';
    case Types.ForecastType.ALLOY:
    case Types.ForecastType.ENSEMBLE:
    case Types.ForecastType.USAGE:
    case Types.ForecastType.THETA:
    case Types.ForecastType.FIELD:
    case Types.ForecastType.CONSENSUS:
      return capitalize(forecastType);
    default:
      return forecastType;
  }
}

function getForecastCompositionSuffix(composition: Types.ForecastComposition | null | undefined) {
  return !composition || composition === Types.ForecastComposition.TOTAL
    ? ''
    : capitalize(composition);
}

function getForecastMetricInstanceSuffix(metricInstance: Types.MetricInstance) {
  const typeSuffix = formatSuffix(getForecastMetricInstanceTypeSuffix(metricInstance));
  const compositionSuffix = getForecastCompositionSuffix(
    metricInstance.arguments.forecastComposition
  );
  return compositionSuffix ? `${typeSuffix}, ${compositionSuffix}` : typeSuffix;
}

function getSortedChildMetrics(metrics: Types.Metric[]): Types.Metric[] {
  const metricsByName = Map(metrics.map(metric => [metric.name, metric]));
  const unshiftMetrics = ['unit_conversion', 'inbound', 'outbound']
    .map(metricName => metricsByName.get(metricName))
    .filter(isTruthy);
  return List(metrics)
    .filter(metric => !unshiftMetrics.includes(metric))
    .unshift(...unshiftMetrics)
    .toArray();
}

function shortForecastName(comparisonMetricInstance: Types.MetricInstance) {
  const forecastType = comparisonMetricInstance.arguments.forecastType;
  if (forecastType === Types.ForecastType.PLAN) {
    return `${getForecastTypeShortName(forecastType)}${getVersionMetricSuffix(
      comparisonMetricInstance.arguments.versionRecency
    )}`;
  }
  const forecastSuffix = getForecastMetricInstanceSuffix(comparisonMetricInstance).trim();
  const versionSuffix = getVersionMetricSuffix(comparisonMetricInstance.arguments.versionRecency);
  return `Forecast ${forecastSuffix}${versionSuffix}`;
}

function isComparisonForecastMetricInstance(comparisonMetricInstance: Types.MetricInstance) {
  return (
    isForecastMetricInstance(comparisonMetricInstance) &&
    isTruthy(comparisonMetricInstance.metric.forecastedMetrics.length) &&
    !isNullish(comparisonMetricInstance.arguments.forecastType)
  );
}

function metricInstancesIgnoringTimeAreEqual(
  leftInstance: Types.MetricInstance,
  rightInstance: Types.MetricInstance
) {
  if (leftInstance.metric.id !== rightInstance.metric.id) return false;
  // can get one side with null child metrics, one side undefined
  return equal(
    {
      ...getDefaultMetricArguments(leftInstance.arguments.period),
      ...leftInstance.arguments,
      timeAgo: null,
      isTimeAgoCalendarPeriodAligned: null,
      period: null,
      childMetrics: isNullish(leftInstance.arguments.childMetrics)
        ? null
        : leftInstance.arguments.childMetrics,
    },
    {
      ...getDefaultMetricArguments(rightInstance.arguments.period),
      ...rightInstance.arguments,
      timeAgo: null,
      isTimeAgoCalendarPeriodAligned: null,
      period: null,
      childMetrics: isNullish(rightInstance.arguments.childMetrics)
        ? null
        : rightInstance.arguments.childMetrics,
    }
  );
}

// Try to keep aligned with ComparisonMetricUtils.java
// in the empty options case
function getComparisonMetricName(
  metricInstance: Types.MetricInstance,
  options: LengthFormatOptions & MetricInstanceFormatOptions = {}
) {
  const comparisonMetrics = metricInstance.arguments.comparisonMetrics;
  if (!comparisonMetrics) return '';

  const metrics = getMetricsList(metricInstance);
  const comparisonText = metrics
    .map(metric => COMPARISON_TEXTS.get(metric.name))
    .find(text => text)!;

  const leftInstance = comparisonMetrics[0];
  const rightInstance = comparisonMetrics[1];
  const leftEffectiveMetric = getEffectiveMetric(leftInstance);
  const rightEffectiveMetric = getEffectiveMetric(rightInstance);

  const leftBaseName = formatMetricInstance(leftInstance, options);
  const rightBaseName = formatMetricInstance(rightInstance, options);

  if (metricInstancesIgnoringTimeAreEqual(leftInstance, rightInstance)) {
    return formatComparisonName(leftBaseName, comparisonText.suffix, '');
  }
  const comparisonString = comparisonText.infix;
  if (
    isComparisonForecastMetricInstance(leftInstance) &&
    isComparisonForecastMetricInstance(rightInstance) &&
    leftEffectiveMetric.forecastedMetrics[0].id === rightEffectiveMetric.forecastedMetrics[0].id
  ) {
    return formatComparisonName(leftBaseName, comparisonString, shortForecastName(rightInstance));
  }
  if (
    // 3. Base metric vs forecasted metric
    isComparisonForecastMetricInstance(rightInstance) &&
    rightEffectiveMetric.forecastedMetrics[0].id === leftEffectiveMetric.id
  ) {
    return formatComparisonName(leftBaseName, comparisonString, shortForecastName(rightInstance));
  }
  if (
    isComparisonForecastMetricInstance(leftInstance) &&
    leftEffectiveMetric.forecastedMetrics[0].id === rightEffectiveMetric.id
  ) {
    return formatComparisonName(shortForecastName(leftInstance), comparisonString, rightBaseName);
  }
  // 4. Base metric vs. targeted metric. We don't support target vs. base.
  // The logic here deviates slightly from the pewter version as we don't have
  // access to a metric's target metric in amalgam. But due to how we currently
  // restrict the comparison options, only the target metrics have "target" in
  // the metric name.
  if (
    leftEffectiveMetric.features.includes(Types.MetricFeature.HAS_TARGET) &&
    rightEffectiveMetric.name.includes('target') &&
    areMetricArgumentsEqual(leftInstance.arguments, rightInstance.arguments, ['timeAgo', 'period'])
  ) {
    return formatComparisonName(leftBaseName, comparisonString, 'Target');
  }

  return formatComparisonName(leftBaseName, comparisonString, rightBaseName);
}

function formatComparisonName(
  leftName: string,
  comparisonString: string,
  rightName: string
): string {
  return [leftName, comparisonString, rightName].filter(isTruthy).join(' ');
}

function formatMetricInstance(
  metricInstance: Types.MetricInstance,
  options: LengthFormatOptions & MetricInstanceFormatOptions = {}
): string {
  if (!metricInstance) {
    return '';
  }
  if (
    metricInstance.metric.features.includes(Types.MetricFeature.IS_COMPS) &&
    metricInstance.arguments.comparisonMetrics?.length === 2
  ) {
    return getComparisonMetricName(metricInstance, options);
  }
  const containedMetrics = getMetricsList(metricInstance);
  if (containedMetrics.length > 1) {
    const prefix = getHigherOrderMetricPrefix(metricInstance);
    const suffix = getHigherOrderMetricSuffix(metricInstance);
    const childMetrics = getSortedChildMetrics(containedMetrics.slice(1));
    const childMetric: Types.MetricInstance = {
      arguments: {...metricInstance.arguments, childMetrics: childMetrics.slice(1)},
      metric: childMetrics[0],
    };
    const name = getHigherOrderMetricInfix(
      metricInstance,
      formatMetricInstance(childMetric, options)
    );
    return `${prefix} ${name}${suffix}`.replace(/ +/g, ' ').trim();
  }

  const metric = metricInstance.metric;
  const displayName = getMetricDisplayName(metricInstance, options.lengthFormat);
  if (metric.name === 'inventory') {
    return displayName;
  }
  const prefix = getMetricPrefix(metricInstance);
  const suffix = getMetricSuffix(metricInstance, options);
  if (metric.name === 'remaining_supply' || metric.name === 'remaining_supply_partner') {
    const partnerSuffix = metric.name === 'remaining_supply_partner' ? ', Partner' : '';
    const {demandSignalPerspective} = metricInstance.arguments;
    const demandPerspectiveSuffix =
      demandSignalPerspective && demandSignalPerspective !== DEFAULT_PERSPECTIVE
        ? `, ${capitalize(demandSignalPerspective)} Demand`
        : '';
    const oosSuffix = getOutOfStockCalculationMethodSuffix(metricInstance);
    const suffix = `${partnerSuffix}${oosSuffix}${demandPerspectiveSuffix}`.trim();
    const extrapolationBase =
      !options.hideForecastSuffixes && metricInstance.arguments.forecastType
        ? metricInstance.arguments.forecastType === Types.ForecastType.HISTORICAL_AVERAGE
          ? ' (Historical)'
          : ` (${getForecastMetricInstanceTypeSuffix(metricInstance).trim()})`
        : '';
    const inventoryTypePhrase = getInventoryTypePhrase(metricInstance.arguments.inventoryTypes);
    const timeUnit = titleCase(assertTruthy(metricInstance.arguments.timeUnit));
    return `${prefix} ${timeUnit} ${inventoryTypePhrase}${extrapolationBase}${suffix}`.trim();
  } else if (metric.name === 'remaining_supply_target') {
    const timeUnit = titleCase(assertTruthy(metricInstance.arguments.timeUnit));
    return `${timeUnit} of Supply Target`;
  } else if (
    metric.name === 'simulated_remaining_supply' ||
    metric.name === 'expected_remaining_supply'
  ) {
    const timeUnit = titleCase(assertTruthy(metricInstance.arguments.timeUnit));
    const simulatedSuffix = Format.inflowOutflowAbbreviationsSuffix(metricInstance.arguments);
    return metric.name.startsWith('simulated')
      ? `${prefix + ' '}Simulated ${timeUnit} of Supply${simulatedSuffix}`
      : `${timeUnit} of Planned Pipeline`;
  } else if (
    metric.name === 'simulated_on_hand_units' ||
    metric.name === 'simulated_required_shipments' ||
    metric.name === 'simulated_required_shipments_rounded' ||
    metric.name === 'recommended_replenishment_units'
  ) {
    const simulatedSuffix = Format.inflowOutflowAbbreviationsSuffix(metricInstance.arguments);
    return `${metric.displayName}${simulatedSuffix}`;
  } else if (
    metric.name === 'converted_product_units' &&
    metricInstance.arguments.unitConversionAttribute !== null
  ) {
    return `${metricInstance.arguments.unitConversionAttribute.name} (Per Product)`;
  } else if (metric.name === 'time_since_sale') {
    switch (metricInstance.arguments.timeUnit) {
      case 'DAYS':
        return `${prefix} Days Since Last Sale${suffix}`.trimLeft();
      case 'WEEKS':
        return `${prefix} Weeks Since Last Sale${suffix}`.trimLeft();
      default:
        break;
    }
  }

  return `${prefix} ${displayName}${suffix}`.trim();
}

// keep in sync with MetricCategory.java in pewter
function formatMetricCategory(metricCategory: Types.MetricCategory): string {
  switch (metricCategory) {
    case Types.MetricCategory.ACTIVE_ITEMS_LOCATIONS:
      return 'Active Items & Locations';
    case Types.MetricCategory.EVENTS:
      return 'Events';
    case Types.MetricCategory.FORECASTS:
      return 'Forecasts';
    case Types.MetricCategory.INVENTORY:
      return 'Inventory';
    case Types.MetricCategory.ORDERS_SHIPMENTS:
      return 'Orders & Shipments';
    case Types.MetricCategory.PLANNING:
      return 'Planning';
    case Types.MetricCategory.PROMOTIONAL_CLEARANCE:
      return 'Promotional & Clearance';
    case Types.MetricCategory.RETAIL_PRICING_COST_MARGINS:
      return 'Retail Pricing, Cost, & Margins';
    case Types.MetricCategory.SALES_DEMAND:
      return 'Sales & Demand';
    case Types.MetricCategory.SUPPLY_CHAIN:
      return 'Supply Chain';
    default:
      return capitalize(metricCategory);
  }
}

const COMPARED_METRIC_INSTANCE_COMPONENT_GETTERS: ReadonlyArray<
  (metricInstance: Types.MetricInstance, identifier: string) => string | null
> = [
  (_, i) => (i.includes('inbound') ? 'Inbound' : i.includes('outbound') ? 'Outbound' : null),
  (_, i) => {
    const component = ['expected', 'planned', 'ordered', 'received', 'shipped'].find(component =>
      i.includes(component)
    );
    return component ? capitalize(component) : null;
  },
  (_, i) => (i.startsWith('sales') ? 'POS' : null),
  (m, i) =>
    i.startsWith('forecast') && m.arguments.forecastType
      ? getForecastTypeShortName(m.arguments.forecastType)
      : null,
  (_, i) => (i.endsWith('plan') ? 'Plan' : null),
  (_, i) => (i.endsWith('target') ? 'Target' : null),
  (_, i) => (i.includes('net') ? 'Net' : i.includes('gross') ? 'Gross' : null),
];

function formatMetricInstancesCompared(metricInstance: Types.MetricInstance) {
  const left = metricInstance.arguments.comparisonMetrics![0];
  const leftIdentifier = getComparisonMetricInstanceIdentifier(left);
  const right = metricInstance.arguments.comparisonMetrics![1];
  const rightIdentifier = getComparisonMetricInstanceIdentifier(right);
  const components = COMPARED_METRIC_INSTANCE_COMPONENT_GETTERS.map(getter => [
    getter(left, leftIdentifier),
    getter(right, rightIdentifier),
  ]);
  const differingExistingComponents = components.filter(
    ([leftComponent, rightComponent]) =>
      leftComponent && rightComponent && leftComponent !== rightComponent
  );
  if (differingExistingComponents.length) {
    return [0, 1].map(index => differingExistingComponents.map(pair => pair[index]).join(', '));
  }
  const differingComponents = components.filter(
    ([leftComponent, rightComponent]) => leftComponent !== rightComponent
  );
  const proposedNames = [0, 1].map(index =>
    differingComponents
      .map(pair => pair[index])
      .filter(isTruthy)
      .join(', ')
  );
  if (proposedNames.every(isTruthy)) {
    return proposedNames;
  }
  return [left, right].map(comparedMetric =>
    formatMetricInstance(comparedMetric, {lengthFormat: TextLengthFormat.ABBREVIATED})
  );
}

function formatMetricInstanceDetailed(
  metricInstance: Types.MetricInstance,
  options: MetricInstanceFormatOptions = {}
) {
  return `${formatMetricInstance(metricInstance, options)} (${formatMetricPeriod(metricInstance)})`;
}

function formatMetricInstanceDetailedWithoutPeriod(
  metricInstance: Types.MetricInstance,
  options: LengthFormatOptions & MetricInstanceFormatOptions = {}
) {
  if (metricInstance.arguments.timeAgo) {
    return `${formatMetricInstance(metricInstance, options)} (${formatMetricTimeAgo(
      metricInstance
    )})`;
  }
  return formatMetricInstance(metricInstance, options);
}

function formatDateTime(
  datetime: number | string | moment.Moment | null,
  options: DateFormatOptions & DateUsingTodayOptions = {}
) {
  const tz = moment(datetime).tz(defaultZone);
  const now = moment(options.today);
  if (options.isInNaturalLanguage) {
    return formatDateTimeInNaturalLanguage(moment(datetime), now);
  }
  if (!options.isTimeFromNow && isShortened(options.lengthFormat)) {
    return tz.isSame(now, 'day') ? `${tz.format('h:mma')} Today` : formatDate(tz, options);
  }

  const prefix = !tz.isAfter(now) || isShortened(options.lengthFormat) ? '' : 'in ';
  const suffix = tz.isAfter(now) || isShortened(options.lengthFormat) ? '' : ' ago';

  return options.isTimeFromNow
    ? `${prefix}${tz.from(now, true)}${suffix}`
    : tz.format('YYYY-MM-DD h:mma z');
}

function formatDeliveryChannel(
  deliveryChannel: Types.DeliveryChannel,
  options: LengthFormatOptions = {}
): string {
  switch (deliveryChannel) {
    case Types.DeliveryChannel.SFTP:
      return 'SFTP';
    case Types.DeliveryChannel.BIGQUERY:
      return 'BigQuery';
    case Types.DeliveryChannel.GCS:
      return isShortened(options.lengthFormat) ? 'GCS' : 'Google Cloud Storage';
    default:
      return capitalize(deliveryChannel);
  }
}

function formatGraphContext(context: Types.GraphContext | null) {
  if (!context) {
    return '';
  }

  switch (context) {
    case Types.GraphContext.ORIGIN:
      return 'Origin';
    case Types.GraphContext.DESTINATION:
      return 'Destination';
    case Types.GraphContext.UPSTREAM:
      return 'Upstream';
    default:
      return '';
  }
}

function formatAttribute(attribute: Types.Attribute) {
  return attribute.name.split(CONCATENATED_IDENTIFIER_DELIMITER).join(', ');
}

function formatAttributeInstance(attributeInstance: Types.AttributeInstance) {
  if (!attributeInstance) {
    return '';
  }
  const name = attributeInstance.attribute.name;
  const context = formatGraphContext(attributeInstance.graphContext);
  if (context) {
    return `${context} ${name}`;
  }
  return name;
}

function formatAttributeInstances(
  attributeInstances: readonly Types.AttributeInstance[],
  options: ListFormatOptions = {}
): string {
  if (!attributeInstances) {
    return '';
  }

  return attributeInstances
    .map(attributeInstance => formatAttributeInstance(attributeInstance))
    .join(options.separator === undefined ? ' / ' : options.separator);
}

function formatShortenedPartnerName(displayName: string) {
  const parts = displayName
    .replace(
      /(Canada|Austria|Germany|France|UK|Italy|Chile|Czech Republic|Columbia|Mexico|Belgium|Netherlands|UAE)$/,
      ''
    )
    .replace(/.com/, '')
    .replace(/\b(AS|SK|Ltd|co|Co)\b/, '')
    .replace(/('|"|,)/, '')
    .replace(/(-)/, ' ')
    .replace(' and ', ' & ')
    .trim()
    .split(' ');

  return parts.length === 1 ? parts[0] : parts.map(part => part[0]).join('');
}

function formatThinAttributeValue(
  attributeName: string,
  value: Types.ThinAttributeValue | null | undefined,
  options: AttributeValueFormatOptions = {}
) {
  if (!value) {
    return '';
  }

  const displayValue =
    value.displayValue || formatAttributeValueByType(value.valueType, value.value, options);

  return formatAttributeValueInner(attributeName, value, displayValue, options);
}

function formatAttributeValueInner(
  attributeName: string,
  val: Omit<Types.ThinAttributeValue, 'valueType'>,
  displayValue: string,
  options: AttributeValueFormatOptions = {}
) {
  let finalDisplayValue = displayValue; // eslint-disable-line fp/no-let
  if (attributeName === 'Week') {
    const interval = val.value as Types.LocalInterval;
    if (options.weekFormat !== 'CALENDAR_FORMAT') {
      finalDisplayValue = formatDate(
        options.weekFormat === 'START_OF_WEEK' ? interval.start : interval.end,
        {...options, isExclusiveEndDate: options.weekFormat === 'END_OF_WEEK'}
      );
    }
  } else if (attributeName === 'Partner' && isShortened(options.lengthFormat)) {
    finalDisplayValue = formatShortenedPartnerName(displayValue);
  }

  return finalDisplayValue;
}

function formatAttributeValue(
  val: Types.AttributeValue | null | undefined,
  options: AttributeValueFormatOptions = {}
) {
  if (!val) {
    return '';
  }
  const displayValue =
    val.displayValue || formatAttributeValueByType(val.attribute.valueType, val.value, options);
  return formatAttributeValueInner(val.attribute.name, val, displayValue, options);
}

function formatAttributeValues(
  values: readonly Types.AttributeValue[],
  options: AttributeValueFormatOptions = {}
) {
  return values.map(value => formatAttributeValue(value, options)).join(', ');
}

function formatAttributeFilter(filter: Types.AttributeFilter) {
  return (
    formatAttributeInstance(filter.attributeInstance) +
    `${!filter.inclusive ? ' not' : ''} including ` +
    formatAttributeValues(filter.values)
  );
}

function formatProductMapping(val: Types.ProductMapping) {
  if (!val) {
    return '';
  }
  return `${val.attributeValueOne.attribute.name}=${val.attributeValueOne.value} <-> ${val.attributeValueTwo.attribute.name}=${val.attributeValueTwo.value}`;
}

// formats should match readable names in pewter/service/metric/src/main/java/com/alloymetrics/service/metric/MetricFilter.java
function formatPredicate(
  predicate: Types.MetricFilterPredicate,
  options: LengthFormatOptions = {}
) {
  const shorten = isShortened(options.lengthFormat);
  switch (predicate) {
    case Types.MetricFilterPredicate.EQ:
      return shorten ? '=' : 'equal to';
    case Types.MetricFilterPredicate.NE:
      return shorten ? '≠' : 'not equal to';
    case Types.MetricFilterPredicate.LT:
      return shorten ? '<' : 'less than';
    case Types.MetricFilterPredicate.GT:
      return shorten ? '>' : 'greater than';
    case Types.MetricFilterPredicate.LE:
      return shorten ? '≤' : 'at most';
    case Types.MetricFilterPredicate.GE:
      return shorten ? '≥' : 'at least';
    case Types.MetricFilterPredicate.TOP:
      return 'top';
    case Types.MetricFilterPredicate.BOTTOM:
      return 'bottom';
    default:
      throw new Error(`Unsupported predicate: ${predicate}`);
  }
}

function isNonWholeNumber(number: number) {
  return Math.round(number) !== number;
}

function formatMetricValue(
  metricInstance: Types.MetricInstance,
  value: number | null,
  metadata?: readonly Types.MetricValueMetadata[] | null,
  options: MetricFormatOptions = {}
) {
  const displayType = getMetricDisplayType(metricInstance);
  return formatMetricValueByDisplayType(displayType, value, metadata, {
    includeCurrency: isAsReportedCurrencyMetric(metricInstance),
    fractionalDigitCount:
      options.includeDecimalsForSmallNonWholeIntegers &&
      displayType === Types.MetricDisplayType.INTEGER &&
      Number.isFinite(value) &&
      Math.abs(value!) < MAX_VALUE_TO_SHOW_DECIMAL_FOR_INTEGERS &&
      isNonWholeNumber(value!)
        ? 1
        : undefined,
    ...options,
  });
}

function formatMetricValueByDisplayType(
  displayType: BaseMetricDisplayType,
  value: number | null,
  metadata?: readonly Types.MetricValueMetadata[] | null,
  options: MetricFormatOptions = {}
) {
  if (value === null) {
    return NAN_STRING;
  }

  switch (displayType) {
    case Types.MetricDisplayType.INTEGER:
      return Format.integer(value, options);
    case Types.MetricDisplayType.FLOAT:
      return Format.float(value, options);
    case Types.MetricDisplayType.MONEY:
      return Format.money(value, metadata, options);
    case Types.MetricDisplayType.PERCENT:
      return Format.percent(value, options);
    case Types.MetricDisplayType.PRICE:
      return Format.price(value, metadata, options);
    default:
      throw new Error(`Unsupported type ${displayType}`);
  }
}

function formatAttributeValueByType(
  valueType: Types.AttributeValueType,
  value: any,
  options: AttributeValueFormatOptions = {}
) {
  switch (valueType) {
    case 'date':
      return Format.date(value, options);
    case 'float':
      return Format.float(value, options);
    case 'integer':
      return Format.integer(value, options);
    case 'interval':
      return Format.interval(value, options);
    case 'string':
      return Format.string(value);
    default:
      throw new Error(`Unsupported type ${valueType}`);
  }
}

function formatMetricFilter(filter: Types.MetricFilter, options: MetricFilterFormatOptions = {}) {
  const formattedMetricInstance =
    options.customName ??
    (isShortened(options.lengthFormat)
      ? formatMetricInstanceDetailedWithoutPeriod(filter.metric, options)
      : formatMetricInstanceDetailed(filter.metric));

  if (isRankBasedMetricFilter(filter)) {
    return `${formatPredicate(filter.predicate, options)} ${
      filter.value
    } by ${formattedMetricInstance}`;
  }

  return [
    formattedMetricInstance,
    formatPredicate(filter.predicate, options),
    formatMetricValue(filter.metric, filter.value),
  ].join(' ');
}

function formatWidgetName(widget: Types.Widget): string {
  if (widget.customName?.length) {
    return widget.customName;
  }
  const widgetName = titleCase(Widgets[widget.type].shortDisplayName);
  const metricNames = getWidgetTitle(widget);
  return `${widgetName}: ${metricNames}`;
}

function formatNumber(
  value: number | null | undefined,
  formatString: string,
  options: NumberFormatOptions & LengthFormatOptions = {alwaysIncludePositiveSign: false}
) {
  const roundedValue =
    options.significantFigures && value !== null && value !== undefined
      ? parseFloat(value.toPrecision(options.significantFigures))
      : value;
  const formattedValue = numeral(roundedValue).format(formatString);
  if (Number.isNaN(roundedValue)) {
    return formattedValue;
  }
  const result =
    formattedValue.includes('NaN') || formattedValue === 'N$aN'
      ? // Workaround for a bug in Numeral.js which prevents formatting small values
        // https://github.com/adamwdraper/Numeral-js/issues/563
        numeral(0).format(formatString)
      : // NumeralJS only allows formatting abbreviations (k, m, b) in lowercase, but we want
        // them capitalized
        formattedValue.toUpperCase();
  return options.alwaysIncludePositiveSign && (value ?? 0) > 0 ? `+${result}` : result;
}

function formatCurrencyMetadata(metadata?: readonly Types.MetricValueMetadata[] | null): string {
  const currencyMetadata: Types.CurrencyMetricValueMetadata | undefined = metadata?.find(
    data => data.type === 'CURRENCY'
  ) as Types.CurrencyMetricValueMetadata | undefined;
  // We don't currently expect multiple currencies, so return an empty string for now
  return !currencyMetadata || currencyMetadata.value.length > 1 ? '' : currencyMetadata.value[0];
}

function formatDollarValues(
  value: number | null | undefined,
  format: string,
  metadata?: readonly Types.MetricValueMetadata[] | null,
  options: LengthFormatOptions & NumberFormatOptions & MoneyFormatOptions = {}
) {
  if (!Number.isFinite(value)) {
    return NAN_STRING;
  }
  const currencyString = formatCurrencyMetadata(metadata);
  if (options.includeCurrency && options.useDollarSign && currencyString === 'USD') {
    return formatNumber(value, `${options.hidePercentAndDollarSign ? '' : '$'}${format}`, options);
  }
  return options.includeCurrency && currencyString
    ? `${formatNumber(value, format, options)} ${currencyString}`
    : formatNumber(value, format, options);
}

function formatMoney(
  value: number | null | undefined,
  metadata?: readonly Types.MetricValueMetadata[] | null,
  options: LengthFormatOptions & NumberFormatOptions & MoneyFormatOptions = {}
) {
  const format = isShortened(options.lengthFormat) ? getShortenedFormatString(options) : '0,0';
  return formatDollarValues(value, format, metadata, options);
}
function formatPercent(
  value: number | null | undefined,
  options: NumberFormatOptions & LengthFormatOptions & PercentFormatOptions = {
    alwaysIncludePositiveSign: false,
  }
) {
  if (value === null || value === undefined || Number.isNaN(value)) {
    return NAN_STRING;
  }
  if (!Number.isFinite(value)) {
    return (value < 0 ? '-' : '') + INFINITY_SYMBOL;
  }

  if (isNonNullish(options.upperLimit) && value > options.upperLimit) {
    return `> ${options.upperLimit * 100}${options.hidePercentAndDollarSign ? '' : '%'}`;
  }
  if (isNonNullish(options.lowerLimit) && value < options.lowerLimit) {
    return `< ${options.lowerLimit * 100}${options.hidePercentAndDollarSign ? '' : '%'}`;
  }

  const fractionalDigitCount = getFractionalDigitCount(options, 1);
  return formatNumber(
    options.hidePercentAndDollarSign ? value * 100 : value, // If we drop the % sign later, we need to manually x 100
    isShortened(options.lengthFormat) || fractionalDigitCount === 0
      ? `0.[${'0'.repeat(fractionalDigitCount)}]${options.hidePercentAndDollarSign ? '' : '%'}`
      : `0.0${options.hidePercentAndDollarSign ? '' : '%'}`,
    options
  );
}

function formatPrice(
  value: number,
  metadata?: readonly Types.MetricValueMetadata[] | null,
  options: LengthFormatOptions & MoneyFormatOptions = {}
) {
  const format = isShortened(options.lengthFormat) ? getShortenedFormatString(options) : '0,0.00';
  return formatDollarValues(value, format, metadata, options);
}

function formatSuffix(value: string) {
  return value ? ` (${value})` : '';
}

function formatVendorDataSource(
  {dataSource, credentialSetName, ediPartnerId, deprecatedAt}: Types.VendorDataSource,
  partners: PartnersById,
  options: LengthFormatOptions = {}
) {
  if (dataSource.id === null) {
    // For unassigned vendor data source,
    // do not display delivery type UNKNOWN
    return 'Unassigned';
  }
  const isEdi = dataSource.name === 'EDI';

  const dataSourceInfo = isShortened(options.lengthFormat)
    ? isEdi
      ? ''
      : dataSource.name
    : isEdi
      ? 'EDI'
      : `${dataSource.name} ${wrapIfExists(dataSource.type, '(', ')')}`;
  const vendorDataSourceInfo = credentialSetName
    ? titleCase(credentialSetName)
    : isEdi
      ? (partners.get(ediPartnerId, null)?.name ?? 'Unknown Partner')
      : null;
  const deprecatedInfo = deprecatedAt ? `(deprecated on ${deprecatedAt})` : null;
  return [[dataSourceInfo, vendorDataSourceInfo].filter(isTruthy).join(' - '), deprecatedInfo]
    .filter(isTruthy)
    .join(' ');
}

function formatFullDataSourceType(sourceType: Types.DataSourceType) {
  switch (sourceType) {
    case Types.DataSourceType.DIRECT:
      return 'Direct Upload';
    case Types.DataSourceType.FORWARD:
      return 'Forwarded Feed';
    case Types.DataSourceType.EDI:
      return 'EDI';
    case Types.DataSourceType.RPA:
      return 'Partner Portal';
    case Types.DataSourceType.UNKNOWN:
      return 'Unknown';
  }
}

function formatDataSourceType(
  sourceType: Types.DataSourceType,
  options: LengthFormatOptions = {lengthFormat: TextLengthFormat.COMPACT}
) {
  const isAbbreviated = ['EDI', 'RPA'].includes(sourceType);
  switch (options.lengthFormat) {
    case TextLengthFormat.ABBREVIATED:
      return isAbbreviated ? sourceType : capitalize(sourceType[0]);
    case TextLengthFormat.FULL:
      return formatFullDataSourceType(sourceType);
    case TextLengthFormat.COMPACT:
    default:
      return isAbbreviated ? sourceType : capitalize(sourceType);
  }
}

// TODO: make EDI it's own data source type so this isn't necessary https://app.shortcut.com/alloytech/story/62221
function formatGroupedVendorDataSource(source: Types.DisplayableSource, partners: PartnersById) {
  if (source.dataSource.name === 'EDI') {
    return partners.get(source.vendorDataSources[0].ediPartnerId)?.name ?? 'Unknown';
  } else {
    return source.dataSource.name;
  }
}

function formatDataIntegrationType(dataIntegrationType?: Types.DataIntegrationType | null): string {
  switch (dataIntegrationType) {
    case Types.DataIntegrationType.ERP:
      return 'ERP';
    case Types.DataIntegrationType.ECOMMERCE_PLATFORM:
      return 'E-Commerce Platform';
    case Types.DataIntegrationType.THIRD_PARTY_LOGISTICS:
      return '3PL';
    default:
      return capitalize(dataIntegrationType ?? '');
  }
}

function formatDataSource(
  dataSourceName: string | null,
  credentialSetName: string | null,
  ediPartnerName: string | null | undefined
) {
  if (dataSourceName === null) {
    return 'Unassigned';
  }
  const isEdi = dataSourceName === 'EDI';
  const vendorDataSourceInfo = credentialSetName
    ? titleCase(credentialSetName)
    : isEdi
      ? (ediPartnerName ?? 'Unknown Partner')
      : null;
  return [dataSourceName, vendorDataSourceInfo].filter(isTruthy).join(' - ');
}

function formatGlobalDataSource(dataSource: Types.DataSource) {
  return `${dataSource.name} (${dataSource.type})`;
}

function formatCalendarEventType(
  type: Types.CalendarEventType,
  options: CalendarEventFormatOptions = {}
) {
  const metadata = CalendarEventTypeMetadata[type];
  if (isShortened(options.lengthFormat)) {
    return metadata.shortName || metadata.subcategory || metadata.category;
  } else if (options.hideCategoryName) {
    return metadata.subcategory || metadata.category;
  }
  return metadata.subcategory ? `${metadata.category}: ${metadata.subcategory}` : metadata.category;
}

function formatTransactionEventQuantity(
  transactionBackedMetricInstance: Types.MetricInstance,
  quantity: number
) {
  const unitMetric = isUnitMetric(transactionBackedMetricInstance);
  const unitsConverted = transactionBackedMetricInstance.arguments.unitConversionAttribute;
  return unitMetric
    ? unitsConverted
      ? Format.float(quantity)
      : Format.integer(quantity)
    : Format.money(quantity);
}

function formatHelmetTitle(environmentName: string | undefined) {
  return !environmentName || environmentName === 'production'
    ? 'Alloy.ai'
    : `${capitalizeFirstLetter(environmentName)} Alloy.ai`;
}

function getFractionalDigitCount(options: LengthFormatOptions, defaultCount = 2): number {
  return options.fractionalDigitCount ?? (options.significantFigures || defaultCount + 1) - 1;
}

function getShortenedFormatString(options: LengthFormatOptions = {}): string {
  return `0,0.[${'0'.repeat(getFractionalDigitCount(options))}]a`;
}

export function getMetricDollarTypeSubtitle(metricInstance: Types.MetricInstance): string | null {
  if (
    isNonNullish(metricInstance.arguments.dollarType) &&
    getEffectiveMetric(metricInstance).requiredArguments.includes(Types.MetricArgument.dollarType)
  ) {
    const baseMetricDollarType = metricInstance.arguments.dollarType;
    const otherComparisonDollarTypes = !metricInstance.arguments.comparisonMetrics
      ? []
      : Array.from(
          new Set(
            metricInstance.arguments.comparisonMetrics
              .filter(
                comparisonMetric =>
                  isNonNullish(comparisonMetric.arguments.dollarType) &&
                  comparisonMetric.arguments.dollarType !== baseMetricDollarType
              )
              .map(comparisonMetric =>
                getDollarTypeDisplayName(comparisonMetric.arguments.dollarType!)
              )
          )
        );

    const baseMetricDollarTypeSubtitle = getDollarTypeDisplayName(baseMetricDollarType);
    return !otherComparisonDollarTypes || otherComparisonDollarTypes.length === 0
      ? baseMetricDollarTypeSubtitle
      : [baseMetricDollarTypeSubtitle, ...otherComparisonDollarTypes].join(' vs. ');
  }
  if (
    isNonNullish(metricInstance.arguments.inventoryMeasure) &&
    getEffectiveMetric(metricInstance).requiredArguments.includes(
      Types.MetricArgument.inventoryMeasure
    ) &&
    metricInstance.arguments.inventoryMeasure !== Types.InventoryMeasure.UNITS
  ) {
    return getInventoryMeasureDisplayName(metricInstance.arguments.inventoryMeasure);
  }
  return null;
}

export function formatSubscriptionType(
  type: Types.WidgetSubscription['type'],
  options: LengthFormatOptions = {}
): string {
  switch (type) {
    case 'EMAIL':
      return 'Email';
    case 'EXTERNAL_STORAGE':
      return isShortened(options.lengthFormat) ? 'External' : 'External Storage';
  }
}

export function getWorkflowTitle(workflow: Types.Workflow): string {
  switch (workflow) {
    case Types.Workflow.PROMOTION_ANALYSIS:
      return 'Promotion Analysis';
    case Types.Workflow.WALMART_NOVA_RECOMMENDATIONS:
      return 'Walmart NOVA Recommendations';
    case Types.Workflow.DIAGNOSTICS_INSTOCK_PERCENT_CHANGE:
      return 'Diagnostics - In Stock % Change';
    case Types.Workflow.DIAGNOSTICS_LOCATION_COUNT_CHANGE:
      return 'Diagnostics - Location Count Change';
    case Types.Workflow.DIAGNOSTICS_MIX_SHIFT:
      return 'Diagnostics - Mix Shift';
    case Types.Workflow.DIAGNOSTICS_PRICE_CHANGE:
      return 'Diagnostics - Price Change';
    case Types.Workflow.DIAGNOSTICS_PROMOTIONS:
      return 'Diagnostics - Promotions';
    case Types.Workflow.DIAGNOSTICS_SALES_PERFORMANCE:
      return 'Diagnostics - Sales Performance';
  }
}

export function allValuesForAttributeString(attribute: Types.Attribute): string {
  return `All ${pluralize(attribute.name)}`;
}

function isShortened(textLengthFormat: TextLengthFormat | null | undefined) {
  return (
    textLengthFormat === TextLengthFormat.COMPACT ||
    textLengthFormat === TextLengthFormat.ABBREVIATED
  );
}

function getPartnerDisplayName(partnerId: number, partners: PartnersById) {
  const partner = partners.get(partnerId);
  return partner ? partner.name : 'Unknown partner';
}

function formatPartnerIdentifier(
  partnerIdentifier: Types.PartnerIdentifier,
  partners: PartnersById
) {
  if (partnerIdentifier.isPair) {
    if (partnerIdentifier.originPartnerId === null) {
      return `To ${partners.get(partnerIdentifier.destinationPartnerId)!.name}`;
    } else if (partnerIdentifier.destinationPartnerId === null) {
      return `From ${partners.get(partnerIdentifier.originPartnerId)!.name}`;
    } else {
      return `${partners.get(partnerIdentifier.originPartnerId)!.name} → ${
        partners.get(partnerIdentifier.destinationPartnerId)!.name
      }`;
    }
  } else {
    return partners.get(partnerIdentifier.partnerId)!.name;
  }
}

function formatDataStatus(dataStatus: Types.SimplifiedFileStatus) {
  return capitalize(dataStatus);
}

// This should be kept in sync with DataStatusV2 in Pewter
function formatDataStatusV2(
  dataStatus: Types.DataStatusV2,
  options: LengthFormatOptions = {lengthFormat: TextLengthFormat.COMPACT},
  dataCategory?: string
) {
  switch (dataStatus) {
    case Types.DataStatusV2.DISCONNECTED:
      return 'Disconnected';
    case Types.DataStatusV2.FORWARD_FILE_MISSING:
      return isShortened(options.lengthFormat) ? 'Missing' : 'Missing File';
    case Types.DataStatusV2.FORWARD_FILE_FAILED:
    case Types.DataStatusV2.FAILED:
    case Types.DataStatusV2.SCRAPER_FAILED:
      return 'Failed';
    case Types.DataStatusV2.IN_PROGRESS:
      return isShortened(options.lengthFormat) ? 'Processing' : 'Processing...';
    case Types.DataStatusV2.EXPECTED:
      return isShortened(options.lengthFormat) ? 'Expected' : 'Expected Today...';
    case Types.DataStatusV2.NO_STATUS:
      const formattedStatus = `${dataCategory ?? ''} Status`.trim();
      return isShortened(options.lengthFormat) ? 'No Status' : `No Recent ${formattedStatus}`;
    case Types.DataStatusV2.SUCCESS:
      const formattedDataCategory = `${dataCategory ?? ''} Data`.trim();
      return isShortened(options.lengthFormat)
        ? 'Success'
        : `Latest ${formattedDataCategory} Available`;
  }
}

// This should be kept in sync with Cadence.java
export const WEEKLY_CADENCES = [
  Types.Cadence.MONDAY,
  Types.Cadence.TUESDAY,
  Types.Cadence.WEDNESDAY,
  Types.Cadence.THURSDAY,
  Types.Cadence.FRIDAY,
  Types.Cadence.SATURDAY,
  Types.Cadence.SUNDAY,
];

function formatCadence(
  cadence: Types.Cadence,
  options: CadenceFormatOptions = {lowercaseNonDays: false, plural: false}
) {
  const cadenceString = titleCase(cadence.toString(), '_');
  if (WEEKLY_CADENCES.includes(cadence)) {
    return options.plural ? pluralize(cadenceString) : cadenceString;
  }
  return options.lowercaseNonDays ? cadenceString.toLocaleLowerCase() : cadenceString;
}

const Format = {
  attribute: formatAttribute,
  attributeInstance: formatAttributeInstance,
  attributeInstanceList: formatAttributeInstances,
  attributeValue: formatAttributeValue,
  attributeValues: formatAttributeValues,
  attributeFilter: formatAttributeFilter,
  thinAttributeValue: formatThinAttributeValue,
  calendarEventType: formatCalendarEventType,
  partner: getPartnerDisplayName,
  partnerIdentifier: formatPartnerIdentifier,
  productMapping: formatProductMapping,
  cadence: formatCadence,
  date: formatDate,
  duration: formatDuration,
  dataIntegrationType: formatDataIntegrationType,
  dataSource: formatDataSource,
  dataSourceType: formatDataSourceType,
  dateRange: formatDateRange,
  dataStatus: formatDataStatus,
  dataStatusV2: formatDataStatusV2,
  dateTime: formatDateTime,
  deliveryChannel: formatDeliveryChannel,
  ellipsize: (str: string, maxLength: number, middle = false) => {
    if (str.length <= maxLength) {
      return str;
    } else if (middle) {
      const lengthOnEachSide = Math.floor(maxLength / 2);
      return (
        str.substring(0, lengthOnEachSide) +
        '…' +
        str.substring(str.length - lengthOnEachSide, str.length)
      );
    } else {
      return str.substring(0, maxLength - 1) + '…';
    }
  },
  fileSize: (size: number | null | undefined) => formatNumber(size, '0.0 b'),
  float: (value: number, options: LengthFormatOptions & FloatFormatOptions = {}) => {
    if (Number.isFinite(value)) {
      if (Math.abs(value) < 1 && options.significantDigitsForSmallMagnitudeNumbers !== undefined) {
        return new Intl.NumberFormat('en-US', {
          maximumSignificantDigits: options.significantDigitsForSmallMagnitudeNumbers,
        }).format(value);
      } else {
        return formatNumber(
          value,
          isShortened(options.lengthFormat) ? getShortenedFormatString(options) : '0,0.00'
        );
      }
    } else {
      return Math.abs(value) === Infinity ? (value < 0 ? '-' : '') + INFINITY_SYMBOL : NAN_STRING;
    }
  },
  helmetTitle: formatHelmetTitle,
  inflowOutflowAbbreviationsSuffix: formatInflowOutflowAbbreviationsSuffix,
  integer: (
    value: number,
    options: LengthFormatOptions & NumberFormatOptions = {
      alwaysIncludePositiveSign: false,
      lengthFormat: TextLengthFormat.FULL,
    }
  ) => {
    return Number.isFinite(value)
      ? formatNumber(
          value,
          isShortened(options.lengthFormat)
            ? getShortenedFormatString(options)
            : `0,0.${'0'.repeat(options.fractionalDigitCount ?? 0)}`,
          options
        )
      : NAN_STRING;
  },
  interval: formatInterval,
  listOfStrings: (strings: readonly string[]) => {
    if (strings.length <= 1) {
      return strings.at(0);
    } else {
      return `${strings.slice(0, -1).join(', ')} and ${strings.at(strings.length - 1)}`;
    }
  },
  metric: formatMetricInstance,
  metricCategory: formatMetricCategory,
  metricsCompared: formatMetricInstancesCompared,
  metricDetailed: formatMetricInstanceDetailed,
  metricFilter: formatMetricFilter,
  metricFilterPredicate: formatPredicate,
  metricPeriod: formatMetricPeriod,
  metricTimeAgo: formatMetricTimeAgo,
  metricValue: formatMetricValue,
  metricValueByDisplayType: formatMetricValueByDisplayType,
  metricWithoutPeriod: formatMetricInstanceDetailedWithoutPeriod,
  minutes: formatMinutes,
  money: formatMoney,
  widgetName: formatWidgetName,
  percent: formatPercent,
  period: formatPeriod,
  price: formatPrice,
  shortApproximateValue: formatShortApproximateValue,
  shortComparisonPeriod: formatComparisonPeriodShorthand,
  simplePeriod: formatSimplePeriod,
  string: (value: string) => value,
  vendorDataSource: formatVendorDataSource,
  groupedVendorDataSource: formatGroupedVendorDataSource,
  globalDataSource: formatGlobalDataSource,
  transactionEventQuantity: formatTransactionEventQuantity,
  subscriptionType: formatSubscriptionType,
  forecastTypeDisplayName: getForecastTypeDisplayName,
  metricDollarTypeSubtitle: getMetricDollarTypeSubtitle,
  workflow: getWorkflowTitle,
};

export default Format;
