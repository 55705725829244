import './GlobalSearchIcon.scss';

import classNames from 'classnames';
import React, {useCallback, useState, useRef, useImperativeHandle, useEffect} from 'react';

import GlobalSearchDropdownContent, {
  focusFirstSearchElement,
} from 'nav/GlobalSearchDropdownContent';
import {GLOBAL_SEARCH_BAR_ID} from 'nav/utils';
import {setGlobalSearchFilterText, setGlobalSearchSelectedTags} from 'redux/actions/navigation';
import useSelector from 'redux/selectors/useSelector';
import useDispatch from 'redux/useDispatch';
import Dropdown, {AttachmentProps} from 'toolkit/components/Dropdown';
import Icon from 'toolkit/components/Icon';
import Suspenseful from 'toolkit/components/Suspenseful';
import Tooltip from 'toolkit/components/Tooltip';
import {getHideOnPhonesClassName} from 'toolkit/styles/utils';
import TagInput from 'toolkit/tags/TagInput';
import {isNextItemEvent} from 'toolkit/utils/events';
import usePrevious from 'toolkit/utils/usePrevious';
import {isNonNullish} from 'utils/functions';
import {FocusableElement} from 'utils/types';

const SEARCH_INPUT_CLASSNAME = 'GlobalSearchIcon';

export type GlobalSearchRef = FocusableElement & {
  isDropdownOpen: () => boolean;
};

function isContainedBySearchField(event: Event) {
  if (event.type !== 'click') {
    return false;
  }

  return !!(event.target as Element).closest(`.${SEARCH_INPUT_CLASSNAME}`);
}

const GlobalSearchIcon = React.forwardRef(
  (
    {
      className,
      dropdownAttachment = {attachment: 'top right', targetAttachment: 'bottom right'},
      customSearchButton,
    }: Props,
    forwardedRef: React.Ref<GlobalSearchRef>
  ) => {
    const innerSearchInputRef = useRef<HTMLInputElement | null>(null);

    const dispatch = useDispatch();
    const filterText = useSelector(state => state.navigation.globalSearchState.filterText);
    const selectedTags = useSelector(state => state.navigation.globalSearchState.selectedTags);

    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const prevIsDropdownOpen = usePrevious(isDropdownOpen);

    useEffect(() => {
      if (isDropdownOpen && !prevIsDropdownOpen) {
        innerSearchInputRef.current!.focus();
      }
    }, [isDropdownOpen, prevIsDropdownOpen, innerSearchInputRef]);

    const openDropdown = useCallback(() => setDropdownOpen(true), []);
    const closeDropdown = useCallback(() => {
      setDropdownOpen(false);
      innerSearchInputRef.current?.blur();
    }, []);
    useImperativeHandle(forwardedRef, () => ({
      focus: () => {
        // Focus happens automatically after the dropdown is opened
        openDropdown();
      },
      isDropdownOpen: () => isDropdownOpen,
    }));
    const handleTagClick = useCallback(() => innerSearchInputRef.current?.focus(), []);
    const handleResultClick = useCallback(() => {
      closeDropdown();
      dispatch(setGlobalSearchFilterText(''));
    }, [closeDropdown, dispatch]);

    const handleRootClose = useCallback(
      (e: Event) => e.target !== innerSearchInputRef.current && !isContainedBySearchField(e),
      []
    );

    const focusSearch = useCallback(() => {
      const input = innerSearchInputRef.current;
      if (input) {
        input.focus();
      }
    }, [innerSearchInputRef]);

    const handleSpecialKeys = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Escape') {
        event.nativeEvent.stopImmediatePropagation();
        innerSearchInputRef.current?.blur();
      } else if (event.key === 'Enter') {
        focusFirstSearchElement()?.click();
      } else if (isNextItemEvent(event)) {
        event.preventDefault();
        // focus the first option on the tethered list
        focusFirstSearchElement();
      }
    }, []);

    const handleFilterTextChange = useCallback(
      (text: string) => dispatch(setGlobalSearchFilterText(text)),
      [dispatch]
    );

    const renderDropdownContent = () => (
      <>
        <div className={classNames(SEARCH_INPUT_CLASSNAME, className)} id={GLOBAL_SEARCH_BAR_ID}>
          <TagInput
            ref={innerSearchInputRef}
            className={classNames(getHideOnPhonesClassName())}
            debounceDelay={100}
            placeholder="Search Alloy.ai…"
            tags={selectedTags}
            value={filterText}
            onChange={handleFilterTextChange}
            onChangeTags={tags => {
              dispatch(setGlobalSearchSelectedTags(tags));
              innerSearchInputRef.current?.focus();
            }}
            onKeyDown={handleSpecialKeys}
          />
        </div>
        <Suspenseful>
          <GlobalSearchDropdownContent
            onFocusSearchRequested={focusSearch}
            onResultClick={handleResultClick}
            onTagClick={handleTagClick}
          />
        </Suspenseful>
      </>
    );

    const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Enter') {
        openDropdown();
      }
    };

    const renderSearchBar = (ref: React.RefObject<any> | undefined) => (
      <Tooltip placement="bottom-end" tooltip="Search Alloy.ai">
        <div
          ref={ref}
          className={classNames('icon-link', 'search', getHideOnPhonesClassName())}
          role="button"
          tabIndex={0}
          onClick={openDropdown}
          onKeyDown={handleKeyDown}
        >
          <Icon icon="search" />
        </div>
      </Tooltip>
    );

    return (
      <Dropdown
        attachment={dropdownAttachment.attachment}
        dropdownContentClassName="global-search-dropdown-content"
        floatOffsetPx={12}
        isOpen={isDropdownOpen}
        renderDropdownContent={renderDropdownContent}
        renderTarget={
          isNonNullish(customSearchButton)
            ? ref => customSearchButton(ref, openDropdown, handleKeyDown)
            : renderSearchBar
        }
        targetAttachment={dropdownAttachment.targetAttachment}
        isFloating
        onClose={closeDropdown}
        onRootClose={handleRootClose}
      />
    );
  }
);
GlobalSearchIcon.displayName = 'GlobalSearchIcon';

interface Props {
  className?: string;
  dropdownAttachment?: AttachmentProps;
  customSearchButton?: (
    ref: React.RefObject<HTMLElement> | undefined,
    onClick: (e: React.MouseEvent<HTMLElement>) => void,
    onKeyDown: (e: React.KeyboardEvent<HTMLElement>) => void
  ) => React.ReactNode;
}

export default GlobalSearchIcon;
