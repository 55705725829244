import {ComputeResultData} from 'toolkit/ag-grid/types';

const getLevel = (data: ComputeResultData) => (data && data.data ? data.level : 0);

export const paddingValue = 2.4;

/** 2.4rem for each indent level */
export function getPaddingAmountForLevel(level: number) {
  return paddingValue * level;
}

export function getPaddingAmount(data: ComputeResultData) {
  return getPaddingAmountForLevel(getLevel(data));
}
