import './Spinner.scss';

import classNames from 'classnames';
import React from 'react';

import Icon from 'toolkit/components/Icon';
import {IconSpec} from 'toolkit/icons/types';

const Spinner: React.FunctionComponent<Props> = ({
  className,
  icon = 'spinner',
  isSpinning = true,
}) => {
  return (
    <div className={classNames('Spinner', className)}>
      <Icon icon={icon} spin={isSpinning} />
    </div>
  );
};
Spinner.displayName = 'Spinner';
interface Props {
  className?: string;
  isSpinning?: boolean;
  icon?: IconSpec;
}
export default Spinner;
