import {Map} from 'immutable';
import React from 'react';

import {ALL_SELECTED, SelectableValues, SelectableValuesByProperty} from 'toolkit/entities/types';
import {titleCase} from 'toolkit/format/text';
import {IconSpec} from 'toolkit/icons/types';
import {ascendingBy} from 'utils/arrays';
import {isTruthy} from 'utils/functions';

export interface EntityProperty<T, K extends string> {
  name: K;
  getValues: (entity: T) => ReadonlyArray<string | null>;
  // FIXME the typing is incorrect;  values are not necessarily strings. Should use <T, V> instead.
  getIcon?: (value: string) => IconSpec;
  getLabel?: (value: string) => string;
  orderMapper?: (value: string) => number;
  isHidden?: () => boolean;
  allowSelectAll?: boolean;
  showTitle?: boolean;
  allValues?: readonly string[];
  hideUnusedValues?: boolean;
}

export type EntityProperties<T, K extends string> = {[key in K]: EntityProperty<T, key>};

export interface EntityFilterItemRenderProps<T, K extends string> {
  property: EntityProperty<T, K>;
  totalCount: number;
  valuesAndCounts: readonly ValueAndCount[];
  selectedValues: SelectableValues;
}

interface ValueAndCount {
  readonly value: string;
  readonly count: number;
}

function getValuesAndCounts<T, K extends string>(
  property: EntityProperty<T, K>,
  entities: readonly T[],
  allValues: readonly string[]
): [number, readonly ValueAndCount[]] {
  const totalCount = entities.filter(e => property.getValues(e).every(isTruthy)).length;
  const valuesAndCounts = entities
    .reduce(
      (counts, entity) => {
        const values = property.getValues(entity);
        if (!values.every(isTruthy)) {
          return counts;
        }
        return values.reduce(
          (counts, value) => counts.update(value, 0, count => count + 1),
          counts
        );
      },
      Map(allValues.map(value => [value, 0]))
    )
    .toKeyedSeq()
    .map<ValueAndCount>((count, value) => ({value, count}))
    .valueSeq()
    .toArray();
  return [totalCount, valuesAndCounts];
}

export function getEntityLabel<T, K extends string, V extends string>(
  property: EntityProperty<T, K>,
  value: V
) {
  return property.getLabel ? property.getLabel(value) : titleCase(value.trim());
}

const compareByValueName = ascendingBy((value: string) => value);

// Generic dashboard/event entity filter renderer that can be used to build e.g. a sidebar or top bar
const EntitiesFilter: <T, K extends string>(
  props: Props<T, K>
) => React.ReactElement<Props<T, K>> = props => {
  return (
    <>
      {Object.keys(props.entityProperties)
        .map(key => props.entityProperties[key as keyof typeof props.entityProperties])
        .filter(property => !property.isHidden?.())
        .map(property => {
          const [totalCount, valuesAndCounts] = getValuesAndCounts(
            property,
            props.entities,
            property.allValues || []
          );
          if (!valuesAndCounts.length) {
            return null;
          }

          const sortedValuesAndCounts = [...valuesAndCounts]
            .filter(item => !property.hideUnusedValues || item.count > 0)
            .sort(
              property.orderMapper
                ? (l, r) => property.orderMapper!(r.value) - property.orderMapper!(l.value)
                : (l, r) =>
                    compareByValueName(
                      getEntityLabel(property, l.value),
                      getEntityLabel(property, r.value)
                    )
            );

          return (
            <React.Fragment key={property.name}>
              {props.renderItem({
                property,
                totalCount,
                valuesAndCounts: sortedValuesAndCounts,
                selectedValues: props.selectedValuesByProperty[property.name] || ALL_SELECTED,
              })}
            </React.Fragment>
          );
        })}
    </>
  );
};

export interface Props<T, K extends string> {
  className?: string;
  entityProperties: EntityProperties<T, K>;
  renderItem: (itemRenderProps: EntityFilterItemRenderProps<T, K>) => React.ReactNode;
  selectedValuesByProperty: SelectableValuesByProperty<K>;
  entities: readonly T[];
  onSelectionChange: (property: string, values: SelectableValues) => void;
}
export default EntitiesFilter;
