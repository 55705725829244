import './TopBarInput.scss';

import classNames from 'classnames';
import React from 'react';

const TopBarInput: React.FunctionComponent<Props> = props => {
  const {inputRef, className, ...rest} = props;
  return (
    <input ref={inputRef} className={classNames('TopBarInput', className)} type="text" {...rest} />
  );
};

TopBarInput.displayName = 'TopBarInput';
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  inputRef?: React.Ref<HTMLInputElement>;
}

export default TopBarInput;
