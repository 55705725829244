import './SearchBar.scss';

import classNames from 'classnames';
import React from 'react';

import DebounceInput, {Props as InputProps} from 'toolkit/components/DebounceInput';
import {IconSpec} from 'toolkit/icons/types';
import {noop} from 'utils/functions';

import Icon from './Icon';

const SearchBar = React.forwardRef(
  (
    {
      className,
      icon = 'search',
      onChange,
      onClear,
      onEnterPressed = noop,
      placeholder = 'Search…',
      showClearButton = true,
      showIcon = true,
      sizeVariant: size = 'large',
      value,
      ...rest
    }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const clear = () => {
      onChange('');
      if (onClear) {
        onClear();
      }
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onEnterPressed!();
      }
    };

    return (
      <div className={classNames('SearchBar', className, size)}>
        {!!icon && showIcon && (
          <div className="icon">
            <Icon icon={icon} />
          </div>
        )}
        <DebounceInput
          ref={ref}
          placeholder={placeholder}
          type="search"
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          {...rest}
        />
        {value && showClearButton && (
          <div className="clear-button" onClick={clear}>
            <Icon icon="times-circle" />
          </div>
        )}
      </div>
    );
  }
);
SearchBar.displayName = 'SearchBar';
type Props = InputProps & {
  className?: string;
  icon?: IconSpec;
  onClear?: () => void;
  onEnterPressed?: () => void;
  placeholder?: string;
  showClearButton?: boolean;
  showIcon?: boolean;
  sizeVariant?: 'small' | 'medium' | 'large';
};

export default SearchBar;
