import * as Types from 'types';
import {ApiError, FailedToFetchError} from 'types/error';

export function getErrorResponse(error?: Error | string | null): Types.ErrorResponse | null {
  return error instanceof ApiError && typeof error.error === 'object' ? error.error : null;
}

export function getErrorMessage(error?: Error | string | null) {
  const message = (error instanceof Error ? error.message : error) || 'An error has occurred';
  if (error instanceof FailedToFetchError) {
    return 'Internet connectivity issue';
  }
  return message;
}
