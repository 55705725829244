import {Map} from 'immutable';
import {useMemo} from 'react';

import Select, {OptionValues, SingleSelectProps} from 'toolkit/components/Select';
import {ascendingBy} from 'utils/arrays';

export type Option<TValue = OptionValues> = {
  value: TValue;
  label: string;
  disabled?: boolean;
};
export type LabeledOptionsSelectProps<T> = Omit<SingleSelectProps<T>, 'options' | 'placeholder'> & {
  options: ReadonlyArray<Option<T>>;
  placeholder?: string;
};

export const compareOptionLabelsAlphabetically = ascendingBy(
  (option: Option<unknown>) => option.label || ''
);

function LabeledOptionsSelect<T extends OptionValues>(props: LabeledOptionsSelectProps<T>) {
  const {options, ...rest} = props;

  const optionsByValue = useMemo(
    () => Map(options.map(option => [option.value, option])),
    [options]
  );

  const newProps: SingleSelectProps<T> = {
    options: options.map(option => option.value),
    getOptionDisplayName: (displayOption: T | null | undefined) => {
      if (displayOption === null || displayOption === undefined) {
        return props.placeholder ?? '';
      }
      return optionsByValue.get(displayOption)?.label ?? '';
    },
    optionIsDisabled: (option: T) => !!optionsByValue.get(option)?.disabled,
    ...rest,
  };

  return Select({
    ...newProps,
  });
}

LabeledOptionsSelect.displayName = 'LabeledOptionsSelect';

export default LabeledOptionsSelect;
