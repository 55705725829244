import './InfoHover.scss';

import classNames from 'classnames';
import React from 'react';
import {Placement} from 'react-bootstrap/Overlay';

import Icon from 'toolkit/components/Icon';
import {isReactIcon} from 'toolkit/icons/font-awesome';
import {IconSpec} from 'toolkit/icons/types';
import {noop} from 'utils/functions';

import HoverPopover from './HoverPopover';

export enum InfoHoverVariant {
  PRIMARY = 'primary',
  DARK = 'dark',
}

const InfoHover: React.FunctionComponent<Props> = ({
  children,
  className,
  contentClassName = 'InfoHover',
  enabled = true,
  hideDelay,
  icon = 'info-circle',
  placement,
  showDelay,
  variant = InfoHoverVariant.PRIMARY,
  onEnterTarget = noop,
  onLeaveTarget = noop,
}) => {
  return (
    <HoverPopover
      enabled={enabled}
      hideDelay={hideDelay}
      placement={placement}
      popoverClassName={classNames(
        'info-hover-popover',
        variant === InfoHoverVariant.DARK && variant
      )}
      showDelay={showDelay}
      target={isReactIcon(icon) ? icon : <Icon className="info-hover-icon" icon={icon} />}
      targetClassName={classNames('info-hover-target', className)}
      onEnterTarget={onEnterTarget}
      onLeaveTarget={onLeaveTarget}
    >
      <div
        className={classNames(
          'InfoHover',
          variant === InfoHoverVariant.DARK && variant,
          contentClassName
        )}
      >
        {children}
      </div>
    </HoverPopover>
  );
};
InfoHover.displayName = 'InfoHover';
interface Props {
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
  enabled?: boolean;
  hideDelay?: number;
  icon?: React.ReactElement | IconSpec;
  placement?: Placement;
  showDelay?: number;
  variant?: InfoHoverVariant;
  onEnterTarget?: () => void;
  onLeaveTarget?: () => void;
}
export default InfoHover;
