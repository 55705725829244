import classNames from 'classnames';
import React, {useCallback} from 'react';

import {useCurrentUser} from 'redux/context/user';
import Button, {Props as ButtonProps} from 'toolkit/components/Button';
import {useLinkClickHandler} from 'toolkit/utils/useLinkClickHandler';
import {isNonNullish} from 'utils/functions';
import {getPromoAnalysisUrl, isPromoAnalysisEnabled} from 'workflows/utils';

export function usePromoAnalysisLinkClickHandler(eventId: number | null | undefined) {
  const currentUser = useCurrentUser();
  const validLinkage = isNonNullish(eventId) && isPromoAnalysisEnabled(currentUser);
  const linkClickHandler = useLinkClickHandler();
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      linkClickHandler(getPromoAnalysisUrl(currentUser.vendor.name, eventId), event);
    },
    [linkClickHandler, eventId, currentUser.vendor.name]
  );
  return validLinkage ? onClick : () => {};
}

export const PromoAnalysisLinkButton: React.FC<Props> = ({
  eventId,
  className,
  children,
  ...buttonProps
}) => {
  return (
    <Button
      className={classNames('PromoAnalysisLinkButton', className)}
      icon="chart-line"
      onClick={usePromoAnalysisLinkClickHandler(eventId)}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

type Props = ButtonProps & {
  eventId: number;
};
