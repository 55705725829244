import Bowser from 'bowser';

import * as Globals from 'app/globals';

import {showCustomAlert} from './alerts';

// These come from vite's browser support: https://vitejs.dev/guide/build
const SUPPORTED_CHROME_VERSION = '>=87';
const SUPPORTED_EDGE_VERSION = '>=88';

const browser = Bowser.getParser(window.navigator.userAgent);

export function isBrowserBroken() {
  return browser.satisfies({
    msedge: '*',
    msie: '*',
  });
}

export function isBrowserSupported() {
  if (Globals.nodeEnv === 'test') {
    return true;
  }
  const os = browser.getOS();
  if (os.name === 'Android' || os.name === 'iOS') {
    return true;
  }
  return browser.satisfies({
    chrome: SUPPORTED_CHROME_VERSION,
    chromium: SUPPORTED_CHROME_VERSION,
    edge: SUPPORTED_EDGE_VERSION,
  });
}

export function showBrowserNotSupportedAlert() {
  showCustomAlert({
    icon: 'exclamation-triangle',
    id: 'BROWSER_ALERT',
    message: `We recommend using the latest version of Google Chrome or Microsoft Edge for Alloy.ai. You may run into issues.`,
  });
}

export function getBrowserName() {
  return `${browser.getBrowserName()} ${browser.getBrowserVersion()}`;
}

export function isPhone() {
  return browser.getPlatform().type === 'mobile'; // tablet is a separate platform type
}

export function isMobile() {
  if (Globals.nodeEnv === 'test') {
    return false;
  }
  return browser.getPlatform().type !== 'desktop';
}

export function isAppleOS() {
  const os = browser.getOS();
  return ['iOS', 'macOS'].includes(os.name || '');
}
