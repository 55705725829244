import './DialogFooter.scss';

import classNames from 'classnames';
import React, {useCallback} from 'react';

import useSelector from 'redux/selectors/useSelector';
import Button, {ButtonDisplayVariant} from 'toolkit/components/Button';
import {ModalSize} from 'toolkit/components/ModalDialog';
import {preventProdTechDemoEdit} from 'utils/demo-vendor';
import {noop} from 'utils/functions';
import {Status} from 'utils/status';

import Spinner from './Spinner';

export const DialogFooter: React.FC<DialogFooterProps> = ({
  cancelActionName = 'Cancel',
  children,
  confirmActionName = 'Confirm',
  confirmButton,
  confirmStatus = Status.unstarted,
  customFooterContent,
  isCancelVisible = true,
  isConfirmEnabled = true,
  isConfirmVisible = true,
  isLinkWarning,
  linkActionName,
  onClose,
  onCancel = onClose,
  onConfirm = noop,
  onLinkClick,
  warnEditOnProdDemoVendor = false,
  size,
  confirmButtonDisplayVariant,
}) => {
  // FIXME: we shouldn't access the Redux state here. Also, dialogs can be used
  // also when a user is not logged in, so don't useCurrentUser; it can crash when e.g. activating
  // credentials.
  const currentVendor = useSelector(state => state.user.vendor);
  const onConfirmWithEditPrevention = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (warnEditOnProdDemoVendor && preventProdTechDemoEdit(currentVendor)) {
        return;
      }

      onConfirm(event);
    },
    [currentVendor, onConfirm, warnEditOnProdDemoVendor]
  );
  const defaultFooterButtons = (
    <div className="default-footer-buttons">
      {customFooterContent && <div className="custom-footer-content">{customFooterContent}</div>}
      {linkActionName && (
        <div className={classNames('link', {warning: isLinkWarning})} onClick={onLinkClick}>
          {linkActionName}
        </div>
      )}
      <div className={classNames('action-buttons', size)}>
        {isCancelVisible && (
          <Button displayVariant={ButtonDisplayVariant.TERTIARY} onClick={onCancel || onClose}>
            {cancelActionName}
          </Button>
        )}
        {isConfirmVisible &&
          (confirmButton || (
            <Button
              disabled={!isConfirmEnabled}
              displayVariant={confirmButtonDisplayVariant}
              onClick={onConfirmWithEditPrevention}
            >
              {confirmStatus === Status.inProgress ? <Spinner /> : confirmActionName}
            </Button>
          ))}
      </div>
    </div>
  );

  return <div className="dialog-footer">{children || defaultFooterButtons}</div>;
};

export interface DialogFooterProps {
  cancelActionName?: string;
  confirmActionName?: string;
  confirmButton?: React.ReactNode;
  confirmButtonDisplayVariant?: ButtonDisplayVariant;
  confirmStatus?: Status;
  customFooterContent?: React.ReactNode;
  children?: React.ReactNode;
  isCancelVisible?: boolean;
  isConfirmEnabled?: boolean;
  isConfirmVisible?: boolean;
  isLinkWarning?: boolean;
  linkActionName?: React.ReactNode;
  onClose?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onCancel?: () => void;
  onConfirm?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onLinkClick?: React.MouseEventHandler<HTMLElement>;
  warnEditOnProdDemoVendor?: boolean;
  size?: ModalSize;
}
