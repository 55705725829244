import classNames from 'classnames';
import equal from 'fast-deep-equal';
import {Set} from 'immutable';
import React from 'react';

import * as Types from 'types';
import {ascendingBy} from 'utils/arrays';
import {noop, sum} from 'utils/functions';

import ChartLegendItem, {ChartLegendItemProps} from './ChartLegendItem';
import ChartLegendOthersItem from './ChartLegendOthersItem';
import {AugmentedChart, AugmentedChartDataset} from './types';
import {DEFAULT_LINE_WIDTH, getCleanDatasetLabel, INACTIVE_LINE_OPACITY, toRgba} from './utils';

const MIN_AVERAGE_ITEM_WIDTH_PX = 100;

const LegendWithOthers: React.FC<LegendWithOthersProps> = ({
  children,
  getLegendItem,
  othersCount,
  visibleCount,
}) => {
  return (
    <>
      {children}
      {othersCount > 0 && (
        <ChartLegendOthersItem
          getLegendItem={getLegendItem}
          othersCount={othersCount}
          visibleCount={visibleCount}
        />
      )}
    </>
  );
};
interface LegendWithOthersProps {
  children: React.ReactNode;
  getLegendItem: (index: number) => React.ReactNode;
  othersCount: number;
  visibleCount: number;
}

export const toggleDatasetVisibility = (
  datasetIndex: number,
  chart: AugmentedChart | null | undefined
) => {
  const meta = chart && chart.getDatasetMeta(datasetIndex);
  if (!meta) {
    return;
  }
  meta.hidden = !meta.hidden;
  chart.update();
};

export const shouldUsePositiveColor = (metricValue: number) => {
  return Number.isFinite(metricValue) && metricValue > 0;
};

const getDatasetColor = (dataset?: AugmentedChartDataset) => {
  if (!dataset) {
    return 'white';
  }
  if (typeof dataset.initialBackgroundColor === 'string') {
    return dataset.initialBackgroundColor;
  }
  const index = Math.max(0, dataset.data.findIndex(shouldUsePositiveColor));
  return dataset.initialBackgroundColor[index];
};
const getDatasetIndicesBy = (
  datasets: readonly AugmentedChartDataset[],
  comparator: (dataset: AugmentedChartDataset) => boolean
) => {
  return datasets
    .map((_, datasetIndex) => datasetIndex)
    .filter(datasetIndex => comparator(datasets[datasetIndex]));
};

const MetricOrColumnLegendItem: React.FC<MetricOrColumnLegendItemProps> = ({
  chart,
  datasetIndices,
  isToggleEnabled,
  onHover,
  onHoverLeave,
  onToggle,
  ...rest
}) => {
  if (!datasetIndices.length || datasetIndices[0] >= chart.data.datasets.length) {
    return null;
  }

  const firstIndex = datasetIndices[0];
  const firstDataset = chart.data.datasets[firstIndex];
  const label = getCleanDatasetLabel(firstDataset?.label || '');

  return (
    <ChartLegendItem
      color={getDatasetColor(firstDataset)}
      isDatasetHidden={!!chart.getDatasetMeta(firstIndex)?.hidden}
      isToggleEnabled={isToggleEnabled}
      primaryLabel={label}
      onMouseEnter={() => onHover(datasetIndices)}
      onMouseLeave={() => onHoverLeave()}
      onToggle={() => onToggle(datasetIndices)}
      {...rest}
    />
  );
};
interface MetricOrColumnLegendItemProps {
  datasetIndices: readonly number[];
  chart: AugmentedChart;
  isToggleEnabled: boolean;
  onHover: (datasetIndices: readonly number[]) => void;
  onHoverLeave: () => void;
  onToggle: (datasetIndices: readonly number[]) => void;
}

// This is some quick presentation math for displaying grouping percentages on launchpad doughnut chart legends
function getPercentagesForDoughnutChartLegend(data: number[], isVertical?: boolean) {
  if (!isVertical) {
    return undefined;
  }
  const dataSum = data.filter(Number.isFinite).reduce(sum, 0);
  return data.map(value => value / dataSum);
}

function getDoughnutChartLegendItems(
  chart: AugmentedChart,
  onHover: (datasetIndices: readonly number[]) => void,
  isToggleEnabled: boolean,
  onToggle: (datasetIndices: readonly number[]) => void,
  isVertical?: boolean,
  maxAvailableWidth?: number
) {
  const labels = chart.data.labels!;
  const percentages = getPercentagesForDoughnutChartLegend(chart.data.datasets[0].data, isVertical);
  const totalItemCount = labels.length;
  const maxVisibleItemCount = getMaxItemCount(maxAvailableWidth, totalItemCount, isVertical);

  const visibleLabels = labels.slice(0, maxVisibleItemCount);
  const defaultSecondaryLabel = isVertical ? '-' : undefined;
  const getLegendItem = (index: number, props: Partial<ChartLegendItemProps> = {}) => {
    const label = labels[index];
    const percentage = percentages?.[index];
    return (
      <ChartLegendItem
        key={`${label}-${index}`}
        className={classNames({vertical: isVertical})}
        color={chart.data.datasets[0].initialBackgroundColor[index]}
        isDatasetHidden={false}
        isToggleEnabled={isToggleEnabled}
        primaryLabel={`${label}`}
        secondaryLabel={
          Number.isFinite(percentage) ? `${(percentage! * 100).toFixed()}%` : defaultSecondaryLabel
        }
        onClick={() => onToggle([index])}
        onMouseEnter={() => onHover([index])}
        onToggle={noop}
        {...props}
      />
    );
  };

  return (
    <LegendWithOthers
      getLegendItem={getLegendItem}
      othersCount={labels.length - maxVisibleItemCount}
      visibleCount={visibleLabels.length}
    >
      {visibleLabels.map((_, index) => getLegendItem(index))}
    </LegendWithOthers>
  );
}

function getColumnGroupingLegendItems(
  chart: AugmentedChart,
  onHover: (datasetIndices: readonly number[]) => void,
  onHoverLeave: () => void,
  isToggleEnabled: boolean,
  onToggle: (datasetIndices: readonly number[]) => void,
  maxAvailableWidth?: number
) {
  const {datasets} = chart.data;

  const legendIndices = getDatasetIndicesBy(
    datasets,
    dataset => !!dataset.showLabelInLegend && !!dataset.columnValue
  );

  const totalItemCount = legendIndices.length;
  const maxVisibleItemCount = getMaxItemCount(maxAvailableWidth, totalItemCount);

  const visibleIndices = legendIndices.slice(0, maxVisibleItemCount);

  const getLegendItem = (index: number, props: Partial<ChartLegendItemProps> = {}) => {
    const datasetIndex = legendIndices[index];
    const dataset = datasets[datasetIndex];
    const otherCorrespondingIndices = getDatasetIndicesBy(datasets, otherDataset =>
      otherDataset.columnValue
        ? otherDataset.columnValue === dataset.columnValue && !otherDataset.showLabelInLegend
        : true
    );
    return (
      <MetricOrColumnLegendItem
        key={`${dataset.columnValue!.id}-${datasetIndex}`}
        chart={chart}
        datasetIndices={Set.of(datasetIndex, ...otherCorrespondingIndices).toArray()}
        isToggleEnabled={isToggleEnabled}
        onHover={onHover}
        onHoverLeave={onHoverLeave}
        onToggle={onToggle}
        {...props}
      />
    );
  };

  return (
    <LegendWithOthers
      getLegendItem={getLegendItem}
      othersCount={legendIndices.length - maxVisibleItemCount}
      visibleCount={visibleIndices.length}
    >
      {visibleIndices.map((_, index) => getLegendItem(index))}
    </LegendWithOthers>
  );
}

function getMaxItemCount(
  maxAvailableWidth: number | undefined,
  totalItemCount: number,
  isVertical?: boolean
) {
  if (maxAvailableWidth === undefined || isVertical) {
    return totalItemCount;
  }

  const allItemsFit = maxAvailableWidth / totalItemCount > MIN_AVERAGE_ITEM_WIDTH_PX;
  return allItemsFit
    ? totalItemCount
    : Math.max(0, Math.floor(maxAvailableWidth / MIN_AVERAGE_ITEM_WIDTH_PX) - 1);
}

export function adjustColorOnHover(
  initialColor: string,
  isHovered: boolean,
  resetHighlight: boolean
) {
  return isHovered
    ? toRgba(initialColor, 1)
    : toRgba(initialColor, resetHighlight ? 1 : INACTIVE_LINE_OPACITY);
}

export function adjustColorProp(
  dataset: AugmentedChartDataset,
  colorPropName: 'backgroundColor' | 'borderColor',
  initialColorPropName: 'initialBackgroundColor' | 'initialBorderColor',
  isHovered: boolean,
  resetHighlight: boolean
) {
  const colorProp = dataset[colorPropName];
  const initialColor = dataset[initialColorPropName];
  if (typeof colorProp === 'function') {
    return; // Gradients are restricted to charts with only one dataset and thus no legend
  }
  if (typeof colorProp === 'string') {
    dataset[colorPropName] = adjustColorOnHover(initialColor as string, isHovered, resetHighlight);
    return;
  }

  const newData = (colorProp as string[]).map((_, index) =>
    adjustColorOnHover(initialColor[index], isHovered, resetHighlight)
  );
  dataset[colorPropName] = newData;
}

function adjustLineWidth(
  dataset: AugmentedChartDataset,
  isHovered: boolean,
  resetLineWidth: boolean
) {
  if (dataset.type === 'line') {
    dataset.borderWidth = resetLineWidth
      ? DEFAULT_LINE_WIDTH
      : isHovered
        ? DEFAULT_LINE_WIDTH + 1
        : DEFAULT_LINE_WIDTH;
  }
}

type HighlightData = {
  highlightedDatasetIndices: Set<number>;
  resetHighlight: boolean | undefined;
  thickenLine: boolean;
};

const previousHighlights: WeakMap<HTMLCanvasElement, HighlightData> = new WeakMap();
export function highlightSelectedItem(
  chart: AugmentedChart,
  highlightedDatasetIndices: Set<number>,
  resetHighlight: boolean,
  thickenLine = true
) {
  const currentHighlight = {
    highlightedDatasetIndices,
    resetHighlight,
    thickenLine,
  };
  if (equal(previousHighlights.get(chart.canvas!), currentHighlight)) {
    // charts become sluggish when they need to rerender on every mouse move on big charts.
    return;
  }
  previousHighlights.set(chart.canvas!, currentHighlight);

  ((chart.config.data?.datasets as AugmentedChartDataset[]) ?? []).forEach(
    (dataset, datasetIndex) => {
      const isHovered = highlightedDatasetIndices.contains(datasetIndex);
      adjustColorProp(dataset, 'borderColor', 'initialBorderColor', isHovered, resetHighlight);
      adjustColorProp(
        dataset,
        'backgroundColor',
        'initialBackgroundColor',
        isHovered,
        resetHighlight
      );
      if (thickenLine) {
        adjustLineWidth(dataset, isHovered, resetHighlight);
      } else {
        adjustLineWidth(dataset, false, true);
      }
    }
  );
  chart.update();
}

export const getDatasetLegendItems = (
  chart: AugmentedChart,
  onHover: (datasetIndices: readonly number[]) => void,
  onHoverLeave: () => void,
  isToggleEnabled: boolean,
  onToggle: (datasetIndices: readonly number[]) => void,
  maxAvailableWidth?: number
) => {
  const indices = getDatasetIndicesBy(
    chart.data.datasets,
    dataset => !!dataset.showLabelInLegend
  ).sort(ascendingBy(datasetIndex => chart.data.datasets[datasetIndex].widgetMetricIndex));
  const maxVisibleItemCount = getMaxItemCount(maxAvailableWidth, indices.length);
  const visibleIndices = indices.slice(0, maxVisibleItemCount);

  const getLegendItem = (index: number, props: Partial<ChartLegendItemProps> = {}) => {
    const datasetIndex = indices[index];
    const dataset = chart.data.datasets[datasetIndex];
    const otherCorrespondingIndices = getDatasetIndicesBy(
      chart.data.datasets,
      otherDataset =>
        !otherDataset.showLabelInLegend &&
        otherDataset.widgetMetricIndex === dataset.widgetMetricIndex
    );
    return (
      <MetricOrColumnLegendItem
        key={dataset.label}
        chart={chart}
        datasetIndices={Set.of(datasetIndex, ...otherCorrespondingIndices).toArray()}
        isToggleEnabled={isToggleEnabled}
        onHover={onHover}
        onHoverLeave={onHoverLeave}
        onToggle={onToggle}
        {...props}
      />
    );
  };

  return (
    <LegendWithOthers
      getLegendItem={getLegendItem}
      othersCount={indices.length - maxVisibleItemCount}
      visibleCount={visibleIndices.length}
    >
      {visibleIndices.map((_, index) => getLegendItem(index))}
    </LegendWithOthers>
  );
};

export const ChartLegendItems: React.FC<ChartLegendItemsProps> = ({
  chart,
  isToggleEnabled = true,
  isVertical,
  onHover,
  onToggle,
  onHoverLeave,
  maxAvailableWidth,
  widgetConfig,
}) => {
  if (!chart) {
    return null;
  }

  if (
    chart?.data?.datasets &&
    chart.data.datasets.length &&
    chart.data.datasets[0].type === 'doughnut'
  ) {
    return getDoughnutChartLegendItems(
      chart,
      onHover,
      isToggleEnabled,
      onToggle,
      isVertical,
      maxAvailableWidth
    );
  }
  if (isVertical) {
    throw new Error('Vertical chart legend only supported on doughnut charts.');
  }
  return !widgetConfig || widgetConfig.metrics.length > 1
    ? getDatasetLegendItems(
        chart,
        onHover,
        onHoverLeave,
        isToggleEnabled,
        onToggle,
        maxAvailableWidth
      )
    : getColumnGroupingLegendItems(
        chart,
        onHover,
        onHoverLeave,
        isToggleEnabled,
        onToggle,
        maxAvailableWidth
      );
};
ChartLegendItems.displayName = 'ChartLegendItems';

interface ChartLegendItemsProps {
  chart: AugmentedChart | undefined;
  onHover: (datasetIndices: readonly number[]) => void;
  onHoverLeave: () => void;
  isToggleEnabled: boolean;
  isVertical?: boolean;
  onToggle: (datasetIndices: readonly number[]) => void;
  toggleEnabled?: boolean;
  maxAvailableWidth?: number;
  widgetConfig?: Types.Widget;
}
