import React, {useCallback} from 'react';
import {NavLink, NavLinkProps, useLocation} from 'react-router-dom';

import {setPlanBreadcrumb} from 'redux/actions/navigation';
import useSelector from 'redux/selectors/useSelector';
import useDispatch from 'redux/useDispatch';

const NavLinkWithBreadcrumb: React.FC<NavLinkWithBreadcrumbProps> = ({
  children,
  onClick,
  linkingEntityName,
  ...rest
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const lastAnalysisViewName = useSelector(state => state.analysis.views.last()!.view.name);
  const onClickWithBackLink = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      dispatch(
        setPlanBreadcrumb({
          slug: location.pathname,
          entityName: linkingEntityName ?? lastAnalysisViewName,
        })
      );
      onClick?.(event);
    },
    [onClick, location.pathname, dispatch, linkingEntityName, lastAnalysisViewName]
  );

  return (
    <NavLink {...rest} onClick={onClickWithBackLink}>
      {children}
    </NavLink>
  );
};

export interface NavLinkWithBreadcrumbProps extends NavLinkProps {
  linkingEntityName?: string;
}

NavLinkWithBreadcrumb.displayName = 'NavLinkWithBreadcrumb';

export default NavLinkWithBreadcrumb;
