import {getAttributesByTypeAndName} from 'redux/selectors/analysis';
import useSelector from 'redux/selectors/useSelector';

import {suspendIf} from './api';
import {Status} from './status';

export function useAllAttributes() {
  const allGroupingsFetchStatus = useSelector(
    state => state.analysis.data.fetchingAllGroupingsStatus
  );
  const allGroupingsRequest = useSelector(state => state.analysis.data.allGroupingsRequest);
  suspendIf(allGroupingsFetchStatus !== Status.succeeded, allGroupingsRequest);
  return useSelector(getAttributesByTypeAndName);
}

export function useAllAttributesById() {
  const allGroupingsFetchStatus = useSelector(
    state => state.analysis.data.fetchingAllGroupingsStatus
  );
  const allGroupingsRequest = useSelector(state => state.analysis.data.allGroupingsRequest);
  suspendIf(allGroupingsFetchStatus !== Status.succeeded, allGroupingsRequest);
  return useSelector(state => state.analysis.data.allGroupings);
}
