import {Buffer} from 'buffer';

import * as Types from 'types';
import {Vendor} from 'types';

export const DIRECT_IMPORT_FILESIZE_LIMIT_IN_BYTES = 25000000;
export const MAXIMUM_NUMBER_FILES_TO_IMPORT = 20;

// Please keep in sync with file_utils::RAWFILE_TABLE_COMPRESSED_EXTENSIONS
const RAWFILE_TABLE_COMPRESSED_EXTENSIONS = [
  '.zip',
  '.gz',
  '.xlsx',
  '.ods',
  '.parquet',
  '.avro',
  '.crv',
];

// Please keep in sync with roardata and pewter config
const FILE_SUBSCRIBER_MAXIMUM_UNCOMPRESSED_FILESIZE_BYTES = 4 * 1024 * 1024 * 1024; // 4 GB
const FILE_SUBSCRIBER_MAXIMUM_COMPRESSED_FILESIZE_BYTES = 2 * 1024 * 1024 * 1024; // 2 GB

export function isFileCompressed(filename: string) {
  return RAWFILE_TABLE_COMPRESSED_EXTENSIONS.some(extension => filename.endsWith(extension));
}

export function isFileTooLargeForDirectImport(file: File) {
  return file.size > DIRECT_IMPORT_FILESIZE_LIMIT_IN_BYTES;
}

export function isFileTooLarge(file: File, onlyAllowDirectImport?: boolean) {
  if (onlyAllowDirectImport) {
    return isFileTooLargeForDirectImport(file);
  }
  return (
    file.size >=
    (isFileCompressed(file.name)
      ? FILE_SUBSCRIBER_MAXIMUM_COMPRESSED_FILESIZE_BYTES
      : FILE_SUBSCRIBER_MAXIMUM_UNCOMPRESSED_FILESIZE_BYTES)
  );
}

export function getLocalUploadCommand(file: Types.FileMetadata, vendorName: string) {
  const typeArg = file.filetype ? ` --file_type ${file.filetype}` : '';
  const nameArg = `"~/Downloads/${file.name
    .replace(/:/, '_')
    .replace(/\\/g, '\\\\')
    .replace(/"/g, '\\"')}"`;
  return `ore roardata upload${typeArg} --force ${vendorName} ${nameArg}`;
}

export function writeLocalFile(filename: string, file: Blob | string, timeout = 60 * 1000) {
  const a = window.document.createElement('a');
  const url = typeof file === 'string' ? file : window.URL.createObjectURL(file);
  a.href = url;
  a.download = filename;
  a.setAttribute('style', 'display:none');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  setTimeout(() => window.URL.revokeObjectURL(url), timeout);
}

export function readLocalFileAsBase64(filePromise: Promise<File>): Promise<string | null> {
  return filePromise.then(file => {
    return new Promise<string | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(Buffer.from(reader.result as ArrayBuffer).toString('base64'));
      reader.onerror = () => reject();
      reader.onabort = () => reject();
      reader.readAsArrayBuffer(file);
    });
  });
}

export function readLocalFile(filePromise: Promise<File>): Promise<string | null> {
  return filePromise.then(file => {
    return new Promise<string | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject();
      reader.onabort = () => reject();
      reader.readAsText(file);
    });
  });
}

export function getFilePromise(fileInput: HTMLInputElement | null): Promise<File> {
  return fileInput
    ? new Promise((resolve, reject) => {
        const filePicker = fileInput;
        if (!filePicker) {
          reject();
        }
        filePicker.addEventListener('change', () => {
          if (filePicker.files?.length) {
            resolve(filePicker.files[0]);
          }
        });
        filePicker.addEventListener('cancel', reject);
        filePicker.click();
      }).then(result => {
        fileInput.value = '';
        return result as File;
      })
    : Promise.reject();
}

export function isFilenameVendorPrefixed(filename: string, vendor: Vendor) {
  return filename.toLowerCase().startsWith(vendor.name.toLowerCase());
}
