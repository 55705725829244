import './CheckableListItem.scss';
import classNames from 'classnames';
import React from 'react';

import Checkbox, {CheckboxType} from 'toolkit/components/Checkbox';

import TruncatedTooltip from './TruncatedTooltip';

const CheckableListItem: React.FunctionComponent<CheckableListItemProps> = ({
  className,
  isChecked,
  isDisabled,
  isExclusive,
  isShadedWhenChecked = true,
  onToggle,
  style,
  value,
  type,
}) => {
  return (
    <Checkbox
      checked={isChecked}
      className={classNames('CheckableListItem', className, {
        shaded: isShadedWhenChecked && isChecked,
        exclusive: isExclusive,
      })}
      disabled={isDisabled}
      label={typeof value === 'string' ? <TruncatedTooltip value={value} /> : value}
      role="button"
      style={style}
      tabIndex={0}
      type={isExclusive ? CheckboxType.EXCLUDED : type}
      onChange={onToggle}
    />
  );
};
interface CheckableListItemProps {
  className?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  isExclusive?: boolean;
  type: CheckboxType;
  value: React.ReactNode;
  onToggle: (isSelected: boolean) => void;
  isShadedWhenChecked?: boolean;
  style?: React.CSSProperties;
}

CheckableListItem.displayName = 'CheckableListItem';
export default CheckableListItem;
