import {LABELS} from 'toolkit/format/constants';
import {PEWTER_COMPUTED_FORECAST_TYPES} from 'toolkit/metrics/editor/types';
import * as Types from 'types';
import {compareByIndex, reverse} from 'utils/arrays';
import {assertTruthy} from 'utils/assert';
import {isTruthy} from 'utils/functions';

import {capitalize} from './text';
import {TextLengthFormat} from './types';

type AdditiveModifierType = 'prefix' | 'suffix' | 'comma' | 'parenthetical';
type ModifierType = AdditiveModifierType | 'base';

interface Modifier {
  type: ModifierType;
  text: string;
}

class MetricNameBuilder {
  private readonly base: string;
  private readonly modifiers: {[mod in AdditiveModifierType]: readonly string[]};

  private constructor(base: string, modifiers: {[mod in AdditiveModifierType]: readonly string[]}) {
    this.base = base;
    this.modifiers = modifiers;
  }

  withModifier(modifier: Modifier | null) {
    if (!modifier?.text) {
      return this;
    }
    return this.with(modifier.type, modifier.text);
  }

  with(type: ModifierType, ...items: ReadonlyArray<string | null>) {
    if (type === 'base') {
      return items.length
        ? new MetricNameBuilder(assertTruthy(items[items.length - 1]), this.modifiers)
        : this;
    }
    return new MetricNameBuilder(this.base, {
      ...this.modifiers,
      [type]: [...this.modifiers[type], ...items.filter(isTruthy)],
    });
  }

  withPrefix(...items: readonly string[]) {
    return this.with('prefix', ...reverse(items));
  }

  withSuffix(...items: ReadonlyArray<string | null>) {
    return this.with('suffix', ...items);
  }

  withComma(...items: readonly string[]) {
    return this.with('comma', ...items);
  }

  withParenthetical(...items: readonly string[]) {
    return this.with('parenthetical', ...items);
  }

  toString() {
    const parts = [];
    if (this.modifiers.prefix.length) {
      parts.push(reverse(this.modifiers.prefix).join(' '), ' ');
    }
    parts.push(this.base);
    if (this.modifiers.suffix.length) {
      parts.push(' ', this.modifiers.suffix.join(' '));
    }
    if (this.modifiers.comma.length) {
      parts.push(', ', this.modifiers.comma.join(', '));
    }
    if (this.modifiers.parenthetical.length) {
      parts.push(' ', this.modifiers.parenthetical.map(item => `(${item})`).join(' '));
    }
    return parts.join('');
  }

  static create(base: string) {
    return new MetricNameBuilder(base, {prefix: [], suffix: [], comma: [], parenthetical: []});
  }
}

const SALES_TYPE_DISPLAY_NAME: {[salesType in Types.SalesType]: string} = {
  [Types.SalesType.CLEARANCE]: 'Clearance',
  [Types.SalesType.PROMO]: 'Promo',
  [Types.SalesType.REGULAR]: 'Regular',
  [Types.SalesType.TOTAL]: '',
} as const;

const INVENTORY_TYPE_ORDER: readonly Types.InventoryType[] = [
  Types.InventoryType.ON_HAND,
  Types.InventoryType.IN_TRANSIT,
  Types.InventoryType.ON_ORDER,
  Types.InventoryType.INTERNAL_ON_ORDER,
  Types.InventoryType.ALLOCATED,
  Types.InventoryType.AVAILABLE,
];

const INVENTORY_TYPE_ABBREVIATIONS: {[key in Types.InventoryType]: string} = {
  [Types.InventoryType.ON_HAND]: 'OH',
  [Types.InventoryType.IN_TRANSIT]: 'IT',
  [Types.InventoryType.ALLOCATED]: 'AL',
  [Types.InventoryType.ON_ORDER]: 'OO',
  [Types.InventoryType.INTERNAL_ON_ORDER]: 'IO',
  [Types.InventoryType.EXPECTED_ON_HAND]: 'EX',
  [Types.InventoryType.AVAILABLE]: 'AV',
} as const;

export function getInventoryTypePhrase(inventoryTypes: readonly Types.InventoryType[] | null) {
  if (
    !inventoryTypes ||
    inventoryTypes.length === 0 ||
    (inventoryTypes.length === 1 && inventoryTypes[0] === Types.InventoryType.ON_HAND)
  ) {
    return 'of Supply';
  }
  return `of Supply (${inventoryTypes
    .map(inventoryType => INVENTORY_TYPE_ABBREVIATIONS[inventoryType])
    .join(', ')})`;
}

const INVENTORY_MEASURE_DISPLAY_NAME: {[inventoryMeasure in Types.InventoryMeasure]: string} = {
  [Types.InventoryMeasure.UNITS]: 'Units',
  [Types.InventoryMeasure.COST]: 'Sell-in Price',
  [Types.InventoryMeasure.RETAIL]: 'Retail Price',
} as const;

const shortMetricNameMaxLength = 30;

interface NameComponentReplacement {
  readonly from: RegExp;
  readonly to: string;
}

const nameReplacements: readonly NameComponentReplacement[] = [
  {from: /\(Inbound\)/, to: '(In)'},
  {from: /\(Outbound\)/, to: '(Out)'},
  {from: /\bProjected\b/, to: 'Proj.'},
  {from: /\bEstimated\b/, to: 'Est.'},
  {from: /\bDeviation\b/, to: 'Dev.'},
  {from: /\bInventory\b/, to: 'Inv.'},
  {from: /\bScheduled\b/, to: 'Sched.'},
];

function getShortMetricDisplayName(name: string, index = 0): string {
  if (name.length > shortMetricNameMaxLength && index < nameReplacements.length) {
    return getShortMetricDisplayName(
      name.replace(nameReplacements[index].from, nameReplacements[index].to),
      index + 1
    );
  }
  return name;
}

export function getStockAggregatorPrefix(stockAggregator: Types.StockAggregator | null): string {
  return stockAggregator && stockAggregator !== Types.StockAggregator.ENDING
    ? capitalize(stockAggregator).trim()
    : '';
}

export function getSalesTypeDisplayName(salesType: Types.SalesType | null) {
  return SALES_TYPE_DISPLAY_NAME[salesType || Types.SalesType.TOTAL];
}

function getReturnsCountingModifier(
  returnsCountingMethod: Types.ReturnsCountingMethod | null
): Modifier | null {
  switch (returnsCountingMethod) {
    case Types.ReturnsCountingMethod.GROSS:
      return {type: 'parenthetical', text: 'Gross'};
    case Types.ReturnsCountingMethod.ONLY:
      return {type: 'base', text: 'Returns'};
    default:
      return null;
  }
}

function getInventoryTypeModifier(
  inventoryTypes: readonly Types.InventoryType[] | null
): Modifier | null {
  if (!inventoryTypes) {
    return null;
  }

  if (inventoryTypes.length === 1) {
    return {type: 'suffix', text: LABELS.inventoryTypes[inventoryTypes[0]]};
  }
  return {
    type: 'parenthetical',
    text: [...inventoryTypes]
      .sort(compareByIndex(INVENTORY_TYPE_ORDER))
      .map(inventoryType => INVENTORY_TYPE_ABBREVIATIONS[inventoryType])
      .join(', '),
  };
}

function getFullMetricDisplayName(metricInstance: Types.MetricInstance): string {
  switch (metricInstance.metric.name) {
    case 'inventory':
      const inventoryMeasure = metricInstance.arguments.inventoryMeasure;
      return MetricNameBuilder.create(metricInstance.metric.displayName)
        .withModifier(getInventoryTypeModifier(metricInstance.arguments.inventoryTypes))
        .withSuffix(
          inventoryMeasure !== Types.InventoryMeasure.UNITS && inventoryMeasure
            ? INVENTORY_MEASURE_DISPLAY_NAME[inventoryMeasure]
            : ''
        )
        .withPrefix(getStockAggregatorPrefix(metricInstance.arguments.stockAggregator))
        .withComma(getSalesTypeDisplayName(metricInstance.arguments.salesType))
        .toString();
    case 'forecast_sales_units_net': {
      if (
        metricInstance.arguments.forecastType &&
        PEWTER_COMPUTED_FORECAST_TYPES.includes(metricInstance.arguments.forecastType)
      ) {
        return MetricNameBuilder.create(metricInstance.metric.displayName)
          .withModifier(getReturnsCountingModifier(metricInstance.arguments.returnsCountingMethod))
          .toString();
      }
      return metricInstance.metric.displayName;
    }
    default:
      return metricInstance.metric.displayName;
  }
}

export function getMetricDisplayName(
  metricInstance: Types.MetricInstance,
  lengthFormat = TextLengthFormat.FULL
): string {
  const fullName = getFullMetricDisplayName(metricInstance);
  if (lengthFormat === TextLengthFormat.FULL) {
    return fullName;
  }
  return getShortMetricDisplayName(fullName);
}
