import './DirectionalIndicator.scss';

import classNames from 'classnames';
import React from 'react';

import {
  ARROW_TREND_DOWN_ICON,
  ARROW_TREND_UP_ICON,
  DOWN_RIGHT_ICON,
  UP_RIGHT_ICON,
} from 'toolkit/icons/alloy-icons';
import {IconSpec} from 'toolkit/icons/types';

import Icon from './Icon';

type ArrowVariant = 'normal' | 'angled' | 'trend';
function getIconVariant(variant: ArrowVariant): {positive: IconSpec; negative: IconSpec} {
  switch (variant) {
    case 'angled':
      return {positive: UP_RIGHT_ICON, negative: DOWN_RIGHT_ICON};
    case 'trend':
      return {positive: ARROW_TREND_UP_ICON, negative: ARROW_TREND_DOWN_ICON};
    default:
      return {positive: 'arrow-up', negative: 'arrow-down'};
  }
}

const DirectionalIndicator: React.FC<Props> = ({
  className,
  colour,
  direction,
  arrowVariant = 'angled',
}) => {
  const icons = getIconVariant(arrowVariant);
  return (
    <div className={classNames('DirectionalIndicator', className, colour)}>
      {direction === 'neutral' ? (
        <Icon icon="minus" />
      ) : (
        <Icon icon={direction === 'positive' ? icons.positive : icons.negative} />
      )}
    </div>
  );
};

DirectionalIndicator.displayName = 'DirectionalIndicator';
interface Props {
  className?: string;
  colour: 'positive' | 'negative' | 'neutral';
  direction: 'positive' | 'negative' | 'neutral';
  arrowVariant?: ArrowVariant;
}

export default DirectionalIndicator;
