import {Map as ImmutableMap} from 'immutable';

import * as Types from 'types';
import Record from 'types/record';

interface RawThinComputeResultColumnExtended {
  // This is for handling possible 'NaN's in metricValues.
  readonly children: readonly Types.ThinComputeResultColumn[];
  readonly metricValues: ReadonlyArray<number | 'NaN' | 'Infinity' | '-Infinity'>;
  readonly metricMetadata: ReadonlyArray<readonly Types.MetricValueMetadata[]>;
  readonly value: Types.ThinAttributeValue | null;
}

interface RawThinComputeResultRowExtended {
  // This is for handling possible 'NaN's in metricValues.
  readonly children: readonly ThinComputeResultRowExtended[];
  readonly columnData: RawThinComputeResultColumnExtended;
  readonly value: Types.ThinAttributeValue | null;
  readonly values: readonly Types.ThinAttributeValue[];
}

export interface ThinComputeResultRowExtended extends Types.ThinComputeResultRow {
  readonly children: readonly ThinComputeResultRowExtended[];
  readonly columnData: Types.ThinComputeResultColumn;
  readonly value: Types.ThinAttributeValue | null;
  // This is a frontend-only hack to support flattened tables. Should be refactored out.
  readonly values: readonly Types.ThinAttributeValue[];
}

// extended type that stores overridden metric values for display
// the pendingMetricValues are mutable so they can be modified
// directly by ag-grid for rendering optimization
export interface EditableThinComputeResultRowExtended extends Types.ThinComputeResultRow {
  readonly children: readonly ThinComputeResultRowExtended[];
  readonly columnData: Types.ThinComputeResultColumn;
  readonly values: readonly Types.ThinAttributeValue[];
  pendingMetricValues?: ImmutableMap<string, number> | undefined;
}

export interface IComputeResultExtended extends Types.ComputeResult {
  // We need to add some information to the result on the frontend side.
  requestId: string;
  rowGroupings: readonly Types.AttributeInstance[];
  columnGroupings: readonly Types.AttributeInstance[];
  filters: readonly Types.AttributeFilter[];
  computeTimeMilliseconds: number | null;
  metrics: readonly Types.MetricInstance[];
  data: ThinComputeResultRowExtended;
  cacheStatus: Types.CacheInitialState | null;
  cacheVersion: string | null;
}
export class ComputeResultExtended extends Record<IComputeResultExtended>()({
  columnGroupings: [],
  data: {
    children: [],
    columnData: {children: [], metricMetadata: [], metricValues: [], value: null},
    value: null,
    values: [],
  },
  metrics: [],
  filters: [],
  requestId: null,
  rowGroupings: [],
  cacheStatus: Types.CacheInitialState.MISS,
  cacheVersion: null,
  computeTimeMilliseconds: null,
  totalRowCount: 0,
}) {
  static fromJS(request: Types.ComputeRequest, data: any) {
    const computeTimeHeader = data.__internalResponseHttpHeaders?.get('alloy-compute-time');
    return new ComputeResultExtended(data).merge({
      requestId: request.requestId,
      rowGroupings: request.rowGroupings,
      columnGroupings: request.columnGroupings,
      filters: request.filters,
      metrics: request.metrics,
      data: ComputeResultExtended.fromRawThinComputeResultRow(data.data),
      cacheStatus: data.__internalResponseHttpHeaders?.get('alloy-compute-cache-status'),
      computeTimeMilliseconds: computeTimeHeader ? parseInt(computeTimeHeader, 10) : undefined,
      cacheVersion: data.__internalResponseHttpHeaders?.get('alloy-compute-cache-version') ?? null,
    });
  }

  static fromRawThinComputeResultColumn(
    col: RawThinComputeResultColumnExtended
  ): Types.ThinComputeResultColumn {
    return {
      ...col,
      metricValues: col.metricValues.map(val => (typeof val === 'number' ? val : parseFloat(val))),
    };
  }

  static fromRawThinComputeResultRow(
    row: RawThinComputeResultRowExtended
  ): ThinComputeResultRowExtended {
    return {
      ...row,
      children: row.children.map(ComputeResultExtended.fromRawThinComputeResultRow),
      columnData: ComputeResultExtended.fromRawThinComputeResultColumn(row.columnData),
    };
  }
}
