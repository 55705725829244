import './TopBarButton.scss';

import classNames from 'classnames';
import React from 'react';

import Icon from 'toolkit/components/Icon';
import Tooltip from 'toolkit/components/Tooltip';
import {isReactIcon} from 'toolkit/icons/font-awesome';
import {IconSpec} from 'toolkit/icons/types';
import TopBarSeparator from 'toolkit/top-bar/TopBarSeparator';
import {swallowEvents} from 'toolkit/utils/events';

const blurEventTarget = (event: React.SyntheticEvent<HTMLElement>) => {
  if (event.target) {
    (event.target as HTMLElement).blur();
  }
};

const TopBarButton = React.forwardRef(
  (
    {
      className,
      children,
      hasHoverBackground = true,
      icon,
      isDisabled,
      isPadded = true,
      isSelected = false,
      isSeparatorHidden = false,
      onClick,
      tooltip,
      ...rest
    }: Props,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const handleClick = onClick
      ? (event: React.MouseEvent<HTMLDivElement>) => {
          blurEventTarget(event);
          onClick(event);
        }
      : undefined;
    const handleKeyDown = onClick
      ? (event: React.KeyboardEvent<HTMLElement>) => {
          const hasSpecialKeyDown = event.ctrlKey || event.metaKey;
          if ((event.key === 'Enter' || event.key === 'ArrowDown') && !hasSpecialKeyDown) {
            blurEventTarget(event);
            swallowEvents(event);
            onClick(event);
          }
        }
      : undefined;

    const isIconOnly = icon && !children;
    const content = (
      <div
        ref={ref}
        className={classNames(
          'TopBarButton',
          className,
          {disabled: isDisabled},
          {selected: isSelected},
          {'no-padding': isIconOnly || !isPadded},
          {'icon-only': isIconOnly},
          {'has-hover-background': hasHoverBackground}
        )}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        {...rest}
      >
        <div className="top-bar-button-content">
          {isReactIcon(icon) ? icon : <Icon className="button-icon" icon={icon} />}
          {children}
        </div>
      </div>
    );
    const contentWithSeparator =
      isSeparatorHidden || isIconOnly ? (
        content
      ) : (
        <>
          {content}
          <TopBarSeparator />
        </>
      );
    return tooltip ? (
      <Tooltip placement="bottom" tooltip={tooltip}>
        {contentWithSeparator}
      </Tooltip>
    ) : (
      contentWithSeparator
    );
  }
);

TopBarButton.displayName = 'TopBarButton';
export type Props = React.HTMLAttributes<HTMLDivElement> & {
  className?: string;
  icon?: IconSpec | React.ReactElement;
  hasHoverBackground?: boolean;
  isDisabled?: boolean;
  isPadded?: boolean;
  isSelected?: boolean;
  isSeparatorHidden?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void;
  tooltip?: React.ReactNode;
};
export default TopBarButton;
