import Cookies from 'js-cookie';

export type FlagOverrides = {
  readonly [flagName: string]: boolean;
};

const FEATURE_FLAG_OVERRIDES_COOKIE = 'feature_flag_overrides';
const FEATURE_FLAG_OVERRIDES_HEADER = 'X-Alloy-Feature-Flag-Overrides';

export function getFeatureFlagOverridesJson() {
  return Cookies.getJSON(FEATURE_FLAG_OVERRIDES_COOKIE) ?? {};
}

export function getFeatureFlagOverrideHeaderEntry(): [string, string | undefined] {
  return [FEATURE_FLAG_OVERRIDES_HEADER, Cookies.get(FEATURE_FLAG_OVERRIDES_COOKIE)];
}

export function resetFeatureFlagOverridesCookie() {
  Cookies.remove(FEATURE_FLAG_OVERRIDES_COOKIE);
}

export function setFeatureFlagOverrides(overrides: FlagOverrides, expires: Date) {
  Cookies.set(FEATURE_FLAG_OVERRIDES_COOKIE, JSON.stringify(overrides), {expires});
}
