import './ErrorPlaceholder.scss';

import classNames from 'classnames';
import React, {useState, useCallback} from 'react';

import * as globals from 'app/globals';
import {useMaybeLoggedInUser} from 'redux/context/user';
import Icon from 'toolkit/components/Icon';
import connectionIssueIcon from 'toolkit/components/images/icon-wifi-slash.svg';
import {IconSpec} from 'toolkit/icons/types';
import {isAtLeast} from 'toolkit/users/utils';
import {getErrorMessage, getErrorResponse} from 'toolkit/utils/errors';
import * as Types from 'types';
import {FailedToFetchError} from 'types/error';
import {getApiErrorStacktrace} from 'utils/exceptions';

import ModalDialog from './ModalDialog';
import SupportLink from './SupportLink';

const CONNECTION_ISSUE_ICON: IconSpec = {
  alloyIcon: connectionIssueIcon,
};

const ErrorPlaceholder: React.FC<Props> = ({error, onRetry}) => {
  const [isDetailsOpen, setDetailsOpen] = useState(false);
  const currentUser = useMaybeLoggedInUser();
  const closeDetails = useCallback(() => setDetailsOpen(false), [setDetailsOpen]);

  const errorMessage = getErrorMessage(error);
  const errorEntity: Types.ErrorResponse | null = getErrorResponse(error);

  const isConnectionIssue = error instanceof FailedToFetchError;

  const stacktrace = typeof error === 'object' ? getApiErrorStacktrace(error) : null;

  return (
    <div
      className={classNames('ErrorPlaceholder', {retryable: isConnectionIssue})}
      onClick={onRetry}
    >
      <Icon
        className="error-icon"
        icon={isConnectionIssue ? CONNECTION_ISSUE_ICON : 'exclamation-triangle'}
      />

      <div className="error-text">{errorMessage}</div>
      {!!stacktrace && <div className="error-stacktrace">{stacktrace}</div>}

      {currentUser.user &&
        isAtLeast(currentUser.user.role, Types.Role.SUPER_ADMIN) &&
        (errorEntity?.errors?.length ?? 0) > 0 && (
          <div className="error-details-button" onClick={() => setDetailsOpen(true)}>
            Show issue details
          </div>
        )}

      {(isConnectionIssue || globals.dev) && onRetry && (
        <div className="contact-support">Click to retry</div>
      )}
      {!isConnectionIssue && (
        <div className="contact-support">
          <SupportLink>Contact Support</SupportLink>
        </div>
      )}
      {isDetailsOpen && (
        <ModalDialog
          cancelActionName="Close"
          isConfirmVisible={false}
          isOpen={isDetailsOpen}
          title="Error Details"
          onClose={closeDetails}
        >
          <div className="error-details-content">
            {errorEntity?.errors?.map((errorItem, index) => (
              <div key={`${index}`} className="error-item">
                <div className="error-item-title">{errorItem.title}</div>
                <div className="error-item-detail">{errorItem.detail}</div>
              </div>
            ))}
          </div>
        </ModalDialog>
      )}
    </div>
  );
};

ErrorPlaceholder.displayName = 'ErrorPlaceholder';
interface Props {
  error?: Error | string | null;
  onRetry?: () => void;
}

export default ErrorPlaceholder;
