import './TruncatedTooltip.scss';

import classNames from 'classnames';
import React, {useRef} from 'react';
import {
  OverlayTrigger,
  Tooltip as ReactBootstrapTooltip,
  OverlayTriggerProps,
} from 'react-bootstrap';
import Measure from 'react-measure';
import {v4 as uuid} from 'uuid';

import Format from 'toolkit/format/format';
import {defaultOverlayTriggerTooltipProps} from 'toolkit/utils/react-bootstrap';
import {getTextWidthInPixels} from 'toolkit/utils/style';

const ELLIPSIZED_TEXT_CHARACTER_INCREMENTS = 10;
const MIN_TEXT_CHARACTER_COUNT = 10;
function getMiddleEllipsizedText(text: string, maxWidth: number, componentId: string): string {
  // eslint-disable-next-line fp/no-let
  let maxCharacterCount = text.length;
  while (
    getTextWidthInPixels(Format.ellipsize(text, maxCharacterCount, true), componentId) > maxWidth &&
    maxCharacterCount > MIN_TEXT_CHARACTER_COUNT
  ) {
    maxCharacterCount -= ELLIPSIZED_TEXT_CHARACTER_INCREMENTS;
  }
  return Format.ellipsize(text, Math.max(maxCharacterCount, MIN_TEXT_CHARACTER_COUNT), true);
}

/**
 * A tooltip that will only appear when its children is truncated.
 * The content of the tooltip is the same as its children.
 */
const TruncatedTooltip: React.FunctionComponent<
  Omit<Props, 'clientWidth' | 'clientHeight' | 'scrollWidth' | 'scrollHeight'>
> = ({ignoreHeight, ...rest}) => (
  <Measure client scroll>
    {({contentRect, measureRef}) => (
      <TruncatedTooltipContent
        ref={measureRef}
        {...rest}
        clientHeight={ignoreHeight ? 0 : (contentRect.client?.height ?? 0)}
        clientWidth={contentRect.client?.width ?? 0}
        scrollHeight={ignoreHeight ? 0 : (contentRect.scroll?.height ?? 0)}
        scrollWidth={contentRect.scroll?.width ?? 0}
      />
    )}
  </Measure>
);

const TruncatedTooltipContent = React.forwardRef(
  (
    {
      truncateAt = 'end',
      value,
      clientWidth,
      clientHeight,
      scrollWidth,
      scrollHeight,
      className,
      tooltipClassName,
      ...restProps
    }: Omit<Props, 'ignoreHeight'>,
    forwardedRef: React.Ref<HTMLDivElement>
  ) => {
    const componentId = useRef(uuid());

    const middleEllipsizedValue =
      truncateAt === 'middle'
        ? getMiddleEllipsizedText(value, clientWidth, componentId.current)
        : value;

    const showTooltip =
      truncateAt === 'end'
        ? scrollWidth > clientWidth || scrollHeight > clientHeight
        : middleEllipsizedValue !== value;

    return showTooltip ? (
      <OverlayTrigger
        {...defaultOverlayTriggerTooltipProps}
        {...(restProps as Omit<OverlayTriggerProps, 'ref'>)}
        overlay={
          <ReactBootstrapTooltip className={tooltipClassName} id="tooltip">
            {value}
          </ReactBootstrapTooltip>
        }
      >
        <div
          ref={forwardedRef}
          className={classNames('TruncatedTooltip', className)}
          id={componentId.current}
        >
          {middleEllipsizedValue}
        </div>
      </OverlayTrigger>
    ) : (
      <div
        ref={forwardedRef}
        className={classNames('TruncatedTooltip', className)}
        id={componentId.current}
      >
        {value}
      </div>
    );
  }
);

TruncatedTooltip.displayName = 'TruncatedTooltip';
type Props = Omit<OverlayTriggerProps, 'children' | 'overlay'> & {
  truncateAt?: 'middle' | 'end';
  value: string;
  clientWidth: number;
  clientHeight: number;
  scrollWidth: number;
  scrollHeight: number;
  className?: string;
  tooltipClassName?: string;
  /** Use ignoreHeight if the line height is changed for the TruncatedTooltip */
  ignoreHeight?: boolean;
};

export default TruncatedTooltip;
