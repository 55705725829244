import classNames from 'classnames';
import React from 'react';
import {Overlay, Popover, PopoverProps} from 'react-bootstrap';
import {Placement} from 'react-bootstrap/Overlay';

import {defaultOverlayProps} from 'toolkit/utils/react-bootstrap';
import {noop} from 'utils/functions';

/**
 * A popover that lets the user move the cursor into it.
 * This interaction pattern is similar to what Facebook does.
 */
export default class HoverPopover extends React.PureComponent<Props, State> {
  static defaultProps = {
    enabled: true,
    hideDelay: 250,
    onEnterTarget: noop,
    onLeaveTarget: noop,
    placement: 'right',
    showDelay: 0,
  };
  private enterTimerId: number | undefined = undefined;
  private leaveTimerId: number | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      isMouseInPopover: false,
      isPopoverVisible: false,
      popoverTarget: null,
    };
  }

  clearEnterTimeout = () => {
    clearTimeout(this.enterTimerId);
    this.enterTimerId = undefined;
  };

  clearLeaveTimeout = () => {
    clearTimeout(this.leaveTimerId);
    this.leaveTimerId = undefined;
  };

  enterTarget = (event: React.MouseEvent<HTMLElement>) => {
    this.clearEnterTimeout();
    this.clearLeaveTimeout();
    const target = event.target as HTMLElement;
    this.enterTimerId = window.setTimeout(() => this.show(target), this.props.showDelay);
    this.props.onEnterTarget!();
  };

  handleMouseMoveInTarget = (event: React.MouseEvent<HTMLElement>) => {
    // if the mouse is already in target, the mouseenter event is not triggered
    if (!this.state.popoverTarget && this.enterTimerId === undefined) {
      this.show(event.target as HTMLElement);
    }
  };

  show = (target: HTMLElement) => {
    if (this.state.isPopoverVisible) {
      // setting the target again repositions the hover element due to react-overlays
      return;
    }

    this.setState({
      isPopoverVisible: true,
      popoverTarget: target,
    });
  };

  hide = () => {
    this.clearEnterTimeout();
    this.setState({
      isMouseInPopover: false,
      isPopoverVisible: false,
    });
  };

  enterPopover = () => {
    this.setState({
      isMouseInPopover: true,
    });
  };

  leaveTarget = () => {
    this.clearEnterTimeout();

    if (this.props.hideDelay) {
      // Wait a bit before closing the popover to allow user to move cursor from target to popover
      this.leaveTimerId = window.setTimeout(() => {
        if (!this.state.isMouseInPopover) {
          this.hide();
        }
      }, this.props.hideDelay);
    } else if (!this.state.isMouseInPopover) {
      this.hide();
    }
    this.props.onLeaveTarget!();
  };

  componentWillUnmount() {
    this.clearEnterTimeout();
    this.clearLeaveTimeout();
  }

  render() {
    const {PopoverComponent = Popover} = this.props;
    return (
      <React.Fragment>
        {this.props.enabled && (
          <Overlay
            {...defaultOverlayProps}
            placement={this.props.placement}
            rootClose={false}
            show={this.state.isPopoverVisible || this.state.isMouseInPopover}
            target={this.state.popoverTarget}
            onHide={this.hide}
          >
            <PopoverComponent
              children={this.props.children}
              className={this.props.popoverClassName}
              id="hoverPopover"
              onMouseEnter={this.enterPopover}
              onMouseLeave={this.hide}
            />
          </Overlay>
        )}
        <span
          className={classNames('target', this.props.targetClassName)}
          onMouseEnter={this.enterTarget}
          onMouseLeave={this.leaveTarget}
          onMouseMove={this.handleMouseMoveInTarget}
        >
          {this.props.target}
        </span>
      </React.Fragment>
    );
  }
}

interface Props {
  children: React.ReactNode;
  enabled?: boolean;
  hideDelay?: number;
  onEnterTarget?: () => void;
  onLeaveTarget?: () => void;
  placement?: Placement;
  popoverClassName?: string;
  showDelay?: number;
  target: React.ReactNode;
  targetClassName?: string;
  PopoverComponent?: React.FC<PopoverProps>;
}
interface State {
  isMouseInPopover: boolean;
  isPopoverVisible: boolean;
  popoverTarget: HTMLElement | null;
}
