/* eslint-disable no-console */

import * as Sentry from '@sentry/browser';

import * as Globals from 'app/globals';
import {getUserTitle} from 'toolkit/users/utils';
import * as Types from 'types';
import {ApiError, FailedToFetchError, InterruptedByRedirect} from 'types/error';
import {getCurrentApiVersion, getOriginalApiVersion} from 'utils/ajax';

import {isRunningEndToEndTests} from './cookies';

const isSentryEnabled = !Globals.dev && process.env.NODE_ENV !== 'test';

interface CustomError extends Error {
  [key: string]: any; // subclasses of Error can contain custom fields
}

function shouldSkipApiErrorLogging(error?: Error | null) {
  if (!(error instanceof ApiError)) {
    return false;
  }
  if (error.code >= 500) {
    return true;
  }

  return !!error.error && typeof error.error === 'object' && !error.error.isLoggingEnabled;
}

function addValueToExtras(event: Sentry.Event, fieldName: string, value: any) {
  if (value !== undefined && value !== null && event.extra) {
    event.extra[fieldName] = value;
  }
}

function isKnownNetworkError(error?: CustomError | null) {
  return (
    error instanceof InterruptedByRedirect ||
    error instanceof FailedToFetchError ||
    error?.name === 'ChunkLoadError'
  );
}

function shouldSkipLogging(originalException?: string | CustomError | null) {
  if (typeof originalException === 'string') {
    return false;
  }
  if (isKnownNetworkError(originalException)) {
    return true;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
  const noLogSet =
    originalException && 'logErrors' in originalException && originalException.logErrors === false;
  return noLogSet || shouldSkipApiErrorLogging(originalException);
}

export function configureSentry(environment: Types.Environment, e2eBuildUrl: string | null) {
  if (!isSentryEnabled) {
    return;
  }

  Sentry.init({
    dsn: environment.sentryDsnAmalgam,
    environment: environment.environmentName,
    release: Globals.version,
    beforeSend: (event: Sentry.ErrorEvent, hint: Sentry.EventHint) => {
      const originalException = hint.originalException as string | CustomError | null | undefined;
      if (shouldSkipLogging(originalException)) {
        return null;
      }

      if (!event.extra) {
        event.extra = {};
      }
      event.extra.isApiOutOfDate = getOriginalApiVersion() !== getCurrentApiVersion();
      event.extra.currentApiVersion = getCurrentApiVersion();
      if (originalException instanceof ApiError) {
        addValueToExtras(event, 'traceId', originalException.traceId);
        addValueToExtras(event, 'code', originalException.code);
      }
      if (
        originalException &&
        typeof originalException === 'object' &&
        'requestUrl' in originalException
      ) {
        addValueToExtras(event, 'requestUrl', originalException.requestUrl);
      }
      return event;
    },
  });

  const scope = Sentry.getCurrentScope();
  scope.setTag('apiVersion', getOriginalApiVersion() || 'unknown');
  if (e2eBuildUrl) {
    scope.setTag('e2eUrl', e2eBuildUrl);
  }
}

export function configureSentryUser(currentUser: Types.CurrentUserData | null) {
  Sentry.getCurrentScope().setUser(
    !currentUser
      ? {}
      : {
          email: currentUser.user.email,
          id: String(currentUser.user.id),
          name: getUserTitle(currentUser.user, currentUser.userInfo),
          vendorId: String(currentUser.user.vendorId),
        }
  );
}

export function captureException(exception: Error, extraData: {[key: string]: any} = {}) {
  if (isSentryEnabled) {
    Sentry.withScope(scope => {
      Object.keys(extraData).forEach(tag => scope.setExtra(tag, extraData[tag]));
      if (exception instanceof ApiError && exception.requestUrl) {
        scope.setExtra('requestUrl', exception.requestUrl);
      }
      if (exception instanceof ApiError && exception.traceId) {
        scope.setTag('traceId', exception.traceId);
      }
      Sentry.captureException(exception);
    });
  }
  if (isRunningEndToEndTests()) {
    window.dispatchEvent(
      new CustomEvent('cypresserror', {
        detail: {error: exception},
      })
    );
  }
}

export function logMessage(
  message: string,
  severity: Sentry.SeverityLevel,
  extraData: Record<string, any> = {}
) {
  if (isSentryEnabled) {
    Sentry.withScope(scope => {
      Object.keys(extraData).forEach(tag => scope.setExtra(tag, extraData[tag]));
      Sentry.captureMessage(message, severity);
    });
  } else {
    console.log(`${severity}: ${message}`, extraData);
  }
}

export function reloadApp() {
  window.location.reload();
}

export function reloadAppOnError(arg: Error) {
  if (!Globals.dev && Globals.nodeEnv !== 'test') {
    window.location.reload();
  }
  return Promise.reject(arg);
}

export function getApiErrorStacktrace(exception: Error | null | undefined) {
  if (exception instanceof ApiError) {
    const apiError = exception;
    if (typeof apiError.error === 'object' && 'stacktrace' in apiError.error!) {
      const errorResponse = apiError.error;
      return errorResponse.stacktrace;
    }
  }
  return null;
}
