import './Button.scss';

import classNames from 'classnames';
import React from 'react';

import Icon, {IconProps} from 'toolkit/components/Icon';
import {IconSpec} from 'toolkit/icons/types';

export enum ButtonDisplayVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  DESTRUCTIVE = 'destructive',
  LINK = 'link',
  LINK_DESTRUCTIVE = 'link_destructive',
  LINK_DOTTED = 'link_dotted',
  CHIP = 'chip',
}

export enum ButtonIconPosition {
  LEFT = 'left',
  ICON_ONLY = 'icon-only',
  RIGHT = 'right',
}

export enum ButtonIconSize {
  MEDIUM = 'medium',
  LARGE = 'large',
}

export function renderButtonContent({
  children,
  disabled,
  icon,
  iconClassName,
  iconPosition,
  iconProps,
  iconSize,
}: ButtonOwnProps) {
  const iconClasses = classNames(
    iconPosition === ButtonIconPosition.RIGHT
      ? 'button-icon-right'
      : iconPosition === ButtonIconPosition.LEFT
        ? 'button-icon-left'
        : 'button-icon',
    'size-' + (iconSize ?? ButtonIconSize.MEDIUM),
    iconClassName,
    {disabled}
  );

  return (
    <>
      {icon &&
        (iconPosition === ButtonIconPosition.LEFT ||
          iconPosition === ButtonIconPosition.ICON_ONLY) && (
          <Icon className={iconClasses} icon={icon} {...iconProps} />
        )}

      {children}
      {icon && iconPosition === ButtonIconPosition.RIGHT && (
        <Icon className={iconClasses} icon={icon} {...iconProps} />
      )}
    </>
  );
}

export function getButtonClassNames({
  className,
  displayVariant,
  icon,
  iconPosition,
  active,
}: ButtonOwnProps) {
  return classNames(
    'Button',
    className,
    displayVariant,
    icon && iconPosition === ButtonIconPosition.ICON_ONLY && 'icon-only',
    active && 'active'
  );
}

const Button = React.forwardRef((props: Props, ref: React.Ref<HTMLButtonElement>) => {
  const {
    children,
    className,
    icon,
    iconPosition,
    iconSize,
    iconProps,
    iconClassName,
    displayVariant,
    active,
    ...buttonProps
  } = props;
  return (
    <button ref={ref} className={getButtonClassNames(props)} {...buttonProps}>
      {renderButtonContent(props)}
    </button>
  );
});

Button.displayName = 'Button';
export interface ButtonOwnProps {
  children?: React.ReactNode;
  className?: string;
  displayVariant?: ButtonDisplayVariant;
  disabled?: boolean;
  icon?: IconSpec;
  iconPosition?: ButtonIconPosition;
  iconProps?: Partial<Omit<IconProps, 'className' | 'icon'>>;
  // Icon-size is only applicable to icon-only buttons
  iconSize?: ButtonIconSize;
  iconClassName?: string;
  active?: boolean;
}

export type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonOwnProps;

Button.defaultProps = {
  displayVariant: ButtonDisplayVariant.PRIMARY,
  iconPosition: ButtonIconPosition.LEFT,
};

export default Button;
