import {RouterState, connectRouter, RouterAction} from 'connected-react-router';
import {History} from 'history';
import {Action, combineReducers} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import {AjaxAction} from 'redux/actions/ajax';
import {AlertAction} from 'redux/actions/alerts';
import {AnalysisAction} from 'redux/actions/analysis';
import {AssistantAction} from 'redux/actions/assistant';
import {AttributesAction} from 'redux/actions/attributes';
import {CredentialsAction} from 'redux/actions/credentials';
import {EnvironmentAction} from 'redux/actions/environment';
import {FilesAction} from 'redux/actions/files';
import {GroupsAction} from 'redux/actions/groups';
import {MapsAction} from 'redux/actions/maps';
import {MetricSnapshotsAction} from 'redux/actions/metric-snapshots';
import {NavigationAction} from 'redux/actions/navigation';
import {NewProductAction} from 'redux/actions/new-product';
import {PlansAction} from 'redux/actions/plans';
import {PoliciesAction} from 'redux/actions/policies';
import {ShareAction} from 'redux/actions/share';
import ActionType from 'redux/actions/types';
import {CurrentUserAction} from 'redux/actions/user';
import {UserDataAction} from 'redux/actions/user-data';
import newProduct, {NewProductState} from 'redux/reducers/new-product';
import {BinaryStatus} from 'utils/status';

import ajax, {AjaxState} from './ajax';
import alerts, {AlertsState} from './alerts';
import analysis, {AnalysisState} from './analysis';
import assistant, {AssistantState} from './assistant';
import attributes, {AttributesState} from './attributes';
import credentials, {CredentialsState} from './credentials';
import environment, {EnvironmentState} from './environment';
import files, {FilesState} from './files';
import groups, {GroupsState} from './groups';
import maps, {MapsState} from './maps';
import metricSnapshots, {MetricSnapshotsState} from './metric-snapshots';
import navigation, {NavigationState} from './navigation';
import plans, {PlansState} from './plans';
import policies, {PoliciesState} from './policies';
import share, {ShareState} from './share';
import user, {CurrentUserState} from './user';
import userData, {UserDataState} from './user-data';

export interface RootState {
  router: RouterState;
  ajax: AjaxState;
  alerts: AlertsState;
  analysis: AnalysisState;
  assistant: AssistantState;
  attributes: AttributesState;
  credentials: CredentialsState;
  environment: EnvironmentState;
  files: FilesState;
  newProduct: NewProductState;
  groups: GroupsState;
  maps: MapsState;
  metricSnapshots: MetricSnapshotsState;
  navigation: NavigationState;
  plans: PlansState;
  policies: PoliciesState;
  share: ShareState;
  user: CurrentUserState;
  userData: UserDataState;
}

export type AvailableAction =
  | RouterAction
  | AjaxAction
  | AlertAction
  | AnalysisAction
  | AssistantAction
  | AttributesAction
  | CredentialsAction
  | EnvironmentAction
  | GroupsAction
  | FilesAction
  | NewProductAction
  | MapsAction
  | MetricSnapshotsAction
  | NavigationAction
  | PlansAction
  | PoliciesAction
  | CurrentUserAction
  | UserDataAction
  | ShareAction;

export type GetState = () => RootState;
export interface Dispatch extends ThunkDispatch<RootState, unknown, AvailableAction> {
  <A extends AvailableAction & {promise: Promise<any>}>(action: A): Promise<BinaryStatus>;
}

export default function createRootReducer(history: History) {
  const mainReducer = combineReducers<RootState>({
    ajax,
    alerts,
    analysis,
    assistant,
    attributes,
    credentials,
    environment,
    files,
    newProduct,
    groups,
    maps,
    metricSnapshots,
    navigation,
    plans,
    policies,
    router: connectRouter(history),
    share,
    user,
    userData,
  });

  return (state: RootState | undefined, action: Action) => {
    return mainReducer(action.type === ActionType.ResetReduxStore ? undefined : state, action);
  };
}
