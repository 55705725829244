import './ChartLegendItem.scss';

import classNames from 'classnames';
import React from 'react';

import TruncatedTooltip from 'toolkit/components/TruncatedTooltip';
import {noop} from 'utils/functions';

const ChartLegendItem = React.forwardRef(
  (props: ChartLegendItemProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      children,
      className,
      color,
      isDatasetHidden,
      isToggleEnabled,
      primaryLabel,
      secondaryLabel,
      onToggle,
      ...rest
    } = props;

    return (
      <div
        ref={ref}
        className={classNames('ChartLegendItem', className, {'is-toggle-enabled': isToggleEnabled})}
        onClick={isToggleEnabled ? onToggle : noop}
        {...rest}
      >
        <div className="legend-item-color-indicator" style={{backgroundColor: color}} />
        <span className={classNames('legend-item-label', {'is-dataset-hidden': isDatasetHidden})}>
          <TruncatedTooltip className="primary" value={primaryLabel} ignoreHeight />
          {secondaryLabel && <span className="spacer" />}
          {secondaryLabel && <span className="secondary">{secondaryLabel}</span>}
        </span>
        {children}
      </div>
    );
  }
);
export type ChartLegendItemProps = React.HTMLAttributes<HTMLDivElement> & {
  className?: string;
  color: string;
  isDatasetHidden: boolean;
  isToggleEnabled: boolean;
  onToggle: () => void;
  primaryLabel: string;
  secondaryLabel?: string;
};
ChartLegendItem.displayName = 'ChartLegendItem';
export default ChartLegendItem;
