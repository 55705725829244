import {List} from 'immutable';
import {Middleware, MiddlewareAPI} from 'redux';

import {
  mapAttributeResult,
  mapAttributeValue,
  mapCompositeResult,
  mapComputeResult,
  mapEventResult,
} from 'app/presentation/mapping';
import {PresentationFile} from 'app/presentation/types';
import {isFail, isLoading} from 'redux/actions/helpers';
import ActionType from 'redux/actions/types';
import {Dispatch, RootState} from 'redux/reducers';
import {ComputeResultExtended} from 'toolkit/compute/types';
import {
  AttributeHierarchyTreeResult,
  AttributeResult,
  CompositeResult,
  WidgetDataType,
} from 'toolkit/views/types';
import * as Types from 'types';
import {Widgets} from 'widgets/utils';

export function getPresentationModeData<T>(
  widget: Types.Widget,
  data: unknown,
  mappings: PresentationFile
): T {
  // FIXME: the ugly casts are here because the function can return almost anything,
  // and it's hard to get the types right
  switch (Widgets[widget.type].dataType) {
    case WidgetDataType.COMPUTE:
      if (data instanceof CompositeResult) {
        return mapCompositeResult(
          data as CompositeResult<ComputeResultExtended | Types.CalendarEventResult>,
          mappings
        ) as any as T;
      }
      return mapComputeResult(data as ComputeResultExtended, mappings) as any as T;
    case WidgetDataType.EVENT:
      return mapEventResult(data as Types.CalendarEventResult, mappings) as any as T;
    case WidgetDataType.COMPOSITE:
      return mapCompositeResult(
        data as CompositeResult<ComputeResultExtended | Types.CalendarEventResult>,
        mappings
      ) as any as T;
    case WidgetDataType.ATTRIBUTE:
      return mapAttributeResult(
        data as CompositeResult<AttributeResult | AttributeHierarchyTreeResult>,
        mappings
      ) as any as T;
    default:
      return data as any as T;
  }
}

const reduxPresentationMiddleware: Middleware = (api: MiddlewareAPI<Dispatch, RootState>) => {
  return (dispatch: Dispatch) => (action: any) => {
    const mappings = api.getState().user.presentationFile;
    if (!api.getState().user.isPresentationMode || !mappings) {
      return dispatch(action);
    }

    if (action.type === ActionType.SetWidgetData) {
      if (!isLoading(action) && !isFail(action)) {
        const data = getPresentationModeData(action.dataKey, action.data, mappings);
        const newAction = {
          ...action,
          data,
        };
        return dispatch(newAction);
      }
    } else if (action.type === ActionType.SetRecommendedAttributeFilterValues) {
      const attrMappings = mappings.attributeMappings[action.request.attribute.name];
      return dispatch({
        ...action,
        data: (action.data as List<Types.AttributeValue>).map(value =>
          mapAttributeValue(value, attrMappings)
        ),
      });
    }
    return dispatch(action);
  };
};
export default reduxPresentationMiddleware;
