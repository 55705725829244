import {Middleware} from 'redux';

import {Dispatch} from 'redux/reducers';
import {ApiError} from 'types/error';
import {captureException} from 'utils/exceptions';
import {BinaryStatus} from 'utils/status';

const reduxPromiseMiddleware: Middleware = () => {
  return (dispatch: Dispatch) => (action: any) => {
    const {promise, ...act} = action;
    if (!promise) {
      return dispatch(action);
    }

    dispatch({...act, state: 'request'});
    return promise
      .then((result: any) => {
        dispatch({...act, data: result, state: 'success'});
        return BinaryStatus.succeeded;
      })
      .catch((error: ApiError) => {
        captureException(error);
        dispatch({...act, error, state: 'failure'});
        return BinaryStatus.failed;
      });
  };
};
export default reduxPromiseMiddleware;
