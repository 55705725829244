import './MultiLabel.scss';

import classNames from 'classnames';
import React from 'react';

import {IconSpec} from 'toolkit/icons/types';

import Icon from './Icon';

export enum MultiLabelDisplayVariant {
  NORMAL,
  LIGHTER,
}

const MultiLabel: React.FC<MultiLabelProps> = ({
  allowMultilineText,
  className,
  icon,
  iconClassName,
  subtitle,
  title,
  titleSuffix,
  displayVariant = MultiLabelDisplayVariant.NORMAL,
  ...rest
}) => {
  return (
    <div
      className={classNames(
        'MultiLabel',
        {lighter: displayVariant === MultiLabelDisplayVariant.LIGHTER},
        className
      )}
      {...rest}
    >
      <Icon className={classNames('multi-label-icon', iconClassName)} icon={icon} />
      <div className="labels-wrapper">
        <div className="multi-label-title-wrapper">
          <div className={classNames('multi-label-title', {multiline: allowMultilineText})}>
            {title}
          </div>
          {titleSuffix && <div className="title-suffix">({titleSuffix})</div>}
        </div>
        <div className={classNames('multi-label-subtitle', {multiline: allowMultilineText})}>
          {subtitle}
        </div>
      </div>
    </div>
  );
};
MultiLabel.displayName = 'MultiLabel';

export type MultiLabelProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> & {
  allowMultilineText?: boolean;
  icon?: IconSpec;
  iconClassName?: string;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  titleSuffix?: React.ReactNode;
  displayVariant?: MultiLabelDisplayVariant;
};

export default MultiLabel;
