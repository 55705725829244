import * as History from 'history';
import {List} from 'immutable';
import moment from 'moment-timezone';
import {match} from 'react-router';

import * as Api from 'api';
import {AnalysisDataActions} from 'redux/actions/analysis';
import {Dispatch, GetState, RootState} from 'redux/reducers';
import {getAvailableViews} from 'redux/selectors/analysis';
import {getVendorEvaluationDate} from 'toolkit/time/utils';
import {PUBLIC_VENDOR_ID} from 'toolkit/users/utils';
import {
  ViewState,
  WidgetData,
  createDefaultWidgetData,
  ViewUrlParams,
  AnalysisUrlParams,
  ThinViewsById,
  FullViewsById,
} from 'toolkit/views/types';
import {
  isViewRefreshRequired,
  getDefaultViewStateProps,
  getViewPathMatch,
  getViewId,
} from 'toolkit/views/utils';
import {getViewParamsFromLocation} from 'toolkit/views/view-urls';
import * as Types from 'types';
import {splice} from 'utils/arrays';
import {assertTruthy} from 'utils/assert';
import {isTruthy} from 'utils/functions';
import {Widgets} from 'widgets/utils';

export function getNewProposedWidgets(
  updatedWidget: Types.Widget,
  updatedChildWidgets: readonly Types.Widget[] | null,
  proposedWidgets: readonly Types.Widget[],
  widgetListIndex: number
) {
  const originalWidget = proposedWidgets[widgetListIndex];
  return splice(proposedWidgets, widgetListIndex, (originalWidget.childCount || 0) + 1, [
    updatedWidget,
    ...(updatedChildWidgets || []),
  ]);
}

export function getProposedChildWidgets(
  config: Types.Widget | null,
  activeView: ViewState,
  widgetListIndex: number
): readonly Types.Widget[] | null {
  return config?.childCount
    ? activeView.proposedWidgets.slice(widgetListIndex + 1, widgetListIndex + 1 + config.childCount)
    : null;
}

export function isInPublicVendor(user: Types.User) {
  return user.vendorId === PUBLIC_VENDOR_ID;
}

export async function getNewViewsByLocation(
  dispatch: Dispatch,
  rootState: RootState,
  location: History.Location
) {
  const views = rootState.analysis.views;
  const match = getViewPathMatch(location.pathname);
  if (match === null) {
    return null;
  }
  const newMainView = await getMainView(
    dispatch,
    match,
    rootState.analysis.data.viewsCache,
    getAvailableViews(rootState)
  );
  if (newMainView === null) {
    return null;
  }
  const detailsViews = List(
    await Promise.all(
      getDetailsViews(match, getAvailableViews(rootState)).map(thinView =>
        getCachedView(dispatch, rootState.analysis.data.viewsCache, thinView.id)
      )
    )
  );
  const evaluationDate =
    (!detailsViews.isEmpty() && !views.isEmpty() && views.first()!.evaluationDate) ||
    getVendorEvaluationDate(rootState.user.settings.analysisSettings?.evaluationDate || null);
  const params = getViewParamsFromLocation(location.search) || List.of();
  const newViews = List.of(newMainView).concat(detailsViews);
  return newViews
    .map((view, index) => {
      return view
        ? getStateFromLocationChange(
            views.get(index)!,
            evaluationDate,
            view,
            match?.params.widgetId ? parseInt(match.params.widgetId, 10) : null,
            params.get(index)
          )
        : null;
    })
    .filter(isTruthy);
}

export function getStateFromLocationChange(
  currentViewState: ViewState | null,
  defaultEvaluationDate: moment.Moment,
  view: Types.View,
  selectedWidgetId: number | null,
  viewParams?: ViewUrlParams | null
) {
  const viewState = currentViewState || new ViewState();
  const needsRefresh = isViewRefreshRequired(viewState.view, view);
  const selectedWidgetIndex = selectedWidgetId
    ? view.widgets.findIndex(widget => widget.id === selectedWidgetId)
    : -1;
  return viewState.merge({
    ...getDefaultViewStateProps(view, viewParams),
    evaluationDate: defaultEvaluationDate,
    editingWidgetIndex: null,
    expandedWidgetIndex: selectedWidgetIndex === -1 ? null : selectedWidgetIndex,
    widgetData: needsRefresh ? view.widgets.map(toDefaultWidgetData) : viewState.widgetData,
  });
}

function toDefaultWidgetData(widget: Types.Widget): WidgetData<unknown> {
  return {
    ...createDefaultWidgetData(),
    needsRefresh: !Widgets[widget.type].isContainer,
  };
}

async function getMainView(
  dispatch: Dispatch,
  match: match<AnalysisUrlParams>,
  viewsCache: FullViewsById,
  availableThinViews: ThinViewsById
): Promise<Types.View | null> {
  const viewId = getViewId(match);
  const viewSlug = match.params.viewSlug;
  return await getMatchingView(dispatch, viewId, viewSlug, viewsCache, availableThinViews);
}

export async function getCachedView(
  dispatch: Dispatch,
  viewsCache: FullViewsById,
  viewId: number | null
) {
  // id === null => new dashboard
  if (viewsCache.has(viewId ?? 0)) {
    return viewsCache.get(viewId ?? 0)!;
  } else if (viewId) {
    const view = await Api.Views.getViewById(viewId!);
    viewsCache.set(viewId, view);
    dispatch(AnalysisDataActions.addVendorViews(List.of(view)));
    return view;
  }
  return null;
}

export function getCachedViewAction(viewId: number | null) {
  return (dispatch: Dispatch, getState: GetState) => {
    return getCachedView(dispatch, getState().analysis.data.viewsCache, viewId);
  };
}

async function getMatchingView(
  dispatch: Dispatch,
  viewId: number | null,
  viewSlug: string | undefined,
  viewsCache: FullViewsById,
  availableThinViews: ThinViewsById
): Promise<Types.View | null> {
  const matchesId = (view: Types.ThinView) => view.id === viewId;
  const availableViewId: number | undefined | null = availableThinViews
    .filter(matchesId)
    .first()?.id;
  if (availableViewId !== undefined) {
    return getCachedView(dispatch, viewsCache, availableViewId);
  }
  if (viewSlug !== undefined) {
    const matchesSlug = (view: Types.ThinView) => view.slug === viewSlug;
    const viewId = availableThinViews.filter(matchesSlug).first()?.id;
    if (viewId) {
      return getCachedView(dispatch, viewsCache, viewId);
    }
  }
  return null;
}

function getDetailsViews(match: match<AnalysisUrlParams> | null, linkableThinViews: ThinViewsById) {
  const slugs = match && match.params[0] ? match.params[0].split('/') : [];
  return List<Types.ThinView>(
    slugs.map(slug => assertTruthy(linkableThinViews.find(view => view.slug === slug)))
  );
}
