import equal from 'fast-deep-equal';
import {SetStateAction, useCallback, useState} from 'react';

/*
 * A hook that returns the value from the previous call if the supplied predicate
 * considers it equal to the new value. This helps when dealing with state or
 * props that are equal by value, but might not be equal by reference, to
 * prevent re-executing effects or memoized computations, or unnecessary renders
 */
export function useValue<T>(val: T, isEqual: (a: T, b: T) => boolean = equal) {
  const [stored, setStored] = useState<T>(val);
  if (stored === val || isEqual(stored, val)) {
    return stored;
  }
  setStored(val);
  return val;
}

export function useValueEqualState<T>(initialValue: T, isEqual: (a: T, b: T) => boolean = equal) {
  const [val, setValRaw] = useState(initialValue);
  const setVal = useCallback(
    (newVal: SetStateAction<T>) => {
      const actualNewVal: T = newVal instanceof Function ? newVal(val) : newVal;
      if (!isEqual(actualNewVal, val)) {
        setValRaw(newVal);
      }
    },
    [isEqual, val]
  );
  return [val, setVal] as const;
}
