import classNames from 'classnames';
import React from 'react';

import {AnalysisEntity} from 'toolkit/analysis/types';

import GlobalSearchLink from './GlobalSearchLink';
import GlobalSearchResultsSection from './GlobalSearchResultsSection';

export default class GlobalSearchEntityResultsGroup<
  T extends AnalysisEntity,
> extends React.Component<Props<T>> {
  static defaultProps = {
    maxResultsPerSection: 5,
  };

  render() {
    const {
      contentClassName,
      onMoreClick,
      onResultClick,
      onFocusSearchRequested,
      matches,
      maxResultsPerSection,
      moreUrl,
      title,
    } = this.props;

    return (
      matches.length > 0 && (
        <GlobalSearchResultsSection
          contentClassName={classNames(contentClassName, 'entity-results-section')}
          moreCount={matches.length - (maxResultsPerSection ?? 5)}
          moreUrl={moreUrl}
          title={title}
          onMoreClick={onMoreClick}
        >
          {matches.slice(0, maxResultsPerSection).map(entity => {
            return (
              <GlobalSearchLink
                key={entity.url}
                entity={entity}
                nameRenderer={entity.nameRenderer}
                onClick={onResultClick}
                onFocusSearchRequested={onFocusSearchRequested}
              />
            );
          })}
        </GlobalSearchResultsSection>
      )
    );
  }
}

interface Props<T> {
  contentClassName?: string;
  matches: readonly T[];
  maxResultsPerSection?: number;
  moreUrl?: string;
  onFocusSearchRequested: () => void;
  onMoreClick?: () => void;
  onResultClick: (entity: T | null) => void;
  title: string;
}
