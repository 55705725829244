import './LoginPage.scss';
import React from 'react';
import {Helmet} from 'react-helmet-async';

import Centered from 'toolkit/components/Centered';
import maintenanceBackground from 'toolkit/images/maintenance.png';

export default function MaintenancePage() {
  return (
    <>
      <Helmet title="Alloy.ai" />
      <div className="LoginPage">
        <Centered className="login-form">
          <div>
            <img
              alt="Alloy.ai is under maintenance."
              className="maintenance-img"
              src={maintenanceBackground}
            />
          </div>
          <div>
            <h3>Sorry, we're temporarily down for maintenance.</h3>
            <h4>
              We'll be back shortly. Contact <a href="mailto:support@alloy.ai">support@alloy.ai</a>{' '}
              with immediate concerns.
            </h4>
          </div>
        </Centered>
      </div>
    </>
  );
}
