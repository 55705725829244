import {List, Map} from 'immutable';

import {DISABLE_ATTRIBUTE_LINKS_FOR_ATTRIBUTES} from 'toolkit/attributes/utils';
import {toThinAttributeFilter} from 'toolkit/filters/utils';
import {DATE_FORMAT} from 'toolkit/format/constants';
import {getEffectiveMetric} from 'toolkit/metrics/utils';
import {
  IViewUrlParams,
  ThinAttributeFilter,
  ThinAttributeInstance,
  ViewUrlParams,
} from 'toolkit/views/types';
import {ConcreteViewLinks} from 'toolkit/views/view-link-types';
import * as Types from 'types';
import {first, uniquify} from 'utils/arrays';
import {assertTruthy} from 'utils/assert';
import {isNonNullish, isTruthy} from 'utils/functions';
import {fromBase64, toBase64} from 'utils/json';

interface FullFilters {
  readonly filters: readonly Types.AttributeFilter[];
  readonly selectionFilters?: readonly Types.AttributeFilter[];
}
export function createViewUrlParams(
  params: Omit<IViewUrlParams, 'filters' | 'selectionFilters'> & FullFilters
) {
  const {filters, selectionFilters, ...rest} = params;
  const thinFilters = filters
    ? removeExtraneousLocationFilters(filters)!.map((filter: Types.AttributeFilter) =>
        toThinAttributeFilter(filter)
      )
    : [];
  const thinSelectionFilters = selectionFilters
    ? removeExtraneousLocationFilters(selectionFilters)!.map((filter: Types.AttributeFilter) =>
        toThinAttributeFilter(filter)
      )
    : [];
  return new ViewUrlParams({...rest, filters: thinFilters, selectionFilters: thinSelectionFilters});
}

export function getViewLink(
  vendorName: string,
  viewId: number | null,
  viewSlugs: readonly string[],
  viewParams?: List<ViewUrlParams | null> | null
) {
  const params = toBase64Params(viewParams);
  const viewIdPart = viewId !== null ? `/${viewId}` : '';
  const slugsPart = viewSlugs.length > 0 ? `/${viewSlugs.join('/')}` : '';
  const paramsPart = params ? `?params=${params}` : '';
  return `/${vendorName}/analysis${viewIdPart}${slugsPart}${paramsPart}`;
}

export function getSkipViewLink(
  vendorName: string,
  widgetConfig: Types.Widget,
  currentViewSlug: string,
  concreteViewLinks: ConcreteViewLinks,
  params: ViewUrlParams
) {
  const destinationViewId = isNonNullish(widgetConfig.viewLinkIds)
    ? first(widgetConfig.viewLinkIds)
    : null;
  const widgetMetricNames = widgetConfig.metrics
    .map(({metricInstance}) => getEffectiveMetric(metricInstance))
    .map(metric => metric!.name);
  const metricLinksAtTarget = concreteViewLinks.allViewLinks.metricLinksByViewId.get(
    destinationViewId,
    Map<string, Types.View>()
  );

  // Try to find a metric link that matches the current widget's metric config as it's more
  // specific than attribute types. If found, use that; else
  const matchingMetricLinks = metricLinksAtTarget
    .keySeq()
    .filter(metricName => widgetMetricNames.includes(metricName))
    .toArray();
  if (matchingMetricLinks.length) {
    const firstMatchingMetricLinkView = assertTruthy(
      metricLinksAtTarget.get(matchingMetricLinks[0])
    );
    return getViewLink(
      vendorName,
      destinationViewId,
      [assertTruthy(currentViewSlug), assertTruthy(firstMatchingMetricLinkView.slug)],
      List.of(params)
    );
  }

  const attrLinksAtTarget = concreteViewLinks.allViewLinks.attributeLinksByViewId.get(
    destinationViewId,
    Map<Types.AttributeType, Types.View>()
  );
  const groupingAttributeTypes = uniquify(
    [...widgetConfig.rowGroupings, ...widgetConfig.columnGroupings].map(
      grouping => grouping.attribute.type
    )
  );
  const matchingAttrLinks = attrLinksAtTarget
    .keySeq()
    .filter(attrType => groupingAttributeTypes.includes(attrType))
    .toArray();
  if (matchingAttrLinks.length) {
    // For now, pick the first out of LOCATION or PRODUCT links; we need to define more explicit
    // logic if this ends up being a problem.
    const linkedAttributeType = matchingAttrLinks[0];
    const firstMatchingAttrLinkView = assertTruthy(attrLinksAtTarget.get(linkedAttributeType));
    return getViewLink(
      vendorName,
      destinationViewId,
      [assertTruthy(currentViewSlug), assertTruthy(firstMatchingAttrLinkView.slug)],
      List.of(null, params)
    );
  }

  return getViewLink(vendorName, destinationViewId, [], List.of(params));
}

export function getPlanLink(
  vendorName: string,
  type: Types.PlanType,
  planFilterSelection?: string
) {
  return `/${vendorName}/planning/${type.toLocaleLowerCase()}${planFilterSelection || ''}`;
}

export function getPlanForecastsLink(vendorName: string, forecastFilterSelection?: string) {
  return `/${vendorName}/planning/forecasts${forecastFilterSelection || ''}`;
}

export const getLinkedAttributeView = (attribute: Types.Attribute, links: ConcreteViewLinks) =>
  !DISABLE_ATTRIBUTE_LINKS_FOR_ATTRIBUTES.contains(attribute.name) &&
  getLinkedAttributeTypeView(attribute.type, links);

export const getLinkedAttributeUrl = (attribute: Types.Attribute, links: ConcreteViewLinks) =>
  !DISABLE_ATTRIBUTE_LINKS_FOR_ATTRIBUTES.contains(attribute.name) &&
  getLinkedAttributeTypeUrl(attribute.type, links);

export const getLinkedAttributeTypeView = (type: Types.AttributeType, links: ConcreteViewLinks) =>
  links.attributeLinks.get(type, null);

export const getLinkedAttributeTypeUrl = (type: Types.AttributeType, links: ConcreteViewLinks) =>
  links.attributeUrlLinks.get(type, null);

export const getLinkedMetricView = (metricName: string, links: ConcreteViewLinks) =>
  links.metricLinks.get(metricName, null);

export const getLinkedMetricUrl = (metricName: string, links: ConcreteViewLinks) =>
  links.metricUrlLinks.get(metricName, null);

export function getViewParamsFromLocation(
  urlSearchPart: string
): List<ViewUrlParams | null> | null {
  const searchParams = new URLSearchParams(urlSearchPart);
  const viewParamsJson: any[] = searchParams.get('params')
    ? fromBase64(searchParams.get('params'))
    : null;
  if (viewParamsJson) {
    return List(viewParamsJson.map(params => (params ? ViewUrlParams.fromJS(params) : null)));
  }

  const attributeIdParam = searchParams.get('attributeId');
  const attributeId = attributeIdParam ? parseInt(attributeIdParam, 10) : null;
  const valueIdParam = searchParams.get('valueId')
    ? searchParams.get('valueId')!.replace(/[a-z, ]/gi, '')
    : null;
  const valueId = valueIdParam ? parseInt(valueIdParam, 10) : null;
  if (attributeId && valueId) {
    const thinAttributeInstance: ThinAttributeInstance = {
      id: attributeId,
      templateAttributeId: attributeId,
      graphContext: null,
    };
    const filter: ThinAttributeFilter = {
      attribute: thinAttributeInstance,
      inclusive: true,
      valueIds: [valueId],
    };
    return List.of(ViewUrlParams.fromJS({filters: [filter]}));
  }

  return null;
}

export function toBase64Params(urlParams: List<ViewUrlParams | null> | null | undefined) {
  if (!urlParams || urlParams.isEmpty()) {
    return null;
  } else if (urlParams.size === 1) {
    // special case to keep the root URL clean
    const paramsJson = toViewParamsJson(urlParams.first());
    if (!paramsJson || !Object.keys(paramsJson).length) {
      return null;
    }
  }
  return toBase64(urlParams.map(toViewParamsJson));
}

function toViewParamsJson(params: ViewUrlParams | null) {
  if (!params) {
    return null;
  }
  // minify the json output; remove all fields that have null or undefined
  const fullParamsJson = params.toJS();
  const {asOfDate, filters, ...rest} = fullParamsJson;

  const minifiedParamsJson = Map({
    ...rest,
    asOfDate: asOfDate ? asOfDate.format(DATE_FORMAT) : null,
    filters:
      params.filters && params.filters.length > 0
        ? params.filters.map((filter: ThinAttributeFilter) => toMinifiedJSFilter(filter))
        : null,
    selectionFilters:
      params.selectionFilters && params.selectionFilters.length > 0
        ? params.selectionFilters.map((filter: ThinAttributeFilter) => toMinifiedJSFilter(filter))
        : null,
  })
    .filter(isTruthy)
    .toJS();
  return Object.keys(minifiedParamsJson).length ? minifiedParamsJson : null;
}

function removeExtraneousLocationFilters(filters: readonly Types.AttributeFilter[] | null) {
  if (
    !filters?.some(isMostSpecificLocationFilter) ||
    filters.some(filter => !!filter.attributeInstance.graphContext)
  ) {
    return filters;
  }
  // If we have a location filter, other location attribute filters can be removed.
  return filters.filter(
    filter =>
      filter.attributeInstance.attribute.type !== 'LOCATION' || isMostSpecificLocationFilter(filter)
  );
}

const toMinifiedJSFilter = (filter: ThinAttributeFilter) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
  const inclusive = filter.inclusive === false ? {inclusive: false} : {};
  return {
    attribute: toMinifiedJSAttributeInstance(filter.attribute),
    valueIds: filter.valueIds,
    ...inclusive,
  };
};

const toMinifiedJSAttributeInstance = (attrInstance: ThinAttributeInstance) => {
  const graphContext = attrInstance.graphContext ? {graphContext: attrInstance.graphContext} : {};
  return {
    id: attrInstance.id,
    ...graphContext,
  };
};

const isMostSpecificLocationFilter = (filter: Types.AttributeFilter) =>
  filter.attributeInstance.attribute.name === 'Location';
