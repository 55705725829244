import './FieldsetColumn.scss';

import classNames from 'classnames';
import React from 'react';

const FieldsetColumn: React.FunctionComponent<Props> = props => {
  const {children, className, ...rest} = props;
  return (
    <div className={classNames('FieldsetColumn', className)} {...rest}>
      {children}
    </div>
  );
};

FieldsetColumn.displayName = 'FieldsetColumn';
type Props = React.HTMLAttributes<HTMLDivElement> & {
  className?: string;
};
export default FieldsetColumn;
