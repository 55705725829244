import * as Globals from 'app/globals';
import {getUserTitle, isAlloyUser} from 'toolkit/users/utils';
import * as Types from 'types';

import Heap from './heap';

interface Analytics {
  init(environment: Types.Environment): void;
  track(eventName: string, prop?: any): void;
  identify(user: Types.User, userInfo: Types.UserInfo, vendor: Types.Vendor): void;
}

class NoopAnalytics implements Analytics {
  init() {}
  track() {}
  identify() {}
}

class ProductionAnalytics implements Analytics {
  init = (environment: Types.Environment) => {
    Heap.init(environment.heapAppId);
  };

  track = (eventName: string, props?: any) => {
    Heap.track(eventName, {...props});
  };

  identify = (user: Types.User, userInfo: Types.UserInfo, vendor: Types.Vendor) => {
    const userName = getUserTitle(user, userInfo);
    Heap.identify(user.email, 'email');
    const userProps = {
      email: user.email,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      functionalArea: userInfo.functionalArea,
      name: userName,
      role: user.role,
      createdAt: userInfo.createdAt,
      lastLoggedInAt: new Date().toISOString(),
    };
    if (isAlloyUser(user.email)) {
      Heap.addUserProperties(userProps);
    } else {
      Heap.addUserProperties({
        ...userProps,
        vendorId: user.vendorId,
        vendorName: vendor.name,
        company: vendor.name,
      });
    }
  };
}

const Analytics = Globals.dev ? new NoopAnalytics() : new ProductionAnalytics();
export default Analytics;
