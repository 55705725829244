import './CacheInspector.scss';

import pluralize from 'pluralize';
import React, {useEffect, useState} from 'react';

import useSelector from 'redux/selectors/useSelector';
import ExpandToggle from 'toolkit/components/ExpandToggle';
import Icon from 'toolkit/components/Icon';
import IconButton from 'toolkit/components/IconButton';
import Input from 'toolkit/components/Input';
import Tooltip from 'toolkit/components/Tooltip';
import Format from 'toolkit/format/format';
import useForceUpdate from 'toolkit/utils/useForceUpdate';
import {useToggle} from 'toolkit/utils/useInputs';
import {
  subscribeToResourceCache,
  unsubscribeFromResourceCache,
  encodePathString,
  NoopResource,
  private__mutableResourceCache,
  invalidate,
  Resource,
  FROZEN_PROMISE,
} from 'utils/api';
import {ascendingBy} from 'utils/arrays';

export function getResourceUrl(resource: Resource<unknown>) {
  return encodePathString(resource.url, resource.pathParams).toLowerCase();
}

const MAX_URL_LENGTH = 22;
const ResourceDisplay: React.FC<{resource: Resource<unknown>; usages: number}> = ({
  resource,
  usages,
}) => {
  const resourceUrl = getResourceUrl(resource);
  return (
    <li>
      <Tooltip
        tooltip={resourceUrl.length > MAX_URL_LENGTH ? resourceUrl : undefined}
        tooltipClassName="CacheInspector--overflow-tooltip"
      >
        <span className="resource-name">{Format.ellipsize(resourceUrl, MAX_URL_LENGTH, true)}</span>{' '}
        ({usages} {pluralize('usages', usages)})
      </Tooltip>
      <IconButton icon="sync" onClick={() => invalidate(resource)} />
      <IconButton
        icon={resource.currentRequest === FROZEN_PROMISE ? 'play-circle' : 'pause-circle'}
        onClick={() => resource.devOnly__toggleFreezing()}
      />
    </li>
  );
};

const ResourceList: React.FC<{searchQuery: string}> = ({searchQuery}) => {
  const itemsInCache = Array.from(private__mutableResourceCache.values());
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    subscribeToResourceCache(forceUpdate);

    return () => unsubscribeFromResourceCache(forceUpdate);
  }, [forceUpdate]);

  const usedItems = itemsInCache
    .filter(({usages, resource}) => usages > 0 && resource !== NoopResource)
    .filter(({resource}) =>
      getResourceUrl(resource).toLowerCase().includes(searchQuery.toLowerCase())
    );
  const unusedItems = itemsInCache
    .filter(({usages, resource}) => usages === 0 && resource !== NoopResource)
    .filter(({resource}) =>
      getResourceUrl(resource).toLowerCase().includes(searchQuery.toLowerCase())
    );

  const [showUnusedItems, toggleShowUnusedItems] = useToggle(false);

  return (
    <div className="resources">
      <ul className="resource-list">
        {usedItems
          .sort(ascendingBy(({resource}) => getResourceUrl(resource)))
          .map(({usages, resource}) => (
            <ResourceDisplay key={resource.getUniqueKey()} resource={resource} usages={usages} />
          ))}
      </ul>
      <ExpandToggle expanded={showUnusedItems} onToggle={toggleShowUnusedItems}>
        Unused
      </ExpandToggle>
      {showUnusedItems && (
        <ul className="resource-list">
          {unusedItems
            .sort(ascendingBy(({resource}) => getResourceUrl(resource)))
            .map(({usages, resource}) => (
              <ResourceDisplay key={resource.getUniqueKey()} resource={resource} usages={usages} />
            ))}
        </ul>
      )}
    </div>
  );
};

export const CacheInspector: React.FC = () => {
  const isVisible = useSelector(state => state.ajax.isCacheInspectorVisible);
  const [isExpanded, setIsExpanded] = useState(true);
  const [searchQuery, setSearchQuery] = useState<string>('');

  if (!isVisible) {
    return null;
  }

  if (!isExpanded) {
    return (
      <div className="CacheInspector" onClick={() => setIsExpanded(true)}>
        <Icon className="expand-icon" icon="expand-alt" />
      </div>
    );
  }

  return (
    <div className="CacheInspector expanded">
      <div>
        <h3>Cache Status</h3>
        <Input
          placeholder="Filter..."
          value={searchQuery}
          onChange={event => setSearchQuery(event.target.value)}
        />
        <ResourceList searchQuery={searchQuery} />
      </div>
      <Icon className="collapse-icon" icon="compress-alt" onClick={() => setIsExpanded(false)} />
    </div>
  );
};
