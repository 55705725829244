import './ConfigLabel.scss';

import classNames from 'classnames';
import React, {useState} from 'react';

import {ButtonDisplayVariant} from 'toolkit/components/Button';
import Icon from 'toolkit/components/Icon';
import IconButton, {IconButtonProps} from 'toolkit/components/IconButton';
import {IconSpec} from 'toolkit/icons/types';
import {noop} from 'utils/functions';

export const enum ConfigLabelDisplayVariant {
  NORMAL,
  NO_BORDERS,
  OUTLINE,
}

export const ConfigLabelButton = React.forwardRef(
  ({className, ...props}: IconButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    return (
      <IconButton ref={ref} className={classNames('ConfigLabelButton', className)} {...props} />
    );
  }
);

const ConfigLabel = React.forwardRef(
  (
    {
      children,
      className,
      editIcon = 'pencil-alt',
      isActive,
      isDeleteEnabled = true,
      isCloseButtonEnabled,
      isEditEnabled = true,
      isEditing,
      isPlaceholder = false,
      isRequired = false,
      isValid = true,
      onClick,
      onEdit = noop,
      onFocus,
      onDelete,
      onCloseButtonClick,
      onKeyDown,
      showDropdownCaret,
      tabIndex,
      value,
      isDisabled,
      buttonPlacement = 'before',
      displayVariant = ConfigLabelDisplayVariant.NORMAL,
      onlyShowButtonsOnHover = false,
    }: Props,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const [isMouseInDeleteButton, setMouseInDeleteButton] = useState(false);

    const handleEdit = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      onEdit!();
    };

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
      if (!e.isDefaultPrevented() && !!onClick) {
        onClick(e);
      }
    };

    return (
      <div
        ref={ref}
        className={classNames(
          'ConfigLabel',
          {'config-label-placeholder': isPlaceholder},
          {'config-label-required': isPlaceholder && isRequired},
          {'config-label-invalid': !isValid},
          {clickable: !!onClick && !isDisabled},
          {editing: isEditing, active: isActive},
          className,
          {disabled: isDisabled},
          {bordered: displayVariant === ConfigLabelDisplayVariant.NORMAL},
          {outlined: displayVariant === ConfigLabelDisplayVariant.OUTLINE},
          {'show-buttons-on-hover': onlyShowButtonsOnHover},
          {'delete-button-hovered': isMouseInDeleteButton}
        )}
        role="button"
        tabIndex={tabIndex}
        onClick={handleClick}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
      >
        <div className="value-text">{value}</div>
        {buttonPlacement === 'after' && children}
        <div className="row-buttons not-draggable">
          {isEditEnabled && !isDisabled && (
            <ConfigLabelButton
              className="button config-label-edit-button"
              icon={editIcon}
              onClick={handleEdit}
            />
          )}
          {isDeleteEnabled && !isDisabled ? (
            <ConfigLabelButton
              className="button config-label-delete-button"
              displayVariant={
                displayVariant === ConfigLabelDisplayVariant.NO_BORDERS
                  ? ButtonDisplayVariant.LINK_DESTRUCTIVE
                  : undefined
              }
              icon="times"
              onClick={onDelete}
              onMouseEnter={() => setMouseInDeleteButton(true)}
              onMouseLeave={() => setMouseInDeleteButton(false)}
            />
          ) : (
            isCloseButtonEnabled &&
            !isDisabled && (
              <ConfigLabelButton
                className="button config-label-delete-button"
                icon="times"
                onClick={onCloseButtonClick}
              />
            )
          )}
          {showDropdownCaret && !isDisabled && (
            <div className="button config-label-dropdown-button">
              <Icon icon="caret-down" />
            </div>
          )}
          {buttonPlacement === 'before' && children}
        </div>
      </div>
    );
  }
);
ConfigLabel.displayName = 'ConfigLabel';

interface Props {
  children?: React.ReactNode;
  className?: string;
  editIcon?: IconSpec;
  isDeleteEnabled?: boolean;
  isCloseButtonEnabled?: boolean;
  showDropdownCaret?: boolean;
  isEditEnabled?: boolean;
  isEditing?: boolean;
  isActive?: boolean;
  isPlaceholder?: boolean;
  isRequired?: boolean;
  isValid?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onCloseButtonClick?: React.MouseEventHandler<HTMLElement>;
  onEdit?: () => void;
  onFocus?: React.FocusEventHandler<HTMLElement>;
  onDelete?: React.MouseEventHandler<HTMLElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLElement>;
  tabIndex?: number;
  value: React.ReactNode;
  isDisabled?: boolean;
  buttonPlacement?: 'before' | 'after';
  displayVariant?: ConfigLabelDisplayVariant;
  onlyShowButtonsOnHover?: boolean;
}

export default ConfigLabel;
