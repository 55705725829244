import './AnalysisEntityCard.scss';

import React from 'react';
import {useHistory} from 'react-router';

import {TemplateAnalysisEntity, WorkflowAnalysisEntity} from 'toolkit/analysis/types';
import CanvasBlock from 'toolkit/components/CanvasBlock';
import Icon from 'toolkit/components/Icon';
import {slugify} from 'toolkit/format/text';
import {IconSpec} from 'toolkit/icons/types';
import {isWorkflowEntity} from 'toolkit/views/utils';

interface EntityCardInfo {
  name: string;
  description: string | null | undefined;
  url: string;
  icon?: IconSpec;
}

function getEntityCardInfo(
  entity: WorkflowAnalysisEntity | TemplateAnalysisEntity
): EntityCardInfo {
  return isWorkflowEntity(entity)
    ? {name: entity.name, description: entity.description, url: entity.url, icon: entity.icon}
    : {
        name: entity.name,
        description: entity.value.description,
        url: entity.url,
        icon: entity.icon,
      };
}

const AnalysisEntityCard: React.FC<Props> = ({entity}) => {
  const history = useHistory();
  const cardInfo = getEntityCardInfo(entity);

  return (
    <CanvasBlock
      key={cardInfo.name}
      className="AnalysisEntityCard"
      id={slugify(cardInfo.name)}
      gapped
      padded
      onClick={() => history.push(cardInfo.url)}
    >
      <div className="title-container">
        <h4>{cardInfo.name}</h4>
        <span>{cardInfo.description}</span>
      </div>
      {cardInfo.icon && <Icon icon={cardInfo.icon} />}
    </CanvasBlock>
  );
};
AnalysisEntityCard.displayName = 'AnalysisEntityCard';

type Props = {
  entity: WorkflowAnalysisEntity | TemplateAnalysisEntity;
};

export default AnalysisEntityCard;
