import {CredentialsValidityStatus} from 'toolkit/credentials/types';
import {defaultCalendar} from 'toolkit/time/constants';
import * as Types from 'types';

export function unsubscribeUser(
  user: Types.User,
  subscription: Types.EmailWidgetSubscription
): Types.EmailWidgetSubscription {
  const givenUserId = createUserId(user.id!);
  const toRecipients = subscription.toRecipients.filter(
    userId => !userIdsEqual(userId, givenUserId)
  );
  const ccRecipients = subscription.ccRecipients.filter(
    userId => !userIdsEqual(userId, givenUserId)
  );
  return {...subscription, toRecipients, ccRecipients};
}

export function getBulkExportCalendar(
  exportConfig: Types.ExternalStorageWidgetSubscription,
  analysisSettings: Types.VendorAnalysisSettings
) {
  return exportConfig.calendar || analysisSettings.calendar || defaultCalendar;
}

export const displayableExportErrors: {
  readonly [key in Types.ExportErrorType]: string;
} = {
  INVALID_CREDENTIALS: 'invalid credentials',
  INCOMPLETE_DATA: 'incomplete data',
  TOO_MUCH_DATA: 'too much exported data',
  CONNECTION_ISSUE: 'connection issues',
  SERVER_ERROR: 'a server error',
  ABORTED: 'aborted',
  UNKNOWN: 'unknown reasons',
};

export function isValidFtpPath(ftpPath: string | null) {
  return !((ftpPath?.length ?? 0) > 0 && ftpPath?.charAt(0) === '/');
}

export const createUserId = (userId: number): Types.UserId => {
  return {id: userId, type: Types.UserType.USER};
};

export const createSubscriptionOnlyUserId = (subscriptionOnlyUserId: number): Types.UserId => {
  return {id: subscriptionOnlyUserId, type: Types.UserType.SUBSCRIPTION_ONLY_USER};
};

export const userIdsEqual = (userId1: Types.UserId, userId2: Types.UserId): boolean => {
  return userId1.type === userId2.type && userId1.id === userId2.id;
};

export function wasSuccessfullyUploaded(validityStatus: CredentialsValidityStatus): boolean {
  return (
    validityStatus.result?.resultCode === Types.ConnectionTestResultCode.OK ||
    validityStatus.result?.resultCode === Types.ConnectionTestResultCode.CANNOT_DELETE
  );
}

export const isUser = (userId: Types.UserId): boolean => userId.type === Types.UserType.USER;

export const isSubscriptionOnlyUser = (userId: Types.UserId): boolean =>
  userId.type === Types.UserType.SUBSCRIPTION_ONLY_USER;
