import './OrdersPreviewTable.scss';

import classNames from 'classnames';
import moment from 'moment-timezone';
import React from 'react';

import {useCurrentUser} from 'redux/context/user';
import {CurrentUser} from 'redux/reducers/user';
import Table from 'toolkit/ag-grid/Table';
import {TypedColDef, TypedValueGetterParams} from 'toolkit/ag-grid/types';
import {DISPLAY_DATE_FORMAT} from 'toolkit/format/constants';
import Format from 'toolkit/format/format';
import {isMoney} from 'toolkit/metrics/utils';
import * as Types from 'types';
import {dateColumnComparator} from 'widgets/tables/impl/columns';

import {getValue} from './OrdersPreviewChart';
import TransactionStatusBadge from './TransactionStatusBadge';
import {dateColumnHeadingFromTransactionMetric} from './utils';

type CellRendererProps = {
  data: TransactionAnalysisData;
};
type QuantityCellRendererParams = {
  metricInstance: Types.MetricInstance;
};
type QuantityCellRendererProps = CellRendererProps & QuantityCellRendererParams;
type LocationCellRendererParams = {
  isOrigin: boolean;
};
type LocationCellRendererProps = CellRendererProps & LocationCellRendererParams;
type OrdersPreviewCellProps = {
  firstRowAttribute: string | number;
  secondRowAttribute: string | number;
  bold?: boolean;
};

const OrdersPreviewCell: React.FC<OrdersPreviewCellProps> = ({
  firstRowAttribute,
  secondRowAttribute,
  bold = false,
}) => {
  const attributeClassname = classNames('transaction-attribute', {
    'transaction-attribute-bold': bold,
  });
  return (
    <div className="transaction-cell">
      {firstRowAttribute && <div className={attributeClassname}>{firstRowAttribute}</div>}
      {secondRowAttribute && <div className={attributeClassname}>{secondRowAttribute}</div>}
    </div>
  );
};

const OrderCellRenderer: React.FC<CellRendererProps> = ({data}) => {
  return (
    <OrdersPreviewCell
      firstRowAttribute={data.purchaseOrderNumberAttributeValue}
      secondRowAttribute={data.salesOrderNumberAttributeValue}
      bold
    />
  );
};

const ItemCellRenderer: React.FC<CellRendererProps> = ({data}) => {
  return (
    <OrdersPreviewCell
      firstRowAttribute={data.itemAttributeValue}
      secondRowAttribute={data.lineItemNumberAttributeValue}
    />
  );
};

const QuantityCellRenderer: React.FC<QuantityCellRendererProps> = ({data, metricInstance}) => {
  const metricValue = getValue(metricInstance, data);
  return (
    <OrdersPreviewCell
      firstRowAttribute={Format.transactionEventQuantity(metricInstance, metricValue)}
      secondRowAttribute={data.unitAttributeValue}
    />
  );
};

const LocationCellRenderer: React.FC<LocationCellRendererProps> = ({data, isOrigin}) => {
  return (
    <OrdersPreviewCell
      firstRowAttribute={
        isOrigin ? data.originLocationAttributeValue : data.destinationLocationAttributeValue
      }
      secondRowAttribute={
        isOrigin ? data.originPartnerAttributeValue : data.destinationPartnerAttributeValue
      }
    />
  );
};

function transactionEventColDefs(
  metricInstance: Types.MetricInstance,
  currentUser: CurrentUser
): Array<TypedColDef<TransactionAnalysisData>> {
  const columns: Array<TypedColDef<TransactionAnalysisData>> = [
    {
      headerName: 'Order',
      sortable: true,
      cellRendererFramework: OrderCellRenderer,
      valueGetter: params => {
        return `${params.data.purchaseOrderNumberAttributeValue}${params.data.salesOrderNumberAttributeValue}`;
      },
    },
    {
      headerName: 'Status',
      sortable: true,
      cellRendererFramework: ({data}: TypedValueGetterParams<TransactionAnalysisData>) => (
        <TransactionStatusBadge status={data.status} />
      ),
      valueGetter: params => `${params.data.status}`,
    },
    {
      headerName: 'Item',
      sortable: true,
      cellRendererFramework: ItemCellRenderer,
      valueGetter: params => {
        return `${params.data.itemAttributeValue}${params.data.lineItemNumberAttributeValue}`;
      },
    },
    {
      headerName: isMoney(metricInstance) ? 'Amount' : 'Quantity',
      sortable: true,
      cellRendererParams: (): QuantityCellRendererParams => ({
        metricInstance,
      }),
      cellRendererFramework: QuantityCellRenderer,
      valueGetter: params => getValue(metricInstance, params.data),
    },
    {
      headerName: dateColumnHeadingFromTransactionMetric(metricInstance),
      sortable: true,
      sort: 'asc',
      valueGetter: params => moment(params.data.datetime).utc().format(DISPLAY_DATE_FORMAT),
      comparator: dateColumnComparator,
    },
    {
      headerName: 'Origin',
      sortable: true,
      cellRendererParams: (): LocationCellRendererParams => ({
        isOrigin: true,
      }),
      cellRendererFramework: LocationCellRenderer,
      valueGetter: params =>
        `${params.data.originLocationAttributeValue}${params.data.originPartnerAttributeValue}`,
    },
    {
      headerName: 'Destination',
      sortable: true,
      cellRendererParams: (): LocationCellRendererParams => ({
        isOrigin: false,
      }),
      cellRendererFramework: LocationCellRenderer,
      valueGetter: params =>
        `${params.data.destinationLocationAttributeValue}${params.data.destinationPartnerAttributeValue}`,
    },
  ];
  if (currentUser.isDevelopmentMode) {
    columns.push({
      headerName: 'Ingestion ID',
      sortable: true,
      cellRendererFramework: ({data}: TypedValueGetterParams<TransactionAnalysisData>) => (
        <div>{data.ingestionId}</div>
      ),
      valueGetter: params => `${params.data.status}`,
    });
  }
  return columns;
}

const OrdersPreviewTable: React.FC<OrdersPreviewTableProps> = ({metricInstance, rowData}) => {
  const currentUser = useCurrentUser();
  return (
    <div className="OrdersPreviewTable">
      <Table
        columnDefs={transactionEventColDefs(metricInstance, currentUser)}
        rowData={rowData}
        rowHeight={60}
      />
    </div>
  );
};

export type TransactionAnalysisData = Types.DisplayTransactionEvent & {
  purchaseOrderNumberAttributeValue: string;
  salesOrderNumberAttributeValue: string;
  originLocationAttributeValue: string;
  originPartnerAttributeValue: string;
  destinationLocationAttributeValue: string;
  destinationPartnerAttributeValue: string;
  lineItemNumberAttributeValue: string;
  itemAttributeValue: string;
  unitAttributeValue: string;
};

interface OrdersPreviewTableProps {
  metricInstance: Types.MetricInstance;
  rowData: readonly TransactionAnalysisData[];
}

OrdersPreviewTable.displayName = 'OrdersPreviewTable';

export default OrdersPreviewTable;
