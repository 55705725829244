import './TopBar.scss';

import classNames from 'classnames';
import React from 'react';

export interface TopBarProps {
  children: React.ReactNode;
  className?: string;
}

const TopBar: React.FunctionComponent<TopBarProps> = ({className, children}) => {
  return (
    <div className={classNames('TopBar', className)}>
      <div className="top-bar-content">{children}</div>
    </div>
  );
};

TopBar.displayName = 'TopBar';
export default TopBar;
