import Fuse from 'fuse.js';

export const DEFAULT_FUSE_OPTIONS: Fuse.IFuseOptions<any> = {
  shouldSort: true,
  threshold: 0.2,
};

export const ANALYSIS_ENTITY_FUSE_OPTIONS: Fuse.IFuseOptions<any> = {
  shouldSort: true,
  threshold: 0.24,
  distance: 1000,
};

export const DATE_PERIOD_FUSE_OPTIONS: Fuse.IFuseOptions<any> = {
  distance: 100,
  includeScore: true,
  shouldSort: false,
  threshold: 0.6,
};
