import './NavTopBarItem.scss';

import classNames from 'classnames';
import React from 'react';
import {NavLink, NavLinkProps} from 'react-router-dom';

const NavTopBarItem = React.forwardRef((props: Props, ref: React.Ref<HTMLDivElement>) => {
  const {className, title, ...rest} = props;
  return (
    <div ref={ref}>
      <NavLink
        activeClassName="selected"
        className={classNames('NavTopBarItem', className)}
        {...rest}
      >
        {title}
      </NavLink>
    </div>
  );
});
type Props = NavLinkProps & {
  title: React.ReactNode;
};
NavTopBarItem.displayName = 'NavTopBarItem';

export default NavTopBarItem;
