import Cookies from 'js-cookie';

const PACKAGE_OVERRIDE_HEADER = 'X-Alloy-Application-Package-Override';

function getCookieName(vendorId: number) {
  return `application_package_override_${vendorId}`;
}

export function getPackageOverride(vendorId: number): number | null {
  return Cookies.getJSON(getCookieName(vendorId)) ?? null;
}

export function getPackageOverrideHeaderEntry(
  vendorId: number | undefined
): [string, string | undefined] {
  return [PACKAGE_OVERRIDE_HEADER, vendorId ? Cookies.get(getCookieName(vendorId)) : undefined];
}

export function resetPackageOverrideCookie(vendorId: number) {
  Cookies.remove(getCookieName(vendorId));
}

export function setPackageOverride(vendorId: number, packageId: number | null, expires: Date) {
  if (!packageId) {
    resetPackageOverrideCookie(vendorId);
  } else {
    Cookies.set(getCookieName(vendorId), JSON.stringify(packageId), {expires});
  }
}
