enum ActionType {
  // Ajax call tracking
  IncrementActiveRequestCount = 'INCREMENT_ACTIVE_REQUEST_COUNT',
  DecrementActiveRequestCount = 'DECREMENT_ACTIVE_REQUEST_COUNT',
  SetEndToEndTestBuildUrl = 'SET_E2E_TEST_BUILD_URL',
  ToggleCacheInspectorVisible = 'TOGGLE_CACHE_INSPECTOR_OPEN',

  // Global alert and error handling (app notifications)
  AddAlert = 'ADD_ALERT',
  ClearAlert = 'CLEAR_ALERT',

  // Users and authentication
  SetAllowedVendors = 'SET_ALLOWED_VENDORS',
  SetCurrentVendor = 'SET_CURRENT_VENDOR',
  SetUser = 'SET_USER',
  SetUserInfo = 'SET_USER_INFO',
  SetEnvironment = 'SET_ENVIRONMENT',
  SetPermissions = 'SET_PERMISSIONS',
  SetPermissionsOverrideDialogOpen = 'SET_PERMISSIONS_OVERRIDE_DIALOG_OPEN',
  SetManualViewUpdateMode = 'SET_MANUAL_VIEW_UPDATE_MODE',
  SetUserFeatures = 'SET_USER_FEATURES',
  SetUserRole = 'SET_USER_ROLE',
  SetUserFeatureFlags = 'SET_USER_FEATURE_FLAGS',
  SetVendorAnalysisSettings = 'SET_VENDOR_ANALYSIS_SETTINGS',
  ResetReduxStore = 'RESET_REDUX_STORE',
  SetDevelopmentMode = 'SET_DEVELOPMENT_MODE',
  SetPresentationMode = 'SET_PRESENTATION_MODE',
  SetPresentationFile = 'SET_PRESENTATION_FILE',

  // Views
  AddVendorViews = 'ADD_VENDOR_VIEWS',
  ClearVendorViews = 'CLEAR_VENDOR_VIEWS',
  ClearUnsavedView = 'CLEAR_UNSAVED_VIEW',
  ClearViewChanges = 'CLEAR_VIEW_CHANGES',
  CopyWidget = 'COPY_WIDGET',
  CreateOrUpdateView = 'CREATE_OR_UPDATE_VIEW',
  CreateOrUpdateViews = 'CREATE_OR_UPDATE_VIEWS',
  DeleteView = 'DELETE_VIEW',
  DeleteEvent = 'DELETE_EVENT',
  CreateEvent = 'UPDATE_EVENT',
  UpdateEvent = 'CREATE_EVENT',
  ReplaceCurrentViews = 'REPLACE_CURRENT_VIEWS',
  SetAllGroupings = 'SET_ALL_GROUPINGS',
  SetAvailableMetrics = 'SET_AVAILABLE_METRICS',
  SetDefaultHierarchy = 'SET_DEFAULT_HIERARCHY',
  SetAvailableMetricDescriptions = 'SET_AVAILABLE_METRIC_DESCRIPTIONS',
  SetAvailableThinViews = 'SET_AVAILABLE_THIN_VIEWS',
  SetEditingLayout = 'SET_EDITING_LAYOUT',
  SelectWidget = 'SELECT_WIDGET',
  SetExpandedWidget = 'SET_EXPANDED_WIDGET',
  SetFavoriteViews = 'SET_FAVORITE_VIEWS',
  SetPartners = 'SET_PARTNERS',
  SetPeriodChooserOpen = 'SET_PERIOD_CHOOSER_OPEN',
  SetUserMenuOpen = 'SET_USER_MENU_OPEN',
  SetGlobalCreateMenuOpen = 'SET_GLOBAL_CREATE_MENU_OPEN',
  SetLoginRedirectUrl = 'SET_LOGIN_REDIRECT_URL',
  SetOriginalWidgets = 'SET_ORIGINAL_WIDGETS',
  SetProposedWidgets = 'SET_PROPOSED_WIDGETS',
  SetViewLinks = 'SET_VIEW_LINKS',
  SetViewMenuOpen = 'SET_VIEW_MENU_OPEN',
  SetViewFavoriteStatus = 'SET_VIEW_FAVORITE_STATUS',
  SetViewShareRequest = 'SET_VIEW_SHARE_REQUEST',
  SetShareScreenshotRequest = 'SET_SHARE_SCREENSHOT_REQUEST',
  SetWidgetData = 'SET_WIDGET_DATA',
  SetProposedWidgetTreeItem = 'SET_PROPOSED_WIDGET_TREE_ITEM',
  AddWidgetToEnd = 'ADD_WIDGET',
  DeleteWidget = 'DELETE_WIDGET',
  MoveWidget = 'MOVE_WIDGET',
  MoveWidgetChild = 'MOVE_WIDGET_CHILD',
  SetWidgets = 'SET_WIDGETS',
  SetDoubleCountingPartners = 'SET_DOUBLE_COUNTING_PARTNERS',
  SetViews = 'SET_VIEWS',

  // Planning
  SetPlans = 'SET_PLANS',
  RestorePlan = 'RESTORE_DEMAND_PLAN',
  ClearPlanVersion = 'CLEAR_PLAN_VERSION',
  RestoreUnsavedPlanVersion = 'RESTORE_UNSAVED_PLAN_VERSION',
  UpdatePlanVersion = 'UPDATE_PLAN_VERSION',
  SetLastSavedPlanVersion = 'SET_LAST_SAVED_PLAN_VERSION',
  SetActivePlanDiff = 'SET_ACTIVE_PLAN_DIFF',
  SetPendingPlanDiff = 'SET_PENDING_PLAN_DIFF',
  SetDeletedManualAdjustmentsSinceLastUpdate = 'SET_DELETED_MANUAL_ADJUSTMENTS_SINCE_LAST_UPDATE',
  ClearDeletedManualAdjustmentsSinceLastUpdate = 'CLEAR_DELETED_MANUAL_ADJUSTMENTS_SINCE_LAST_UPDATE',
  SetPlanSettings = 'SET_PLAN_SETTINGS',
  SetPlanViewFilterType = 'SET_PLAN_VIEW_FILTER_TYPE',
  SetPendingNpiStatus = 'SET_PENDING_NPI_STATUS',
  ClearPendingNpiStatuses = 'CLEAR_PENDING_NPI_STATUSES',
  FetchDisabledForecastTypes = 'FETCH_DISABLED_FORECAST_TYPES',
  SetForecastPageSelectionPath = 'SET_FORECAST_PAGE_SELECTION_PATH',
  ClearForecastsPageUnsavedChanges = 'CLEAR_FORECASTS_PAGE_UNSAVED_CHANGES',
  SetSelectedPlanningPath = 'SET_SELECTED_PLANNING_PATH',
  FetchPlanDemandSources = 'SET_PLAN_DEMAND_SOURCES',
  FetchDiscontinuedSkus = 'SET_DISCONTINUED_SKUS',
  SetIsViewingDirectImportSecondaryProduct = 'SET_IS_VIEWING_DIRECT_IMPORT_SECONDARY_PRODUCT',
  SetRefreshNeeded = 'SET_REFRESH_NEEDED',

  // New Product Introduction
  SetNewProductSettings = 'SET_NEW_PRODUCT_SETTINGS',
  FetchNewProductIntroductions = 'FETCH_NEW_PRODUCTS',
  UpdateNewProductIntroduction = 'UPDATE_NEW_PRODUCT',
  UpdateNewProductIntroductions = 'UPDATE_NEW_PRODUCTS',
  SetNpiModalOpen = 'SET_NEW_PRODUCT_MODAL_OPEN',

  // Analysis config
  ClearSelectionFilters = 'CLEAR_SELECTION_FILTERS',
  SetSelectedCalendarDate = 'SET_SELECTED_CALENDAR_DATE',
  SetDefaultEvaluationPeriod = 'SET_DEFAULT_EVALUATION_PERIOD',
  SetUnitConversionAttribute = 'SET_UNIT_CONVERSION_ATTRIBUTE',
  SetDashboardCurrency = 'SET_DASHBOARD_CURRENCY',
  SetEvaluationDate = 'SET_EVALUATION_DATE',
  SetComputeEvaluationDateTime = 'SET_COMPUTE_EVALUATION_DATE_TIME',
  SetGlobalFilters = 'SET_GLOBAL_FILTERS',
  SetSelectionFiltersForWidget = 'SET_SELECTION_FILTERS_FOR_WIDGET',
  SetEditingWidget = 'SET_EDITING_WIDGET',
  SetEditingWidgetSubscription = 'SET_EDITING_WIDGET_SUBSCRIPTION',
  SetViewWidgetFilesIndex = 'SET_VIEW_WIDGET_FILES_INDEX',
  SetWidgetConfig = 'SET_WIDGET_CONFIG',
  SetWidgetConfigWithoutUpdate = 'SET_WIDGET_CONFIG_WITHOUT_UPDATE',

  // Attribute Master
  SetAttributeMasterConfig = 'SET_ATTRIBUTE_MASTER_CONFIG',
  AddAssignableValue = 'ADD_ASSIGNABLE_VALUE',
  SetAssignableValues = 'SET_ASSIGNABLE_VALUES',
  SetRecommendedFilterAttributes = 'SET_RECOMMENDED_FILTER_ATTRIBUTES',
  SetRecommendedAttributeFilterValues = 'SET_RECOMMENDED_ATTRIBUTE_FILTER_VALUES',

  // Calendars
  RefreshCalendarEventWidgets = 'REFRESH_CALENDAR_EVENT_WIDGETS',
  SetAvailableCalendarEvents = 'SET_AVAILABLE_CALENDAR_EVENTS',

  // Map data
  SetLocationData = 'SET_LOCATION_DATA',
  SetMapPostalData = 'SET_MAP_POSTAL_DATA',
  SetMapStateData = 'SET_MAP_STATE_DATA',
  SetAvailableMapDatasets = 'SET_AVAILABLE_MAP_DATASETS',

  // Metric Snapshots
  SetMetricSnapshotMetadata = 'SET_METRIC_SNAPSHOT_METADATA',

  // Data Ranges
  SetEdgeDataRanges = 'SET_EDGE_DATA_RANGES',

  // Groups and experiments
  SetGroups = 'SET_GROUPS',
  SetExperiments = 'SET_EXPERIMENTS',
  AddGroup = 'ADD_GROUP',
  SetExperiment = 'SET_EXPERIMENT',
  DeleteExperiment = 'DELETE_EXPERIMENT',
  SetExperimentVisitedTimestampsForUser = 'SET_EXPERIMENT_VISITED_TIMESTAMPS_FOR_USER',
  SetExperimentVisitedTimestampsForVendor = 'SET_EXPERIMENT_VISITED_TIMESTAMPS_FOR_VENDOR',
  SetExperimentVisitedTime = 'SET_EXPERIMENT_VISITED_TIME',
  SetSelectedExperiment = 'SET_SELECTED_EXPERIMENT',
  SetFavoriteExperimentIds = 'SET_FAVORITE_EXPERIMENT_IDS',
  SetExperimentFavoriteStatus = 'SET_EXPERIMENT_FAVORITE_STATUS',
  SetExperimentBreakdownDisplayMode = 'SET_EXPERIMENT_BREAKDOWN_DISPLAY_MODE',
  SetExperimentChartDisplayMode = 'SET_EXPERIMENT_CHART_DISPLAY_MODE',
  SetExperimentTimeDisplayMode = 'SET_EXPERIMENT_TIME_DISPLAY_MODE',

  // Settings: Credentials
  SaveCredentials = 'SAVE_CREDENTIALS',
  DeleteCredentials = 'DELETE_CREDENTIALS',
  SetCredentials = 'SET_CREDENTIALS',
  SetCredentialValidityStatus = 'SET_CREDENTIAL_VALIDITY_STATUS',

  // Settings: Tags
  DeleteTag = 'DELETE_TAG',
  SaveTag = 'SAVE_TAG',

  // Settings: Users
  DeleteUser = 'DELETE_USER',
  SetSavedUser = 'SET_SAVED_USER',
  SetSavedUserInfo = 'SET_SAVED_USER_INFO',
  SetAllUserData = 'SET_ALL_USER_DATA',
  SetAllUserInfoData = 'SET_ALL_USER_INFO_DATA',
  SetSavedFeatureFlags = 'SET_SAVED_FEATURE_FLAGS',
  SetAllFeaturesByUser = 'SET_ALL_FEATURES_BY_USER',
  SetAvailableForecastsByMetricName = 'SET_AVAILABLE_FORECASTS_BY_PARTNER_AND_FORECASTED_METRIC_ID',

  // Settings: Files
  SetUsedFileTypes = 'SET_USED_FILETYPES',
  SetFileClassificationPatterns = 'SET_FILE_CLASSIFICATION_PATTERNS',

  // Settings: Policies
  SetPolicyData = 'SET_POLICY_DATA',

  // Navigation
  SetDashboardManagementFilterText = 'SET_DASHBOARD_MANAGEMENT_FILTER_TEXT',
  SetEventManagementFilterText = 'SET_EVENT_MANAGEMENT_FILTER_TEXT',
  SetDestinationManagementFilterText = 'SET_DESTINATION_MANAGEMENT_FILTER_TEXT',
  SetIntegrationsPageFilterText = 'SET_INTEGRATIONS_PAGE_FILTER_TEXT',

  SetDashboardManagementActiveFilter = 'SET_DASHBOARD_MANAGEMENT_ACTIVE_FILTER',
  SetDashboardManagementTableDateSortType = 'SET_DASHBOARD_MANAGEMENT_DATE_SORT_TYPE',
  SetDashboardManagementTableSortModel = 'SET_DASHBOARD_MANAGEMENT_TABLE_SORT_MODEL',
  SetGlobalSearchFilterText = 'SET_GLOBAL_SEARCH_FILTER_TEXT',
  SetGlobalSearchSelectedTags = 'SET_GLOBAL_SEARCH_SELECTED_TAGS',
  SetEventManagementActiveFilter = 'SET_EVENT_MANAGEMENT_ACTIVE_FILTER',
  SetEventManagementTableSortModel = 'SET_EVENT_MANAGEMENT_TABLE_SORT_MODEL',
  SetDestinationManagementActiveFilter = 'SET_DESTINATION_MANAGEMENT_ACTIVE_FILTER',
  SetDestinationManagementTableSortModel = 'SET_SUBSCRIPTION_MANAGEMENT_TABLE_SORT_MODEL',
  SetIntegrationsPageCountriesFilter = 'SET_INTEGRATIONS_PAGE_COUNTRIES_FILTER',
  SetIntegrationsPageTypesFilter = 'SET_INTEGRATIONS_PAGE_TYPES_FILTER',
  SetIntegrationsPageTagsFilter = 'SET_INTEGRATIONS_PAGE_TAGS_FILTER',
  SetIntegrationsPageScrollPosition = 'SET_INTEGRATIONS_PAGE_SCROLL_POSITION',
  SetPlanBreadcrumb = 'SET_PLAN_BREADCRUMB',
  SetReturnTo = 'SET_RETURN_TO',
  SetNavigationInProgress = 'SET_NAVIGATION_IN_PROGRESS',

  // Alloy Assistant
  SetAssistantSidebarOpenState = 'SET_ASK_ALLOY_SIDEBAR_OPEN',
  SetAssistantSession = 'SET_ASK_ALLOY_SESSION',
  SetActiveAssistantSession = 'SET_ACTIVE_ASK_ALLOY_SESSION',
  StartNewAssistantSession = 'START_NEW_ASK_ALLOY_SESSION',
  SetLoadingAssistantResponse = 'SET_LOADING_ASK_ALLOY_RESPONSE',
}
export default ActionType;
