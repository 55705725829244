import {LOCATION_CHANGE, LocationChangeAction} from 'connected-react-router';
import {Middleware, MiddlewareAPI} from 'redux';

import {VendorLocationChangeAction} from 'app/router-types';
import {Dispatch, RootState} from 'redux/reducers';

const reduxVendorMiddleware: Middleware = (api: MiddlewareAPI<Dispatch, RootState>) => {
  return (dispatch: Dispatch) => (action: LocationChangeAction) => {
    if (action.type === LOCATION_CHANGE) {
      const locationChangeAction: VendorLocationChangeAction = {
        ...action,
        analysisSettings: api.getState().user.settings?.analysisSettings ?? undefined,
        vendor: api.getState().user.vendor ?? undefined,
      };
      return dispatch(locationChangeAction);
    }
    return dispatch(action);
  };
};
export default reduxVendorMiddleware;
