import {Map, Set} from 'immutable';
import moment from 'moment-timezone';

import * as Types from 'types';

import {DatePeriodType} from './types';

export const DEFAULT_PERIOD: Types.SimpleDatePeriod = {
  amount: 1,
  unit: Types.CalendarUnit.WEEKS,
  type: 'lastn',
};

export const DEFAULT_FORECAST_PERIOD: Types.NextNDatePeriod = {
  amount: 13,
  unit: Types.CalendarUnit.WEEKS,
  type: 'nextn',
};

export const DEFAULT_TIME_AGO_PERIOD: Types.SimplePeriod = {
  amount: 1,
  unit: Types.CalendarUnit.YEARS,
};

export const DEFAULT_HISTORICAL_PERIOD: Types.SimplePeriod = {
  amount: 4,
  unit: Types.CalendarUnit.WEEKS,
};

export const TODAY_PERIOD: Types.DatePeriod = {
  type: 'future',
  amount: 0,
  unit: Types.CalendarUnit.DAYS,
};

export const pastPeriodTypes = Set.of<DatePeriodType>(
  'fixed',
  'fixed_to_now',
  'trailing',
  'todate',
  'todate_weekend',
  'previous',
  'lastn',
  'complex'
);

export const frequencyLabels = Map<Types.SubscriptionFrequency, string>({
  DAILY: 'Daily',
  FRIDAY: 'Friday',
  MONDAY: 'Monday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
  THURSDAY: 'Thursday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
} as any);

export const simplePastPeriodTypes = pastPeriodTypes.remove('complex');

export const futurePeriodTypes = Set.of<DatePeriodType>('nextn', 'future', 'now_to_fixed', 'to_go');

export const allPeriodTypes = pastPeriodTypes.union(futurePeriodTypes);

export const defaultWeekFormat = Types.WeekFormat.START_OF_WEEK;
export const defaultZone = moment.tz.guess();
export const defaultCalendar: Types.RetailCalendarEnum = Types.RetailCalendarEnum.ISO8601;
