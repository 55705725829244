import {Map} from 'immutable';

import {isLoading, isFail} from 'redux/actions/helpers';
import {PoliciesAction} from 'redux/actions/policies';
import ActionType from 'redux/actions/types';
import * as Types from 'types';

export interface PoliciesState {
  readonly policiesByUser: Map<number, readonly Types.AttributeFilter[]> | null;
  readonly isFetchingPolicyData: boolean;
}

const initialPolicyState: PoliciesState = {
  isFetchingPolicyData: false,
  policiesByUser: null,
};

export default function policies(
  state = initialPolicyState,
  action: PoliciesAction
): PoliciesState {
  if (action.type === ActionType.SetPolicyData) {
    if (isLoading(action)) {
      return {...state, isFetchingPolicyData: true};
    } else if (isFail(action)) {
      return {...state, isFetchingPolicyData: false};
    }
    return {
      ...state,
      isFetchingPolicyData: false,
      policiesByUser: Map(action.data!.map(entry => [entry.userId, entry.filters])),
    };
  }
  return state;
}
