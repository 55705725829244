import React from 'react';

/** Since table cells are layed out via flex by default, cell renderers
 * that require block / inline layout (e.g. view links, which are styled
 * as regular anchors) need to add a wrapper element. A plain ol' div
 * is all that's required, but having this dedicated component serves
 * as documentation of the div's purpose.
 */
const BlockLayoutCell: React.FunctionComponent<{children: React.ReactNode}> = ({children}) => {
  return <div>{children}</div>;
};

export default BlockLayoutCell;
