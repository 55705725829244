import React, {useState, useEffect, useRef} from 'react';
import {useDebounce} from 'use-debounce';

import usePrevious from 'toolkit/utils/usePrevious';

const DebounceInput = React.forwardRef(
  (
    {onChange, value = '', debounceDelay = 300, ...rest}: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const [searchText, setSearchText] = useState(value || '');
    const [debouncedText] = useDebounce(searchText, debounceDelay!);

    const lastOnChangeValue = useRef<string | null>(null);
    // ugly hack to not call onChange when the component mounts
    const didMountRef = useRef(false);
    useEffect(() => {
      if (didMountRef.current) {
        onChange(debouncedText);
        lastOnChangeValue.current = debouncedText;
      } else {
        didMountRef.current = true;
      }
    }, [debouncedText, onChange, didMountRef]);

    const prevValue = usePrevious(value);
    useEffect(() => {
      if (prevValue !== value && value !== lastOnChangeValue.current) {
        setSearchText(value || '');
      }
    }, [prevValue, value]);

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value || '');
    };

    return <input ref={ref} type="search" {...rest} value={searchText} onChange={onInputChange} />;
  }
);

DebounceInput.displayName = 'DebounceInput';
export type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> & {
  debounceDelay?: number;
  onChange: (inputText: string) => void;
  value?: string;
};

export default DebounceInput;
