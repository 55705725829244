import * as Types from 'types';

export const ACTUALS_PERIOD_WEEKLY: Types.SimpleDatePeriod = {
  type: 'lastn',
  amount: 52,
  unit: Types.CalendarUnit.WEEKS,
};

export const FORECAST_PERIOD_WEEKLY: Types.SimpleDatePeriod = {
  type: 'nextn',
  amount: 52,
  unit: Types.CalendarUnit.WEEKS,
};

export const ACTUALS_PERIOD_MONTHLY: Types.SimpleDatePeriod = {
  type: 'lastn',
  amount: 12,
  unit: Types.CalendarUnit.MONTHS,
};

export const FORECAST_PERIOD_MONTHLY: Types.SimpleDatePeriod = {
  type: 'nextn',
  amount: 12,
  unit: Types.CalendarUnit.MONTHS,
};

export const CHART_PERIOD_WEEKLY: Types.DatePeriod = {
  type: 'complex',
  startPeriod: {
    type: 'lastn',
    amount: 4,
    unit: Types.CalendarUnit.WEEKS,
  },
  endPeriod: {
    type: 'nextn',
    amount: 48,
    unit: Types.CalendarUnit.WEEKS,
  },
};

export const CHART_PERIOD_MONTHLY: Types.DatePeriod = {
  type: 'complex',
  startPeriod: {
    type: 'lastn',
    amount: 4,
    unit: Types.CalendarUnit.MONTHS,
  },
  endPeriod: {
    type: 'nextn',
    amount: 12,
    unit: Types.CalendarUnit.MONTHS,
  },
};
