import {CurrentUser} from 'redux/reducers/user';
import {MetricDirection} from 'toolkit/metrics/types';
import * as Types from 'types';

export enum ComparisonMetricType {
  NONE = 'NONE',
  ABSOLUTE = 'ABSOLUTE',
  PERCENT = 'PERCENT',
  CONTRIBUTION = 'CONTRIBUTION',
}

export interface ArgumentSelectProps {
  readonly currentUser: CurrentUser;
  readonly metricInstance?: Types.MetricInstance;
  readonly planSettings: Types.PlanSettings | null;
}

// TODO: consider retrieving these options from the API. Duplicating them here requires us to
// keep the client up to date with the server, but also allows us to hide options that may
// not be applicable in certain circumstances.

// The props is marked optional to allow functions without props arguments,
// but if props is used then it can be assumed to be present
export type ArgumentSelectOptionCreator<T> = (props?: ArgumentSelectProps) => readonly T[];
export interface ArgumentSelectOptions {
  dollarType: ArgumentSelectOptionCreator<Types.DollarType>;
  errorMetric: ArgumentSelectOptionCreator<Types.ErrorMetric>;
  forecastType: ArgumentSelectOptionCreator<Types.ForecastType>;
  forecastComposition: ArgumentSelectOptionCreator<Types.ForecastComposition>;
  granularity: ArgumentSelectOptionCreator<Types.CalendarUnit>;
  inventoryMeasure: ArgumentSelectOptionCreator<Types.InventoryMeasure>;
  inventoryTypes: ArgumentSelectOptionCreator<Types.InventoryType>;
  returnsCountingMethod: ArgumentSelectOptionCreator<Types.ReturnsCountingMethod>;
  stockAggregator: ArgumentSelectOptionCreator<Types.StockAggregator>;
  timeUnit: ArgumentSelectOptionCreator<Types.CalendarUnit.DAYS | Types.CalendarUnit.WEEKS>;
  salesType: ArgumentSelectOptionCreator<Types.SalesType>;
  storeType: ArgumentSelectOptionCreator<Types.StoreType>;
  perspective: ArgumentSelectOptionCreator<Types.GraphPerspective>;
  inflowTypes: ArgumentSelectOptionCreator<Types.InflowType>;
  outflowTypes: ArgumentSelectOptionCreator<Types.OutflowType>;
  outOfStockCalculationMethod: ArgumentSelectOptionCreator<Types.OutOfStockCalculationMethod>;
}

export const PEWTER_COMPUTED_FORECAST_TYPES: readonly Types.ForecastType[] = [
  Types.ForecastType.ANNUAL_GROWTH,
  Types.ForecastType.HISTORICAL_AVERAGE,
];

export interface MetricInstanceFlags {
  readonly showAsPercentOfGrouping: boolean;
  readonly showAsPercentOfTotal: boolean;
  readonly showCumulative: boolean;
  readonly showPerLocation: boolean;
  readonly showErrorMetric: boolean;
  readonly useGranularity: boolean;
  readonly useUnitConversion: boolean;
  readonly useTimeShift: boolean;
  readonly compsMetricType: ComparisonMetricType;
}

export interface MetricInstanceProposedData {
  readonly effectiveMetric?: Types.Metric;
  readonly flags?: MetricInstanceFlags;
  readonly statisticalAggregator?: string;
  readonly forecastType?: Types.ForecastType;
  readonly perspective?: Types.GraphPerspective;
  readonly direction?: MetricDirection;
  readonly demandSignalPerspective?: Types.GraphPerspective;
  readonly instanceArguments?: Types.MetricArguments;
}

export const COMPARISON_METRIC_NAMES: readonly string[] = [
  'metric_comps_absolute',
  'metric_comps_percent',
  'contribution',
];
