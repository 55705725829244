import {EnvironmentAction} from 'redux/actions/environment';
import ActionType from 'redux/actions/types';
import * as Types from 'types';

export interface EnvironmentState {
  readonly environment: Types.Environment;
}

const initialEnvironmentState: EnvironmentState = {
  environment: {
    environmentName: '',
    subEnvironmentName: '',
    gcpProject: '',
    intercomAppId: '',
    mapboxToken: '',
    mapboxUser: '',
    heapAppId: '',
    sentryDsnAmalgam: '',
    version: '',
    zendeskWidgetKey: '',
  },
};

export default function environment(
  state = initialEnvironmentState,
  action: EnvironmentAction
): EnvironmentState {
  if (action.type === ActionType.SetEnvironment) {
    return {
      ...state,
      environment: action.environment,
    };
  }
  return state;
}
