import ActionType from 'redux/actions/types';

export type AjaxAction =
  | {readonly type: ActionType.IncrementActiveRequestCount}
  | {readonly type: ActionType.DecrementActiveRequestCount}
  | {readonly type: ActionType.SetEndToEndTestBuildUrl; buildUrl: string}
  | {readonly type: ActionType.ToggleCacheInspectorVisible};

export function incrementActiveRequestCount(): AjaxAction {
  return {
    type: ActionType.IncrementActiveRequestCount,
  };
}

export function decrementActiveRequestCount(): AjaxAction {
  return {
    type: ActionType.DecrementActiveRequestCount,
  };
}

export function setEndToEndTestBuildUrl(buildUrl: string): AjaxAction {
  return {
    buildUrl,
    type: ActionType.SetEndToEndTestBuildUrl,
  };
}

export function toggleCacheInspectorVisible(): AjaxAction {
  return {
    type: ActionType.ToggleCacheInspectorVisible,
  };
}
