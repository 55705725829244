import './CanvasBlock.scss';

import classNames from 'classnames';
import React from 'react';

const CanvasBlock = React.forwardRef(
  (
    {
      children,
      className,
      gapped = false,
      padded = false,
      bordered = true,
      rounded = true,
      showOverflow = false,
      ...rest
    }: Props,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        className={classNames(
          'CanvasBlock',
          {gapped, padded, bordered, rounded, 'show-overflow': showOverflow},
          className
        )}
        {...rest}
      >
        {children}
      </div>
    );
  }
);

CanvasBlock.displayName = 'CanvasBlock';
type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  gapped?: boolean;
  padded?: boolean;
  bordered?: boolean;
  rounded?: boolean;
  showOverflow?: boolean;
};

export default CanvasBlock;
