import React from 'react';

import Loader from 'toolkit/components/Loader';

const Suspenseful: React.FunctionComponent<Props> = props => {
  return <React.Suspense fallback={props.fallback || <Loader />}>{props.children}</React.Suspense>;
};
interface Props {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}
export default Suspenseful;

const defaultLoader = () => <Loader />;

export function withSuspense<P extends object>({
  component,
  fallback = defaultLoader,
}: {
  component: React.ComponentType<P>;
  fallback?: React.ComponentType<P>;
}) {
  const HocSuspensefulWidget: React.FunctionComponent<P> = props => {
    return (
      <React.Suspense fallback={React.createElement(fallback, props)}>
        {React.createElement(component, props)}
      </React.Suspense>
    );
  };
  return HocSuspensefulWidget;
}
