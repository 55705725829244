import {Map} from 'immutable';

import {GroupsAction} from 'redux/actions/groups';
import {isLoading, isFail} from 'redux/actions/helpers';
import ActionType from 'redux/actions/types';
import {ExperimentState} from 'toolkit/groups/types';
import {defaultExperimentState} from 'toolkit/groups/utils';
import * as Types from 'types';
import {replaceAt} from 'utils/arrays';
import {Status} from 'utils/status';

export interface GroupsState {
  readonly experiments: readonly Types.Experiment[];
  readonly experimentsFetchStatus: Status;
  readonly experimentStateById: Map<number | null, ExperimentState>;
  readonly favoriteExperimentIds: readonly number[];
  readonly groups: readonly Types.Group[];
  readonly groupsFetchStatus: Status;
  readonly proposedExperiment: Types.Experiment | null;
  readonly visitedTimestampsForUser: Map<number, string>;
  readonly visitedTimestampsForVendor: Map<number, string>;
}

const initialState: GroupsState = {
  experiments: [],
  experimentsFetchStatus: Status.unstarted,
  experimentStateById: Map(),
  favoriteExperimentIds: [],
  groups: [],
  groupsFetchStatus: Status.unstarted,
  proposedExperiment: null,
  visitedTimestampsForUser: Map(),
  visitedTimestampsForVendor: Map(),
};

export default function groups(state = initialState, action: GroupsAction): GroupsState {
  if (action.type === ActionType.SetGroups) {
    if (isLoading(action)) {
      return {...state, groupsFetchStatus: Status.inProgress};
    } else if (isFail(action)) {
      return {...state, groupsFetchStatus: Status.failed};
    }
    return {
      ...state,
      groups: action.data!,
      groupsFetchStatus: Status.succeeded,
    };
  } else if (action.type === ActionType.SetExperiments) {
    if (isLoading(action)) {
      return {...state, experimentsFetchStatus: Status.inProgress};
    } else if (isFail(action)) {
      return {...state, experimentsFetchStatus: Status.failed};
    }
    return {
      ...state,
      experiments: action.data!,
      experimentsFetchStatus: Status.succeeded,
    };
  } else if (action.type === ActionType.AddGroup) {
    return {...state, groups: [...state.groups, action.group]};
  } else if (action.type === ActionType.SetExperiment) {
    const index = state.experiments.findIndex(experiment => experiment.id === action.experiment.id);
    const experiments =
      index === -1
        ? [...state.experiments, action.experiment]
        : replaceAt(state.experiments, index, action.experiment);
    return {...state, experiments};
  } else if (action.type === ActionType.DeleteExperiment) {
    return {
      ...state,
      experiments: state.experiments.filter(item => item.id !== action.experimentId),
      visitedTimestampsForUser: state.visitedTimestampsForUser.delete(action.experimentId),
    };
  } else if (action.type === ActionType.SetExperimentVisitedTimestampsForUser) {
    return {
      ...state,
      visitedTimestampsForUser: Map<number, string>(
        action.timestamps.map<[number, string]>(item => [item.entityId, item.visited])
      ),
    };
  } else if (action.type === ActionType.SetExperimentVisitedTimestampsForVendor) {
    return {
      ...state,
      visitedTimestampsForVendor: Map<number, string>(
        action.timestamps.map<[number, string]>(item => [item.entityId, item.visited])
      ),
    };
  } else if (action.type === ActionType.SetExperimentVisitedTime) {
    return {
      ...state,
      visitedTimestampsForUser: state.visitedTimestampsForUser.set(
        action.experimentId,
        action.time
      ),
      visitedTimestampsForVendor: state.visitedTimestampsForVendor.set(
        action.experimentId,
        action.time
      ),
    };
  } else if (action.type === ActionType.SetFavoriteExperimentIds) {
    if (!isLoading(action) && !isFail(action)) {
      return {
        ...state,
        favoriteExperimentIds: action.data!,
      };
    }
  } else if (action.type === ActionType.SetExperimentFavoriteStatus) {
    if (!isLoading(action) && !isFail(action)) {
      const otherFavorites = state.favoriteExperimentIds.filter(id => id !== action.experimentId);
      return {
        ...state,
        favoriteExperimentIds: action.isFavorite
          ? [...otherFavorites, action.experimentId]
          : otherFavorites,
      };
    }
  } else if (action.type === ActionType.SaveTag) {
    return action.previousTag
      ? {
          ...state,
          experiments: state.experiments.map(experiment => ({
            ...experiment,
            tags: experiment.tags.map(tag =>
              tag === action.previousTag!.name ? action.tagToSave.name : tag
            ),
          })),
        }
      : state;
  } else if (action.type === ActionType.DeleteTag) {
    return {
      ...state,
      experiments: state.experiments.map(experiment => ({
        ...experiment,
        tags: experiment.tags.filter(tag => tag !== action.tag.name),
      })),
    };
  } else if (action.type === ActionType.SetExperimentBreakdownDisplayMode) {
    const experimentState: ExperimentState =
      state.experimentStateById.get(action.experimentId) || defaultExperimentState;
    return {
      ...state,
      experimentStateById: state.experimentStateById.set(action.experimentId, {
        ...experimentState,
        breakdownDisplayMode: action.displayMode,
      }),
    };
  } else if (action.type === ActionType.SetExperimentChartDisplayMode) {
    const experimentState: ExperimentState =
      state.experimentStateById.get(action.experimentId) || defaultExperimentState;
    return {
      ...state,
      experimentStateById: state.experimentStateById.set(action.experimentId, {
        ...experimentState,
        chartDisplayMode: action.displayMode,
      }),
    };
  } else if (action.type === ActionType.SetExperimentTimeDisplayMode) {
    const experimentState: ExperimentState =
      state.experimentStateById.get(action.experimentId) || defaultExperimentState;
    return {
      ...state,
      experimentStateById: state.experimentStateById.set(action.experimentId, {
        ...experimentState,
        timeDisplayMode: action.displayMode,
      }),
    };
  } else if (action.type === ActionType.SetSelectedExperiment) {
    return {...state, proposedExperiment: action.experiment};
  }
  return state;
}
