type NodeEnv = 'production' | 'development' | 'test';

declare global {
  interface Window {
    __ALLOY_API_URL__: string;
    __ALLOY_DEV__: boolean;
    __ALLOY_NODE_ENV__: NodeEnv;
    __ALLOY_VERSION__: string;
    __ALLOY_PROFILING__: boolean;
  }
}

export const apiUrl: string = window.__ALLOY_API_URL__ ?? '';
export const dev: boolean = window.__ALLOY_DEV__ ?? false;
export const nodeEnv: NodeEnv = window.__ALLOY_NODE_ENV__ ?? 'production';
export const version: string = window.__ALLOY_VERSION__ ?? 'unknown';
export const profiling: boolean = window.__ALLOY_PROFILING__ ?? false;

export const usingDevApi = dev && apiUrl.includes('alloy.test');
// on local environments, Jersey fails to parse the request size if it's larger than a 32-bit Int and errors out
// on environments deployed with Istio, the max request size is 25MB as defined in istio-filters.yaml
export const maxRequestSize: number = usingDevApi ? 2147483647 : 25000000;

export const appContainerElementId = 'app';
