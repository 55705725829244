import './Icon.scss';

import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, {CSSProperties, useMemo} from 'react';

import {isAlloyIcon, isBase64PngIcon} from 'toolkit/icons/font-awesome';
import {IconSpec} from 'toolkit/icons/types';

const Icon: React.FC<IconProps> = ({
  className,
  disabled,
  fixedWidth,
  icon,
  label,
  onClick,
  spin,
  style,
}) => {
  const [iconContent, isFontAwesomeIcon]: [string | null, false] | [IconProp, true] =
    useMemo(() => {
      if (!icon) {
        return [null, false];
      } else if (isAlloyIcon(icon)) {
        return [icon.alloyIcon, false];
      } else if (isBase64PngIcon(icon)) {
        return [`data:image/png;base64,${icon.base64}`, false];
      }
      return [icon, true];
    }, [icon]);
  if (!iconContent) {
    return null;
  }
  if (!isFontAwesomeIcon) {
    return (
      <img
        alt={label}
        className={classNames('Icon', className, {'fa-spin': spin}, {disabled})}
        draggable={false}
        src={iconContent}
        style={style}
        onClick={onClick}
      />
    );
  }
  return (
    <FontAwesomeIcon
      className={classNames('Icon', className)}
      fixedWidth={fixedWidth}
      icon={iconContent}
      spin={spin}
      style={style}
      onClick={onClick}
    />
  );
};
Icon.displayName = 'Icon';
export interface IconProps {
  spin?: boolean;
  className?: string;
  disabled?: boolean;
  icon?: IconSpec | null;
  fixedWidth?: boolean;
  onClick?: (event: React.MouseEvent<unknown>) => void;
  label?: string;
  style?: CSSProperties;
}
export default Icon;
