import {Record as ImmutableRecord} from 'immutable';

/**
 * In immutable records, all fields must have a default value. For many fields, this isn't appropriate,
 * leading us to use `null` for many fields that would ideally be required in the constructor instead.
 *
 * This is fixed by declaring our own version of record that makes these fields required in the
 * constructor and returns a record that doesn't make the fields in question nullable.
 */
export default function Record<T extends object>() {
  return <DefaultValues extends {[K in keyof Required<T>]: T[K] | null}>(
    defaultValues: DefaultValues
  ) => {
    type FieldsMadeOptional = {
      [K in keyof Required<T>]: null extends DefaultValues[K] ? (null extends T[K] ? K : never) : K;
    }[keyof T];

    type RequiredConstructorArguments = {[K in keyof Omit<T, FieldsMadeOptional>]: T[K]};
    type ConstructorArguments = RequiredConstructorArguments & {[K in FieldsMadeOptional]?: T[K]};

    type RecordType = {[K in keyof T]-?: undefined extends T[K] ? T[K] | null : T[K]};
    type CreatedRecord = ImmutableRecord<RecordType> & Readonly<RecordType>;
    interface Factory {
      (values: ConstructorArguments): CreatedRecord;
      new (values: ConstructorArguments): CreatedRecord;
    }
    interface HasAllDefaultsFactory {
      (values?: ConstructorArguments): CreatedRecord;
      new (values?: ConstructorArguments): CreatedRecord;
    }

    return ImmutableRecord(defaultValues) as unknown as RequiredConstructorArguments extends object
      ? HasAllDefaultsFactory
      : Factory;
  };
}

// -------------------- Tests that this works as expected --------------------------
// Note: the expected behaviour here is with strictNullChecks enabled

// interface ITestRecord {
//   required: string;
//   requiredWithDefault: string;
//   optional?: string;
//   nullable: string | null;
// }
//
// class TestRecord extends Record<ITestRecord>()({
//   required: null,
//   requiredWithDefault: '',
//   optional: null,
//   nullable: null,
// }) {}
//
// new TestRecord({}); // should error, missing required field
// const record = new TestRecord({required: 'test'}); // should succeed
// record.nullable.toString(); // should fail, nullable can be null
// record.required.toString(); // should succeed, required can never be null
// record.fieldThatDoesNotExist; // should fail
//
// interface AllOptional {
//   field?: string;
// }
// Record<AllOptional>()({}); // should fail, default is required
// class EmptyRecord extends Record<AllOptional>()({field: null}) {}
//
// const empty = new EmptyRecord(); // should succeed, all values are optional
// empty.field.toString(); // should fail, can be null
