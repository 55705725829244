import {isTruthy} from './functions';

export function matchesSearchQuery(text: string | null | undefined, query = ''): boolean {
  const re = new RegExp(
    `.*${Array.from(query)
      .map(char => char.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')) // Escape regex characters.
      .join('.*')}.*`,
    'i'
  );
  return re.test(text ?? '');
}

export function toCamelCaseShorthand(text: string): string {
  return text
    .split(/[_\- ]/)
    .filter(isTruthy)
    .map(item => item[0].replace(/[^a-zA-Z]*/, ''))
    .join('')
    .toUpperCase();
}
