export enum CalendarEventStatus {
  UPCOMING = 'UPCOMING',
  IN_PROGRESS = 'IN PROGRESS',
  COMPLETED = 'COMPLETED',
}

export interface EventUrlParams {
  readonly vendor: string;
  readonly eventId?: string;
}
