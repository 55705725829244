import './Loader.scss';

import classNames from 'classnames';
import React from 'react';

import {Status} from 'utils/status';

import ErrorPlaceholder from './ErrorPlaceholder';

const Loader: React.FC<LoaderProps> = ({
  status = Status.inProgress,
  children,
  className,
  hideLabel = false,
}) => {
  switch (status) {
    case Status.succeeded:
      return <>{children}</>;
    case Status.failed:
      return <ErrorPlaceholder />;
    case Status.unstarted:
    case Status.inProgress:
    default:
      return <div className={classNames('Loader', className, {'with-label': !hideLabel})} />;
  }
};

Loader.displayName = 'Loader';
export interface LoaderProps {
  className?: string;
  children?: React.ReactNode;
  hideLabel?: boolean;
  status?: Status;
}
export default Loader;
