import {MetricSnapshotsAction} from 'redux/actions/metric-snapshots';
import ActionType from 'redux/actions/types';
import * as Types from 'types';

export interface MetricSnapshotsState {
  readonly metricSnapshotMetadata: readonly Types.MetricSnapshotMetadata[];
}

const initialMetricSnapshotState: MetricSnapshotsState = {
  metricSnapshotMetadata: [],
};

export default function metricSnapshots(
  state = initialMetricSnapshotState,
  action: MetricSnapshotsAction
): MetricSnapshotsState {
  if (action.type === ActionType.SetMetricSnapshotMetadata) {
    return {...state, metricSnapshotMetadata: action.metricSnapshotMetadata};
  }
  return state;
}
