import * as Api from 'api';
import Analytics from 'app/analytics/analytics';
import {Alert, addAlert} from 'redux/actions/alerts';
import {store} from 'redux/store/store';
import {ApiError} from 'types/error';
import {captureException} from 'utils/exceptions';

export const DEFAULT_AUTO_CLOSE_INTERVAL_MSEC = 10000;
const WARNING_AUTO_CLOSE_INTERVAL_MSEC = 15000;

export function showCustomAlert(alert: Alert) {
  store.dispatch(addAlert(alert));
}

export function showAlert(message: string) {
  store.dispatch(
    addAlert({
      autoCloseIntervalMsec: DEFAULT_AUTO_CLOSE_INTERVAL_MSEC,
      exception: null,
      id: message,
      message,
    })
  );
}

type TemplateString = {
  format: TemplateStringsArray;
  arguments: readonly string[];
};

/**
 * A tag function for template literal strings to be passed to showErrorAlert. This preserves the
 * format string so that we can use it to group logged errors.
 */
export function errorString(
  format: TemplateStringsArray,
  ...rest: readonly string[]
): TemplateString {
  return {format, arguments: rest};
}

function getMessage(message?: string | TemplateString) {
  if (!message) {
    return message;
  }
  if (typeof message === 'string') {
    return message;
  }
  return String.raw(message.format, ...message.arguments);
}

export function showErrorAlert(exception: Error | null, message?: string | TemplateString) {
  const isAlloyOutOfDate = exception?.name === 'ImportError';

  const format = (typeof message === 'string' ? message : message?.format?.join('%s')) || null;
  const exceptionMessage = exception?.message || null;

  if (exception && !isAlloyOutOfDate) {
    captureException(exception);
    Analytics.track('Show Error Alert', {
      exceptionName: exception.name,
      exceptionMessage,
      format,
      message: getMessage(message) || null,
    });
  }

  if (!(exception instanceof ApiError)) {
    Api.Errors.logError({
      stack: exception?.stack || null,
      message: getMessage(message) || null,
      exceptionMessage,
      format,
    });
  }
  const messageToShow = getMessage(message) || exception?.message || 'Sorry, an error occurred.';
  store.dispatch(
    addAlert({
      exception,
      icon: 'exclamation-triangle',
      id: `ERROR_ALERT-${messageToShow}`,
      isSingleton: true,
      message: messageToShow,
      showReloadLink: isAlloyOutOfDate,
    })
  );
}

export function showWarningAlert(message: string) {
  store.dispatch(
    addAlert({
      autoCloseIntervalMsec: WARNING_AUTO_CLOSE_INTERVAL_MSEC,
      icon: 'exclamation-triangle',
      id: 'WARNING_ALERT',
      message,
    })
  );
}

export function promptUserToReload(message: string) {
  store.dispatch(
    addAlert({
      id: 'RELOAD_PROMPT',
      isSingleton: true,
      message,
      showReloadLink: true,
    })
  );
}

export function showReloadAlert(e?: Error) {
  store.dispatch(
    addAlert({
      exception: e,
      icon: 'exclamation-triangle',
      id: 'RELOAD_ALERT',
      isSingleton: true,
      message: 'Alloy.ai has been updated and needs to be reloaded.',
      showReloadLink: true,
      showAgainDelayMsec: 30 * 60 * 1000, // 30min delay between reload banners
    })
  );

  return Promise.reject(e);
}
