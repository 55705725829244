import './SupportLink.scss';

import React from 'react';

import {SUPPORT_EMAIL} from 'toolkit/users/utils';
import {noop} from 'utils/functions';

export function openZendeskWidget() {
  if (window.zE) {
    window.zE.activate();
  }
}

const SupportLink: React.FC<Props> = ({children, onClick = noop}) => {
  return (
    <a
      className="SupportLink"
      href={window.zE ? undefined : `mailto:${SUPPORT_EMAIL}`}
      onClick={() => {
        openZendeskWidget();
        onClick();
      }}
    >
      {children}
    </a>
  );
};

SupportLink.displayName = 'SupportLink';
interface Props {
  children: React.ReactNode;
  onClick?: () => void;
}

export default SupportLink;
