import equal from 'fast-deep-equal';

import Format from 'toolkit/format/format';
import * as Types from 'types';
import {ascendingBy, descendingBy, removeAt, replaceAt, subtract} from 'utils/arrays';
import {assertTruthy} from 'utils/assert';
import {sum} from 'utils/functions';

import {getFiltersAsAttributeValues} from './utils';

export function isAddingAdjustment(newValue: number) {
  return Number.isFinite(newValue);
}

export function overrideToPath(
  override: Types.ThinUserForecastOverride | Types.ThinUserManualAdjustment
) {
  return override.attributeValues.map(value => assertTruthy(value.id));
}

export function adjustmentsApplyToSameValue(
  adjustment1: Types.ThinUserManualAdjustment,
  adjustment2: Types.ThinUserManualAdjustment
) {
  return (
    equal(overrideToPath(adjustment1), overrideToPath(adjustment2)) &&
    equal(adjustment1.metricValue.interval, adjustment2.metricValue.interval) &&
    adjustment1.metricValue.metricName === adjustment2.metricValue.metricName
  );
}

export function overridesApplyToSameValue(
  override1: Types.ThinUserForecastOverride,
  override2: Types.ThinUserForecastOverride
) {
  return equal(overrideToPath(override1), overrideToPath(override2));
}

export function findActiveForecastOverride(
  filters: readonly Types.AttributeFilter[],
  planVersion: Types.PlanVersion
) {
  const attributeValues = getFiltersAsAttributeValues(filters);
  return [...planVersion.forecastOverrides]
    .sort(descendingBy(override => override.attributeValues.length))
    .find(
      override =>
        override.attributeValues.length <= attributeValues.length &&
        override.attributeValues.every((value, index) => value.id === attributeValues[index].id)
    );
}

function applyNewForecastOverride(
  planVersion: Types.PlanVersion,
  newOverride: Types.ThinUserForecastOverride
): Types.PlanVersion {
  const pathValueIds = overrideToPath(newOverride);
  const newOverrides =
    pathValueIds.length &&
    getDirectParentOverride(
      planVersion.forecastOverrides,
      pathValueIds.slice(0, pathValueIds.length - 1)
    )?.forecastType === newOverride.forecastType
      ? []
      : [newOverride];
  const overridesToKeep = [
    ...planVersion.forecastOverrides.filter(
      override =>
        override.attributeValues.length < pathValueIds.length ||
        !equal(overrideToPath(override).slice(0, pathValueIds.length), pathValueIds)
    ),
    ...newOverrides,
  ];
  return {...planVersion, forecastOverrides: overridesToKeep};
}

export const applyManualAdjustment = (
  planVersion: Types.PlanVersion,
  newAdjustment: Types.ThinUserManualAdjustment
): Types.PlanVersion => {
  const oldAdjustments = planVersion.manualAdjustments;
  const matchingAdjustmentIndex = oldAdjustments.findIndex(existingAdjustment =>
    adjustmentsApplyToSameValue(existingAdjustment, newAdjustment)
  );

  const newAdjustments = isAddingAdjustment(newAdjustment.metricValue.value)
    ? matchingAdjustmentIndex >= 0
      ? replaceAt(oldAdjustments, matchingAdjustmentIndex, newAdjustment)
      : [...oldAdjustments, newAdjustment]
    : matchingAdjustmentIndex >= 0
      ? removeAt(oldAdjustments, matchingAdjustmentIndex)
      : oldAdjustments;
  return {...planVersion, manualAdjustments: newAdjustments};
};

export function getPlanVersionDiff(
  lastSavedPlanVersion: Types.PlanVersion,
  pendingPlanVersion: Types.PlanVersion
): Types.PlanDiff {
  return {
    addedManualAdjustments: subtract(
      pendingPlanVersion.manualAdjustments,
      lastSavedPlanVersion.manualAdjustments
    ),
    removedManualAdjustments: subtract(
      lastSavedPlanVersion.manualAdjustments,
      pendingPlanVersion.manualAdjustments
    ),
    addedForecastOverrides: subtract(
      pendingPlanVersion.forecastOverrides,
      lastSavedPlanVersion.forecastOverrides
    ),
    removedForecastOverrides: subtract(
      lastSavedPlanVersion.forecastOverrides,
      pendingPlanVersion.forecastOverrides
    ),
    newProductIntroduction: null,
  };
}

export function applyManualAdjustments(
  savedPlanVersion: Types.PlanVersion,
  pendingPlanVersion: Types.PlanVersion,
  adjustments: readonly Types.ThinUserManualAdjustment[]
): Types.PlanDiff {
  return applyOverrides(savedPlanVersion, pendingPlanVersion, adjustments, applyManualAdjustment);
}

export function applyForecastOverride(
  savedPlanVersion: Types.PlanVersion,
  pendingPlanVersion: Types.PlanVersion,
  override: Types.ThinUserForecastOverride
): Types.PlanDiff {
  return applyOverrides(savedPlanVersion, pendingPlanVersion, [override], applyNewForecastOverride);
}

function applyOverrides<T>(
  savedPlanVersion: Types.PlanVersion,
  pendingPlanVersion: Types.PlanVersion,
  adjustments: readonly T[],
  applicator: (planVersion: Types.PlanVersion, adjustment: T) => Types.PlanVersion
): Types.PlanDiff {
  const newPlanVersion = adjustments.reduce(
    (planVersion, adjustment) => applicator(planVersion, adjustment),
    pendingPlanVersion
  );
  return getPlanVersionDiff(savedPlanVersion, newPlanVersion);
}

export function applyDiffToVersion(
  planVersion: Types.PlanVersion | null,
  planDiff: Types.PlanDiff | null
): Types.PlanVersion | null {
  if (!planDiff || !planVersion) {
    return planVersion;
  }

  const existingManualAdjustments = planVersion.manualAdjustments || [];
  const addedManualAdjustments = planDiff?.addedManualAdjustments || [];
  const removedManualAdjustments = planDiff?.removedManualAdjustments || [];
  const existingForecastOverrides = planVersion.forecastOverrides || [];
  const addedForecastOverrides = planDiff?.addedForecastOverrides || [];
  const removedForecastOverrides = planDiff?.removedForecastOverrides || [];
  return {
    ...planVersion,
    manualAdjustments: existingManualAdjustments
      .filter(isMissingInArray(removedManualAdjustments, adjustmentsApplyToSameValue))
      .filter(isMissingInArray(addedManualAdjustments, adjustmentsApplyToSameValue))
      .concat(addedManualAdjustments),
    forecastOverrides: existingForecastOverrides
      .filter(isMissingInArray(removedForecastOverrides, overridesApplyToSameValue))
      .filter(isMissingInArray(addedForecastOverrides, overridesApplyToSameValue))
      .concat(addedForecastOverrides),
  };
}

function isMissingInArray<T>(array: readonly T[], equality: (a: T, b: T) => boolean) {
  return (a: T) => !array.some(b => equality(a, b));
}

// implementation matches function with same name in pewter
export function removeInapplicableManualAdjustment(
  adjustments: readonly Types.ThinUserManualAdjustment[],
  pathValueIds: readonly number[]
): readonly Types.ThinUserManualAdjustment[] {
  const directParentAdjustment = getDirectParentOverride(adjustments, pathValueIds);
  if (
    directParentAdjustment &&
    directParentAdjustment.attributeValues.length === pathValueIds.length
  ) {
    return [directParentAdjustment];
  }
  const applicableAdjustmentsAtSameLevelOrAbove = getApplicableAdjustmentsAtSameLevelOrAbove(
    adjustments,
    directParentAdjustment,
    pathValueIds
  );
  const applicableAdjustmentAtChildLevels = getDirectChildrenAdjustments(adjustments, pathValueIds);
  return [...applicableAdjustmentsAtSameLevelOrAbove, ...applicableAdjustmentAtChildLevels];
}

export function getContradictoryAdjustmentsMessage(
  metricInstance: Types.MetricInstance,
  adjustments: readonly Types.ThinUserManualAdjustment[],
  newAdjustment: Types.ThinUserManualAdjustment,
  planPathAttributeNames: readonly string[]
): string | null {
  const pathValueIds = overrideToPath(newAdjustment);
  const applicableAdjustments = adjustments.filter(
    adjustment =>
      equal(adjustment.metricValue.interval, newAdjustment.metricValue.interval) &&
      adjustment.metricValue.metricName === newAdjustment.metricValue.metricName &&
      !equal(overrideToPath(adjustment), pathValueIds)
  );
  const date = Format.date(newAdjustment.metricValue.interval.start);
  const directParentAdjustment = getDirectParentOverride(applicableAdjustments, pathValueIds);
  if (directParentAdjustment) {
    if (directParentAdjustment.metricValue.value < newAdjustment.metricValue.value) {
      return `${date}: there is already an adjustment on ${formatAdjustmentAttributeValues(
        directParentAdjustment,
        planPathAttributeNames
      )} with value ${Format.metricValue(
        metricInstance,
        directParentAdjustment.metricValue.value
      )}`;
    }
    const sumOfDirectChildrenOfDirectParentAdjustment = getDirectChildrenAdjustments(
      [...applicableAdjustments, newAdjustment],
      overrideToPath(directParentAdjustment)
    )
      .map(adjustment => adjustment.metricValue.value)
      .reduce(sum, 0);
    if (directParentAdjustment.metricValue.value < sumOfDirectChildrenOfDirectParentAdjustment) {
      return `${date}: after applying this adjustment, adjustments under the adjustment of ${Format.metricValue(
        metricInstance,
        directParentAdjustment.metricValue.value
      )} on ${formatAdjustmentAttributeValues(
        directParentAdjustment,
        planPathAttributeNames
      )} would add up to ${Format.metricValue(
        metricInstance,
        sumOfDirectChildrenOfDirectParentAdjustment
      )}`;
    }
  }

  const sumOfDirectChildren = getDirectChildrenAdjustments(
    applicableAdjustments,
    overrideToPath(newAdjustment)
  )
    .map(adjustment => adjustment.metricValue.value)
    .reduce(sum, 0);
  if (newAdjustment.metricValue.value < sumOfDirectChildren) {
    return `${date}: the new adjustment of ${Format.metricValue(
      metricInstance,
      newAdjustment.metricValue.value
    )} is less than the adjustments under it, which total ${Format.metricValue(
      metricInstance,
      sumOfDirectChildren
    )}`;
  }

  return null;
}

export function formatAdjustmentAttributeValues(
  adjustment: Types.ThinUserManualAdjustment,
  planPathAttributeNames: readonly string[]
) {
  return (
    adjustment.attributeValues
      .map((value, i) => Format.thinAttributeValue(planPathAttributeNames[i], value))
      .join(', ') || 'All'
  );
}

function getDirectParentOverride<
  T extends Types.ThinUserManualAdjustment | Types.ThinUserForecastOverride,
>(overrides: readonly T[], pathValueIds: readonly number[]) {
  return overrides
    .filter(
      override =>
        override.attributeValues.length <= pathValueIds.length &&
        override.attributeValues.every(value => pathValueIds.includes(assertTruthy(value.id)))
    )
    .sort(descendingBy(override => override.attributeValues.length))[0];
}

function getApplicableAdjustmentsAtSameLevelOrAbove(
  adjustments: readonly Types.ThinUserManualAdjustment[],
  directParentAdjustment: Types.ThinUserManualAdjustment,
  pathValueIds: readonly number[]
) {
  return directParentAdjustment
    ? [
        ...adjustments.filter(
          adjustment =>
            adjustment.attributeValues.length > directParentAdjustment.attributeValues.length &&
            adjustment.attributeValues.length <= pathValueIds.length &&
            adjustment.attributeValues
              .slice(0, adjustment.attributeValues.length - 1)
              .every(value => pathValueIds.includes(assertTruthy(value.id)))
        ),
        directParentAdjustment,
      ]
    : [];
}

function getDirectChildrenAdjustments(
  adjustments: readonly Types.ThinUserManualAdjustment[],
  pathValueIds: readonly number[]
) {
  const applicableAdjustmentAtChildLevels: Types.ThinUserManualAdjustment[] = [];
  adjustments
    .filter(
      adjustment =>
        adjustment.attributeValues.length > pathValueIds.length &&
        pathValueIds.every(
          (pathValueId, index) => adjustment.attributeValues[index].id === pathValueId
        )
    )
    .sort(ascendingBy(override => override.attributeValues.length))
    .forEach(adjustment => {
      if (
        applicableAdjustmentAtChildLevels.every(existingAdjustment =>
          existingAdjustment.attributeValues.some(
            (value, index) => adjustment.attributeValues[index].id !== value.id
          )
        )
      ) {
        applicableAdjustmentAtChildLevels.push(adjustment);
      }
    });
  return applicableAdjustmentAtChildLevels;
}
