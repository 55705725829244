import './Tooltip.scss';

import classNames from 'classnames';
import React from 'react';
import {
  OverlayTrigger,
  Tooltip as ReactBootstrapTooltip,
  OverlayTriggerProps,
} from 'react-bootstrap';

import {defaultOverlayTriggerTooltipProps} from 'toolkit/utils/react-bootstrap';

const Tooltip: React.FunctionComponent<Props> = ({
  className,
  children,
  tooltip,
  tooltipClassName,
  hideArrow,
  whiteBackground = false,
  ...restProps
}) => {
  return (
    <span className={className}>
      {tooltip ? (
        <OverlayTrigger
          {...defaultOverlayTriggerTooltipProps}
          {...(restProps as Omit<OverlayTriggerProps, 'ref'>)}
          overlay={
            <ReactBootstrapTooltip
              className={classNames(
                'Tooltip',
                {'no-arrow': hideArrow},
                {'white-background': whiteBackground},
                tooltipClassName
              )}
              id="tooltip"
            >
              {tooltip}
            </ReactBootstrapTooltip>
          }
        >
          <span>{children}</span>
        </OverlayTrigger>
      ) : (
        children
      )}
    </span>
  );
};

Tooltip.displayName = 'Tooltip';
export interface Props extends Omit<OverlayTriggerProps, 'children' | 'overlay'> {
  children?: React.ReactNode;
  className?: string;
  tooltip?: React.ReactNode;
  tooltipClassName?: string;
  hideArrow?: boolean;
  whiteBackground?: boolean;
}

export default Tooltip;
