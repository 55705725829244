import {Placement} from '@popperjs/core';
import classNames from 'classnames';
import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import Button, {
  ButtonDisplayVariant,
  ButtonIconPosition,
  ButtonIconSize,
  Props as ButtonProps,
} from 'toolkit/components/Button';
import {IconSpec} from 'toolkit/icons/types';
import {defaultOverlayTriggerTooltipProps} from 'toolkit/utils/react-bootstrap';

const IconButton = React.forwardRef(
  (props: IconButtonProps, ref?: React.Ref<HTMLButtonElement>) => {
    const {tooltip, icon, className, tooltipPlacement, ...rest} = props;
    const getButton = () => (
      <Button ref={ref} className={classNames('IconButton', className)} icon={icon} {...rest} />
    );
    return !tooltip ? (
      getButton()
    ) : (
      <OverlayTrigger
        {...defaultOverlayTriggerTooltipProps}
        overlay={<Tooltip id="tooltip">{tooltip}</Tooltip>}
        placement={tooltipPlacement}
      >
        {getButton()}
      </OverlayTrigger>
    );
  }
);

IconButton.displayName = 'IconButton';
IconButton.defaultProps = {
  iconPosition: ButtonIconPosition.ICON_ONLY,
  // Icon-size is only applicable to icon-only buttons
  iconSize: ButtonIconSize.MEDIUM,
  displayVariant: ButtonDisplayVariant.TERTIARY,
};

/* A helper that creates a dedicated icon button component that uses the specified icon.
 * This is helpful for creating consistency with often-used button icons.
 * Example:
 *   export const LunchButton = iconButtonWith("utensils");
 *   ...
 *  <LunchButton onClick={eat} />
 */
export const iconButtonWith = (icon: IconSpec, className: string) => {
  return React.forwardRef(
    (props: Omit<IconButtonProps, 'icon'>, ref?: React.Ref<HTMLButtonElement>) => (
      <IconButton ref={ref} className={classNames(className)} icon={icon} {...props} />
    )
  );
};

export type IconButtonProps = ButtonProps & {
  tooltip?: string;
  iconClassName?: string;
  tooltipPlacement?: Placement;
};

export default IconButton;
