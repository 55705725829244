import './AnalysisEntityCardLayout.scss';

import React from 'react';

import AnalysisEntityCard from 'dashboard/AnalysisEntityCard';
import {TemplateAnalysisEntity, WorkflowAnalysisEntity} from 'toolkit/analysis/types';

const AnalysisEntityCardLayout: React.FC<Props> = ({analysisEntities}) => {
  return (
    <div className="AnalysisEntityCardLayout">
      {analysisEntities.map(entity => (
        <AnalysisEntityCard key={entity.name} entity={entity} />
      ))}
    </div>
  );
};
AnalysisEntityCardLayout.displayName = 'AnalysisEntityCardLayout';

type Props = {
  analysisEntities: ReadonlyArray<WorkflowAnalysisEntity | TemplateAnalysisEntity>;
};

export default AnalysisEntityCardLayout;
