import {IconProp, library} from '@fortawesome/fontawesome-svg-core';
import {
  faBell as farFaBell,
  faCalendarAlt as farFaCalendarAlt,
  faChartBar as farFaChartBar,
  faCheckSquare as farFaCheckSquare,
  faClipboard as farFaClipboard,
  faClock as farFaClock,
  faFileImage as farFaFileImage,
  faFrown as farFaFrown,
  faMinusSquare as farFaMinusSquare,
  faPlusSquare as farFaPlusSquare,
  faQuestionCircle as farFaQuestionCircle,
  faSquare as farFaSquare,
  faStar as farFaStar,
  faTimesCircle as farFaTimesCircle,
  faTrashAlt as farFaTrashAlt,
} from '@fortawesome/free-regular-svg-icons';
import {
  faAngleDown,
  faAngleUp,
  faArrowCircleRight,
  faArrowDown,
  faArrowLeft,
  faArrowsAltH,
  faArrowRight,
  faArrowUp,
  faBan,
  faBars,
  faConciergeBell,
  faStream,
  faBackward,
  faBell,
  faBug,
  faCalculator,
  faCalendar,
  faCalendarAlt,
  faCaretDown,
  faCaretRight,
  faChartLine,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faClock,
  faCloudUploadAlt,
  faCog,
  faCogs,
  faCubes,
  faComment,
  faCompress,
  faCompressAlt,
  faDatabase,
  faDollarSign,
  faDownload,
  faDraftingCompass,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExchangeAlt,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faExpandAlt,
  faExternalLinkAlt,
  faExternalLinkSquareAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileExport,
  faFilter,
  faGlobeAmericas,
  faHashtag,
  faHistory,
  faHouseUser,
  faIndent,
  faInfoCircle,
  faKey,
  faLayerGroup,
  faLightbulb,
  faLink,
  faLock,
  faMap,
  faMapMarkerAlt,
  faMicrophoneSlash,
  faMinus,
  faMinusCircle,
  faPaintBrush,
  faPaste,
  faPauseCircle,
  faPenSquare,
  faPencilAlt,
  faPercentage,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faProjectDiagram,
  faQuestionCircle,
  faRedo,
  faRocket,
  faSearch,
  faShare,
  faSignOutAlt,
  faSlidersH,
  faSpinner,
  faStar,
  faSync,
  faSyncAlt,
  faTable,
  faTag,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faUnlockAlt,
  faUpload,
  faUser,
  faUserAlt,
  faUserFriends,
  faUndo,
  faArchive,
  faRuler,
  faStore,
  faHome,
  faColumns,
  faCloudDownloadAlt,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';

import {AlloyIcon, Base64PngIcon, IconSpec} from './types';

export function isFontAwesomeIcon(icon: IconSpec | React.ReactElement): icon is IconProp {
  return (
    Array.isArray(icon) ||
    (typeof icon === 'object' && icon !== null && 'iconName' in icon) ||
    typeof icon === 'string'
  );
}

export function isAlloyIcon(icon: IconSpec | React.ReactElement): icon is AlloyIcon {
  return typeof icon === 'object' && icon !== null && 'alloyIcon' in icon;
}

export function isBase64PngIcon(icon: IconSpec | React.ReactElement): icon is Base64PngIcon {
  return typeof icon === 'object' && icon !== null && 'base64' in icon;
}

export function isReactIcon(icon?: IconSpec | React.ReactElement): icon is React.ReactElement {
  return !!icon && !isAlloyIcon(icon) && !isFontAwesomeIcon(icon) && typeof icon === 'object';
}

export function configureFontAwesome() {
  // If you need to add a new icon here, check first if you can use an existing icon instead.

  // solid icons
  library.add(
    faAngleDown,
    faAngleUp,
    faArchive,
    faArrowCircleRight,
    faArrowsAltH,
    faArrowDown,
    faArrowUp,
    faArrowLeft,
    faArrowRight,
    faBackward,
    faBan,
    faBars,
    faConciergeBell,
    faStream,
    faBell,
    faBug,
    faCalculator,
    faCalendar,
    faCalendarAlt,
    faCaretDown,
    faCaretRight,
    faChartLine,
    faCheck,
    faCheckCircle,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faCircle,
    faClock,
    faCloudDownloadAlt,
    faCloudUploadAlt,
    faCog,
    faCogs,
    faColumns,
    faComment,
    faCompress,
    faCompressAlt,
    faCubes,
    faDatabase,
    faDollarSign,
    faDownload,
    faDraftingCompass,
    faEdit,
    faEllipsisH,
    faEllipsisV,
    faEnvelope,
    faExchangeAlt,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle,
    faExpand,
    faExpandAlt,
    faExternalLinkAlt,
    faExternalLinkSquareAlt,
    faEye,
    faEyeSlash,
    faFile,
    faFileExport,
    faFilter,
    faGlobeAmericas,
    faHashtag,
    faHistory,
    faHome,
    faHouseUser,
    faIndent,
    faInfoCircle,
    faKey,
    faLayerGroup,
    faLightbulb,
    faLink,
    faLock,
    faMap,
    faMapMarkerAlt,
    faMicrophoneSlash,
    faMinus,
    faMinusCircle,
    faPaintBrush,
    faPaste,
    faPauseCircle,
    faPenSquare,
    faPencilAlt,
    faPercentage,
    faPlayCircle,
    faPlus,
    faPlusCircle,
    faProjectDiagram,
    faQuestionCircle,
    faRedo,
    faRocket,
    faRuler,
    faSearch,
    faShare,
    faSignOutAlt,
    faSlidersH,
    faSpinner,
    faStar,
    faStore,
    faSync,
    faSyncAlt,
    faTable,
    faTag,
    faTimes,
    faTimesCircle,
    faTrash,
    faTrashAlt,
    faUndo,
    faUnlockAlt,
    faUpload,
    faUser,
    faUserAlt,
    faUserFriends
  );

  // regular icons
  library.add(
    farFaBell,
    farFaCalendarAlt,
    farFaChartBar,
    farFaCheckSquare,
    farFaClipboard,
    farFaClock,
    farFaFileImage,
    farFaFrown,
    farFaMinusSquare,
    farFaPlusSquare,
    farFaQuestionCircle,
    farFaSquare,
    farFaStar,
    farFaTimesCircle,
    farFaTrashAlt
  );
}
