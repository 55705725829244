import {ChartType} from 'chart.js';
import {ChartOptions} from 'chart.js/auto';
import {AnnotationOptions} from 'chartjs-plugin-annotation';
import React from 'react';

import {DisplayableEvent} from 'toolkit/time/types';
import {ComputeWidgetData, NewProductWidgetData, PlanningWidgetData} from 'toolkit/views/types';
import * as Types from 'types';
import {BaseAnalysisWidgetProps} from 'widgets/types';

import {AugmentedChartDataset} from './impl/types';

export type DatasetColorizer = (
  metricInstance: Types.MetricInstance,
  attrValue: Types.ThinAttributeValue | null,
  metricInstanceIndex: number,
  attrValueIndex?: number
) => string | string[];

export type DatasetFilter = (dataset: AugmentedChartDataset) => boolean;

export type LineChartType = 'line' | 'bar';

export enum ChartLegendDisplayMode {
  VISIBLE = 'VISIBLE',
  HIDDEN = 'HIDDEN',
}

export enum ChartLegendPosition {
  TOP_LEFT = 'TOP_LEFT',
  BOTTOM = 'BOTTOM',
}

export enum ChartScaleId {
  X = 'x-axis',
  YLeft = 'y-axis-left',
  YRight = 'y-axis-right',
}

export type ChartValueFormatter = (value: number, metric: Types.MetricInstance) => string;

export type ChartWidgetProps<T extends ChartType> = BaseAnalysisWidgetProps & {
  additionalEvents?: readonly DisplayableEvent[];
  customAnnotations?: readonly AnnotationOptions[];
  datasetColorizer?: DatasetColorizer | null;
  datasetFilter?: DatasetFilter | null;
  isMenuButtonVisible?: boolean;
  forceStartAxisAtZero?: 'all' | ChartScaleId.YLeft | ChartScaleId.YRight | undefined;
  getModifiedChartOptions?: (options: ChartOptions<T>) => ChartOptions<T>;
  legendDisplayMode?: ChartLegendDisplayMode;
  legendPosition?: ChartLegendPosition;
  headerComponent?: React.ReactNode;
  showChartEvents: boolean;
  showChartWhenNoData?: boolean;
  showDoughnutInsightText: boolean;
  showDoughnutVerticalLegend: boolean;
  isLineGradientEnabled?: boolean;
  useDashesInForecastLines: boolean;
  widgetData: PlanningWidgetData | ComputeWidgetData | NewProductWidgetData;
  shouldHighlightByDefault?: (metricInstance: Types.MetricInstance) => boolean;
  getDatasetLabel?: (
    metricInstance: Types.MetricInstance | null,
    attributeValue: Types.AttributeValue | null
  ) => string;
};

export type PlanningChartWidgetProps = ChartWidgetProps<'line' | 'bar'> & {
  isDevelopmentModeSupported: boolean;
  planViewingGranularity?: Types.CalendarUnit | null;
  planningView: Types.View;
  setPlanViewingGranularity?: (granularity: Types.CalendarUnit) => void;
  supportedPlanViewingGranularities?: readonly Types.CalendarUnit[];
  widgetData: PlanningWidgetData;
  showHiddenMetrics: boolean;
  onToggleShowAllMetrics: () => void;
  onTogglePlanningTreeVisibility: () => void;
};

export type ExperimentChartWidgetProps = ChartWidgetProps<'line' | 'bar'> & {
  widgetData: ComputeWidgetData;
};
