import './OrdersPreviewTopBar.scss';

import classNames from 'classnames';
import React from 'react';

import MultiLabel from 'toolkit/components/MultiLabel';
import {getFilterDisplayValues, mergeAttributeFilters} from 'toolkit/filters/utils';
import Format from 'toolkit/format/format';
import {TextLengthFormat} from 'toolkit/format/types';
import {PeriodIntervalDisplayName} from 'toolkit/time/PeriodInterval';
import TopBar from 'toolkit/top-bar/TopBar';
import TopBarButton from 'toolkit/top-bar/TopBarButton';
import * as Types from 'types';
import {assertTruthy} from 'utils/assert';

import {dateColumnHeadingFromTransactionMetric} from './utils';

const OrdersPreviewTopBar: React.FC<OrdersPreviewTopBar> = ({attributeFilters, metricInstance}) => (
  <div className="OrdersPreviewTopBar">
    <TopBar>
      <TopBarButton isDisabled>
        <MultiLabel
          subtitle={
            <PeriodIntervalDisplayName
              options={{lengthFormat: TextLengthFormat.COMPACT}}
              period={assertTruthy(metricInstance.arguments.period)}
            />
          }
          title={dateColumnHeadingFromTransactionMetric(metricInstance)}
        />
      </TopBarButton>
      {mergeAttributeFilters(attributeFilters).map((filter, index) => {
        return (
          <TopBarButton key={`${filter.attributeInstance.attribute.name}-${index}}`} isDisabled>
            <MultiLabel
              subtitle={
                <span className={classNames('values-list', {exclusive: !filter.inclusive})}>
                  {getFilterDisplayValues(filter).join(', ')}
                </span>
              }
              title={Format.attributeInstance(filter.attributeInstance)}
            />
          </TopBarButton>
        );
      })}
    </TopBar>
  </div>
);

interface OrdersPreviewTopBar {
  attributeFilters: Types.AttributeFilter[];
  metricInstance: Types.MetricInstance;
}

OrdersPreviewTopBar.displayName = 'OrdersPreviewTopBar';

export default OrdersPreviewTopBar;
