import './TagOption.scss';

import React from 'react';

import Icon from 'toolkit/components/Icon';
import Tooltip from 'toolkit/components/Tooltip';
import TruncatedTooltip from 'toolkit/components/TruncatedTooltip';

import {SUPER_ADMIN_ONLY_TAGS} from './types';

export const restrictedTagTooltipIcon = (
  <Tooltip tooltip="Tag restricted to Super Admins only">
    <Icon className="restricted-tag" icon="exclamation-triangle" />
  </Tooltip>
);

const TagOption: React.FC<Props> = ({tagName}) => (
  <div className="TagOption">
    <TruncatedTooltip className="option-name" value={tagName} />
    {SUPER_ADMIN_ONLY_TAGS.includes(tagName) && restrictedTagTooltipIcon}
  </div>
);
TagOption.displayName = 'TagOption';

type Props = {
  tagName: string;
};

export default TagOption;
