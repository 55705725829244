import './MetricValueCell.scss';

import classNames from 'classnames';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import {withColorizedMetricValueCell} from 'toolkit/ag-grid/cell-renderers/ColorizedMetricValueCell';
import {
  MetricCellValue,
  TypedRowNode,
  TypedCellRendererParams,
  ComputeResultData,
} from 'toolkit/ag-grid/types';
import ExternalLink from 'toolkit/components/ExternalLink';
import {getTooltipText} from 'toolkit/metrics/metric-value-metadata';
import {MetricsByName} from 'toolkit/metrics/types';
import {getEffectiveMetricName, getOriginalValue} from 'toolkit/metrics/utils';
import {defaultOverlayTriggerTooltipProps} from 'toolkit/utils/react-bootstrap';
import useBoolean from 'toolkit/utils/useBoolean';
import {getLinkedMetricUrl, getLinkedMetricView} from 'toolkit/views/view-urls';
import ViewNavLink from 'toolkit/views/ViewNavLink';
import {assertTruthy} from 'utils/assert';

import BlockLayoutCell from './BlockLayoutCell';
import {getLevelFromData, getLinkSelectionFilters} from './selection';
import {MetricCellParams} from './types';
import useChildListMutationObserver from './useChildListMutationObserver';

const MetricValueCell: React.FunctionComponent<Props> = (props: Props) => {
  const [isMouseInWidget, mouseEnter, mouseLeave] = useBoolean(false);

  const getDisplayValue = () => {
    const metricName = assertTruthy(
      getEffectiveMetricName(props.metricInstanceConfig.metricInstance)
    );
    const linkedView = getLinkedMetricView(metricName, props.viewLinks);
    const linkedUrl = !linkedView ? getLinkedMetricUrl(metricName, props.viewLinks) : null;

    if (
      (!linkedView && !linkedUrl) ||
      props.compact ||
      !props.value ||
      !props.viewSlugs ||
      getLevelFromData(props.data) === -1 ||
      (props.isFlattened && props.data.data.values.length < props.attributeInstances.length)
    ) {
      return <span>{props.valueFormatted}</span>;
    }

    if (linkedView) {
      const filters = getLinkSelectionFilters(
        props.viewFilters,
        props.data,
        props.node,
        props.attributeInstances,
        props.attributeInstances.length - 1,
        props.isFlattened,
        props.colDef.cellRendererParams?.columnGroupings ?? [],
        props.colDef.cellRendererParams?.columnPath?.toArray() ?? []
      );

      if (filters.length) {
        return (
          <BlockLayoutCell>
            <ViewNavLink
              calendarProperties={props.calendarProperties}
              evaluationDate={props.evaluationDate}
              evaluationPeriod={props.evaluationPeriod}
              filters={filters}
              linkedView={linkedView}
              vendorName={props.vendorName}
              viewLinks={props.viewLinks}
              viewSlugs={props.viewSlugs}
              viewUrlParams={props.viewUrlParams}
            >
              {props.valueFormatted}
            </ViewNavLink>
          </BlockLayoutCell>
        );
      }
    } else if (linkedUrl) {
      return (
        <BlockLayoutCell>
          <ExternalLink href={linkedUrl} showIcon={isMouseInWidget}>
            {props.valueFormatted}
          </ExternalLink>
        </BlockLayoutCell>
      );
    }

    return <span>{props.valueFormatted}</span>;
  };

  const overlayTriggerKey = useChildListMutationObserver(props.eGridCell);

  const getValueWithTooltip = () => {
    if (props.disableTooltip) {
      return getDisplayValue();
    }
    const metadata = props.value && props.value.metadata;
    if (!isMouseInWidget || !props.value || !metadata || !metadata.length) {
      return getDisplayValue();
    }
    const tooltip = getTooltipText(
      props.value.metadata,
      props.metricInstanceConfig.metricInstance,
      props.metricsByName
    );
    if (!tooltip) {
      return getDisplayValue();
    }
    return (
      <OverlayTrigger
        {...defaultOverlayTriggerTooltipProps}
        key={overlayTriggerKey}
        overlay={<Tooltip id="tooltip">{tooltip}</Tooltip>}
        placement="top"
      >
        <span>{getDisplayValue()}</span>
      </OverlayTrigger>
    );
  };

  return (
    <div
      className={classNames('MetricValueCell', {
        'overridden-value': !!getOriginalValue(props.value.metadata),
      })}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <div className="value">{getValueWithTooltip()}</div>
    </div>
  );
};

MetricValueCell.displayName = 'MetricValueCell';
export default withColorizedMetricValueCell(MetricValueCell);

type Props = MetricCellParams &
  TypedCellRendererParams<ComputeResultData> & {
    eGridCell: HTMLElement;
    data: ComputeResultData;
    node: TypedRowNode<ComputeResultData>;
    value: MetricCellValue;
    valueFormatted: string;
    disableTooltip: boolean;
    metricsByName: MetricsByName;
  };
