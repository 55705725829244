import {AttributeValueMap, PresentationFile} from 'app/presentation/types';
import {ComputeResultExtended, ThinComputeResultRowExtended} from 'toolkit/compute/types';
import {updateFilterValues} from 'toolkit/filters/utils';
import {AttributeHierarchyTreeResult, AttributeResult, CompositeResult} from 'toolkit/views/types';
import * as Types from 'types';
import {assertTruthy} from 'utils/assert';

export function mapFilter(filter: Types.AttributeFilter, mappings: PresentationFile) {
  const valueMap = mappings.attributeMappings[filter.attributeInstance.attribute.name];
  return valueMap
    ? updateFilterValues(
        filter,
        filter.values.map(value => mapAttributeValue(value, valueMap))
      )
    : filter;
}

export function mapAttributeValue(
  value: Types.AttributeValue,
  attrValueMap: AttributeValueMap | null
) {
  return attrValueMap
    ? {
        ...value,
        displayValue: attrValueMap[value.displayValue || ''] || value.displayValue,
        value: attrValueMap[value.value] || value.value,
      }
    : value;
}

export function mapView(view: Types.View, file: PresentationFile): Types.View {
  const newFilters = view.filters.map(filter => {
    const mappings = file.attributeMappings[filter.attributeInstance.attribute.name];
    const newValues = filter.values.map(value => mapAttributeValue(value, mappings));
    return updateFilterValues(filter, newValues);
  });

  return {...view, filters: newFilters};
}

export function mapThinAttributeValue(
  attribute: Types.AttributeInstance | null,
  attrValue: Types.ThinAttributeValue | null,
  mappings: PresentationFile
): Types.ThinAttributeValue | null {
  const attrMappings = attribute ? mappings.attributeMappings[attribute.attribute.name] : null;
  return attrValue && attrMappings
    ? {
        ...attrValue,
        displayValue: attrMappings[attrValue.displayValue || ''] || attrValue.displayValue,
        value: attrMappings[attrValue.value] || attrValue.value,
      }
    : attrValue;
}

function mapColumn(
  column: Types.ThinComputeResultColumn,
  remainingColGroupings: ReadonlyArray<Types.AttributeInstance | null>,
  mappings: PresentationFile
): Types.ThinComputeResultColumn {
  return {
    ...column,
    children: column.children
      ? column.children.map(child => mapColumn(child, remainingColGroupings.slice(1), mappings)!)
      : column.children,
    value: mapThinAttributeValue(remainingColGroupings[0], column.value, mappings),
  };
}

function mapRow(
  row: ThinComputeResultRowExtended,
  remainingRowGroupings: ReadonlyArray<Types.AttributeInstance | null>,
  remainingColGroupings: ReadonlyArray<Types.AttributeInstance | null>,
  mappings: PresentationFile
): ThinComputeResultRowExtended {
  const childRowGroupings = remainingRowGroupings.slice(1);
  return {
    children: row.children
      ? row.children.map(
          child => mapRow(child, childRowGroupings, remainingColGroupings, mappings)!
        )
      : row.children,
    columnData: mapColumn(row.columnData, remainingColGroupings, mappings)!,
    value: mapThinAttributeValue(remainingRowGroupings[0], row.value, mappings),
    values: row.values,
  };
}

function mapTree(
  treeNode: Types.AttributeHierarchyTree | null,
  remainingRowGroupings: ReadonlyArray<Types.AttributeInstance | null>,
  mappings: PresentationFile
): Types.AttributeHierarchyTree | null {
  if (treeNode === null) {
    return null;
  }
  const childRowGroupings = remainingRowGroupings.slice(1);
  return {
    value: mapThinAttributeValue(remainingRowGroupings[0], treeNode.value, mappings),
    children: treeNode.children
      ? treeNode.children.map(child => assertTruthy(mapTree(child, childRowGroupings, mappings)))
      : treeNode.children,
  };
}

export function mapComputeResult(
  result: ComputeResultExtended,
  mappings: PresentationFile
): ComputeResultExtended {
  return result.set(
    'data',
    mapRow(
      result.data,
      [null, ...result.rowGroupings], // root ro w & columns
      [null, ...result.columnGroupings],
      mappings
    )
  );
}

export function mapAttributeHierarchyTree(
  hierarchyTree: AttributeHierarchyTreeResult,
  attributes: AttributeResult,
  mappings: PresentationFile
): AttributeHierarchyTreeResult {
  return hierarchyTree.set(
    'tree',
    assertTruthy(mapTree(hierarchyTree.tree, [null, ...attributes.attributeInstances], mappings))
  );
}

export function mapEventResult(
  result: Types.CalendarEventResult,
  mappings: PresentationFile
): Types.CalendarEventResult {
  return {
    ...result,
    events: result.events.map(event => ({
      ...event,
      eventLevelFilters: event.eventLevelFilters.map(filter => mapFilter(filter, mappings)),
    })),
  };
}

export function mapCompositeResult(
  result: CompositeResult<ComputeResultExtended | Types.CalendarEventResult>,
  mappings: PresentationFile
): CompositeResult<ComputeResultExtended | Types.CalendarEventResult> {
  return new CompositeResult<ComputeResultExtended | Types.CalendarEventResult>({
    compute: result.compute ? mapComputeResult(result.compute, mappings) : undefined,
    events: result.events ? mapEventResult(result.events, mappings) : undefined,
    computeResults: result.computeResults
      ? result.computeResults.map(computeResult => mapComputeResult(computeResult, mappings))
      : undefined,
  });
}

export function mapAttributeResult(
  result: CompositeResult<AttributeResult | AttributeHierarchyTreeResult>,
  mappings: PresentationFile
): CompositeResult<AttributeResult | AttributeHierarchyTreeResult> {
  if (!result.attribute) {
    return result;
  }

  return new CompositeResult<AttributeResult | AttributeHierarchyTreeResult>({
    ...result,
    attributeHierarchyTree: result.attributeHierarchyTree
      ? mapAttributeHierarchyTree(result.attributeHierarchyTree, result.attribute, mappings)
      : undefined,
  });
}
