import {configureHeap} from './heap-lib';

class HeapImpl {
  init(heapAppId: string) {
    if (heapAppId) {
      configureHeap(heapAppId);
    }
  }
  track(eventName: string, props?: any) {
    if (window.heap) {
      window.heap.track(eventName, props);
    }
  }
  identify(identity: string, identityType: string) {
    if (window.heap) {
      window.heap.identify(identity, identityType);
    }
  }
  addUserProperties(props: any) {
    if (window.heap) {
      window.heap.addUserProperties(props);
    }
  }
}

const Heap = new HeapImpl();
export default Heap;
