import './TopBarSeparator.scss';

import React from 'react';

const TopBarSeparator: React.FC = () => {
  return <span className="TopBarSeparator" />;
};

TopBarSeparator.displayName = 'TopBarSeparator';
export default TopBarSeparator;
