import './main.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';

import {setFavicon} from 'app/favicon-urls';
import * as Globals from 'app/globals';
import {store} from 'redux/store/store';
import {configureFontAwesome} from 'toolkit/icons/font-awesome';

import App from './App';

configureFontAwesome();

if (Globals.dev) {
  window.store = store;
  setFavicon('dev');
}

const root = ReactDOM.createRoot(document.getElementById(Globals.appContainerElementId)!);
root.render(<App />);
