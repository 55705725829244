import {isFail, isLoading} from 'redux/actions/helpers';
import {NewProductAction} from 'redux/actions/new-product';
import ActionType from 'redux/actions/types';
import * as Types from 'types';
import {Status} from 'utils/status';

interface NewProductSettingsState {
  readonly newProductSettings: Types.NewProductSettings | null;
  readonly newProductSettingsFetchStatus: Status;
}

export interface NewProductState {
  readonly settings: NewProductSettingsState;
  readonly isNpiModalOpen: boolean;
}

const initialState: NewProductState = {
  isNpiModalOpen: false,
  settings: {
    newProductSettings: null,
    newProductSettingsFetchStatus: Status.unstarted,
  },
};

export default function newProduct(
  state = initialState,
  action: NewProductAction
): NewProductState {
  if (action.type === ActionType.SetNpiModalOpen) {
    return {
      ...state,
      isNpiModalOpen: !!action.isNpiModalOpen,
    };
  } else if (action.type === ActionType.SetNewProductSettings) {
    if (isLoading(action)) {
      return {
        ...state,
        settings: {...state.settings, newProductSettingsFetchStatus: Status.inProgress},
      };
    } else if (isFail(action)) {
      return {
        ...state,
        settings: {...state.settings, newProductSettingsFetchStatus: Status.failed},
      };
    } else {
      return {
        ...state,
        settings: {
          newProductSettings: action.data!,
          newProductSettingsFetchStatus: Status.succeeded,
        },
      };
    }
  }
  return state;
}
