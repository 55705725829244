import './MetricDirectionIconCell.scss';

import React from 'react';

import {
  ComputeResultData,
  MetricCellValue,
  TypedCellRendererParams,
  TypedRowNode,
} from 'toolkit/ag-grid/types';
import DirectionalIndicator from 'toolkit/components/DirectionalIndicator';
import Tooltip from 'toolkit/components/Tooltip';
import {MetricsByName} from 'toolkit/metrics/types';
import {getMetricHighlight} from 'toolkit/metrics/utils';

import {MetricCellParams} from './types';

const MetricDirectionIconCell: React.FC<Props> = ({
  metricInstanceConfig,
  value,
  valueFormatted,
}) => {
  const isNeutral = !Number.isFinite(value.value) || !value.value;
  return (
    <div className="MetricDirectionIconCell">
      <Tooltip tooltip={isNeutral ? null : valueFormatted}>
        <DirectionalIndicator
          colour={getMetricHighlight(
            metricInstanceConfig.metricInstance,
            value.value,
            null,
            metricInstanceConfig.useReverseColors
          )}
          direction={getMetricHighlight(
            metricInstanceConfig.metricInstance,
            value.value,
            null,
            undefined,
            true
          )}
        />
      </Tooltip>
    </div>
  );
};

type Props = MetricCellParams &
  TypedCellRendererParams<ComputeResultData> & {
    eGridCell: HTMLElement;
    data: ComputeResultData;
    node: TypedRowNode<ComputeResultData>;
    value: MetricCellValue;
    valueFormatted: string;
    disableTooltip: boolean;
    metricsByName: MetricsByName;
  };

export default MetricDirectionIconCell;
