import {List, Map} from 'immutable';

import {CredentialsAction} from 'redux/actions/credentials';
import {isLoading, isFail} from 'redux/actions/helpers';
import ActionType from 'redux/actions/types';
import {getCredentialsKey} from 'settings/credentials/utils';
import {CredentialsValidityStatus} from 'toolkit/credentials/types';
import * as Types from 'types';
import {Status} from 'utils/status';

export interface CredentialsState {
  readonly credentials: Map<List<string | null>, Types.VendorCredentials>;
  readonly credentialsByGroup: Map<string, List<Types.VendorCredentials>>;
  readonly fetchingCredentialsStatus: Status;
  readonly validityStatuses: Map<List<string | null | undefined>, CredentialsValidityStatus>;
}
export const initialState: CredentialsState = {
  credentials: Map(),
  credentialsByGroup: Map(),
  fetchingCredentialsStatus: Status.unstarted,
  validityStatuses: Map(),
};

const getCredentialsMap = (credentialsList: readonly Types.VendorCredentials[]) =>
  Map<List<string | null>, Types.VendorCredentials>(
    credentialsList.map<[List<string | null>, Types.VendorCredentials]>(creds => [
      getCredentialsKey(creds),
      creds,
    ])
  );

const getCredentialsByGroup = (credentialsList: readonly Types.VendorCredentials[]) =>
  List(credentialsList)
    .groupBy(creds => creds.groupName)
    .map(values => values.toList())
    .toMap();

export default function credentials(
  state = initialState,
  action: CredentialsAction
): CredentialsState {
  if (action.type === ActionType.SetCredentials) {
    if (isLoading(action)) {
      return {...state, fetchingCredentialsStatus: Status.inProgress};
    } else if (isFail(action)) {
      return {
        ...state,
        credentials: Map(),
        credentialsByGroup: Map(),
        fetchingCredentialsStatus: Status.failed,
      };
    }
    return {
      ...state,
      credentials: getCredentialsMap(action.data!),
      credentialsByGroup: getCredentialsByGroup(action.data!),
      fetchingCredentialsStatus: Status.succeeded,
    };
  } else if (action.type === ActionType.SaveCredentials) {
    const key = getCredentialsKey(action.credentials);

    if (!state.credentials.get(key)) {
      if (state.credentialsByGroup.get(action.credentials.groupName)) {
        const credsList = state.credentialsByGroup.get(
          action.credentials.groupName,
          List.of<Types.VendorCredentials>()
        );
        return {
          ...state,
          credentials: state.credentials.set(key, action.credentials),
          credentialsByGroup: state.credentialsByGroup.set(
            action.credentials.groupName,
            credsList.set(
              credsList.findIndex(
                creds => creds.credentialSetName === action.credentials.credentialSetName
              ),
              action.credentials
            )
          ),
        };
      }
      return {
        ...state,
        credentials: state.credentials.set(key, action.credentials),
        credentialsByGroup: state.credentialsByGroup.set(
          action.credentials.groupName,
          List.of(action.credentials)
        ),
      };
    }
    const vendorCreds = state.credentials.get(key, undefined)!;
    const newCredentials = Object.fromEntries(
      Object.entries(vendorCreds.credentials).map(([fieldName, credentialValue]) => [
        fieldName,
        action.credentials.credentials[fieldName] || credentialValue,
      ])
    );

    return {
      ...state,
      credentials: state.credentials.set(key, {...vendorCreds, credentials: newCredentials}),
    };
  } else if (action.type === ActionType.SetCredentialValidityStatus) {
    const key = List.of(action.groupName, action.credentialSetName, `${action.extraParams}`);
    return {...state, validityStatuses: state.validityStatuses.set(key, action.status)};
  } else if (action.type === ActionType.DeleteCredentials) {
    const key = List.of(action.groupName, action.credentialSetName);

    return {
      ...state,
      credentials: state.credentials.delete(key),
      credentialsByGroup: state.credentialsByGroup.has(action.groupName)
        ? state.credentialsByGroup.set(
            action.groupName,
            state.credentialsByGroup
              .get(action.groupName)!
              .filter(vendorCreds => vendorCreds.credentialSetName !== action.credentialSetName)
          )
        : state.credentialsByGroup,
    };
  }

  return state;
}
