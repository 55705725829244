import './NewTopBar.scss';

import classNames from 'classnames';
import React from 'react';

export interface NewTopBarProps {
  children: React.ReactNode;
  className?: string;
}

const NewTopBar: React.FunctionComponent<NewTopBarProps> = ({className, children}) => (
  <div className={classNames('NewTopBar', className)}>
    <div className="new-top-bar-content">{children}</div>
  </div>
);

NewTopBar.displayName = 'NewTopBar';
export default NewTopBar;
