import './CompactSelect.scss';

import classNames from 'classnames';
import React from 'react';

import LabeledOptionsSelect, {
  LabeledOptionsSelectProps,
} from 'toolkit/components/LabeledOptionsSelect';
import {OptionValues} from 'toolkit/components/Select';

function CompactSelect<T extends OptionValues>({
  className,
  size = 'small',
  ...rest
}: CompactSelectProps<T>) {
  return (
    <LabeledOptionsSelect
      className={classNames('CompactSelect', `size-${size}`, className)}
      menuClassName="compact-select-menu"
      {...rest}
    />
  );
}
CompactSelect.displayName = 'CompactSelect';

export type CompactSelectProps<T extends OptionValues> = Omit<
  LabeledOptionsSelectProps<T>,
  'clearable' | 'onChange'
> & {
  size?: 'small' | 'medium';
} & (
    | {
        clearable?: false;
        onChange?: (value: T) => void;
      }
    | {
        clearable: true;
        onChange?: (value: T | null) => void;
      }
  );

export default CompactSelect;
