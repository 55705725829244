import './react-window.scss';

import classnames from 'classnames';
import React from 'react';
import Measure from 'react-measure';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeList, FixedSizeListProps, ListChildComponentProps} from 'react-window';

// Renders a virtualized list where all elements are the same size.
export function AutoFixedHeightList<T>({
  itemCount,
  getItem,
  getKey,
  renderItem,
}: AutoFixedHeightListProps<T>) {
  // react-window FixedSizeList needs to know the height of each item.
  // Trick: invisibly render the first element to measure its height.
  return (
    <AutoSizer>
      {({width, height}) => (
        <Measure bounds>
          {({measureRef, contentRect}) => (
            <div>
              <div
                ref={measureRef}
                className={classnames('auto-fixed-height-list-item', 'invisible')}
                style={{width}}
                aria-hidden
              >
                {renderItem({item: getItem(), key: 'invisible-item'})}
              </div>
              <FixedSizeList
                height={height}
                itemCount={itemCount}
                itemSize={contentRect.bounds?.height ?? 0}
                width={width}
              >
                {props => {
                  const {index, style} = props;
                  const item = getItem(index);
                  const key = getKey(item, index);
                  return (
                    <div key={key} className="auto-fixed-height-list-item" style={style}>
                      {renderItem({item, key, ...props})}
                    </div>
                  );
                }}
              </FixedSizeList>
            </div>
          )}
        </Measure>
      )}
    </AutoSizer>
  );
}

export type AutoFixedHeightListProps<ItemType, DataType = any> = Pick<
  FixedSizeListProps<DataType>,
  'itemCount'
> & {
  getItem: (index?: number) => ItemType;
  getKey: (item: ItemType, index?: number) => string;
  renderItem: (
    props: Partial<ListChildComponentProps<DataType>> & {item: ItemType; key: string}
  ) => React.ReactNode;
};
