import {List} from 'immutable';
import React from 'react';

import {AnalysisDataActions, IAnalysisDataActions} from 'redux/actions/analysis';
import {ShareActions} from 'redux/actions/share';
import {Dispatch} from 'redux/reducers';
import {store} from 'redux/store/store';
import * as Types from 'types';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addVendorViews: (views: List<Types.View>) => dispatch(AnalysisDataActions.addVendorViews(views)),
  fetchAllGroupings: () => dispatch(AnalysisDataActions.fetchAllGroupings()),
  fetchAllThinViews: () => dispatch(AnalysisDataActions.fetchAllThinViews()),
  clearVendorViews: () => dispatch(AnalysisDataActions.clearVendorViews()),
  fetchAllCalendarEvents: () => dispatch(AnalysisDataActions.fetchAllCalendarEvents()),
  fetchAvailableForecasts: () => dispatch(AnalysisDataActions.fetchAvailableForecasts()),
  fetchAvailableMetricDescriptions: () =>
    dispatch(AnalysisDataActions.fetchAvailableMetricDescriptions()),
  updateAvailableMetricDescriptions: (descriptions: List<Types.MetricDescription>) =>
    dispatch(AnalysisDataActions.updateAvailableMetricDescriptions(descriptions)),
  fetchAvailableMetrics: () => dispatch(AnalysisDataActions.fetchAvailableMetrics()),
  fetchEdgeDataRanges: () => dispatch(AnalysisDataActions.fetchEdgeDataRanges()),
  fetchPartners: () => dispatch(AnalysisDataActions.fetchPartners()),
  setAvailableThinViews: (views: List<Types.ThinView>) =>
    dispatch(AnalysisDataActions.setAvailableThinViews(views)),
  setViewFavoriteStatus: (view: Types.ThinView, isFavorite: boolean) =>
    dispatch(AnalysisDataActions.setViewFavoriteStatus(view, isFavorite)),
  setViewLinks: (viewLinks: readonly Types.ViewLink[]) =>
    dispatch(AnalysisDataActions.setViewLinks(viewLinks)),
  setShareScreenshotRequest: (shareRequest: Types.ShareScreenshotRequest | null) =>
    dispatch(ShareActions.setShareScreenshotRequest(shareRequest)),
  setShareViewRequest: (view: Types.View | null, shareRequest: Types.ShareViewRequest | null) =>
    dispatch(ShareActions.setShareViewRequest(view, shareRequest)),
  setView: (view: Types.View) => dispatch(AnalysisDataActions.setView(view)),
  storeViews: (views: List<Types.View>) => dispatch(AnalysisDataActions.storeViews(views)),
  setDoubleCountingPartners: (doubleCountingPartners: readonly Types.DoubleCountingPartner[]) =>
    dispatch(AnalysisDataActions.setDoubleCountingPartners(doubleCountingPartners)),
});
const actionProps = mapDispatchToProps(store.dispatch);

export function withAnalysisDataActionCreators<P>(
  Component: React.ComponentType<P & IAnalysisDataActions>
) {
  const HocComponent: React.FC<P> = props => {
    return <Component {...props} {...actionProps} />;
  };
  return HocComponent;
}
