import moment, {Moment} from 'moment-timezone';
import {v4 as uuid} from 'uuid';

import * as Api from 'api';
import {AlloyColors} from 'colors';
import {AttributesById} from 'toolkit/attributes/types';
import {createAttributeInstance, getAttribute} from 'toolkit/attributes/utils';
import {DATE_FORMAT} from 'toolkit/format/constants';
import Format from 'toolkit/format/format';
import {capitalize} from 'toolkit/format/text';
import {getEffectiveMetric, isUnitMetric} from 'toolkit/metrics/utils';
import * as Types from 'types';
import {last} from 'utils/arrays';
import {isTruthy} from 'utils/functions';
import {defaultDevOptions} from 'widgets/utils';

import {ORDERS_DEFAULT_LOCATION_ATTRIBUTE_NAME} from './constants';
import {TransactionAnalysisData} from './OrdersPreviewTable';

export function widgetTypeSupportsOrdersPreview(widgetType: Types.WidgetType) {
  return [Types.WidgetType.TABLE, Types.WidgetType.PIVOT_TABLE].includes(widgetType);
}

export function getTransactionTypeFromMetric(metricInstance: Types.MetricInstance) {
  return getEffectiveMetric(metricInstance)!.transactionType;
}

export function dateColumnHeadingFromTransactionMetric(metricInstance: Types.MetricInstance) {
  const transactionType = getTransactionTypeFromMetric(metricInstance);
  return transactionType ? `${capitalize(transactionType)} Date` : 'Date';
}

export function transactionStatusColor(status: Types.TransactionStatus) {
  // these colors should match up with those defined
  // in TransactionStatusBadge.scss
  switch (status) {
    case Types.TransactionStatus.NEW:
      return AlloyColors.Primary;
    case Types.TransactionStatus.CONFIRMED:
      return AlloyColors.BlueDark;
    case Types.TransactionStatus.SHIPPED:
      return AlloyColors.Orange;
    case Types.TransactionStatus.ARRIVED:
      return AlloyColors.GreenDark;
    case Types.TransactionStatus.RECEIVED:
      return AlloyColors.Yellow;
    case Types.TransactionStatus.COMPLETED:
      return AlloyColors.Indigo;
    case Types.TransactionStatus.CANCELLED:
      return AlloyColors.Red;
    default:
      return AlloyColors.Grey50;
  }
}

export function makeTransactionAnalysisRequest(
  attributeInstances: readonly Types.AttributeInstance[],
  metrics: readonly Types.MetricInstance[],
  filters: readonly Types.AttributeFilter[],
  calendar: Types.RetailCalendarEnum,
  evaluationDate: Moment,
  productAttribute: Types.Attribute | null,
  locationAttribute: Types.Attribute | null
): Promise<readonly Types.DisplayTransactionEvent[]> {
  return Api.TransactionEventData.queryEvents({
    attributeInstances,
    productAttribute,
    locationAttribute,
    calendar,
    devOptions: defaultDevOptions,
    evaluationDate: evaluationDate.format(DATE_FORMAT),
    filters,
    metrics,
    requestId: uuid(),
  }).then(transactionEvents =>
    // we ignore partial statuses for now and just combine them with their full status buckets (sc-79081)
    transactionEvents.map(transactionEvent => ({
      ...transactionEvent,
      status: transactionEvent.status.replace('PARTIALLY_', '') as Types.TransactionStatus,
    }))
  );
}

export function getDefaultProductAttribute(productAttributeHierarchy: readonly Types.Attribute[]) {
  if (productAttributeHierarchy.length === 0) {
    return null;
  }
  return last(productAttributeHierarchy);
}

export function getDefaultLocationAttribute(allAttributes: AttributesById) {
  return getAttribute(allAttributes, ORDERS_DEFAULT_LOCATION_ATTRIBUTE_NAME);
}

export function getFormattedTransactionAttributeValue(
  attributeName: string,
  attributeValue: Types.ThinAttributeValue | null,
  prefix = ''
) {
  return attributeValue?.value
    ? `${prefix}${Format.thinAttributeValue(attributeName, attributeValue)}`
    : '';
}

export function getAttributesForTransactionQuery(
  allGroupings: AttributesById,
  itemAttribute: Types.Attribute | null,
  locationAttribute: Types.Attribute
) {
  const attributes = [
    createAttributeInstance(getAttribute(allGroupings, 'Purchase Order Number')),
    createAttributeInstance(getAttribute(allGroupings, 'Sales Order Number')),
    createAttributeInstance(locationAttribute, Types.GraphContext.ORIGIN),
    createAttributeInstance(getAttribute(allGroupings, 'Partner'), Types.GraphContext.ORIGIN),
    createAttributeInstance(locationAttribute, Types.GraphContext.DESTINATION),
    createAttributeInstance(getAttribute(allGroupings, 'Partner'), Types.GraphContext.DESTINATION),
    createAttributeInstance(getAttribute(allGroupings, 'Line Item Number')),
  ];
  if (itemAttribute) {
    attributes.push(createAttributeInstance(itemAttribute));
  }

  return attributes.filter(isTruthy);
}

export function augmentTransactionEventResult(
  transactionEvents: readonly Types.DisplayTransactionEvent[],
  metricInstance: Types.MetricInstance,
  itemAttribute: Types.Attribute | null,
  locationAttribute: Types.Attribute
): TransactionAnalysisData[] {
  return transactionEvents.map((transactionEvent: Types.DisplayTransactionEvent) => ({
    ...transactionEvent,
    // `transactionEvent.attributeValues` are in the same order as they are
    // requested in `getAttributesForTransactionQuery` above ^
    purchaseOrderNumberAttributeValue: getFormattedTransactionAttributeValue(
      'Purchase Order Number',
      transactionEvent.attributes['Purchase Order Number'],
      'PO '
    ),
    salesOrderNumberAttributeValue: getFormattedTransactionAttributeValue(
      'Sales Order Number',
      transactionEvent.attributes['Sales Order Number'],
      'SO '
    ),
    originLocationAttributeValue: getFormattedTransactionAttributeValue(
      locationAttribute.name,
      transactionEvent.origin
    ),
    originPartnerAttributeValue: getFormattedTransactionAttributeValue(
      'Partner',
      transactionEvent.originPartner
    ),
    destinationLocationAttributeValue: getFormattedTransactionAttributeValue(
      locationAttribute.name,
      transactionEvent.destination
    ),
    destinationPartnerAttributeValue: getFormattedTransactionAttributeValue(
      'Partner',
      transactionEvent.destinationPartner
    ),
    lineItemNumberAttributeValue: getFormattedTransactionAttributeValue(
      'Line Item Number',
      transactionEvent.attributes['Line Item Number'],
      'LI '
    ),
    itemAttributeValue: itemAttribute
      ? getFormattedTransactionAttributeValue(itemAttribute.name, transactionEvent.product)
      : '',
    unitAttributeValue: metricInstance.arguments.unitConversionAttribute
      ? metricInstance.arguments.unitConversionAttribute.name
      : isUnitMetric(metricInstance)
        ? 'Units'
        : '',
  }));
}

export function getTransactionStatusMetricSumPerDay(
  days: string[],
  augmentedTransactionData: readonly TransactionAnalysisData[],
  status: Types.TransactionStatus,
  valueGetter: (item: TransactionAnalysisData) => number
) {
  return days.map(date =>
    augmentedTransactionData
      .filter(
        transactionData =>
          moment.utc(transactionData.datetime).isSame(moment.utc(date), 'day') &&
          transactionData.status === status
      )
      .reduce((sum, item) => sum + valueGetter(item), 0)
  );
}

export function getItemColumnAttribute(
  ordersDefaultProductAttributeId: number | null,
  defaultProductHierarchy: readonly Types.Attribute[],
  allGroupings: AttributesById
): Types.Attribute | null {
  return (
    (ordersDefaultProductAttributeId && allGroupings.get(ordersDefaultProductAttributeId)) ||
    getDefaultProductAttribute(defaultProductHierarchy)
  );
}

export function getLocationColumnAttribute(
  ordersDefaultLocationAttributeId: number | null,
  allGroupings: AttributesById
): Types.Attribute | null {
  return (
    (ordersDefaultLocationAttributeId && allGroupings.get(ordersDefaultLocationAttributeId)) ||
    getDefaultLocationAttribute(allGroupings)
  );
}
