import './FavoritesListEmpty.scss';

import React from 'react';

import Icon from 'toolkit/components/Icon';
import emptyFavIcon from 'toolkit/images/icon-favorite-empty.svg';

const FavoritesListEmpty: React.FC = () => (
  <>
    <Icon className="FavoritesListEmpty-icon" icon={{alloyIcon: emptyFavIcon}} />
    <p className="FavoritesListEmpty-header">Add your first favorite</p>
    <p className="FavoritesListEmpty-body">
      Click the <Icon icon={['far', 'star']} /> on any dashboard to keep track of the insights
      you’re most interested in.
    </p>
  </>
);

FavoritesListEmpty.displayName = 'FavoritesListEmpty';

export default FavoritesListEmpty;
