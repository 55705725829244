/* eslint-disable no-console */

import * as Globals from 'app/globals';

import {captureException} from './exceptions';
import {isNonNullish, isTruthy} from './functions';

// Limit the logging for these to once per session to prevent consuming our sentry quota
let hasLoggedTruthiness = false; // eslint-disable-line fp/no-let
let hasLoggedNullishness = false; // eslint-disable-line fp/no-let

export function assertTruthy<T>(value: T | null | undefined | false | 0 | ''): T {
  if (!isTruthy(value)) {
    // FIXME: replace this with a throw
    if (!hasLoggedTruthiness) {
      hasLoggedTruthiness = true;
      captureException(new Error(`value was expected to be truthy, but was ${value}`));
      if (Globals.dev) {
        console.warn(`value was expected to be truthy, but was ${value}`);
      }
    }
    return value as any as T;
  }
  return value;
}

export function assertNonNullish<T>(value: T | null | undefined): T {
  if (!isNonNullish(value)) {
    // FIXME: replace this with a throw
    if (!hasLoggedNullishness) {
      hasLoggedNullishness = true;
      captureException(new Error(`value was expected to be non-nullish, but was ${value}`));
      if (Globals.dev) {
        console.warn(`value was expected to be truthy, but was ${value}`);
      }
    }
    return value as any as T;
  }
  return value;
}
