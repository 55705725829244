import classNames from 'classnames';
import moment from 'moment-timezone';
import React from 'react';

import Format, {DateFormatOptions} from 'toolkit/format/format';

import Tooltip, {Props as TooltipProps} from './Tooltip';

const DateTooltip: React.FC<Props> = ({className, children, date, options}) => {
  return (
    <Tooltip
      className={classNames('DateTooltip', className)}
      tooltip={!!date && Format.dateTime(date)}
    >
      {children || <span>{Format.dateTime(date, options)}</span>}
    </Tooltip>
  );
};

DateTooltip.displayName = 'DateTooltip';
type Props = TooltipProps & {
  date: moment.Moment | string | null;
  options: DateFormatOptions;
};

export default DateTooltip;
