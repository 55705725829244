/* eslint-disable */
import moment from 'moment-timezone';

import * as Globals from 'app/globals';
import * as Types from 'types';
import {isAlloyUser} from 'toolkit/users/utils';

const MIN_DAYS_SINCE_ACCOUNT_CREATION = 90;

export function initializeSurvey(user: Types.User, userInfo: Types.UserInfo, vendor: Types.Vendor) {
  if (
    isAlloyUser(user?.email) ||
    Globals.nodeEnv === 'test' ||
    !userInfo.createdAt ||
    moment.duration(moment().diff(userInfo.createdAt)).asDays() < MIN_DAYS_SINCE_ACCOUNT_CREATION ||
    vendor.isDemo
  ) {
    return;
  }

  // code snippet from Delighted
  // @ts-ignore
  // prettier-ignore
  !(function (e, t, r, n) { if (!e[n]) { for ( var i = (e[n] = []), a = [ 'survey', 'reset', 'config', 'init', 'set', 'get', 'event', 'identify', 'track', 'page', 'screen', 'group', 'alias', ], s = 0; s < a.length; s++) { let c = a[s]; i[c] = i[c] || (function (e) { return function () { let t = Array.prototype.slice.call(arguments); i.push([e, t]); }; })(c); } i.SNIPPET_VERSION = '1.0.1'; let o = t.createElement('script'); (o.type = 'text/javascript'), (o.async = !0), (o.src = 'https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/' + r + '/' + n + '.js'); let p = t.getElementsByTagName('script')[0]; p.parentNode.insertBefore(o, p); } })(window, document, 'ih6oIztjiZinSp5i', 'delighted');

  // @ts-ignore
  delighted.survey({
    email: user.email,
    name: `${userInfo.firstName} ${userInfo.lastName}`,
    properties: {
      vendorName: vendor.name,
      role: user.role,
      functionalArea: userInfo.functionalArea,
    },
  });
}
