import './EmptyAppLoader.scss';

import React from 'react';

import {NavTopBarEmpty} from 'nav/NavTopBar';
import CenteredLoader from 'toolkit/components/CenteredLoader';

const EmptyAppLoader: React.FC = () => (
  <div className="EmptyAppLoader">
    <NavTopBarEmpty />
    <CenteredLoader />
  </div>
);
EmptyAppLoader.displayName = 'EmptyAppLoader';

export default EmptyAppLoader;
