import memoizeOne from 'memoize-one';
import {DependencyList, useMemo} from 'react';

export class Lazy<T> {
  private readonly fn: () => T;

  constructor(fn: () => T | T) {
    this.fn = memoizeOne(fn);
  }

  public get(): T {
    return this.fn();
  }
}

export function resolve<T>(obj: T | Lazy<T>): T {
  return obj instanceof Lazy ? obj.get() : obj;
}

export function useLazy<T>(fn: () => T, deps: DependencyList): Lazy<T> {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => new Lazy(fn), deps);
}
