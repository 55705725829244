import './ItemChooserFooter.scss';

import classNames from 'classnames';
import React from 'react';

const ItemChooserFooter: React.FC<Props> = ({children, className}) => {
  return <div className={classNames('ItemChooserFooter', className)}>{children}</div>;
};
interface Props {
  children?: React.ReactNode;
  className?: string;
}
ItemChooserFooter.displayName = 'ItemChooserFooter';
export default ItemChooserFooter;
