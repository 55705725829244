import {CurrentUser} from 'redux/reducers/user';
import {Settings} from 'settings/utils';
import {AttributesById} from 'toolkit/attributes/types';
import {
  createAttributeInstance,
  createAttributeValue,
  getAttributeNameForGranularity,
  getOneLessGranularity,
} from 'toolkit/attributes/utils';
import {createFilter} from 'toolkit/filters/utils';
import {slugify} from 'toolkit/format/text';
import {MetricsByName} from 'toolkit/metrics/types';
import {createDefaultMetricInstance} from 'toolkit/metrics/utils';
import {createEmptyView, getHomeUrl} from 'toolkit/views/utils';
import * as Types from 'types';
import {assertTruthy} from 'utils/assert';
import {getDefaultWidgetProperties, getGrouping} from 'widgets/utils';

import {AskAlloyRequest} from './types';

export const GLOBAL_SEARCH_BAR_ID = 'global-search-bar';
export const GLOBAL_SEARCH_CONTENT_ID = 'global-search-bar-content';
export const GLOBAL_SEARCH_ITEM_CLASSNAME = 'focusable-search-item';

export function getNewVendorPathname(currentUser: CurrentUser, newVendor: Types.Vendor) {
  const segments = window.location.pathname.split('/');
  if (segments[1] !== currentUser.vendor.name) {
    segments.splice(2, segments.length - 2);
  }
  if (segments[2] === 'analysis' || segments[2] === 'experiments') {
    return getHomeUrl(newVendor.name);
  }

  segments[1] = newVendor.name;
  return segments.join('/');
}

export function changeVendor(currentUser: CurrentUser, newVendor: Types.Vendor) {
  window.location.pathname = getNewVendorPathname(currentUser, newVendor);
}

export function getGlobalSearchContentElement(): HTMLElement | null | undefined {
  return document.getElementById(GLOBAL_SEARCH_CONTENT_ID);
}

export function createAskAlloyView(
  currentUser: CurrentUser,
  settings: Settings,
  allGroupings: AttributesById,
  availableMetrics: MetricsByName,
  askAlloyRequest: AskAlloyRequest,
  missingPartnerQuery: boolean
): Types.View {
  const evaluationPeriod = settings.analysisSettings.dashboardPeriod;
  const metric = createDefaultMetricInstance(
    askAlloyRequest.metric,
    evaluationPeriod,
    settings,
    availableMetrics,
    currentUser.settings.analysisSettings.currency
  );

  // If the period is 1 of something, like trailing 1 week, we want to group the table
  // by 'one less granularity', i.e. day. But if the period is 4 weeks or 12 months,
  // we can just group by weeks (by setting this to months).
  const dashboardPeriodGranularity =
    settings.analysisSettings.dashboardPeriod &&
    'amount' in settings.analysisSettings.dashboardPeriod &&
    settings.analysisSettings.dashboardPeriod.amount === 1
      ? settings.analysisSettings.dashboardPeriod.unit
      : Types.CalendarUnit.MONTHS;
  const partnerAttribute = getGrouping(allGroupings, 'Partner');
  const requestGrouping = askAlloyRequest.groupingAttribute
    ? createAttributeInstance(askAlloyRequest.groupingAttribute)
    : partnerAttribute;
  const requestedDateGrouping =
    askAlloyRequest.groupingAttribute?.type === Types.AttributeType.DATE;
  const dateGrouping = getGrouping(
    allGroupings,
    getAttributeNameForGranularity(getOneLessGranularity(dashboardPeriodGranularity))
  );
  const partnerFilter = askAlloyRequest.partner
    ? createFilter(partnerAttribute, [
        createAttributeValue(
          partnerAttribute.attribute,
          assertTruthy(askAlloyRequest.partner.id),
          askAlloyRequest.partner.name
        ),
      ])
    : null;

  const widgets = [
    createAskAlloyChartWidget(
      metric,
      requestedDateGrouping ? requestGrouping : dateGrouping,
      requestedDateGrouping ? partnerAttribute : requestGrouping
    ),
    createAskAlloyTableWidget(
      metric,
      requestedDateGrouping ? partnerAttribute : requestGrouping,
      requestedDateGrouping ? requestGrouping : dateGrouping
    ),
  ];

  const searchQuery = getAskAlloyRequestSearchQuery(askAlloyRequest, missingPartnerQuery);

  return {
    ...createEmptyView(currentUser.user, settings.analysisSettings),
    filters: partnerFilter ? [partnerFilter] : [],
    layoutType: Types.LayoutType.VERTICAL,
    name: searchQuery,
    slug: slugify(searchQuery),
    widgets,
  };
}

function createAskAlloyChartWidget(
  metric: Types.MetricInstance,
  rowGrouping: Types.AttributeInstance,
  columnGrouping: Types.AttributeInstance
): Types.Widget {
  return {
    ...getDefaultWidgetProperties(),
    columnGroupings: [columnGrouping],
    metrics: [{metricInstance: metric, seriesType: Types.WidgetSeriesType.BAR}],
    metricsSplitIndex: 1,
    rowGroupings: [rowGrouping],
    type: Types.WidgetType.STACKED_CHART,
  };
}

function createAskAlloyTableWidget(
  metric: Types.MetricInstance,
  rowGrouping: Types.AttributeInstance,
  columnGrouping: Types.AttributeInstance
): Types.Widget {
  return {
    ...getDefaultWidgetProperties(),
    columnGroupings: [columnGrouping],
    metrics: [{metricInstance: metric}],
    rowGroupings: [rowGrouping],
    type: Types.WidgetType.PIVOT_TABLE,
  };
}

function getAskAlloyRequestSearchQuery(request: AskAlloyRequest, missingPartnerQuery: boolean) {
  const {groupingAttribute, metric, partner} = request;
  const metricPart = metric.displayName;
  const partnerFilterPart = !missingPartnerQuery
    ? ` at ${partner ? partner.name : 'All Partners'}`
    : '';
  const groupingPart = groupingAttribute ? ` by ${groupingAttribute.name}` : '';

  return `${metricPart}${partnerFilterPart}${groupingPart}`;
}
