import {groupBy, mapValue} from 'utils/maps';

export interface PathAndValue<K, V> {
  path: readonly K[];
  value: V;
}

export interface ArrayTree<K, V> {
  children: ReadonlyMap<K, ArrayTree<K, V>>;
  values: readonly V[];
}

export function buildArrayTree<K, V>(
  pathsAndValues: ReadonlyArray<PathAndValue<K, V>>
): ArrayTree<K, V> {
  const childPathsAndValuesByChildKey = groupBy(
    pathsAndValues.filter(pathAndValue => pathAndValue.path.length),
    (pathAndValue: PathAndValue<K, V>) => pathAndValue.path[0],
    (pathAndValue: PathAndValue<K, V>) => ({...pathAndValue, path: pathAndValue.path.slice(1)})
  );
  const children: ReadonlyMap<K, ArrayTree<K, V>> = mapValue(
    childPathsAndValuesByChildKey,
    buildArrayTree
  );

  return {
    children,
    values: pathsAndValues
      .filter(pathAndValue => !pathAndValue.path.length)
      .map(pathAndValue => pathAndValue.value),
  };
}

export function getValuesForPath<K, V>(
  arrayTree: ArrayTree<K, V>,
  path: readonly K[]
): readonly V[] {
  if (!path.length) {
    return arrayTree.values;
  }
  const [childKey, ...remainingKeys] = path;
  const childTree = arrayTree.children.get(childKey);
  if (childTree) {
    return getValuesForPath(childTree, remainingKeys);
  }
  return [];
}

export function getValuesUnderPath<K, V>(
  arrayTree: ArrayTree<K, V>,
  path: readonly K[]
): readonly V[] {
  const child = getChild(arrayTree, path);
  if (child) {
    return getAllValues(child);
  }
  return [];
}

export function getChild<K, V>(
  arrayTree: ArrayTree<K, V>,
  path: readonly K[]
): ArrayTree<K, V> | undefined {
  if (!path.length) {
    return arrayTree;
  }
  const [childKey, ...remainingKeys] = path;
  const child = arrayTree.children.get(childKey);
  if (!child) {
    return undefined;
  }
  return getChild(child, remainingKeys);
}

function getAllValues<K, V>(arrayTree: ArrayTree<K, V>): readonly V[] {
  return [...arrayTree.values, ...Array.from(arrayTree.children.values()).flatMap(getAllValues)];
}
