import './NavTopBarLogo.scss';

import classNames from 'classnames';
import React from 'react';
import {NavLink} from 'react-router-dom';

import Icon from 'toolkit/components/Icon';
import {IconSpec} from 'toolkit/icons/types';
import alloyLogo from 'toolkit/images/alloy-logo.svg';
import {getHideOnPhonesClassName} from 'toolkit/styles/utils';

const ALLOY_LOGO: IconSpec = {alloyIcon: alloyLogo};

const NavTopBarLogo: React.FC<Props> = ({to}) => {
  return (
    <div className={classNames('NavTopBarLogo', getHideOnPhonesClassName())}>
      <NavLink to={to}>
        <Icon icon={ALLOY_LOGO} label="Alloy.ai logo" />
      </NavLink>
    </div>
  );
};

NavTopBarLogo.displayName = 'NavTopBarLogo';
type Props = {
  to: string;
};

export default NavTopBarLogo;
