import {List} from 'immutable';

import {getCredentialSetNameSuffix} from 'toolkit/credentials/utils';
import * as Types from 'types';

export const getCredentialsKey = (credentials: Types.VendorCredentials) =>
  List.of(credentials.groupName, credentials.credentialSetName);

export const getCredentialsDisplayName = (
  credentials: Types.VendorCredentials,
  credGroup: Types.CredentialsGroup
) => {
  const groupDisplayName = credGroup === undefined ? credentials.groupName : credGroup.displayName;
  return `${groupDisplayName}${getCredentialSetNameSuffix(credentials.credentialSetName)}`;
};

export const getCredentialRequestKey = (
  request: Types.CredentialRequest | Types.CreateCredentialRequest
) => List.of(request.credentialGroupName, request.credentialSetName);
