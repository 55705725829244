export const sum = (a: number, b: number) => a + b;
export const sumIfAvailable = (a: number, b: number) => {
  if (Number.isNaN(a)) {
    return b;
  } else if (Number.isNaN(b)) {
    return a;
  } else {
    return a + b;
  }
};
export const clip = (x: number, minValue: number, maxValue: number) =>
  Math.max(minValue, Math.min(maxValue, x));
export const clipWithRollover = (x: number, minValue: number, maxValue: number) => {
  if (minValue <= x && x <= maxValue) {
    return x;
  } else if (x < minValue) {
    return maxValue + 1 - (minValue - x);
  } else {
    return minValue - 1 + (x - maxValue);
  }
};
export const identity = <K>(item: K): K => item;
export const noop = () => undefined;
export const trueFunc = () => true;
export const falseFunc = () => false;

/**
 * Implements a truthiness comparison that allows for type narrowing to T.
 *
 * Usage: const: string[] = ['foo', null].filter(isTruthy);
 */
export function isTruthy<T>(value: T | null | undefined | false | 0 | ''): value is T {
  return Boolean(value);
}

export function isNonNullish<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function isNullish<T>(value: T | null | undefined): value is null | undefined {
  return value === null || value === undefined;
}

export function nullishFirstComparator<T>(comparator: (valueA: T, valueB: T) => number) {
  return (valueA: T | null | undefined, valueB: T | null | undefined): number => {
    if (isNullish(valueA) && isNullish(valueB)) {
      return 0;
    } else if (isNullish(valueA)) {
      return -1;
    } else if (isNullish(valueB)) {
      return 1;
    } else {
      return comparator(valueA, valueB);
    }
  };
}

export function nullishLastComparator<T>(comparator: (valueA: T, valueB: T) => number) {
  return (valueA: T | null | undefined, valueB: T | null | undefined): number => {
    if (isNullish(valueA) && isNullish(valueB)) {
      return 0;
    } else if (isNullish(valueA)) {
      return 1;
    } else if (isNullish(valueB)) {
      return -1;
    } else {
      return comparator(valueA, valueB);
    }
  };
}
