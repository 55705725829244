import './Centered.scss';

import classNames from 'classnames';
import React from 'react';

const Centered: React.FunctionComponent<React.HTMLAttributes<HTMLDivElement>> = props => {
  const {children, className, ...elementProps} = props;
  return (
    <div className={classNames('Centered', className)} {...elementProps}>
      <div className="centered-content">{children}</div>
    </div>
  );
};
Centered.displayName = 'Centered';
export default Centered;
