import {Column} from 'ag-grid-community';
import {useState, useEffect} from 'react';

export function useTableHeaderFilterActive(column: Column) {
  const [filterActive, setFilterActive] = useState(column.isFilterActive());

  useEffect(() => {
    const onFilterChanged = () => {
      setFilterActive(column.isFilterActive());
    };

    column.addEventListener('filterChanged', onFilterChanged);
    return () => {
      column.removeEventListener('filterChanged', onFilterChanged);
    };
  }, [column]);

  return filterActive;
}
