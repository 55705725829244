import {isNonNullish} from 'utils/functions';

export function convertRemToPixels(rem: number) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

/**
 * Returns the width of the passed in text in pixels using the page's font. It a
 * component ID is passed in, the font used for the calculations will match the
 * font style of the component.
 *
 * Taken from https://stackoverflow.com/questions/58704990/calculate-pixel-width-of-text-without-knowing-font-in-react-javascript
 */
export function getTextWidthInPixels(text: string, componentId?: string) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d')!;
  const component = isNonNullish(componentId)
    ? (document.getElementById(componentId) ?? document.body)
    : document.body;
  context.font = getComputedStyle(component).font;
  return Math.ceil(context.measureText(text).width);
}
