import * as Globals from 'app/globals';
import * as Types from 'types';

export function isOnProdTechDemo(vendor: Types.Vendor | null) {
  return Globals.apiUrl === 'https://app.alloy.ai' && vendor?.isDemo;
}

export function preventProdTechDemoEdit(vendor: Types.Vendor | null) {
  if (isOnProdTechDemo(vendor)) {
    const confirm = window.prompt(
      'This change could affect the stability of e2e tests! Please type "confirm" if you are sure.'
    );
    if (confirm === 'confirm') {
      return false;
    }
    return true;
  }
  return false;
}
