import Cookies from 'js-cookie';
import {useCallback, useState} from 'react';

import {DatePeriod} from 'types';

export const LOGGED_IN = 'alloy_logged_in';
export const NO_PROMPTS = 'no_prompts';
export const SIGN_ON_METHOD = 'alloy_sign_on_method';
export const SSO_DOMAIN = 'alloy_sso_domain';
export const XSRF_TOKEN = 'xsrf_token';
export const E2E_BUILD_URL = 'e2e_build_url';
export const E2E_PLANNING_PERIOD = 'e2e_test_planning_period';
export const OAUTH_STATE_TOKEN = 'alloy-state';

const EXPIRES_IN_ONE_DAY = {expires: 1};

export function isPromptDisabledFor(promptName: string) {
  const noPromptsCookie = Cookies.getJSON(NO_PROMPTS);
  return noPromptsCookie && noPromptsCookie[promptName];
}

export function setPromptDisabledFor(promptName: string, isDisabled: boolean) {
  const noPromptsCookie = Cookies.getJSON(NO_PROMPTS) || {};
  if (isDisabled) {
    noPromptsCookie[promptName] = isDisabled;
  } else {
    delete noPromptsCookie[promptName];
  }
  Cookies.set(NO_PROMPTS, JSON.stringify(noPromptsCookie));
}

export function isRunningEndToEndTests(): boolean {
  return !!getEndToEndTestBuildUrlFromCookie();
}

export function getEndToEndTestBuildUrlFromCookie(): string | null {
  return Cookies.get(E2E_BUILD_URL) || null;
}

export function getEndToEndTestPlanningPeriod(): DatePeriod {
  const periodAsString = Cookies.get(E2E_PLANNING_PERIOD);
  return periodAsString ? JSON.parse(periodAsString) : null;
}

export function getOauthStateCookie(): string {
  const rawState = Cookies.get(OAUTH_STATE_TOKEN) || null;
  if (!rawState) {
    const state = Math.random().toString(36).slice(2);
    setOauthStateCookie(state);
    return state;
  } else {
    return rawState;
  }
}

export function setOauthStateCookie(state: string): void {
  Cookies.set(OAUTH_STATE_TOKEN, state, EXPIRES_IN_ONE_DAY);
}

export function useCookiePrompt(promptName: string) {
  // using state to track whether to show the prompt or not because
  // on calling setPromptDisabledFor, there's a delay before the cookie
  // is actually set
  const [isPromptDisabled, setPromptDisabled] = useState(isPromptDisabledFor(promptName));
  const handleSetPrompt = useCallback(
    (isPromptDisabled: boolean) => {
      setPromptDisabledFor(promptName, isPromptDisabled);
      setPromptDisabled(isPromptDisabled);
    },
    [promptName]
  );
  return [isPromptDisabled, handleSetPrompt];
}
