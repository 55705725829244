import './AttributeCell.scss';

import classNames from 'classnames';
import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import {ComputeResultData, TypedRowNode} from 'toolkit/ag-grid/types';
import {getNodesToRoot} from 'toolkit/ag-grid/utils';
import {UNKNOWN_VALUE_ID} from 'toolkit/attributes/utils';
import ExternalLink from 'toolkit/components/ExternalLink';
import Format from 'toolkit/format/format';
import PlanNavLink from 'toolkit/plans/PlanNavLink';
import {defaultOverlayTriggerTooltipProps} from 'toolkit/utils/react-bootstrap';
import {ViewLinkParams} from 'toolkit/views/view-link-types';
import {getLinkedAttributeUrl, getLinkedAttributeView} from 'toolkit/views/view-urls';
import ViewNavLink from 'toolkit/views/ViewNavLink';
import * as Types from 'types';
import {isTruthy} from 'utils/functions';

import BlockLayoutCell from './BlockLayoutCell';
import {getLevelFromData, getLinkSelectionFilters} from './selection';

function getUnknownMessage(attribute: Types.Attribute) {
  switch (attribute.type) {
    case 'PRODUCT':
      if (attribute.partnerId === null) {
        return (
          `Alloy.ai doesn't have a '${attribute.name}' value for this data. ` +
          "You may need to update product mapping in 'Product Mappings', or " +
          'update product attributes on the Products page.'
        );
      } else {
        return (
          `Alloy.ai doesn't have a '${attribute.name}' value for this data. ` +
          'It may not be reported by the data partner. If this seems wrong, contact support.'
        );
      }
    case 'VIRTUAL': {
      return (
        `Alloy.ai cannot determine a '${attribute.name}' value for this data. ` +
        'Contact support and we will try to resolve it.'
      );
    }
    case 'LOCATION':
      return (
        "You are seeing an 'Unknown' category because Alloy.ai does not have " +
        `'${attribute.name}' data for some locations.`
      );
    case 'TRANSACTION_EVENT':
      return (
        "You are seeing an 'Unknown' category because Alloy.ai does not have the " +
        `'${attribute.name}' for the corresponding transactions.`
      );
    default:
      throw Error(`Unknown attribute type ${attribute.type}`);
  }
}

function tryGetPlanPath(
  currentNode: TypedRowNode<ComputeResultData>,
  currentValue: Types.AttributeValue,
  currentLevel: number,
  attributeInstances: readonly Types.AttributeInstance[],
  planVersion: Types.PlanVersion,
  isFlattened: boolean
): readonly Types.ThinAttributeValue[] | null {
  if ((!currentLevel && currentLevel !== 0) || currentLevel < 0) {
    return null;
  }
  const planAttributes = planVersion.basePlan.attributes.slice(
    0,
    planVersion.basePlan.attributes.findIndex(
      planAttribute => planAttribute.id === currentValue.attribute.id
    ) + 1
  );

  if (!planAttributes?.length) {
    return null;
  }
  const rowPathIndex = currentLevel - planAttributes.length + 1;

  if (
    rowPathIndex < 0 ||
    planAttributes.some(
      (planAttribute, index) =>
        planAttribute.id !== attributeInstances[rowPathIndex + index].attribute.id
    )
  ) {
    return null;
  }
  const rowPath = isFlattened
    ? currentNode.data.data.values
    : getNodesToRoot(currentNode)
        .map(node => node?.data?.data?.value)
        .filter(isTruthy)
        .reverse()
        .toArray();

  if (!rowPath) {
    return null;
  }
  return rowPath.slice(rowPathIndex, rowPathIndex + planAttributes.length);
}

class AttributeCell extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isMouseInWidget: false,
    };
  }

  mouseEnter = () => this.setState({isMouseInWidget: true});

  // delay the setState call when leaving the cell as it interferes with ag-grid removing tooltips
  mouseLeave = () => setTimeout(() => this.setState({isMouseInWidget: false}), 0);

  getLevel = () =>
    this.props.level !== undefined ? this.props.level : getLevelFromData(this.props.data);

  getAttributeInstance = () =>
    this.getLevel() < 0
      ? this.props.attributeInstances[0]
      : this.props.attributeInstances[this.getLevel()];

  getDisplayValue = () => {
    if (
      this.props.compact ||
      !this.props.value ||
      !this.props.viewSlugs ||
      this.getAttributeInstance().attribute.valueType === Types.AttributeValueType.date
    ) {
      return <span>{this.props.valueFormatted}</span>;
    }

    const linkedPlan = this.props.viewLinks.planLinksByWidgetId.get(this.props.widgetId);
    if (linkedPlan) {
      const path = tryGetPlanPath(
        this.props.node,
        this.props.value,
        this.getLevel(),
        this.props.attributeInstances,
        linkedPlan,
        this.props.isFlattened
      );
      if (path?.length) {
        return (
          <PlanNavLink path={path} type={linkedPlan.basePlan.type} hasUnderline>
            {this.props.valueFormatted}
          </PlanNavLink>
        );
      }
    }

    const linkedView = getLinkedAttributeView(
      this.getAttributeInstance().attribute,
      this.props.viewLinks
    );
    const linkedUrl = getLinkedAttributeUrl(
      this.getAttributeInstance().attribute,
      this.props.viewLinks
    );
    if (this.props.isFlattened || this.getLevel() === this.props.attributeInstances.length - 1) {
      if (linkedView) {
        const filters = getLinkSelectionFilters(
          this.props.viewFilters,
          this.props.data,
          this.props.node,
          this.props.attributeInstances,
          this.getLevel(),
          this.props.isFlattened,
          [],
          []
        );

        if (filters.length) {
          return (
            <BlockLayoutCell>
              <ViewNavLink
                calendarProperties={this.props.calendarProperties}
                evaluationDate={this.props.evaluationDate}
                evaluationPeriod={this.props.evaluationPeriod}
                filters={filters}
                linkedView={linkedView}
                vendorName={this.props.vendorName}
                viewLinks={this.props.viewLinks}
                viewSlugs={this.props.viewSlugs}
                viewUrlParams={this.props.viewUrlParams}
              >
                {this.props.valueFormatted}
              </ViewNavLink>
            </BlockLayoutCell>
          );
        }
      } else if (linkedUrl) {
        return (
          <BlockLayoutCell>
            <ExternalLink
              href={linkedUrl}
              iconPosition="right"
              showIcon={this.state.isMouseInWidget}
            >
              {this.props.valueFormatted}
            </ExternalLink>
          </BlockLayoutCell>
        );
      }
    }

    return <span>{this.props.valueFormatted}</span>;
  };

  getValueWithTooltip = () => {
    if (
      !this.state.isMouseInWidget ||
      !this.props.value ||
      !this.props.value.attribute ||
      (this.props.value.attribute.valueType !== 'interval' &&
        this.props.value.id !== UNKNOWN_VALUE_ID)
    ) {
      return this.getDisplayValue();
    }

    return (
      <OverlayTrigger
        {...defaultOverlayTriggerTooltipProps}
        overlay={
          <Tooltip id="tooltip">
            {this.props.value.id === UNKNOWN_VALUE_ID
              ? getUnknownMessage(this.getAttributeInstance().attribute)
              : Format.interval(this.props.value.value)}
          </Tooltip>
        }
        placement="top"
      >
        <div className="value">{this.getDisplayValue()}</div>
      </OverlayTrigger>
    );
  };

  render() {
    return (
      <div
        className={classNames('AttributeCell', {'left-aligned': this.props.isFlattened})}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
      >
        <div className="value">{this.getValueWithTooltip()}</div>
      </div>
    );
  }
}
export type AttributeCellParams = ViewLinkParams & {
  attributeInstances: readonly Types.AttributeInstance[];
  compact: boolean;
  isFlattened: boolean;
  level: number;
  viewFilters: readonly Types.AttributeFilter[];
};
type Props = AttributeCellParams & {
  data: ComputeResultData;
  node: TypedRowNode<ComputeResultData>;
  value: Types.AttributeValue;
  valueFormatted: string;
};
interface State {
  isMouseInWidget: boolean;
}

export default AttributeCell;
