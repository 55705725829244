import * as Types from 'types';

export enum AssistantMessageDisplayMode {
  DEFAULT,
  BORDERLESS,
}

export enum DrilldownPlacement {
  HEADER,
  BELOW_TEXT,
}

export enum AssistantMessageTextAlignment {
  ALIGN_TO_ICON,
  ALIGN_TO_MESSAGE,
}

export enum DraggableType {
  WIDGET,
}

export enum AssistantSidebarStatus {
  CLOSED,
  SMALL,
  EXPANDED,
}

export enum AssistantSubmissionMode {
  NEW_QUERY,
  APPEND,
  RE_RUN,
}

export const DEFAULT_DEV_INFO: Types.AiComputeDevInfo = {
  cacheStatus: null,
  computeTimeMs: null,
  debugSession: null,
  requestComputed: null,
  requestFinished: null,
  requestStarted: null,
};
