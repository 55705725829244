import {useCallback} from 'react';
import {OverlayTriggerProps} from 'react-bootstrap/OverlayTrigger';
import {useRootClose} from 'react-overlays';

import {appContainerElementId} from 'app/globals';

/**
 * Hide delay for elements that need to close quickly.
 * Often used when there are several items with tooltips positioned next to each other.
 */
export const QUICK_HIDE_DELAY = 100;
/**
 * Standard hide delay for tooltips.
 */
export const TOOLTIP_HIDE_DELAY = 350;

export const defaultPopperConfig = {
  modifiers: [
    {
      // https://popper.js.org/docs/v2/modifiers/prevent-overflow
      name: 'preventOverflow',
      options: {
        // By default, dropdowns have 5px padding to their left, this adds padding to all sides.
        padding: 5,
        // By default, overlays are contained within their scrollParent, which is
        // undesirable, especially for nested popovers as we want them to pop out
        boundariesElement: 'window',
        // by default preventOverflow is only applied to the x-axis,
        // this applies it to the y-axis too to prevent overflow on
        // the top or bottom of the page.
        altAxis: true,
      },
    },
  ],
};

export const defaultOverlayProps = {
  animation: false,
  rootClose: true,
  popperConfig: defaultPopperConfig,
};

// There is a bug on react-bootstrap that makes the tooltip flicker when the tooltip
// position is on top of the target element. Adding the following `popperConfig` is
// a temporary fix until react-bootstrap fixes the issue.
//
// The type definitions for OverlayTriggerProps don't include popperConfig, but they
// are allowed values, see the API docs below.
//
// - https://github.com/react-bootstrap/react-bootstrap/issues/3393
// - https://react-bootstrap.github.io/components/overlays/#overlay-trigger-props
export const defaultOverlayTriggerProps: Pick<OverlayTriggerProps, 'placement'> & {
  popperConfig: object;
} = {
  placement: 'top',
  popperConfig: {
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          enabled: false,
        },
      },
      {
        name: 'hide',
        options: {
          enabled: false,
        },
      },
    ],
  },
};

/**
 * Use this for overlay triggers that show tooltips and tooltip-like components.
 */
export const defaultOverlayTriggerTooltipProps = {
  ...defaultOverlayTriggerProps,
  delay: TOOLTIP_HIDE_DELAY,
};

// useRootClose ensures that the click event doesn't close the element inside of
// which it originates. However "inside" refers to pure DOM tree structure,
// not the React component tree. So if a Dropdown contains something that is
// rendered via a portal (e.g. an Overlay), then a click inside this "something"
// will incorrectly trigger a root close, because in DOM terms, the Overlay is
// not a descendant of the Dropdown. We use this pattern a lot.
// This helper performs the "click inside container" check in a way that handles
// this situation. It assumes that anything that is outside the container
// element into which React renders our app is a dropdown-ish thing rendered via
// portal. It also assume that all such dropdowns are nested inside each other,
// and those that are further down in the DOM are those that are nested more deeply.
export function clickHappenedInsideContainer(
  clickEvent: {target: EventTarget | null},
  container: HTMLElement | null
) {
  // It could technically be a text node, but the logic will still work
  const clickTarget = clickEvent.target as HTMLElement;

  // eslint-disable-next-line fp/no-let
  let clickTargetAncestor: HTMLElement | null = clickTarget;
  while (
    clickTargetAncestor &&
    clickTargetAncestor.id !== appContainerElementId &&
    clickTargetAncestor.parentElement !== document.body
  ) {
    clickTargetAncestor = clickTargetAncestor.parentElement;
    if (clickTargetAncestor === container) {
      // the click *actually* (i.e. in DOM terms) happened inside the container
      return true;
    }
  }

  if (!clickTargetAncestor) {
    // The click target is not in the DOM anymore, which probably means that another
    // event handler already did something in response to the click. The most reasonable
    // assumption is that it was a button click in a nested popup that closed the popup.
    // Note: this also means a button click that causes the button to disappear outside
    // the popup will not trigger the popup closing.
    return true;
  }

  if (clickTargetAncestor.id === appContainerElementId) {
    // We ended up at the app root (but didn't pass the container). So either the container
    // is rendered via portal (in which case the click target can't be conceptually inside it),
    // or the container is also under the app root, but the click target isn't inside it.
    return false;
  }

  // eslint-disable-next-line fp/no-let
  let containerAncestor: HTMLElement | null = container;
  while (
    containerAncestor &&
    containerAncestor.id !== appContainerElementId &&
    containerAncestor.parentElement !== document.body
  ) {
    if (containerAncestor === clickTarget) {
      // this means the container is inside the click target, not the other way around
      return false;
    }
    containerAncestor = containerAncestor.parentElement;
  }

  if (!containerAncestor) {
    // The container isn't in the DOM anymore. This is unlikely (because if the container
    // has been unmounted, the root close functionality would've been removed), but we're
    // being defensive.
    return true;
  }

  if (containerAncestor.id === appContainerElementId) {
    // The click target is not under the app root (we would've already returned in that case),
    // but the container is. We cannot handle this case, but fortunately it's not relevant,
    // because for the question "is the click inside the dropdown", the container is the dropdown,
    // which is always rendered via portal, so we shouldn't be here. We return true, because if
    // we *are* here for whatever reason, it's better to default to *not* closing the dropdown
    // accidentally.
    return true;
  }

  if (clickTargetAncestor === containerAncestor) {
    // The ancestors are identical, i.e. the click target and the container are in the same
    // "portal tree". In that case, the click target is *conceptually* inside the container
    // if and only if it's *actually* inside the container, and if that were the case we
    // would've already left this function.
    return false;
  }

  // eslint-disable-next-line fp/no-let
  let afterContainerAncestor = containerAncestor;
  // eslint-disable-next-line fp/no-let
  let afterClickTargetAncestor = clickTargetAncestor;

  while (afterClickTargetAncestor || afterContainerAncestor) {
    afterClickTargetAncestor = afterClickTargetAncestor?.nextElementSibling as HTMLElement;
    afterContainerAncestor = afterContainerAncestor?.nextElementSibling as HTMLElement;

    if (!afterClickTargetAncestor && !afterContainerAncestor) {
      // Shouldn't happen, see comment before final return
      break;
    }

    // If `containerAncestor` is before `clickTargetAncestor`, we assume that the latter is
    // a popup inside the former, and thus the click is indeed inside the container.
    if (clickTargetAncestor === afterContainerAncestor) {
      return true;
    }

    // If `clickTargetAncestor` is before `containerAncestor`, then under our "nesting" assumption,
    // `containerAncestor` is a conceptual descendant of `clickTargetAncestor`, so if anything,
    // the container would be inside the click target, not vice versa.
    if (containerAncestor === afterClickTargetAncestor) {
      return false;
    }
  }

  // If we're here that's a bug. `clickTargetAncestor` and `containerAncestor` are necessarily
  // siblings (with the body as the parent), so one of the two `if`s in he previous `while`
  // loop must've caught.
  return true;
}

/**
 * Like react-bootstrap's `useRootClose`, but can handle our nested dropdowns that are rendered
 * to a different place in the DOM.
 */
export function useRootClosePortalAware(
  ref: React.RefObject<HTMLElement>,
  onRootClose: (e: Event) => void,
  options?: {disabled: boolean}
) {
  const onClose = useCallback(
    (event: Event) => {
      if (event.type === 'click' && clickHappenedInsideContainer(event, ref.current)) {
        return;
      }
      onRootClose(event);
    },
    [onRootClose, ref]
  );
  useRootClose(ref, onClose, options);
}
