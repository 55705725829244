import {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {
  DashboardTransferMessage,
  DashboardTransferMessageContent,
} from 'dashboard/tab-transfer/types';
import {storeAndGoToNewView} from 'redux/actions/analysis';
import {useCurrentUser} from 'redux/context/user';
import useDispatch from 'redux/useDispatch';
import {getIntelligenceManagementPageUrl} from 'toolkit/views/utils';
import * as Types from 'types';

const VALID_DASHBOARD_TRANSFER_ORIGINS = [
  /https:\/\/alloy\.test:3000/,
  /https:\/\/\w+\.alloymetrics\.com/,
  /https:\/\/app\.alloy\.ai/,
];

const isValidTransferOrigin = (origin: string) =>
  VALID_DASHBOARD_TRANSFER_ORIGINS.some(regex => regex.test(origin));

/*
  Hook for the newly created tab sets up a message listener to accept dashboard data
  from the old tab and a URL watcher to inform the old tab that it is read to accept
  data. Uses the `transferComplete` state to ensure we only get dashboard data once.

  Should only be used once in the app, probably somewhere high up the render tree
  (a component that is always on the page), currently in GlobalHotKeys
*/
export const useDashboardTransferClient = () => {
  const [transferComplete, setTransferComplete] = useState(false);
  const currentUser = useCurrentUser();
  const location = useLocation();
  const dispatch = useDispatch();

  const currentlyOnAnalysisManagementPage = useMemo(
    () => /\/analysis\/manage/.test(location.pathname),
    [location.pathname]
  );

  /*
    Informs original tab that it is done loading and is on the Dashboard management page
  */
  useEffect(() => {
    if (currentlyOnAnalysisManagementPage && window.opener && !transferComplete) {
      const message: DashboardTransferMessage = {
        content: DashboardTransferMessageContent.FINISH_LOAD,
        vendorId: currentUser.vendor.id,
      };
      window.opener.postMessage(message, '*');
    }
  }, [currentlyOnAnalysisManagementPage, currentUser.vendor, transferComplete]);

  /*
    Waits for View data from original tab
  */
  useEffect(() => {
    if (transferComplete) {
      return;
    }
    const handleMessage = (event: MessageEvent<DashboardTransferMessage>) => {
      if (!isValidTransferOrigin(event.origin)) {
        return;
      }

      const content = event.data?.content ?? '';
      const viewData = event.data?.view;

      if (
        content === DashboardTransferMessageContent.DASHBOARD &&
        viewData &&
        currentlyOnAnalysisManagementPage
      ) {
        dispatch(storeAndGoToNewView({...viewData, id: null}));
        setTransferComplete(true);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [dispatch, currentlyOnAnalysisManagementPage, transferComplete]);
};

/*
  Hook for components that want to transfer dashboard data to a new tab. Sets up
  a listener for the new tab to say it's ready to accept data, and pass the correct
  data back. Returns a callback function that can be used to kick off the process.
  Note that opening the new tab to `.../analysis/manage` URL is important as that
  is the URL the new tab will look for to say it's done loading.

  Could potentially be used for different use cases in lower level, specific
  components throughout the app.
*/
export const useDashboardTransferServer = () => {
  const [opener, setOpener] = useState<Window | null>(null);
  const [view, setView] = useState<Types.View | undefined>(undefined);
  const location = useLocation();

  /*
    Waits for new tab's FINISH_LOAD message and transfers the View to the new tab
  */
  useEffect(() => {
    if (!opener) {
      return;
    }
    const handleMessage = (event: MessageEvent<DashboardTransferMessage>) => {
      if (!isValidTransferOrigin(event.origin)) {
        return;
      }

      const content = event.data?.content ?? '';
      const currentlyOnAnalysisDashboard =
        /\/analysis\/\d+\//.test(location.pathname) ||
        /\/analysis\/new-dashboard/.test(location.pathname);

      if (content === DashboardTransferMessageContent.FINISH_LOAD && currentlyOnAnalysisDashboard) {
        const data: DashboardTransferMessage = {
          content: DashboardTransferMessageContent.DASHBOARD,
          view,
        };
        opener.postMessage(data, '*');
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [opener, location, view]);

  return useCallback(
    (vendorName: string, view: Types.View) => {
      setView(view);
      setOpener(window.open(getIntelligenceManagementPageUrl(vendorName)));
    },
    [setOpener]
  );
};
