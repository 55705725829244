import {push} from 'connected-react-router';
import React from 'react';

import useDispatch from 'redux/useDispatch';

export function useLinkClickHandler() {
  const dispatch = useDispatch();
  return (url: string, event?: React.MouseEvent<Element, MouseEvent> | MouseEvent | null) => {
    const isMetaKeyPressed = event && event.metaKey;
    if (isMetaKeyPressed) {
      window.open(url, '_blank');
    } else {
      dispatch(push(url));
    }
  };
}
