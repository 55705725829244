import './QuickFilterCell.scss';

import {GridApi} from 'ag-grid-community';
import classNames from 'classnames';
import React from 'react';

import {ComputeResultData, TypedRowNode} from 'toolkit/ag-grid/types';
import {ButtonDisplayVariant} from 'toolkit/components/Button';
import IconButton from 'toolkit/components/IconButton';
import filterIcon from 'toolkit/images/icon-filter.svg';
import * as Types from 'types';

import {AttributeTreeCellParams} from './types';

export function isSelectionAllowedForNode(node: TypedRowNode<ComputeResultData>) {
  // eslint-disable-next-line fp/no-let
  let currentNode: TypedRowNode<ComputeResultData> | null = node;
  while (currentNode) {
    const type = currentNode.data?.data?.value?.valueType;
    if (type === Types.AttributeValueType.interval || type === Types.AttributeValueType.date) {
      return false;
    }
    currentNode = currentNode.parent;
  }
  return true;
}

const QuickFilterCell: React.FC<Props> = props => {
  if (!isSelectionAllowedForNode(props.node)) {
    return <div />;
  }

  const onRowSelectionChanged = () => {
    props.onRowSelectionChanged?.(props.node);
  };

  const selected = props.node.isSelected();
  return (
    <div className="QuickFilterCell">
      {!props.node.isRowPinned() && (
        <IconButton
          className={classNames('filter-button', {selected})}
          displayVariant={selected ? ButtonDisplayVariant.PRIMARY : ButtonDisplayVariant.SECONDARY}
          icon={{alloyIcon: filterIcon}}
          onClick={onRowSelectionChanged}
        />
      )}
    </div>
  );
};
QuickFilterCell.displayName = 'QuickFilterCell';

type Props = AttributeTreeCellParams & {
  data: ComputeResultData;
  level: number;
  node: TypedRowNode<ComputeResultData>;
  value: Types.AttributeValue;
  valueFormatted: string;
  api: GridApi;
};

export default QuickFilterCell;
