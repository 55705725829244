import './TagInput.scss';

import classNames from 'classnames';
import React from 'react';

import DebounceInput, {Props as InputProps} from 'toolkit/components/DebounceInput';
import Icon from 'toolkit/components/Icon';

import TagsList from './TagsList';

const TagInput = React.forwardRef(
  (
    {
      className,
      onChange,
      onChangeTags,
      placeholder = 'Search…',
      tags,
      value,
      variant = 'light',
      ...rest
    }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const handleKeyDownCapture = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        !event.defaultPrevented &&
        event.key === 'Backspace' &&
        !event.currentTarget.selectionStart &&
        !event.currentTarget.selectionEnd &&
        tags.length
      ) {
        onChangeTags(tags.slice(0, tags.length - 1));
      }
    };
    const handleTagClick = (tag: string) => onChangeTags(tags.filter(item => item !== tag));
    const clear = () => {
      onChange('');
      onChangeTags([]);
    };

    return (
      <div className={classNames('TagInput', className, {dark: variant === 'dark'})}>
        <div className="icon">
          <Icon icon="search" />
        </div>
        <TagsList value={tags} variant={variant} onItemClick={handleTagClick} />
        <DebounceInput
          ref={ref}
          placeholder={tags.length ? undefined : placeholder}
          type="search"
          value={value}
          onChange={onChange}
          onKeyDownCapture={handleKeyDownCapture}
          {...rest}
        />
        {(`${value}`.length > 0 || tags.length > 0) && (
          <div className="clear-button" onClick={clear}>
            <Icon icon="times-circle" />
          </div>
        )}
      </div>
    );
  }
);

TagInput.displayName = 'TagInput';
type Props = InputProps & {
  className?: string;
  onChangeTags: (proposedTags: readonly string[]) => void;
  placeholder?: string;
  tags: readonly string[];
  variant?: 'light' | 'dark';
};

export default TagInput;
