import './ExpandToggle.scss';

import React from 'react';

import Icon from 'toolkit/components/Icon';

const ExpandToggle: React.FunctionComponent<Props> = ({children, expanded, onToggle}) => (
  <button className="ExpandToggle" onClick={onToggle}>
    <div className="toggle-icon">
      <Icon icon={expanded ? 'caret-down' : 'caret-right'} fixedWidth />
    </div>
    <span>{children}</span>
  </button>
);

interface Props {
  expanded: boolean;
  onToggle: () => void;
  children: React.ReactNode;
}

export default ExpandToggle;
