import {isMobile, isPhone} from 'app/browser-utils';

export function getHideOnPhonesClassName() {
  return {
    'hidden-due-to-phone': isPhone(),
  };
}

export function getShowOnPhonesClassName() {
  return {
    'hidden-due-to-non-phone': !isPhone(),
  };
}

export function getMobileClassName() {
  return {
    'displayed-on-mobile': isMobile(),
  };
}
