import * as Types from 'types';

export const GRID_LAYOUT_COLUMNS = 12;
export const GRID_LAYOUT_DEFAULT_ROWS = 8;

function canFitMoreWidgetsToScreen(view: Types.View) {
  if (!isGridLikeLayout(view.layoutType)) {
    return false;
  }

  const largestBottomY = Math.max(
    ...view.widgets.map(widget => (widget.layoutParams?.y || 0) + (widget.layoutParams?.h || 0))
  );
  return largestBottomY <= GRID_LAYOUT_DEFAULT_ROWS;
}

export function isFitToScreenEnabled(view: Types.View) {
  return canFitMoreWidgetsToScreen(view) || view.isFixedHeight;
}

export function canAddWidgetsToGrid(view: Types.View) {
  if (!isGridLikeLayout(view.layoutType)) {
    return false;
  }

  return !view.isFixedHeight || canFitMoreWidgetsToScreen(view);
}

export function isGridLikeLayout(layoutType: Types.LayoutType) {
  return layoutType === Types.LayoutType.GRID || layoutType === Types.LayoutType.LANDING_PAGE;
}
