import * as Globals from 'app/globals';

function getFaviconUrl(environment: string) {
  if (!environment || (environment === 'production' && !Globals.dev)) {
    return;
  }

  // eslint-disable-next-line fp/no-let
  let faviconSuffix = environment;
  if (Globals.dev && !Globals.usingDevApi) {
    faviconSuffix += '-dev';
  }

  return new URL(`/src/template/favicon-${faviconSuffix}.png`, import.meta.url).href;
}

export function setFavicon(environment: string) {
  const faviconUrl = getFaviconUrl(environment);
  if (!faviconUrl) {
    return;
  }

  const favicon = document.querySelector<HTMLLinkElement>("link[rel~='icon']")!;
  favicon.href = faviconUrl;
}
