import moment from 'moment-timezone';
import React, {useContext, useEffect, useState} from 'react';

import * as Api from 'api';
import {DATE_FORMAT} from 'toolkit/format/constants';
import Format, {IntervalFormatOptions} from 'toolkit/format/format';
import BatchingMemoizer from 'toolkit/utils/BatchingMemoizer';
import * as Types from 'types';

import {PeriodEvaluationContext} from './PeriodEvaluationContext';

/** A hook that returns the interval that corresponds to the given period, using the evaluation date
 * and retail calendar provided by the closest PeriodEvaluationContext.Provider (which in most places
 * is pulled from the vendor analysis settings, but modified as appropriate e.g. on dashboards).
 *
 * Will initially return null if the period's interval isn't in the cache yet. Calls to the server
 * are batched, so if multiple usePeriodInterval() calls happen in short succession (even from different
 * components), there's only one AJAX call.
 */
export function usePeriodInterval(
  period: Types.DatePeriod,
  timeAgo: Types.SimplePeriod | null = null,
  isTimeAgoCalendarPeriodAligned: boolean | null = null
): Types.LocalInterval | null {
  const {calendar, evaluationDate} = useContext(PeriodEvaluationContext);
  const [result, setResult] = useState<Types.LocalInterval | null>(null);

  useEffect(() => {
    if (!period || !evaluationDate || !calendar) {
      setResult(null);
      return undefined;
    }
    getPeriodInterval(
      period,
      evaluationDate,
      calendar,
      timeAgo,
      isTimeAgoCalendarPeriodAligned
    ).then(interval => setResult(interval));
    return () => {
      setResult(null);
    };
  }, [evaluationDate, calendar, period, timeAgo, isTimeAgoCalendarPeriodAligned]);

  return result;
}

export function getPeriodInterval(
  period: Types.DatePeriod,
  evaluationDate: moment.Moment,
  calendar: Types.RetailCalendarEnum,
  timeAgo?: Types.SimplePeriod | null,
  isTimeAgoCalendarPeriodAligned?: boolean | null
): Promise<Types.LocalInterval> {
  return intervalMemoized.get({
    period,
    evaluationDate: evaluationDate.format(DATE_FORMAT),
    calendar,
    timeAgo: timeAgo ?? null,
    isTimeAgoCalendarPeriodAligned: isTimeAgoCalendarPeriodAligned ?? null,
  });
}

export function getPeriodIntervalIfAvailable(
  period: Types.DatePeriod,
  evaluationDate: moment.Moment,
  calendar: Types.RetailCalendarEnum,
  timeAgo?: Types.SimplePeriod,
  isTimeAgoCalendarPeriodAligned?: boolean
): Types.LocalInterval | undefined {
  return intervalMemoized.getIfAvailable({
    period,
    evaluationDate: evaluationDate.format(DATE_FORMAT),
    calendar,
    timeAgo: timeAgo ?? null,
    isTimeAgoCalendarPeriodAligned: isTimeAgoCalendarPeriodAligned ?? null,
  });
}

/**
 * A helper component that just renders the textual representation of the given period's
 * interval, using the currently applicable PeriodEvaluationContext. In particular this
 * allows class components to make use of the functionality provided by the
 * usePeriodInterval hook.
 */
export const PeriodIntervalDisplayName: React.FC<PeriodIntervalDisplayNameProps> = ({
  period,
  timeAgo,
  isTimeAgoCalendarPeriodAligned,
  options = {},
}) => {
  const interval = usePeriodInterval(period, timeAgo, isTimeAgoCalendarPeriodAligned);
  const rendered = interval ? Format.interval(interval, options) : <>&nbsp;</>;
  return <>{rendered}</>;
};

interface PeriodIntervalDisplayNameProps {
  period: Types.DatePeriod;
  timeAgo?: Types.MetricArguments['timeAgo'];
  isTimeAgoCalendarPeriodAligned?: Types.MetricArguments['isTimeAgoCalendarPeriodAligned'];
  options?: IntervalFormatOptions;
}

// The getIntervals() pewter API allows getting the intervals for many periods at once, as long
// as evaluation date, retail calendar, and timeAgo are the same. That's why we're using a
// BatchingMemoizer so we don't fire a separate AJAX call for each individual period.
interface IntervalGetterParameters {
  readonly evaluationDate: string;
  readonly calendar: Types.RetailCalendarEnum;
  readonly timeAgo: Types.SimplePeriod | null;
  readonly isTimeAgoCalendarPeriodAligned: boolean | null;
  readonly period: Types.DatePeriod;
}
type IntervalGetterGroup = Omit<IntervalGetterParameters, 'period'>;
const grouper = (params: IntervalGetterParameters) => ({
  evaluationDate: params.evaluationDate,
  calendar: params.calendar,
  timeAgo: params.timeAgo,
  isTimeAgoCalendarPeriodAligned: params.isTimeAgoCalendarPeriodAligned,
});
const getter = (group: IntervalGetterGroup, params: IntervalGetterParameters[]) =>
  Api.Calendars.getIntervals({
    ...group,
    periods: params.map(p => p.period),
  });
const intervalMemoized = BatchingMemoizer(grouper, getter);
