import './NavTopBarLinks.scss';

import classNames from 'classnames';
import React, {useEffect} from 'react';

import NavTopBarItem from 'nav/NavTopBarItem';
import {fetchPolicyData} from 'redux/actions/policies';
import {useCurrentUser} from 'redux/context/user';
import useSelector from 'redux/selectors/useSelector';
import useDispatch from 'redux/useDispatch';
import {FeatureFlag} from 'toolkit/feature-flags/types';
import {isDemandPlanningApplicationEnabled} from 'toolkit/plans/utils';
import {getHideOnPhonesClassName} from 'toolkit/styles/utils';
import {
  getIntelligenceManagementPageUrl,
  getHomeUrl,
  SALES_DIAGNOSTICS_SLUG,
  WORKFLOW_SLUG,
  PROMOTIONS_SLUG,
} from 'toolkit/views/utils';
import * as Types from 'types';
import {assertTruthy} from 'utils/assert';

const NavTopBarLinks: React.FC = () => {
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  const policies: readonly Types.AttributeFilter[] = useSelector(
    state => state.policies.policiesByUser?.get(assertTruthy(state.user.user!.id)) ?? []
  );
  useEffect(() => {
    if (!policies) {
      dispatch(fetchPolicyData());
    }
  }, [dispatch, policies]);

  return (
    <>
      <NavTopBarItem title="Home" to={getHomeUrl(currentUser.vendor.name)} />
      {currentUser.featureFlags.includes(FeatureFlag.SALES_DIAGNOSTICS) && (
        <NavTopBarItem
          className="diagnostics-button"
          isActive={(match, location) =>
            !!match ||
            location.pathname.startsWith(`/${currentUser.vendor.name}/${SALES_DIAGNOSTICS_SLUG}`) ||
            location.pathname.startsWith(
              `/${currentUser.vendor.name}/${WORKFLOW_SLUG}/${SALES_DIAGNOSTICS_SLUG}`
            )
          }
          title="Diagnostics"
          to={`/${currentUser.vendor.name}/${SALES_DIAGNOSTICS_SLUG}`}
        />
      )}
      <NavTopBarItem
        isActive={(match, location) =>
          !!match ||
          location.pathname.startsWith(`/${currentUser.vendor.name}/experiments/`) ||
          location.pathname.startsWith(`/${currentUser.vendor.name}/analysis/`) ||
          location.pathname.startsWith(`/${currentUser.vendor.name}/${PROMOTIONS_SLUG}`)
        }
        title="Intelligence"
        to={getIntelligenceManagementPageUrl(currentUser.vendor.name)}
      />
      {isDemandPlanningApplicationEnabled(currentUser, policies) && (
        <NavTopBarItem
          className={classNames(getHideOnPhonesClassName())}
          isActive={(match, location) =>
            !!match || location.pathname.startsWith(`/${currentUser.vendor.name}/planning/`)
          }
          title="Planning"
          to={`/${currentUser.vendor.name}/planning/demand`}
        />
      )}
      <NavTopBarItem
        className={classNames(getHideOnPhonesClassName())}
        title="Data"
        to={`/${currentUser.vendor.name}/data`}
      />
    </>
  );
};

NavTopBarLinks.displayName = 'NavTopBarLinks';

export default NavTopBarLinks;
