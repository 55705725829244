import './GlobalSearchLink.scss';

import classNames from 'classnames';
import React from 'react';
import {NavLink} from 'react-router-dom';

import {AnalysisEntity} from 'toolkit/analysis/types';
import Icon from 'toolkit/components/Icon';
import {handleItemKeyNavigation} from 'toolkit/utils/events';

import {getEntityNameRenderer} from './AnalysisEntityNameRenderer';
import {getGlobalSearchContentElement, GLOBAL_SEARCH_ITEM_CLASSNAME} from './utils';

export default class GlobalSearchLink<T extends AnalysisEntity> extends React.Component<Props<T>> {
  render() {
    const {entity, nameRenderer, onClick, onFocus, onFocusSearchRequested} = this.props;

    return (
      <NavLink
        className={classNames('GlobalSearchLink', GLOBAL_SEARCH_ITEM_CLASSNAME)}
        to={entity.url}
        onClick={() => onClick(entity)}
        onFocus={onFocus}
        onKeyDown={event =>
          handleItemKeyNavigation(
            event,
            GLOBAL_SEARCH_ITEM_CLASSNAME,
            onFocusSearchRequested,
            getGlobalSearchContentElement
          )
        }
      >
        <Icon className="global-search-item-icon" icon={entity.icon} />
        {nameRenderer ? nameRenderer(entity) : getEntityNameRenderer(entity)}
      </NavLink>
    );
  }
}

interface Props<T> {
  entity: T;
  nameRenderer?: (entity: T) => React.ReactElement;
  onClick: (entity: T) => void;
  onFocus?: () => void;
  onFocusSearchRequested: () => void;
}
