import './PlanNavLink.scss';

import classNames from 'classnames';
import React from 'react';
import {NavLink} from 'react-router-dom';

import {useCurrentUser} from 'redux/context/user';
import {getPlanLink} from 'toolkit/views/view-urls';
import * as Types from 'types';

import NavLinkWithBreadcrumb from './NavLinkWithBreadcrumb';
import {pathToSearchStringWithGranularity} from './utils';

const PlanNavLink: React.FC<Props> = ({
  children,
  className,
  hasUnderline = false,
  path,
  target,
  type,
  onClick,
  planViewingGranularity,
  linkingEntityName,
  setBreadcrumb = true,
}) => {
  const vendorName = useCurrentUser().vendor.name;

  return setBreadcrumb ? (
    <NavLinkWithBreadcrumb
      className={classNames('PlanNavLink', className, {'with-underline': hasUnderline})}
      linkingEntityName={linkingEntityName}
      target={target}
      to={`${getPlanLink(vendorName, type)}${pathToSearchStringWithGranularity(
        path,
        planViewingGranularity
      )}`}
      onClick={onClick}
    >
      {children}
    </NavLinkWithBreadcrumb>
  ) : (
    <NavLink
      className={classNames('PlanNavLink', className, {'with-underline': hasUnderline})}
      target={target}
      to={`${getPlanLink(vendorName, type)}${pathToSearchStringWithGranularity(
        path,
        planViewingGranularity
      )}`}
      onClick={onClick}
    >
      {children}
    </NavLink>
  );
};
PlanNavLink.displayName = 'PlanNavLink';

type Props = {
  className?: string;
  children: React.ReactNode;
  hasUnderline?: boolean;
  path: readonly Types.ThinAttributeValue[];
  target?: string;
  type: Types.PlanType;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  planViewingGranularity?: Types.CalendarUnit | null;
  linkingEntityName?: string;
  setBreadcrumb?: boolean;
};

export default PlanNavLink;
