import React from 'react';

import ActionType from 'redux/actions/types';
import {IconSpec} from 'toolkit/icons/types';

export interface Alert {
  /** If set, alert closes after specified interval. Alert does not auto-close by default. */
  readonly autoCloseIntervalMsec?: number;
  readonly contentRenderer?: () => React.ReactNode;
  readonly customCloseAction?: () => void;
  readonly exception?: Error | null;
  readonly icon?: IconSpec;
  readonly id: string;
  readonly isSingleton?: boolean;
  readonly message?: string;
  readonly showReloadLink?: boolean;
  readonly showAgainDelayMsec?: number;
}

export type AlertAction =
  | {type: ActionType.AddAlert; alert: Alert}
  | {type: ActionType.ClearAlert; alertId: string};

export function addAlert(alert: Alert): AlertAction {
  return {
    alert,
    type: ActionType.AddAlert,
  };
}

export function clearAlert(alertId: string): AlertAction {
  return {
    alertId,
    type: ActionType.ClearAlert,
  };
}
