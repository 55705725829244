import React from 'react';

import {showReloadAlert} from 'app/alerts';

const LazyAgGrid = React.lazy(() =>
  import('ag-grid-react')
    .then(agGridDefaultExport => ({default: agGridDefaultExport.AgGridReact}))
    .catch(showReloadAlert)
);
export default LazyAgGrid;
