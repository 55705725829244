import './CalendarEventPopover.scss';

import React from 'react';

import {useCurrentUser} from 'redux/context/user';
import {ButtonDisplayVariant} from 'toolkit/components/Button';
import HoverPopover from 'toolkit/components/HoverPopover';
import Format from 'toolkit/format/format';
import {TextLengthFormat} from 'toolkit/format/types';
import {DisplayableEvent} from 'toolkit/time/types';
import {PromoAnalysisLinkButton} from 'workflows/promo-analysis/PromoAnalysisLinkButton';
import {isPromoAnalysisEnabled} from 'workflows/utils';

// Experimentally determined to be roughly 2 lines of text
const MAX_NOTE_DISPLAY_LENGTH = 55;

const CalendarEventPopover: React.FC<Props> = ({children, events}) => {
  const currentUser = useCurrentUser();
  return (
    <HoverPopover
      hideDelay={200}
      placement="left"
      popoverClassName="CalendarEventPopover"
      showDelay={200}
      target={children}
    >
      <div className="event-popover-content">
        {events.map(event => (
          <div key={event.key} className="event">
            <div className="header">{event.mainText}</div>
            <div>
              {event.displayInterval ??
                Format.interval(event.interval, {lengthFormat: TextLengthFormat.COMPACT})}
            </div>
            <div>{event.type}</div>
            {event.secondaryText && <div>{event.secondaryText}</div>}
            {event.liftText && <div>{event.liftText}</div>}
            {event.note && <div>{Format.ellipsize(event.note, MAX_NOTE_DISPLAY_LENGTH)}</div>}
            {event.sourceCalendarEvent?.id && isPromoAnalysisEnabled(currentUser) && (
              <PromoAnalysisLinkButton
                className="analysis-link"
                displayVariant={ButtonDisplayVariant.LINK}
                eventId={event.sourceCalendarEvent.id}
              >
                Analyze
              </PromoAnalysisLinkButton>
            )}
          </div>
        ))}
      </div>
    </HoverPopover>
  );
};
CalendarEventPopover.displayName = 'CalendarEventPopover';

interface Props {
  children: React.ReactNode;
  events: readonly DisplayableEvent[];
}

export default CalendarEventPopover;
