import Mousetrap from 'mousetrap';
import React, {useEffect} from 'react';

import {SELECT_CLASSNAME} from 'toolkit/components/Select';

import {HotKeyDefinition} from './HotKeyDefinitions';

const HotKeys: React.FC<HotKeysProps> = ({definitions}) => {
  useEffect(() => {
    const mousetrap = new Mousetrap();

    definitions.forEach(definition =>
      mousetrap.bind([...definition.keyMaps], event => {
        if (
          definition.handler &&
          (definition.isEnabledWithModals ||
            (!document.body.classList.contains('modal-open') &&
              !document.body.classList.contains('dropdown-open'))) &&
          // Selects, when focused but not open, listen for text input, but aren't an actual input element, so mousetrap
          // doesn't discard the first key press on one
          !document.activeElement?.classList.contains(SELECT_CLASSNAME)
        ) {
          event.stopPropagation();
          event.preventDefault();
          definition.handler(event);
        }
      })
    );
    return () => {
      mousetrap.reset();
    };
  }, [definitions]);

  return null;
};

interface HotKeysProps {
  definitions: readonly HotKeyDefinition[];
}

export default HotKeys;
