import './GlobalSearchResultsSection.scss';

import classNames from 'classnames';
import React from 'react';
import {NavLink} from 'react-router-dom';

const GlobalSearchResultsSection: React.FC<Props> = ({
  contentClassName,
  children,
  title,
  moreCount = 0,
  moreUrl,
  onMoreClick,
}: Props) => {
  return (
    <div className="GlobalSearchResultsSection">
      {title && <div className="global-search-dropdown-result-section-title">{title}</div>}
      <div
        className={classNames('global-search-dropdown-result-section-content', contentClassName)}
      >
        {children}
      </div>
      {moreCount > 0 && !!moreUrl && (
        <NavLink className="more-content-link" to={moreUrl} onClick={onMoreClick}>
          +{moreCount} more…
        </NavLink>
      )}
    </div>
  );
};
GlobalSearchResultsSection.displayName = 'GlobalSearchResultsSection';

interface Props {
  contentClassName: string;
  title?: string;
  children: React.ReactNode;
  moreUrl?: string;
  moreCount?: number;
  onMoreClick?: () => void;
}

export default GlobalSearchResultsSection;
