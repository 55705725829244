import moment from 'moment-timezone';

export function stringifyException(exception: Error) {
  return JSON.stringify(exception, Object.getOwnPropertyNames(exception));
}

export function isValidJson(jsonString: string | null) {
  if (jsonString === null) {
    return true;
  }

  try {
    JSON.parse(jsonString); // null is valid for this method
    return true;
  } catch (err) {
    return false;
  }
}

export function isValidJsonObject(jsonString: string | null) {
  // return true for null values
  return !jsonString || (isValidJson(jsonString) && typeof JSON.parse(jsonString) === 'object');
}

export function getJsonValue(jsonString: string | null, fieldName: string) {
  try {
    return jsonString && jsonString !== 'null' ? JSON.parse(jsonString)[fieldName] : null;
  } catch (err) {
    return null;
  }
}

export function getJsonMomentDate(
  jsonString: string | null,
  fieldName: string
): moment.Moment | null {
  const value = getJsonValue(jsonString, fieldName);
  const date = moment(value || null);
  return date.isValid() ? date : null;
}

export function setJsonFields(jsonString: string | null, fields: {[key: string]: unknown}) {
  try {
    const object = jsonString === 'null' || !jsonString ? {} : JSON.parse(jsonString);
    for (const key of Object.keys(fields)) {
      object[key] = fields[key];
    }
    return JSON.stringify(object, null, 2);
  } catch (err) {
    return jsonString; // no-op on errors
  }
}

export function removeJsonField(jsonString: string | null, field: string) {
  try {
    const object = jsonString === 'null' || !jsonString ? {} : JSON.parse(jsonString);
    if (object.hasOwnProperty(field)) {
      delete object[field];
    }
    return JSON.stringify(object, null, 2);
  } catch (err) {
    return jsonString; // no-op on errors
  }
}

export function toBase64(jsObject: object) {
  return btoa(JSON.stringify(jsObject));
}

export function fromBase64(data: string | null) {
  return data !== null ? JSON.parse(atob(data)) : null;
}
