import './Checkbox.scss';

import classNames from 'classnames';
import React from 'react';

import {IconSpec} from 'toolkit/icons/types';
import {noop} from 'utils/functions';

import Icon from './Icon';

export enum CheckboxType {
  NONE = 'none',
  RADIO = 'radio',
  CHECK = 'check',
  MULTIPLE = 'multiple',
  EXCLUDED = 'excluded',
}

export enum CheckboxDisplayVariant {
  PRIMARY = 'primary',
  FILLED = 'filled',
}

const getIconFromDisplayVariant = (type: CheckboxType): IconSpec => {
  switch (type) {
    case CheckboxType.MULTIPLE:
      return 'minus';
    case CheckboxType.EXCLUDED:
      return 'times';
    default:
      return 'check';
  }
};

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  onChange,
  className,
  disabled = false,
  displayVariant = CheckboxDisplayVariant.PRIMARY,
  label,
  role,
  style,
  tabIndex,
  type = CheckboxType.CHECK,
  ...divProps
}) => {
  const handleClick = () => {
    if (disabled) {
      return;
    }
    onChange(!checked);
  };

  const typeClassName = `type-${type}`;
  const checkboxClassName = classNames('icon-container', typeClassName);
  return (
    <div
      {...divProps}
      className={classNames('Checkbox', className, displayVariant, typeClassName, {
        checked,
        disabled,
        'icon-only': !label,
      })}
      role={role}
      style={style}
      tabIndex={tabIndex}
      onClick={handleClick}
    >
      {type === CheckboxType.NONE ? null : type === CheckboxType.RADIO ? (
        // specify a noop onChange here so that it no longer throws a console error
        // we handle the click on the parent component instead
        <input checked={checked} className={checkboxClassName} type="radio" onChange={noop} />
      ) : (
        <div className={checkboxClassName}>
          {checked ? <Icon icon={getIconFromDisplayVariant(type)} /> : null}
        </div>
      )}
      {!!label && <span className={classNames('label', typeClassName)}>{label}</span>}
    </div>
  );
};
Checkbox.displayName = 'Checkbox';

export type CheckboxProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick' | 'onChange'> & {
  checked: boolean | undefined;
  onChange: (checked: boolean) => void;
  className?: string;
  disabled?: boolean;
  displayVariant?: CheckboxDisplayVariant;
  label?: React.ReactNode;
  type?: CheckboxType;
};

export default Checkbox;
