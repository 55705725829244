import React from 'react';

import {AnalysisEntity} from 'toolkit/analysis/types';

export const getEntityNameRenderer = ({name}: AnalysisEntity) => (
  <span className="entity-name">{name}</span>
);

export const getTrySearchingEntityNameRenderer = (entity: AnalysisEntity) => (
  <>Try Searching: &ldquo;{getEntityNameRenderer(entity)}&rdquo;</>
);
