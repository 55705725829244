import {List, Map, OrderedSet} from 'immutable';

/**
 * A BatchingMemoizer wraps an AJAX call (or any asynchronous retrieval) that allows
 * requesting multiple values at once, and it provides two things:
 *
 * - it memoizes the results, so subsequent requests will resolve immediately, and
 * - it takes care of batching retrievals that can happen in one go, by debouncing calls
 *
 * On a high level, a BatchingMemoizer<K, G, V> is like a dictionary with keys K and values V
 * (except async, so it returns a Promise<V>). The third type parameter G is the grouping
 * type, where all keys that have the same group can be handled in a single retrieval.
 *
 * Say you have a server API that returns the textual representation of numbers in a given
 * language:
 *
 *     { lang: 'en', numbers: [1, 2, 3] } => ['one', 'two', 'three']
 *     { lang: 'de', numbers: [1, 2, 3] } => ['eins', 'zwei', 'drei']
 *
 * You often need to get many such values in quick succession, but the language doesn't change
 * often. You would prefer to not kick off many single-value AJAX calls.
 *
 * Your key type would be {lang, num}, and you want to be able to say
 *
 *     renderNumber({lang: 'en', num: 42}).then(alert);
 *
 * This is how you would do it:
 *
 *     // Requests that share a language can be satisfied in a single call, so the language
 *     // is the group.
 *     const grouper = ({lang, num}) => lang;
 *
 *     // The getter will be called with a single group and all pending keys that belong
 *     // to that group, so you can combine them into a single API call.
 *     const getter = (group, keys) => myApiCall({lang: group, numbers: keys.map(key => key.num)});
 *
 *     const renderNumber = BatchingMemoizer(grouper, getter);
 *
 * Key and group equality are based on immutable.is(), and since we're using a patched version
 * of immutable-js that that uses fast-deep-equal, you can use arrays and objects and it'll work
 * as expected.
 */

export default function BatchingMemoizer<K, G, V>(
  grouper: (key: K) => G,
  getter: (group: G, keys: K[]) => Promise<readonly V[]>,
  delayMS = 200
) {
  // We're keeping the memoizer's state private (instead of e.g. in Redux) since it should only
  // ever be accessed via the exposed functionality, not directly. Since the V for a given K
  // never changes, we also do not need any sort of notification mechanism.
  const state = {
    memoizedValueGroups: Map<G, Map<K, V>>(),
    promises: Map<G, Promise<void>>(),
    missingValues: Map<G, OrderedSet<K>>(),
  };

  const getImpl = (key: K): [value: V | undefined, promise: Promise<V>] => {
    const group = grouper(key);
    const memoizedValues = state.memoizedValueGroups.get(group, Map<K, V>());
    if (memoizedValues.has(key)) {
      const value = memoizedValues.get(key)!;
      return [value, Promise.resolve(value)];
    }
    const promise = getOrCreatePromise(group, key).then(
      () => state.memoizedValueGroups.get(group)!.get(key)!
    );
    return [undefined, promise];
  };

  // the following two functions are what we return
  const getIfAvailable = (key: K): V | undefined => {
    const [result] = getImpl(key);
    return result;
  };

  const get = (key: K): Promise<V> => {
    const [, promise] = getImpl(key);
    return promise;
  };

  // Enqueues the K for retrieval and returns a promise that is resolved once all currently
  // enqueued keys (in the same group) have their values available.
  const getOrCreatePromise = (group: G, key: K): Promise<void> => {
    state.missingValues = state.missingValues.set(
      group,
      state.missingValues.get(group, OrderedSet()).add(key)
    );

    if (state.promises.has(group)) {
      return state.promises.get(group)!;
    }
    const promise = new Promise<void>(resolve => {
      setTimeout(() => {
        loadMissingValues(group).then(resolve);
      }, delayMS);
    });
    state.promises = state.promises.set(group, promise);
    return promise;
  };

  const loadMissingValues = (group: G): Promise<void> => {
    const toGet = state.missingValues.get(group)!;

    // Remove the promise and empty the queue -- if new values are requested while the AJAX request
    // is pending, a new queue has to be opened. While it's possible that a new request is asking
    // for a value which we're already in the process of retrieving, this does not seem worth adding
    // special-case behavior for.
    state.missingValues = state.missingValues.remove(group);
    state.promises = state.promises.remove(group);

    // FIXME -- If the getter (which is probably an AJAX call) fails, what's the best behavior?
    // We could re-enqueue, but we should probably have a backoff logic in that case.
    return getter(group, toGet.toArray()).then(results => {
      // zip() returns two-element arrays, which the Map constructor then interprets as key/value pairs.
      const map = Map(toGet.zip(List(results)));
      state.memoizedValueGroups = state.memoizedValueGroups.set(
        group,
        state.memoizedValueGroups.get(group, Map<K, V>()).merge(map)
      );
    });
  };

  return {get, getIfAvailable};
}
