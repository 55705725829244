import {AnalysisEntity} from 'toolkit/analysis/types';
import {convertRemToPixels} from 'toolkit/utils/style';
import {getPaddingAmountForLevel} from 'widgets/tables/impl/cell-utils';

import {TypedRowNode} from './types';

/** Cell padding is $spacing-8 on each side */
const TABLE_CELL_PADDING_REM = 1.6;
/** Cell border is 1px on each side */
const TABLE_CELL_BORDER_PX = 2;
export function getTruncatedBaseCellOffsetPx(hasDefaultCellPadding: boolean) {
  const cellPaddingPx = hasDefaultCellPadding ? convertRemToPixels(TABLE_CELL_PADDING_REM) : 0;
  return TABLE_CELL_BORDER_PX + cellPaddingPx;
}

const EXPANDABLE_CARET_ICON_WIDTH_PX = 25;
export function getTruncatedAttributeCellOffsetPx(node: TypedRowNode<unknown>) {
  const treeLevelPaddingRem = getPaddingAmountForLevel(node.level);
  const expandableIconWidthPx = node.isExpandable() ? EXPANDABLE_CARET_ICON_WIDTH_PX : 0;
  return expandableIconWidthPx + convertRemToPixels(treeLevelPaddingRem);
}

const FAVORITE_BUTTON_WIDTH_PX = 37.25;
const PRIVATE_ICON_WIDTH_PX = 16.75;
export function getTruncatedEntityNameCellOffsetPx(node: TypedRowNode<AnalysisEntity>) {
  const privateIconWidthPx = node.data.isPrivate ? PRIVATE_ICON_WIDTH_PX : 0;
  return FAVORITE_BUTTON_WIDTH_PX + privateIconWidthPx;
}

const EVENT_ICON_WIDTH_PX = 38;
export function getTruncatedEventNameCellOffsetPx() {
  return EVENT_ICON_WIDTH_PX;
}

const ATTRIBUTE_MASTER_EDIT_BUTTON_WIDTH_PX = 30;
const ATTRIBUTE_MASTER_UNEDITABLE_CELL_PADDING_REM = 2.8;
export function getTruncatedAttributeMasterCellOffsetPx(
  isEditable: boolean,
  isEditingEnabled: boolean
) {
  return !isEditable || !isEditingEnabled
    ? convertRemToPixels(ATTRIBUTE_MASTER_UNEDITABLE_CELL_PADDING_REM)
    : ATTRIBUTE_MASTER_EDIT_BUTTON_WIDTH_PX;
}
