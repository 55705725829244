import {List, Map, Set} from 'immutable';

import {isFail, isLoading} from 'redux/actions/helpers';
import ActionType from 'redux/actions/types';
import {UserDataAction} from 'redux/actions/user-data';
import {FeatureFlag} from 'toolkit/feature-flags/types';
import {UsersByEmail, UsersById, UserInfosByEmail} from 'toolkit/users/types';
import * as Types from 'types';
import {assertTruthy} from 'utils/assert';
import {Status} from 'utils/status';

export interface UserDataState {
  readonly allFeaturesByUser: Map<number, Set<FeatureFlag>>;
  readonly allUsers: UsersByEmail;
  readonly allUsersById: UsersById;
  readonly allUserInfos: UserInfosByEmail;
  readonly allowedVendors: List<Types.Vendor>;
  readonly fetchingAllUserInfoStatus: Status;
  readonly fetchingAllUsersStatus: Status;
}

const initialUserDataState: UserDataState = {
  allFeaturesByUser: Map(),
  allUserInfos: Map(),
  allUsers: Map(),
  allUsersById: Map(),
  allowedVendors: List.of(),
  fetchingAllUserInfoStatus: Status.unstarted,
  fetchingAllUsersStatus: Status.unstarted,
};

export default function userData(
  state = initialUserDataState,
  action: UserDataAction
): UserDataState {
  if (action.type === ActionType.SetAllowedVendors) {
    return {...state, allowedVendors: action.vendors};
  } else if (action.type === ActionType.SetAllFeaturesByUser) {
    if (!isLoading(action) && !isFail(action)) {
      return {
        ...state,
        allFeaturesByUser: Map<FeatureFlag[]>(action.data!).mapEntries(entry => [
          parseInt(entry[0], 10),
          Set(entry[1]),
        ]),
      };
    }
  } else if (action.type === ActionType.SetAllUserData) {
    if (isLoading(action)) {
      return {...state, fetchingAllUsersStatus: Status.inProgress};
    } else if (isFail(action)) {
      return {...state, fetchingAllUsersStatus: Status.failed};
    }
    const allUsers = Map<string, Types.User>(action.data!.map(item => [item.email, item]));
    return {
      ...state,
      allUsers,
      allUsersById: Map<number, Types.User>(
        action.data!.map<[number, Types.User]>(item => [assertTruthy(item.id), item])
      ),
      fetchingAllUsersStatus: Status.succeeded,
    };
  } else if (action.type === ActionType.SetAllUserInfoData) {
    if (isLoading(action)) {
      return {...state, fetchingAllUserInfoStatus: Status.inProgress};
    } else if (isFail(action)) {
      return {...state, fetchingAllUserInfoStatus: Status.failed};
    }
    return {
      ...state,
      allUserInfos: Map<Types.UserInfo>(action.data!),
      fetchingAllUserInfoStatus: Status.succeeded,
    };
  } else if (action.type === ActionType.SetSavedUser) {
    const savedUser = action.user;
    return {...state, allUsers: state.allUsers.set(savedUser.email, savedUser)};
  } else if (action.type === ActionType.SetSavedUserInfo) {
    const savedUserInfo = action.userInfo;
    return {...state, allUserInfos: state.allUserInfos.set(action.email, savedUserInfo)};
  } else if (action.type === ActionType.SetSavedFeatureFlags) {
    if (!isLoading(action) && !isFail(action)) {
      return {...state, allFeaturesByUser: action.allFeaturesByUser.map(features => Set(features))};
    }
  } else if (action.type === ActionType.DeleteUser) {
    return {
      ...state,
      allUserInfos: state.allUserInfos.delete(action.email),
      allUsers: state.allUsers.delete(action.email),
    };
  }
  return state;
}
