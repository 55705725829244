import './TreeExpandCollapseArrow.scss';

import classNames from 'classnames';
import React from 'react';

const TreeExpandCollapseArrow: React.FunctionComponent<Props> = ({isExpanded, className}) => {
  return (
    <i
      className={classNames('TreeExpandCollapseArrow', className, {
        'chevron-down': isExpanded,
        'chevron-right': !isExpanded,
      })}
    />
  );
};
TreeExpandCollapseArrow.displayName = 'TreeExpandCollapseArrow';
interface Props {
  isExpanded: boolean;
  className?: string;
}
export default TreeExpandCollapseArrow;
