import {List} from 'immutable';
import {createSelector} from 'reselect';

import {getCurrentUserFromState} from 'redux/context/user';
import {RootState} from 'redux/reducers';
import {CurrentUser} from 'redux/reducers/user';
import {AttributesByTypeAndName, PartnersById} from 'toolkit/attributes/types';
import {MetricsByName} from 'toolkit/metrics/types';
import {isPlanningMetric} from 'toolkit/metrics/utils';
import {ViewUrlParams, BreadcrumbItem, ThinViewsById} from 'toolkit/views/types';
import {isBuiltInView, isLaunchpadView} from 'toolkit/views/utils';
import * as Types from 'types';
import {allEnumValues} from 'types/utils';
import {assertTruthy} from 'utils/assert';
import {Status, getCombinedStatus} from 'utils/status';

import {createImmutableSelector} from './selector-utils';

export const getAllMetrics = (state: RootState) => state.analysis.data.allMetrics;
const getMetricNamesWithAnyData = (state: RootState) => state.analysis.data.metricNamesWithAnyData;
export const getAllMetricsWithAnyData = createImmutableSelector(
  [getAllMetrics, getMetricNamesWithAnyData],
  (allMetrics, metricNamesWithAnyData) =>
    allMetrics.filter(metric => metricNamesWithAnyData.includes(metric.name))
);
export const getAttributesByTypeAndName = createSelector(
  [(state: RootState) => state.analysis.data.allGroupings],
  (allAttributes): AttributesByTypeAndName => {
    const grouped: Map<Types.AttributeType, Map<string, Types.Attribute>> = new Map();
    allEnumValues(Types.AttributeType).forEach(attributeType =>
      grouped.set(attributeType, new Map())
    );
    allAttributes.forEach(attribute => {
      grouped.get(attribute.type)!.set(attribute.name, attribute);
    });
    return grouped;
  }
);
const getAnalysisViews = (state: RootState) =>
  state.analysis.views.map(viewState => viewState.view);
const getThinViews = (state: RootState) => state.analysis.data.thinViews;
const getViewParams = (state: RootState) =>
  state.analysis.views.map(viewState => viewState.viewParams);
export const getPartners = (state: RootState) => state.analysis.data.partners;
export const getDoubleCountingPartners = (state: RootState) =>
  state.analysis.data.doubleCountingPartners;
const getMainView = (state: RootState) => state.analysis.views.get(0)?.view;
const getSlugs = (state: RootState) => state.analysis.views.map(viewState => viewState.view.slug!);
const fetchingAllGroupingsStatus = (state: RootState) =>
  state.analysis.data.fetchingAllGroupingsStatus;
const getFetchingMetricsStatus = (state: RootState) => state.analysis.data.fetchingMetricsStatus;
const getVendor = (state: RootState) => state.user.vendor!;

export const getSyndicatedPartners = createImmutableSelector(
  [getPartners],
  (partnersById: PartnersById) =>
    partnersById
      .valueSeq()
      .filter(partner => partner.isSyndicatedDataSource)
      .toArray()
);

export const getViewUrlParamsList = createImmutableSelector(
  [getViewParams],
  (params: List<ViewUrlParams | null>) => params
);

export const getViewSlugs = createImmutableSelector(
  [getSlugs, getMainView],
  (slugs: List<string>, mainView: Types.View | undefined) => {
    return isLaunchpadView(mainView) ? slugs.slice(1).toArray() : slugs.toArray();
  }
);

/** The main dashboard. */
export const mainView = (state: RootState) => state.analysis.views.first()!;

export const getAllMetricsWithAnyDataById = createImmutableSelector(
  [getAllMetricsWithAnyData],
  (metricsByName: MetricsByName) => metricsByName.mapEntries(entry => [entry[1].id, entry[1]])
);

export const getAllMetricsById = createImmutableSelector(
  [getAllMetrics],
  (metricsByName: MetricsByName) => metricsByName.mapEntries(entry => [entry[1].id, entry[1]])
);

function getCurrentLoggedInUser(state: RootState) {
  return getCurrentUserFromState(state.user);
}

export const getVisibleMetrics = createImmutableSelector(
  [getAllMetricsWithAnyData, getVendor, getCurrentLoggedInUser],

  (metrics: MetricsByName, vendor: Types.Vendor, currentUser: CurrentUser) =>
    metrics.filter(metric => {
      const isHiddenMetric = metric.features.includes(Types.MetricFeature.IS_HIDDEN);
      const isHiddenPlanningMetric = isPlanningMetric(metric) && !vendor.isDemandPlanningEnabled;

      const isVisibleMetric = !isHiddenMetric && !isHiddenPlanningMetric;
      return currentUser.isDevelopmentMode || isVisibleMetric;
    })
);

const getAnalysisViewStack = createImmutableSelector(
  [getAnalysisViews],
  (views: List<Types.View>) => views
);

export const getAllBreadcrumbs = createSelector([getAnalysisViewStack], (views: List<Types.View>) =>
  views
    .map<BreadcrumbItem>(view => ({slug: assertTruthy(view.slug), entityName: view.name}))
    .toArray()
);

export const getRootViewId = createSelector(
  [getAnalysisViewStack],
  (views: List<Types.View>) => views.first()?.id
);

export const getVisibleThinViews = createImmutableSelector([getThinViews], (views: ThinViewsById) =>
  views
    .filter(view => !isBuiltInView(views, view))
    .filter(view => !view.archivedAt)
    .filter(view => view.isPublished || view.type !== Types.ViewType.TEMPLATE)
);

export const getAvailableViews = createImmutableSelector(
  [getThinViews],
  (views: ThinViewsById) => views
);

export const getWidgetConfigLoadStatus = createImmutableSelector(
  [fetchingAllGroupingsStatus, getFetchingMetricsStatus],
  (fetchingAllGroupingsStatus: Status, fetchingMetricsStatus: Status) =>
    getCombinedStatus(fetchingAllGroupingsStatus, fetchingMetricsStatus)
);
