import './TransactionStatusBadge.scss';

import classNames from 'classnames';
import React from 'react';

import * as Types from 'types';

const TransactionStatusBadge: React.FC<Props> = ({status}) => (
  <div className={classNames('TransactionStatusBadge', status.toLocaleLowerCase())}>{status}</div>
);
TransactionStatusBadge.displayName = 'TransactionStatusBadge';

interface Props {
  status: Types.TransactionStatus;
}

export default TransactionStatusBadge;
