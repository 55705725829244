import './TableComponents.scss';

import {IHeaderParams} from 'ag-grid-community';
import classNames from 'classnames';
import React, {ReactElement, useRef, useState} from 'react';
import {NavLink} from 'react-router-dom';

import {CurrentUserProps} from 'redux/context/user';
import {DateSortType} from 'toolkit/analysis/types';
import CompactSelect from 'toolkit/components/CompactSelect';
import Icon from 'toolkit/components/Icon';
import ItemChooser from 'toolkit/components/ItemChooser';
import ItemChooserApplyButton from 'toolkit/components/ItemChooserApplyButton';
import ItemChooserFooter from 'toolkit/components/ItemChooserFooter';
import {Option} from 'toolkit/components/LabeledOptionsSelect';
import TagOption from 'toolkit/tags/TagOption';
import {isAtLeast} from 'toolkit/users/utils';
import {swallowEvents} from 'toolkit/utils/events';
import {clickHappenedInsideContainer} from 'toolkit/utils/react-bootstrap';
import * as Types from 'types';
import TableSortIndicator from 'widgets/tables/impl/TableSortIndicator';
import {useTableHeaderFilterActive} from 'widgets/tables/impl/useTableHeaderFilterActive';

export const TagsEditor: React.FC<TagsEditorProps> = ({
  allTags,
  currentUser,
  hideTagIcon,
  noTagsText,
  onClose,
  onConfirm,
  selectedTags,
}) => {
  const [proposedTags, setProposedTags] = useState<readonly string[]>(selectedTags || []);

  return (
    <div className="TagsEditor">
      <ItemChooser
        className="items-list"
        hideIcon={hideTagIcon}
        isSelectAllVisible={false}
        itemName="tag"
        itemRenderer={item => <TagOption tagName={item} />}
        noResultsText={noTagsText}
        possibleValues={allTags}
        selectedValues={proposedTags}
        multi
        onSelectionChanged={setProposedTags}
      />
      <ItemChooserFooter className="tags-editor-footer">
        {isAtLeast(currentUser.user.role, Types.Role.ADMIN) && (
          <NavLink className="manage-tags-link" to={`/${currentUser.vendor.name}/settings/tags`}>
            Manage
          </NavLink>
        )}
        <ItemChooserApplyButton
          className="tags-editor-apply-button"
          onClick={() => {
            onConfirm(proposedTags);
            onClose();
          }}
        >
          {allTags.length > 0 ? 'Apply' : 'Close'}
        </ItemChooserApplyButton>
      </ItemChooserFooter>
    </div>
  );
};
type TagsEditorProps = CurrentUserProps & {
  allTags: readonly string[];
  hideTagIcon?: boolean;
  noTagsText?: string | ReactElement;
  onConfirm: (proposedTags: readonly string[]) => void;
  onClose: () => void;
  selectedTags: readonly string[];
};

const BaseDashboardManagementHeaderCell: React.FC<BaseDashboardManagementHeaderCellProps> = ({
  column,
  api,
  progressSort,
  children,
  className,
  onClick = event => progressSort(event.shiftKey),
  enableMenu,
  showColumnMenu,
}) => {
  const refButton = useRef(null);
  const isFilterActive = useTableHeaderFilterActive(column);

  const onMenuClicked = (event: React.MouseEvent<HTMLDivElement>) => {
    swallowEvents(event);
    if (refButton.current) {
      showColumnMenu!(refButton.current);
    }
  };

  return (
    <div className={classNames('BaseDashboardManagementHeaderCell', className)} onClick={onClick}>
      <TableSortIndicator
        column={column}
        gridApi={api}
        underlineWhenActive={false}
        arrowOnRight
        boldWhenActive
      >
        {children}
        {enableMenu && (
          <div
            ref={refButton}
            className={classNames('menu-button', {'show-on-hover': !isFilterActive})}
            onClick={onMenuClicked}
          >
            <Icon icon={isFilterActive ? 'filter' : 'bars'} />
          </div>
        )}
      </TableSortIndicator>
    </div>
  );
};
BaseDashboardManagementHeaderCell.displayName = 'BaseDashboardManagementHeaderCell';
type BaseDashboardManagementHeaderCellProps = IHeaderParams & {
  children: React.ReactNode;
  className?: string;
  onClick?: React.MouseEventHandler;
};

const dateSortTypeOptions: Array<Option<DateSortType>> = [
  {label: DateSortType.LastOpenedByMe, value: DateSortType.LastOpenedByMe},
  {label: DateSortType.LastOpenedByAnyone, value: DateSortType.LastOpenedByAnyone},
  {label: DateSortType.LastModified, value: DateSortType.LastModified},
];

export type DateTypeChooserParams = {
  dateSortType: DateSortType;
  onDateSortTypeChanged: (sortType: DateSortType) => void;
};
type DateTypeChooserProps = IHeaderParams & DateTypeChooserParams;

export const DateTypeChooser: React.FC<DateTypeChooserProps> = props => {
  const selectContainerRef = React.createRef<HTMLDivElement>();
  const onSortRequested = (event: React.MouseEvent<HTMLElement>) => {
    if (
      selectContainerRef.current &&
      clickHappenedInsideContainer(event, selectContainerRef.current)
    ) {
      return;
    }
    props.progressSort(event.shiftKey);
  };
  const handleDateTypeChange = (selection: DateSortType | undefined) => {
    if (selection) {
      props.onDateSortTypeChanged(selection);
      props.setSort('desc', false);
    }
  };

  return (
    <BaseDashboardManagementHeaderCell
      {...props}
      className="NewDateTypeChooser"
      onClick={onSortRequested}
    >
      <div ref={selectContainerRef}>
        <CompactSelect
          className="ag-header-cell-text date-select"
          options={dateSortTypeOptions}
          searchable={false}
          selectedOption={props.dateSortType}
          onChange={handleDateTypeChange}
        />
      </div>
    </BaseDashboardManagementHeaderCell>
  );
};

export const DashboardManagementHeaderCell: React.FC<IHeaderParams> = props => (
  <BaseDashboardManagementHeaderCell {...props} className="DashboardManagementHeaderCell">
    {props.displayName}
  </BaseDashboardManagementHeaderCell>
);
