import './ConfirmDialog.scss';

import React from 'react';

import ModalDialog, {ModalDialogProps} from './ModalDialog';

const ConfirmDialog: React.FunctionComponent<ModalDialogProps> = props => {
  return (
    <ModalDialog dialogClassName="ConfirmDialog" {...props}>
      {props.children}
    </ModalDialog>
  );
};

ConfirmDialog.displayName = 'ConfirmDialog';
// eslint-disable-next-line no-restricted-syntax
export default ConfirmDialog;
