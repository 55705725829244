import {LocationChangeAction, LOCATION_CHANGE} from 'connected-react-router';
import * as History from 'history';
import {batch} from 'react-redux';
import {Middleware, MiddlewareAPI} from 'redux';

import {getNewViewsByLocation} from 'dashboard/utils';
import {AnalysisAction, SetViewsAction} from 'redux/actions/analysis';
import {setNavigationInProgress} from 'redux/actions/navigation';
import ActionType from 'redux/actions/types';
import {Dispatch, RootState} from 'redux/reducers';

const reduxNavigationMiddleware: Middleware = (api: MiddlewareAPI<Dispatch, RootState>) => {
  return (dispatch: Dispatch) => async (action: AnalysisAction | LocationChangeAction) => {
    async function dispatchSetNewViewsAction(
      action: AnalysisAction | LocationChangeAction,
      location: History.Location
    ) {
      return await batch(async () => {
        dispatch(setNavigationInProgress(true));
        dispatch(action);
        // we cannot prevent navigation, so we perform each navigation and
        // later check if the user hasn't clicked on anything else while dashboard was loading
        const previousUrl = api.getState().navigation.currentUrl;
        const newViews = await getNewViewsByLocation(api.dispatch, api.getState(), location);
        // if the user clicks another url before the dashboard loads it could end up in a mixed state without this
        if (previousUrl === api.getState().navigation.currentUrl) {
          const setNewViewsAction: SetViewsAction = {
            type: ActionType.SetViews,
            newViews,
            location,
          };

          dispatch(setNewViewsAction);
          // also guarded by above check. if there is a concurrent request it has its own setNavigationInProgress pair
          // and it should eventually resolve to false
          dispatch(setNavigationInProgress(false));
        }
      });
    }

    if (action.type === LOCATION_CHANGE) {
      return await dispatchSetNewViewsAction(action, action.payload.location);
    }
    return dispatch(action);
  };
};

export default reduxNavigationMiddleware;
