import './EntityManagementSidebar.scss';

import classNames from 'classnames';
import React, {useMemo} from 'react';

import {
  ManagementSidebar,
  ManagementSidebarItem,
  ManagementSidebarTitle,
} from 'toolkit/components/ManagementSidebar';
import {ALL_SELECTED, SelectableValues, SelectableValuesByProperty} from 'toolkit/entities/types';
import {titleCase} from 'toolkit/format/text';

import EntitiesFilter, {
  EntityFilterItemRenderProps,
  EntityProperties,
  getEntityLabel,
} from './EntitiesFilter';

function EntityManagementSidebar<T, K extends string>(
  props: Props<T, K>
): React.ReactElement<Props<T, K>> {
  function renderItem({
    property,
    valuesAndCounts,
    totalCount,
    selectedValues,
  }: EntityFilterItemRenderProps<T, K>) {
    return (
      <div className="entity-management-sidebar-section">
        {property.showTitle && valuesAndCounts.length > 0 && (
          <ManagementSidebarTitle>{titleCase(property.name)}</ManagementSidebarTitle>
        )}
        {property.allowSelectAll && (
          <ManagementSidebarItem
            className="category-item"
            count={totalCount}
            name="All"
            selected={selectedValues === ALL_SELECTED}
            onClick={() => props.onSelectionChange(property.name, ALL_SELECTED)}
          />
        )}
        {valuesAndCounts.map(({value, count}) => (
          <ManagementSidebarItem
            key={value}
            className="category-item"
            count={count}
            icon={property.getIcon?.(value) || null}
            name={getEntityLabel(property, value)}
            selected={selectedValues !== ALL_SELECTED && selectedValues.includes(value)}
            onClick={() => props.onSelectionChange(property.name, [value])}
          />
        ))}
      </div>
    );
  }

  return (
    <ManagementSidebar className={classNames('EntityManagementSidebar', props.className)}>
      <EntitiesFilter {...props} renderItem={renderItem} />
    </ManagementSidebar>
  );
}

export function useFilteredEntities<T, K extends string>(
  entityProperties: EntityProperties<T, K>,
  availableEntities: readonly T[],
  selectedValuesByProperty: SelectableValuesByProperty<string>
): readonly T[] {
  return useMemo(() => {
    return availableEntities.filter(entity => {
      return Object.keys(selectedValuesByProperty).every(propertyName => {
        const selectedValues = selectedValuesByProperty[propertyName];
        if (selectedValues === ALL_SELECTED) {
          return true;
        }
        const entityValues = entityProperties[propertyName as K].getValues(entity);
        return selectedValues.some(value => entityValues.includes(value));
      });
    });
  }, [availableEntities, entityProperties, selectedValuesByProperty]);
}

interface Props<T, K extends string> {
  className?: string;
  entityProperties: EntityProperties<T, K>;
  selectedValuesByProperty: SelectableValuesByProperty<string>;
  entities: readonly T[];
  onSelectionChange: (property: string, values: SelectableValues) => void;
}

export default EntityManagementSidebar;
